import React from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import { Add } from "@mui/icons-material";
import { Searchbar } from "../Pages/OfferingsPages/OfferingPagesTabHeader";

function AllCustomersTableHeader({
  totalClients,
  searchAction,
  handleDownloadAllCustomersReport,
}) {
  return (
    <AddFlex
      justify="space-between"
      alignItems="center"
      style={{
        borderBottom: "none",
        width: "100%",
      }}
    >
      <AddFlex flexDirection="column" style={{ gap: "4px" }}>
        <AddFlex style={{ gap: "8px" }}>
          <CustomText fontSize="18px" color="#101828" fontWeight="500">
            Clients
          </CustomText>
          <AddFlex
            padding="2px 8px"
            borderRadius="16px"
            style={{ fontSize: "12px", color: primaryActionColor }}
            backgroundColor={primaryActionColorLight}
            alignItems="center"
          >
            {totalClients} Clients
          </AddFlex>
        </AddFlex>
        <CustomText fontSize="14px" color="#667085">
          All your clients in one place!
        </CustomText>
      </AddFlex>
      <div style={{ flexGrow: "2" }}></div>
      <Searchbar showSearchIcon={true} onChange={searchAction} />
      <PrimaryActionButton
        padding="10px"
        margin="0 0 0 20px"
        onClick={handleDownloadAllCustomersReport}
      >
        <Add sx={{ fontSize: "14px" }} /> Export List
      </PrimaryActionButton>
    </AddFlex>
  );
}

export default AllCustomersTableHeader;
