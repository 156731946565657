import { Backdrop, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import GalleryComponent from "../CreateOffering/GalleryComponent";
import {
  AddFlex,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import { primaryActionColor } from "../constants";
import styled from "styled-components";

const Image = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
  background: ${(props) =>
    props.tempURL.length > 0
      ? `url(${props.tempURL}) no-repeat center center / cover`
      : "grey"};
`;

function ImageUploadComponent({ disabled, isLoading, tempURL, handleGetFile }) {
  const [openGallery, setOpenGallery] = useState(false);
  const _handleGetFile = (isMultiple, file) => {
    // const url = URL.createObjectURL(e.target.files[0]);
    handleGetFile(file[0].src);
  };
  return (
    <>
      <AddFlex alignItems="center">
        <Image tempURL={tempURL}>
          {isLoading && (
            <CircularProgress
              size={"14px"}
              color="info"
              sx={{ color: "white" }}
            />
          )}
        </Image>
        {!disabled && (
          <SecondaryActionButton
            margin="0 0 0 20px"
            border={`1px solid ${primaryActionColor}`}
            padding={"6px 8px 6px 8px"}
            borderRadius="8px"
            onClick={() => setOpenGallery(true)}
            alignItems="center"
          >
            <CustomText color="#000000AF" fontSize="12px">
              Upload Image
            </CustomText>
          </SecondaryActionButton>
        )}
      </AddFlex>
      <Backdrop
        open={openGallery}
        style={{ position: "fixed", top: 0, left: 0, zIndex: 1111111 }}
        onClick={() => {
          setOpenGallery(false);
        }}
      >
        <GalleryComponent
          isMultiple={false}
          open={openGallery}
          prevSelectedImages={null}
          closeBackdrop={() => setOpenGallery(false)}
          handleGetSelectedImages={_handleGetFile}
        />
      </Backdrop>
    </>
  );
}

export default ImageUploadComponent;
