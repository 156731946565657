import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getOfferingFromOfferingId, handleOpenInNew } from "../utils";
import {
  setCurrentLeadAction,
  setCurrentPageDataAction,
} from "../Store/leadsSlice";
import {
  AddFlex,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import { LeadActions } from "../constants";
import { format } from "date-fns";
import { Drawer } from "@mui/material";
import google from "../ImageAssets/Google_logo.png";
import phone from "../ImageAssets/call_logo.png";
import LeadActionsRightDrawer from "./Components/LeadActionsRightDrawer";

const CallCard = ({ lead }) => {
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const currentLead = useSelector((state) => state.leads.currentLead);
  const dispatch = useDispatch();
  const [_lead, setLead] = useState(null);
  const getRecentNote = () => {
    const note = _lead.timeline.find(
      (action) =>
        action.type === LeadActions.NoteAdded ||
        action.type === LeadActions.NoteUpdate
    );
    return note;
  };
  const [leadDataDialogOpen, setLeadDataDialogOpen] = useState(false);
  const handleSelectedLead = () => {
    const offering = getOfferingFromOfferingId(features, lead.pageId);
    dispatch(setCurrentPageDataAction(offering));
    dispatch(setCurrentLeadAction(_lead));
    setLeadDataDialogOpen(true);
  };
  useEffect(() => {
    setLead(currentLead);
  }, [currentLead]);
  useEffect(() => {
    setLead(lead);
  }, []);
  return (
    _lead && (
      <>
        <div
          style={{ marginBottom: "8px", borderBottom: "1px solid #ececec" }}
          onClick={handleSelectedLead}
        >
          <AddFlex width="100%" style={{ borderBottom: "1px dashed #ececec" }}>
            <AddFlex
              padding="12px 4px"
              alignItems="flex-start"
              style={{ width: "100%" }}
            >
              <img
                src={
                  _lead.scheduledCall.type === LeadActions.ScheduleGoogleMeet
                    ? google
                    : phone
                }
                style={{
                  width: "24px",
                  margin: "auto 16px auto 0",
                  height: "24px",
                }}
                alt=""
              />
              <AddFlex flexDirection="column" gap="4px">
                <CustomText color="black" fontSize="16px" fontWeight="500">
                  {_lead.customerDetails.name} @{" "}
                  {format(
                    new Date(_lead.scheduledCall.timeSlot.start),
                    "hh:mm a"
                  )}
                </CustomText>
                <CustomText fontSize="12px" color="black">
                  {getRecentNote()?.data?.text}
                </CustomText>
                <CustomText fontSize="14px" color="black">
                  {_lead.scheduledCall.meetingData?.description}
                </CustomText>
              </AddFlex>
              <div style={{ flexGrow: "1" }}></div>
              {_lead.scheduledCall.type === LeadActions.ScheduleGoogleMeet && (
                <PrimaryActionButton
                  padding="8px 12px"
                  onClick={() => {
                    handleOpenInNew(
                      _lead.scheduledCall.meetingData.hangoutLink
                    );
                  }}
                >
                  Join
                </PrimaryActionButton>
              )}
            </AddFlex>
          </AddFlex>
          <AddFlex alignItems="center" justify="space-between" padding="8px">
            <CustomText color="black" fontWeight="500" fontSize="14px">
              Mark As Completed
            </CustomText>
            <AddFlex alignItems="center" gap="10px">
              <SecondaryActionButton padding="4px 10px" borderRadius="25px">
                Yes
              </SecondaryActionButton>
              <SecondaryActionButton padding="4px 10px" borderRadius="25px">
                No
              </SecondaryActionButton>
            </AddFlex>
          </AddFlex>
        </div>
        <Drawer
          open={leadDataDialogOpen}
          anchor="right"
          onClose={() => {
            setLeadDataDialogOpen(false);
          }}
        >
          <LeadActionsRightDrawer
            onClose={() => {
              // dispatch(setCurrentLeadAction(null));
              setLeadDataDialogOpen(false);
            }}
          />
        </Drawer>
      </>
    )
  );
};

export default CallCard;
