import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import BoxWithIcons from "./InputForm";
import {
  AddFlex,
  CustomInput,
  CustomText,
  Gap,
  HalfunderlineHeader,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import { Backdrop, Checkbox, CircularProgress } from "@mui/material";
import GoogleMeetIcon from "../ImageAssets/meet_logo.png";
import PhoneCall from "../ImageAssets/call_logo.png";

import FormSidebar, { Label } from "./FormSidebar";
import SingleLineTypeDisplay, {
  InValidFormComponentState,
} from "./TypeDisplays/SingleLineTypeDisplay";
import TextAreaInputDisplay from "./TypeDisplays/TextAreaInputDisplay";
import SliderTypeDisplay from "./TypeDisplays/SliderTypeDisplay";
import DecisionBoxTypeDisplay from "./TypeDisplays/DecisionBoxTypeDisplay";
import DropdownTypeDisplay from "./TypeDisplays/DropdownTypeDisplay";
import RadioTypeDisplay from "./TypeDisplays/RadioTypeDisplay";
import CheckBoxTypeDisplay from "./TypeDisplays/CheckBoxTypeDisplay";
import DateTypeDisplay from "./TypeDisplays/DateTypeDisplay";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  generateUUID,
  getApiCallParams,
  getFrontendBaseURL,
  handleOpenInNew,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  openAdminFrontendUrl,
} from "../utils";
import { useDispatch } from "react-redux";
import { changeFieldsOfFormAction, usersApiCall } from "../Store/userSlice";
import { UserFormsCrudApi } from "../Api/ApiEndPoints";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FormIcon from "../ImageAssets/add_notes.png";
import GoogleMeetTypeDisplay from "./TypeDisplays/GoogleMeetTypeDisplay";
import PhoneCallTypeDisplay from "./TypeDisplays/PhoneCallTypeDisplay";
import CustomUrlComponent from "../CustomUrlComponent";
import {
  THUNK_API_STATES,
  headerTextColor,
  primaryActionColor,
  primaryActionColorLight,
} from "../constants";
import ButtonWithAlertDialog from "../CreateOffering/Components/ButtonWithAlertDialog";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import LinearScaleOutlinedIcon from "@mui/icons-material/LinearScaleOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import CheckBox from "@mui/icons-material/CheckBox";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {
  HeaderColor,
  SubHeaderColor,
} from "../CreateOffering/CreateOfferingMain";
import { Add, FileUploadOutlined, Image } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import ImageInFormDisplay from "./TypeDisplays/ImageInFormDisplay";
import FileUploadInFormDisplay from "./TypeDisplays/FileUploadInFormDisplay";

const IconParent = styled.div`
  visibility: hidden;
`;

const FieldContainer = styled.div`
  height: 10%;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  padding: 18px 28px;
  /* box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1); */
  flex-grow: 1;
  background-color: ${(props) => (props.isClicked ? "#f9fafb" : "white")};
  border: 1px solid #d0d5dd;
  transition: all 0.2s ease-in-out;
  border-left: ${(props) =>
    props.isClicked ? "-4px solid #0066DA" : "1px solid #d0d5dd"};
  &:hover {
    background-color: #f9fafb;
    /* border: ${(props) =>
      props.isClicked
        ? "1px dashed #0066DA"
        : "1px dashed #000000"}; // Dotted border on hover */
    border-left: 4px solid #0066da;
  }
`;

export const LineView = styled.div`
  display: flex;
  width: 200px;
  padding: 8px 0px;
  align-items: center;
  gap: 21px;
  font-weight: 300;
  font-size: 14px;
  /* margin-top: 10px; */
  color: #101828;
  border-bottom: 1px solid #d0d5dd;
`;

const MainParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const HoverContainer = styled(AddFlex)`
  &:hover ${IconParent} {
    visibility: visible;
    opacity: 1;
  }
  align-items: center;
  margin-left: -25px;
`;

const FormNameContainer = styled(AddFlex)`
  padding: 18px 28px;
  width: 75%;
  margin-left: 5px;

  cursor: pointer;
  align-items: center;
  background-color: ${(props) => (props.isSelected ? "#f5fbff" : "white")};
  border-bottom: 1px solid #ececec;
  border: ${(props) =>
    props.isSelected ? "1px solid #0066DA" : "1px solid #d0d5dd"};
  border-left: ${(props) =>
    props.isSelected ? "4px solid #0066DA" : "1px dashed d0d5dd"};
  /* box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  &:hover {
    /* border: ${(props) =>
      props.isSelected
        ? "1px dashed #0066DA"
        : "1px dashed #000000"}; // Dotted border on hover */
    /* border-left: ${(props) =>
      props.isSelected ? "4px solid #0066DA" : "1px dashed #000000"}; */
  }
`;

const FormName = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* margin-left: 10px; */
`;

const FormDescription = styled.div`
  font-size: 13px;
  color: #475467;
  /* margin-left: 40px; */
`;

const FormHeaderName = styled.div`
  color: ${headerTextColor};
  font-size: 18px;
  font-weight: 600;
  /* line-height: 18px; /* 90% */
  /* letter-spacing: 0.18px; */
  margin-left: 12px;
`;

const InputDiv = styled.div`
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  padding: 8px 10px;
  color: ${headerTextColor};
  font-size: 14px;
  font-weight: 400;
  gap: 10px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid ${primaryActionColor}; // Dotted border on hover
  }
`;

export const FormFieldTypeView = styled.div`
  color: "black";
  width: max-content;
  font-size: 12px;
  font-weight: normal;
`;

const FormFieldsParent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 75%;
`;

const LeftItemsContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const FormBuilderComponent = () => {
  let { formId } = useParams();
  const navigate = useNavigate();
  const [syncingFormToLeads, setSyncingFormsToLeads] = useState(false);
  const userName = useSelector((state) => state.user.apiUserData.userName);
  var form = useSelector((state) => state.user.apiUserData.forms[formId]);
  if (isNullOrUndefined(form)) {
    form = {
      id: formId,
      header: {
        data: {
          label: "Untitled Form",
        },
      },
      fields: {},
    };
  }
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [nameContainerClicked, setNameContainerClicked] = useState(false);
  const dispatch = useDispatch();
  const getInputTypes = () => {
    return [
      {
        type: "input",
        icon: <TextFieldsOutlinedIcon />,
        data: {
          label: "Single Line Question",
        },
      },
      {
        type: "textarea",
        icon: <NotesOutlinedIcon />,
        data: {
          label: "Multi Line Question",
        },
      },
      {
        type: "slider",
        icon: <LinearScaleOutlinedIcon />,
        data: {
          label: "Slider Question",
          min: 0,
          max: 100,
        },
      },
      {
        type: "checkbox",
        icon: <CheckBox />,
        data: {
          label: "Check box Questions",
          choices: [
            { label: "First Choice" },
            { label: "Second Choice" },
            { label: "Third Choice" },
          ],
        },
      },
      {
        type: "radio",
        icon: <RadioButtonCheckedOutlinedIcon />,
        data: {
          label: "Radio Button Questions",
          choices: [
            { label: "First Choice" },
            { label: "Second Choice" },
            { label: "Third Choice" },
          ],
        },
      },
      {
        type: "dropdown",
        icon: <ListOutlinedIcon />,
        data: {
          label: "Dropdown Questions",
          choices: [
            { label: "First Choice" },
            { label: "Second Choice" },
            { label: "Third Choice" },
          ],
        },
      },
      {
        type: "decisionbox",
        icon: <CheckBox />,
        data: {
          label: "Decision Box Questions",
        },
      },
      {
        type: "image",
        icon: <Image />,
        data: {
          readOnly: true,
          label: "Add Image",
        },
      },
      {
        type: "date",
        icon: <CalendarMonthOutlinedIcon />,
        data: {
          label: "Date Questions",
        },
      },
      {
        type: "file-upload",
        icon: <FileUploadOutlined />,
        data: {
          label: "File Upload",
        },
      },
    ];
  };

  const getCallPlatforms = () => {
    return [
      {
        type: "googlemeet",
        icon: <img src={GoogleMeetIcon} alt="growezy-google-meet" />,
        data: {
          label: "Google Meet",
          customerDetails: {
            Name: "",
            Email: "",
            PhoneNumber: "",
          },
        },
      },
      {
        type: "phonecall",
        icon: <img src={PhoneCall} alt="growezy-google-meet" />,
        data: {
          label: "Phone call",
          customerDetails: {
            Name: "",
            PhoneNumber: "",
          },
        },
      },
    ];
  };
  const inputTypes = getInputTypes();

  const getDisplayComponent = (field) => {
    switch (field.type) {
      case "input":
        return <SingleLineTypeDisplay />;
      case "textarea":
        return <TextAreaInputDisplay />;
      case "slider":
        return <SliderTypeDisplay />;
      case "checkbox":
        return <CheckBoxTypeDisplay />;
      case "radio":
        return <RadioTypeDisplay />;
      case "dropdown":
        return <DropdownTypeDisplay />;
      case "date":
        return <DateTypeDisplay />;
      case "decisionbox":
        return <DecisionBoxTypeDisplay />;
      case "googlemeet":
        return <GoogleMeetTypeDisplay data={field} />;
      case "phonecall":
        return <PhoneCallTypeDisplay />;
      case "image":
        return <ImageInFormDisplay />;
      case "file-upload":
        return <FileUploadInFormDisplay />;
    }
  };

  const getTypeString = (type) => {
    switch (type) {
      case "input":
        return "Input";
      case "textarea":
        return "Pragraph";
      case "slider":
        return "Slider";
      case "checkbox":
        return "Checkbox";
      case "radio":
        return "Radio Button";
      case "dropdown":
        return "Drop down";
      case "date":
        return "Date";
      case "decisionbox":
        return "Decision";
      case "googlemeet":
        return "Google Meet";
      case "phonecall":
        return "Phone Call";
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      Object.values(form.fields),
      result.source.index,
      result.destination.index
    );
    dispatch(changeFieldsOfFormAction({ formId: formId, fields: items }));
    //TODO handle API failed case.
    dispatch(
      usersApiCall(
        getApiCallParams(UserFormsCrudApi, {
          formId: form.id,
          fields: items,
          formUpdatetype: "replace",
        })
      )
    );
  };

  const handleCheckIfFormHasCallTypeQuestion = () => {
    return Object.values(form.fields).some(
      (field) => field.type === "googlemeet" || field.type === "phonecall"
    );
  };

  const handleAddField = async (field, index) => {
    const newField = {
      ...field,
      id: generateUUID(),
      errors: [],
      index: Object.values(form.fields ? form.fields : {}).length,
    };

    if (
      (field.type === "googlemeet" || field.type === "phonecall") &&
      handleCheckIfFormHasCallTypeQuestion()
    ) {
      return {
        message: "failed",
        error: "You can add only one call type question.",
      };
      // return enqueueSnackbar("You can add only one call type question.", {
      //   variant: "error",
      // });
    }

    setSelectedField(newField);
    setNameContainerClicked(false);
    delete newField.icon;
    return dispatch(
      usersApiCall(
        getApiCallParams(UserFormsCrudApi, {
          formId: form.id,
          formField: newField,
          fieldUpdatetype: "create",
          formUpdatetype: "update",
        })
      )
    )
      .then((actionResult) => {
        if (actionResult.meta.requestStatus === "fulfilled") {
          // Handle success
          return { message: "success", error: null };
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        return { message: "failed", error: "Something went wrong" };
      });
  };

  const handleDeleteField = (field, index) => {
    const obj = Object.values(form.fields).find(
      (_field) => _field.id === field.id
    );
    dispatch(
      usersApiCall(
        getApiCallParams(UserFormsCrudApi, {
          formId: form.id,
          formField: obj,
          fieldUpdatetype: "delete",
          formUpdatetype: "update",
        })
      )
    );
  };

  const handleSyncToLeads = (checked) => {
    setSyncingFormsToLeads(true);
    const obj = {
      ...form.header,
      data: {
        ...form.header.data,
        isSyncedToLeads: checked,
      },
    };
    dispatch(
      usersApiCall(
        getApiCallParams(UserFormsCrudApi, {
          formId: form.id,
          formField: obj,
          fieldUpdatetype: "update",
          formUpdatetype: "update",
        })
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        enqueueSnackbar(
          checked
            ? "Form synced to leads"
            : "Form is no longer synced to leads",
          {
            variant: "success",
          }
        );
      } else if (
        actionResult.meta.requestStatus === THUNK_API_STATES.rejected
      ) {
        enqueueSnackbar("something went wrong.", {
          variant: "error",
        });
      }
      setSyncingFormsToLeads(false);
    });
  };

  const handleFieldClick = (event, field) => {
    event.stopPropagation();
    setDrawerOpen(true);
    setNameContainerClicked(false);
  };

  const onActionClicked = (field, level, index) => {
    switch (level) {
      case 0:
        setDrawerOpen(true);
        break;
      case 1:
        handleAddField(field, index);
        break;
      case 2:
        handleDeleteField(field, index);
        break;
    }
  };

  const renderFormField = (field, index) => {
    return (
      <Draggable key={field.id} draggableId={field.id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <HoverContainer>
              <DragIndicatorIcon sx={{ color: "#D0D5DD" }} />
              <FieldContainer
                isClicked={
                  isNullOrUndefined(selectedField)
                    ? false
                    : selectedField.id === field.id
                }
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedField(field);
                  // if (field.type === "googlemeet" || field.type === "phonecall")
                  //   return;
                  handleFieldClick(event, field);
                }}
              >
                <AddFlex flexDirection="column" width="100%">
                  <AddFlex
                    flexDirection="column"
                    style={{
                      justifyContent: "space-between",
                      // paddingLeft: "14px",
                      // margin: "10px",
                      flexShrink: 0,
                      marginBottom: "0px",
                      // color: primaryActionColor,
                    }}
                  >
                    {!isArrayNullOrUndefined(field.errors) && (
                      <InValidFormComponentState
                        errors={field.errors.join(",")}
                      />
                    )}
                    {field.type !== "googlemeet" &&
                      field.type !== "phonecall" && (
                        <Label
                          dangerouslySetInnerHTML={{ __html: field.data.label }}
                        ></Label>
                      )}
                    {/* <FormFieldTypeView>
                      {getTypeString(field.type)}
                    </FormFieldTypeView> */}
                  </AddFlex>
                  <div>
                    {field.type !== "header" &&
                      React.cloneElement(getDisplayComponent(field), {
                        data: field.data,
                      })}
                  </div>
                </AddFlex>
              </FieldContainer>
              <IconParent>
                <BoxWithIcons
                  onActionClicked={(level) =>
                    onActionClicked(field, level, index)
                  }
                />
              </IconParent>
            </HoverContainer>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <>
      <Backdrop
        open={drawerOpen}
        onClick={() => setDrawerOpen(false)}
        style={{ zIndex: 2500 }}
      />
      <MainParent>
        <AddFlex
          alignItems="center"
          padding="20px"
          style={{
            borderBottom: "1px solid #EAECF0",
            position: "sticky",
            zIndex: 2000,
          }}
        >
          <img src={FormIcon} style={{ width: "28px", height: "28px" }} />
          <FormHeaderName
            dangerouslySetInnerHTML={{ __html: form.header.data.label }}
          />
          <Gap />
          {/* <DeleteIcon sx={{ color: "#E94235", gap: "10px" }} /> */}
          <ButtonWithAlertDialog
            component={
              <SecondaryActionButton style={{ border: "none" }} margin="0 10px">
                Delete
              </SecondaryActionButton>
            }
            title="Are you sure you want to delete?"
            description="This form cannot be retrevied again."
            buttons={[
              {
                title: "Agree",
                action: async () => {
                  dispatch(
                    usersApiCall(
                      getApiCallParams(UserFormsCrudApi, {
                        formId: form.id,
                        formUpdatetype: "delete",
                      })
                    )
                  ).then((actionResult) => {
                    if (actionResult.meta.requestStatus === "fulfilled") {
                      navigate(-1);
                    }
                  });
                },
              },
            ]}
          />

          <PrimaryActionButton
            margin="0 10px"
            padding="8px 40px"
            onClick={() => {
              handleOpenInNew(
                `${getFrontendBaseURL(userName)}form/${form.urlKey}`
              );
              // openAdminFrontendUrl(`/form/preview/${formId}/preview`);
            }}
          >
            Preview
          </PrimaryActionButton>
        </AddFlex>
        <div
          style={{
            flex: 1,
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "23vw",
              padding: "10px 25px",
              borderRight: "1px solid #e0e0e0",
              overflow: "scroll",
            }}
          >
            <HalfunderlineHeader
              margin={"10px 0 20px 0"}
              title={"FORM ELEMENTS"}
              fontSize={"14px"}
              lineHeight={"3px"}
              lineWidth={"28px"}
            />
            <LeftItemsContainer>
              {inputTypes.map((item, index) => (
                <InputFieldType
                  handleAddField={handleAddField}
                  index={index}
                  item={item}
                />
              ))}
              {!form.isIntake && (
                <>
                  <CustomText color="red" fontSize="14px" fontWeight="500">
                    Add any one
                  </CustomText>
                  {getCallPlatforms().map((item, index) => (
                    <InputFieldType
                      handleAddField={handleAddField}
                      index={index}
                      item={item}
                    />
                  ))}
                </>
              )}
            </LeftItemsContainer>
          </div>
          <div
            style={{
              width: "70vw",
              overflowY: "auto",
              height: "100%",
              paddingTop: "30px",
              paddingLeft: "5vw",
              paddingBottom: "200px",
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <AddFlex
                style={{
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                {!form.isIntake && (
                  <AddFlex
                    margin="25px 0 0 0"
                    gap="12px"
                    alignItems="flex-start"
                  >
                    {syncingFormToLeads ? (
                      <CircularProgress
                        size={14}
                        sx={{ color: primaryActionColor }}
                      />
                    ) : (
                      <Checkbox
                        sx={{ padding: 0, margin: "0px 0 0 0" }}
                        checked={form.header.data.isSyncedToLeads}
                        onChange={(e) => {
                          handleSyncToLeads(e.target.checked);
                        }}
                      />
                    )}
                    <AddFlex
                      // margin={isMobileView() ? "0 0 0 4px" : "0 0 0 10px"}
                      flexDirection="column"
                      gap="8px"
                    >
                      <CustomText
                        fontSize="16px"
                        fontWeight="500"
                        color={HeaderColor}
                      >
                        Sync this form to leads
                      </CustomText>
                      <CustomText
                        fontSize="14px"
                        fontWeight="200"
                        color={SubHeaderColor}
                        style={{ width: "60%" }}
                      >
                        When the form is synced to leads, all the responses will
                        be added to your "Leads" section by default.
                      </CustomText>
                    </AddFlex>
                  </AddFlex>
                )}
                <CustomUrlComponent
                  margin={"0 0 0 5px"}
                  name={form.header.data.label}
                  endpoint={UserFormsCrudApi}
                  payloadData={{
                    formId: form.id,
                    updateKey: "urlKey",
                    urlKey: "formUrlKey",
                    fieldUpdatetype: "update",
                    formUpdatetype: "update",
                  }}
                  footerText={"You can customise this link"}
                  width={"75%"}
                  urlKey={form.urlKey}
                  throwError={() => {}}
                  leftText={getFrontendBaseURL(userName) + "form/"}
                />
                <FormNameContainer
                  isSelected={nameContainerClicked}
                  onClick={() => {
                    setSelectedField({
                      type: "header",
                      data: {
                        id: "header",
                        label: form.header.data.label,
                        instructions: form.header.data.instructions,
                      },
                    });
                    setDrawerOpen(true);
                    setNameContainerClicked(true);
                  }}
                >
                  <AddFlex gap="5px" flexDirection="column">
                    <CustomText
                      fontSize="12px"
                      margin="0 0 12px 0"
                      fontWeight="400"
                      color="black"
                      style={{ letterSpacing: "0.18px" }}
                    >
                      FORM TITLE
                    </CustomText>
                    <Label
                      style={{ margin: 0, width: "100%" }}
                      dangerouslySetInnerHTML={{
                        __html: form.header.data.label,
                      }}
                    ></Label>
                    <FormDescription
                      style={{ margin: "2px 0 0 0" }}
                      dangerouslySetInnerHTML={{
                        __html: form.header.data.instructions,
                      }}
                    ></FormDescription>
                  </AddFlex>
                </FormNameContainer>
                {/* <div
                  style={{
                    color: "#000",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "18px" ,
                    letterSpacing: "-0.4px",
                  }}
                >
                  Form Questions
                </div> */}
                <Droppable droppableId="formFields">
                  {(provided) => (
                    <FormFieldsParent
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {form?.fields &&
                        Object.values(form.fields).map(renderFormField)}
                      {provided.placeholder}
                    </FormFieldsParent>
                  )}
                </Droppable>
              </AddFlex>
            </DragDropContext>
          </div>
        </div>
      </MainParent>
      {drawerOpen && (selectedField || nameContainerClicked) && (
        <FormSidebar
          drawerOpen={drawerOpen}
          form={form}
          formField={selectedField}
          setDrawerOpen={setDrawerOpen}
          setSelectedField={setSelectedField}
          setNameContainerClicked={setNameContainerClicked}
        ></FormSidebar>
      )}
    </>
  );
};

// Utility function to reorder the list as a result of drag and drop
const reorder = (fields, startIndex, endIndex) => {
  // Remove the item from its original position
  const removed = fields.splice(startIndex, 1)[0];
  // Insert the item at its new position
  fields.splice(endIndex, 0, removed);

  // Update the index property of each item to match its new position
  const updatedList = fields.map((item, index) => ({
    ...item,
    index: index, // Assign the new index
  }));
  return updatedList;
};

export default FormBuilderComponent;

const InputFieldType = ({ item, index, handleAddField }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    try {
      setLoading(true);
      const { error } = await handleAddField(item, index);
      if (!isNullOrUndefined(error)) {
        return enqueueSnackbar(error, {
          variant: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("something went wrong", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <InputDiv onClick={handleClick}>
      {item.icon && (
        <AddFlex
          alignItems="center"
          justifyContent="center"
          padding="3px"
          backgroundColor={primaryActionColorLight}
          borderRadius="2px"
        >
          {React.cloneElement(item.icon, {
            style: {
              color: primaryActionColor,
              width: "18px",
              height: "18px",
            },
          })}
        </AddFlex>
      )}
      {item.data.label}
      <Gap />
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        <Add sx={{ color: "#808080" }} />
      )}
    </InputDiv>
  );
};
