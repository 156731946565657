import React, { useState } from "react";
import { BlockPicker, SketchPicker } from "react-color";
import {
  AddFlex,
  CustomInput,
  CustomText,
  DialogHeader,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import useDialogActionsHook from "../CustomHooks.js/useDialogActionsHook";
import { Dialog, Divider, Drawer, Radio } from "@mui/material";
import styled from "styled-components";
import DarkMode from "../ImageAssets/darkMode.png";
import LightMode from "../ImageAssets/lightMode.png";
import { primaryActionColor, primaryActionColorLight } from "../constants";

const Button = styled.button`
  padding: 10px;
  outline: none;
  border: 1px solid grey;
  background-color: white;
  width: max-content;
  border-radius: 25px;
  cursor: pointer;
`;

const ColorCustomisations = ({ slectedThemeColors, margin, onSubmit }) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogActionsHook();
  const [themeColors, setThemeColors] = useState(
    slectedThemeColors || {
      mode: "dark",
      buttonColors: "#EAE1C9",
      buttonTextColors: "black",
      accentColors: "#EAE1C9",
    }
  );
  const handleModeChange = (mode) => {
    console.log(mode);
    const defaultThemeColors =
      mode === "light"
        ? {
            mode: "light",
            buttonColors: "#000000",
            buttonTextColors: "#ffffff",
            accentColors: "#8C5301",
          }
        : {
            mode: "dark",
            buttonColors: "#EAE1C9",
            buttonTextColors: "#000000",
            accentColors: "#EAE1C9",
          };
    setThemeColors(defaultThemeColors);
  };
  return (
    <AddFlex margin={margin} padding="10px">
      <AddFlex
        flexDirection="column"
        backgroundColor={primaryActionColorLight}
        padding="20px"
        gap="10px"
        borderRadius="8px"
        width="100%"
        style={{ boxShadow: "0 0 8px 2px rgba(0,0,0,0.1)" }}
      >
        <AddFlex gap="10px" alignItems="center">
          <CustomText color="black" fontSize="16px" fontWeight="500">
            Customise the look and feel
          </CustomText>
          <AddFlex
            padding="5px 20px"
            backgroundColor={primaryActionColor}
            width="max-content"
            borderRadius="25px"
          >
            <CustomText fontSize="14px" color="white">
              Beta
            </CustomText>
          </AddFlex>
        </AddFlex>

        <CustomText fontSize="14px">
          Pick colors that reflect your brand identity and resonate with your
          audience. Customize your brand's look with a simple selection.
        </CustomText>
        <Button onClick={openDialog}>Select Brand Colors</Button>
      </AddFlex>
      <Drawer
        open={isDialogOpen}
        anchor="right"
        PaperProps={{
          sx: {
            width: "40vw",
            // height: "80vh",
          },
        }}
      >
        <DialogHeader
          mainText={"Select Brand Color"}
          subText={""}
          padding={"10px"}
          onClose={() => {
            closeDialog();
          }}
        />
        <AddFlex
          margin="26px 0 0 0"
          padding="10px 20px"
          gap="20px"
          backgroundColor="white"
        >
          <ModeCard
            imgSrc={DarkMode}
            title={"Dark Mode"}
            isChecked={themeColors.mode === "dark"}
            onChange={() => {
              handleModeChange("dark");
            }}
          />
          <ModeCard
            imgSrc={LightMode}
            title={"Light Mode"}
            isChecked={themeColors.mode === "light"}
            onChange={() => {
              handleModeChange("light");
            }}
          />
        </AddFlex>
        <Divider sx={{ margin: "26px 0" }} />
        <AddFlex
          gap="26px"
          padding="10px 20px"
          grow={1}
          style={{ overflow: "scroll" }}
          flexDirection="column"
        >
          {themeColors.mode === "light" && (
            <>
              <ColorPickerInput
                colorValue={themeColors?.buttonColors}
                label={"Select Button Colors"}
                handleChangeColor={(color) => {
                  setThemeColors((prev) => ({ ...prev, buttonColors: color }));
                }}
              />
              <ColorPickerInput
                colorValue={themeColors?.buttonTextColors}
                label={"Select Button Text Colors"}
                handleChangeColor={(color) => {
                  setThemeColors((prev) => ({
                    ...prev,
                    buttonTextColors: color,
                  }));
                }}
              />
              <ColorPickerInput
                colorValue={themeColors?.accentColors}
                label={"Select Accent Colors"}
                handleChangeColor={(color) => {
                  setThemeColors((prev) => ({ ...prev, accentColors: color }));
                }}
              />
            </>
          )}
        </AddFlex>
        <AddFlex padding="10px" style={{ borderTop: "1px solid #ececec" }}>
          <PrimaryActionButton
            margin="0 auto"
            onClick={() => {
              onSubmit(themeColors);
              closeDialog();
            }}
          >
            Done
          </PrimaryActionButton>
        </AddFlex>
      </Drawer>
    </AddFlex>
  );
};

function ColorPickerInput({ label, colorValue, margin, handleChangeColor }) {
  const [color, setColor] = useState("#000000");
  const [showColorPicker, setShowColorPicker] = useState();
  return (
    <div style={{ margin: margin, position: "relative" }}>
      <CustomInput
        label={label}
        value={colorValue}
        leftWidget={
          <div
            style={{
              backgroundColor: colorValue,
              padding: "10px",
              border: "1px solid #ececec",
              borderRadius: "3px",
            }}
          ></div>
        }
        onClick={() => {
          setShowColorPicker(true);
        }}
      />
      {showColorPicker && (
        <div
          style={{
            position: "absolute",
            top: "120%",
            padding: "10px",
            backgroundColor: "white",
            zIndex: 11111111,
          }}
        >
          <SketchPicker
            onChange={(value) => {
              setColor(value.hex);
            }}
            color={color}
            presetColors={[]}
            styles={{
              default: {
                picker: {
                  boxShadow: "none",
                  border: "1px solid #ececec",
                },
                sliders: {
                  display: "none",
                },
                color: {
                  display: "none",
                },
              },
            }}
          />
          <AddFlex gap="5px" margin="10px 0 0 0">
            <SecondaryActionButton
              onClick={() => {
                setShowColorPicker(false);
              }}
              style={{ width: "50%" }}
            >
              Cancel
            </SecondaryActionButton>
            <PrimaryActionButton
              onClick={() => {
                handleChangeColor(color);
                setShowColorPicker(false);
              }}
              style={{ flexGrow: 1 }}
            >
              Done
            </PrimaryActionButton>
          </AddFlex>
        </div>
      )}
    </div>
  );
}

const ModeCard = ({ imgSrc, title, isChecked, onChange }) => {
  return (
    <AddFlex
      flexDirection="column"
      width="40%"
      borderRadius="8px"
      border="1px solid #ececec"
      style={{ boxShadow: "0 0 8px 4px rgba(0,0,0,0.05)", cursor: "pointer" }}
      onClick={onChange}
    >
      <AddFlex
        borderRadius="8px 8px 0 0"
        padding="10px"
        alignItems="center"
        gap="10px"
      >
        <Radio sx={{ padding: 0 }} checked={isChecked} size="small" />
        <CustomText color="black" fontWeight="500">
          {title}
        </CustomText>
      </AddFlex>
      <img
        src={imgSrc}
        style={{
          width: "100%",
          objectFit: "cover",
          borderRadius: "0 0 8px 8px",
        }}
      />
    </AddFlex>
  );
};

export default ColorCustomisations;
