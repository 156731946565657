import { AddCircleOutline, Cancel } from "@mui/icons-material";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { AddFlex, CustomText } from "../../reusableStyles";
import styled from "styled-components";
import { primaryActionColor } from "../../constants";

const DayContainer = styled.div`
  display: flex;
  align-items: ${({ isAvailable }) => (isAvailable ? "flex-start" : "center")};
  /* margin-bottom: 10px; */
  padding: 10px 20px;
  border-bottom: ${(props) => !props.isLast && "1px solid #f2f6f9"};
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

// const Checkbox = styled.input`
//   margin-right: 10px;
// `;

const TimeSelect = styled(Select)`
  width: 100px;
  height: 38px;
  margin: 0 5px;
`;

const Container = styled(AddFlex)`
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const TimeSlot = ({
  day,
  handleToggleAvailablity,
  onChange,
  handleAddInterval,
  handleApplyToAll,
  handleRemoveInterval,
  isLast,
  isFirst,
  times,
}) => {
  return (
    <DayContainer isAvailable={times.available} isLast={isLast}>
      <Container width="50%" alignItems="center">
        <Checkbox
          type="checkbox"
          checked={times.available}
          sx={{
            color: primaryActionColor,
            "&.Mui-checked": {
              color: primaryActionColor,
            },
          }}
          onChange={(e) =>
            handleToggleAvailablity(day.toLowerCase(), e.target.checked)
          }
        />
        <CustomText color="black">{day}</CustomText>
      </Container>
      <Container flexDirection="column">
        {times.available ? (
          times.intervals?.map((time, intervalIndex) => (
            <AddFlex alignItems="center" margin="0 0 10px 0">
              <TimeSelect
                value={time.start}
                onChange={(e) =>
                  onChange(
                    day.toLowerCase(),
                    intervalIndex,
                    "start",
                    e.target.value
                  )
                }
              >
                {/* Generate time slots */}
                {Array.from({ length: 24 * 4 }, (_, index) => {
                  const hour = Math.floor(index / 4);
                  const minutes = (index % 4) * 15;
                  const time = `${hour.toString().padStart(2, "0")}:${minutes
                    .toString()
                    .padStart(2, "0")}`;
                  return (
                    <MenuItem key={time} value={time}>
                      <CustomText color="black">{time}</CustomText>
                    </MenuItem>
                  );
                })}
              </TimeSelect>
              <span style={{ margin: "0 15px" }}>-</span>
              <TimeSelect
                value={time.end}
                onChange={(e) =>
                  onChange(
                    day.toLowerCase(),
                    intervalIndex,
                    "end",
                    e.target.value
                  )
                }
              >
                {/* Generate time slots */}
                {Array.from({ length: 24 * 4 }, (_, index) => {
                  const hour = Math.floor(index / 4);
                  const minutes = (index % 4) * 15;
                  const time = `${hour.toString().padStart(2, "0")}:${minutes
                    .toString()
                    .padStart(2, "0")}`;
                  return (
                    <MenuItem key={time} value={time}>
                      <CustomText color="black">{time}</CustomText>
                    </MenuItem>
                  );
                })}
              </TimeSelect>

              {intervalIndex === 0 ? (
                <AddCircleOutline
                  onClick={() => handleAddInterval(day.toLowerCase())}
                  sx={{ fontSize: "16px", margin: "0 0 0 10px" }}
                />
              ) : (
                <Cancel
                  onClick={() =>
                    handleRemoveInterval(day.toLowerCase(), intervalIndex)
                  }
                  sx={{ fontSize: "16px", margin: "0 0 0 10px" }}
                />
              )}
            </AddFlex>
          ))
        ) : (
          <AddFlex alignItems="center" gap="10px">
            <CustomText fontWeight="300">Unavailable</CustomText>
            {isFirst && (
              <CustomText
                margin="0 0 0 auto"
                fontSize="12px"
                fontWeight="700"
                color={primaryActionColor}
                onClick={() => handleApplyToAll(times)}
              >
                Apply to all
              </CustomText>
            )}
          </AddFlex>
        )}
        {times.available && isFirst && (
          <CustomText
            margin="0 0 0 auto"
            fontSize="12px"
            fontWeight="700"
            color={primaryActionColor}
            onClick={() => handleApplyToAll(times)}
          >
            Apply to all
          </CustomText>
        )}
      </Container>
    </DayContainer>
  );
};

export default TimeSlot;
