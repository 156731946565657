import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomText,
  Gap,
  HeaderWithLeftBorder,
} from "../../reusableStyles";
import SectionHeader from "./SectionHeader";
import DropdownComponent from "../../DropdownComponent";
import {
  Currency,
  Status,
  getFeatureIdFromName,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { IOSSwitch } from "../../CustomSwitch";
import SuccessChip from "../../Integrations/SuccessChip";
import { DeleteOutline } from "@mui/icons-material";
import { UpdateFeature } from "../../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { userFeatureApi } from "../../Store/UserFeaturesSlice";
import { useDispatch } from "react-redux";

function CurrencySettingsForOffering({
  offering,
  pageType,
  isTravel,
  handleSetOffering,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPrimaryCurrency, setSelectedPrimaryCurrency] = useState("");
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [internationalCurrencies, setInternationalCurrencies] = useState([]);
  const dispatch = useDispatch();

  const handleGetNewScheduleDetails = (scheduleDetails, getTicketFunction) => {
    return scheduleDetails.map((schedule) => {
      return isTravel
        ? {
            ...schedule,
            tickets: schedule.tickets?.map((ticket) => {
              return getTicketFunction(ticket);
            }),
          }
        : {
            ...schedule,
            timeSlots: schedule.timeSlots?.map((timeSlot) => {
              return {
                ...timeSlot,
                tickets: timeSlot.tickets?.map((ticket) => {
                  return getTicketFunction(ticket);
                }),
              };
            }),
          };
    });
  };

  const handleDeleteInternationalPrice = (currency) => {
    const scheduleDetails = [...offering.scheduleDetails];

    // this function returns the updated ticket object
    const getTicketFunction = (ticket) => {
      if (ticket.isFree) return ticket;
      const prices = { ...ticket.prices };
      delete prices[currency];
      return {
        ...ticket,
        prices: prices,
      };
    };

    const newScheduleDetails = handleGetNewScheduleDetails(
      scheduleDetails,
      getTicketFunction
    );
    handleSetOffering("scheduleDetails", newScheduleDetails);
  };

  const handleAddInternationalCurrency = (currency) => {
    const scheduleDetails = [...offering.scheduleDetails];

    // this function returns the updated ticket object
    const getTicketFunction = (ticket) => {
      if (ticket.isFree) return ticket;
      const prices = { ...ticket.prices, [currency]: 0 };
      return {
        ...ticket,
        prices: prices,
      };
    };

    const newScheduleDetails = handleGetNewScheduleDetails(
      scheduleDetails,
      getTicketFunction
    );
    handleSetOffering("scheduleDetails", newScheduleDetails);
  };

  const handleRemoveInternationalCurrenciesFromPricing = () => {
    const scheduleDetails = [...offering.scheduleDetails];
    const internationalCurrencies = offering.internationalCurrencies;
    if (isArrayNullOrUndefined(internationalCurrencies)) return;

    // this function returns the updated ticket object
    const getTicketFunction = (ticket) => {
      if (ticket.isFree) return ticket;
      const prices = { ...ticket.prices };
      internationalCurrencies.forEach((currency) => delete prices[currency]);
      return {
        ...ticket,
        prices: prices,
      };
    };

    const newScheduleDetails = handleGetNewScheduleDetails(
      scheduleDetails,
      getTicketFunction
    );
    handleSetOffering("scheduleDetails", newScheduleDetails);
  };

  const handleChangePrimaryCurrencyInPricing = (currency) => {
    const scheduleDetails = [...offering.scheduleDetails];
    const primaryCurrency = offering.primaryCurrency;
    let internationalCurrencies = offering.internationalCurrencies;

    //this function returns the ticket

    const getTicketFunction = (ticket) => {
      if (ticket.isFree) return ticket;
      const prices = { ...ticket.prices, [currency]: 0 };
      //if primary currency is USD, and then changed to some other, with initernational pricing enabled, then the USD be added to the prices
      if (
        !isNullOrUndefined(internationalCurrencies) &&
        !Object.keys(prices).includes(Currency.USD)
      ) {
        prices[Currency.USD] = 0;
      }

      //if primary currency is USD, and then changed to some other, with initernational pricing enabled, then the USD should not be deleted
      if (
        isNullOrUndefined(internationalCurrencies) ||
        (!isArrayNullOrUndefined(internationalCurrencies) &&
          primaryCurrency !== Currency.USD)
      ) {
        delete prices[primaryCurrency];
      }
      return {
        ...ticket,
        prices: prices,
      };
    };
    const newScheduleDetails = handleGetNewScheduleDetails(
      scheduleDetails,
      getTicketFunction
    );

    // if primary currency is USD and international payments is enabled.

    /**
     * 1. Add usd to international payments.
     */
    if (!isNullOrUndefined(internationalCurrencies)) {
      if (primaryCurrency === Currency.USD) {
        if (internationalCurrencies.length === 0) {
          internationalCurrencies = [Currency.USD];
        } else {
          internationalCurrencies = [Currency.USD, ...internationalCurrencies];
        }
      }
      internationalCurrencies = internationalCurrencies.filter(
        (_curr) => _curr !== currency
      );
      handleSetOffering("internationalCurrencies", internationalCurrencies);
    }
    handleSetOffering("scheduleDetails", newScheduleDetails);
  };

  // const handleCurrencyAlertSuccess = (currency) => {
  //   setLoading(true);
  //   const newOffering = {
  //     ...offering,
  //     status: Status.UNPUBLISHED,
  //     primaryCurrency: currency,
  //   };
  //   dispatch(
  //     userFeatureApi({
  //       payload: {
  //         action: "update",
  //         itemData: newOffering,
  //         featureId: getFeatureIdFromName(pageType),
  //       },
  //       endpoint: UpdateFeature,
  //     })
  //   ).then((actionResult) => {
  //     if (actionResult.meta.requestStatus === "fulfilled") {
  //       enqueueSnackbar("This offering is now unpublished", {
  //         anchorOrigin: {
  //           vertical: "bottom",
  //           horizontal: "center",
  //         },
  //       });
  //     } else if (actionResult.meta.requestStatus === "rejected") {
  //       enqueueSnackbar("Something went wrong", {
  //         variant: "error",
  //         anchorOrigin: {
  //           vertical: "bottom",
  //           horizontal: "center",
  //         },
  //       });
  //       setLoading(false);
  //     }
  //   });
  // };

  useEffect(() => {
    setOptions(Object.keys(Currency));
    setInternationalCurrencies(
      Object.keys(Currency).filter(
        (currency) =>
          currency !== Currency.USD && currency !== offering.primaryCurrency
      )
    );
  }, [offering]);
  return (
    <>
      <div
        style={{
          padding: "0 8px",
          borderLeft: "4px solid #8C5301",
          margin: "0px 0 24px 0",
        }}
      >
        <CustomText color="black" fontWeight="600">
          {"Customise Currency"}
        </CustomText>
      </div>
      <AddFlex gap="32px" flexDirection="column" style={{ width: "80%" }}>
        <AddFlex alignItems="center" justifyContent="space-between">
          <SectionHeader
            headerText={"Primary Currency"}
            subHeaderText={
              "This will be the default currency for this offering"
            }
            maxSubTextWidth={"100%"}
          />
          <Gap />
          <Select
            onChange={(e) => {
              // if (offering.status === Status.PUBLISHED) {
              //   setShowAlertDialog(true);
              //   setSelectedPrimaryCurrency(e.target.value);
              //   return;
              // }
              if (!isNullOrUndefined(offering.primaryCurrency)) {
                handleChangePrimaryCurrencyInPricing(e.target.value);
              }
              handleSetOffering("primaryCurrency", e.target.value);
            }}
            size="small"
            sx={{ width: "25%" }}
            value={offering.primaryCurrency}
          >
            {options.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
        </AddFlex>
        <AddFlex alignItems="flex-start">
          <SectionHeader
            headerText={"Accept other currencies."}
            subHeaderText={
              <AddFlex gap="10px" flexDirection="column">
                <CustomText>
                  This enables you to add dynamic country wise pricing.
                </CustomText>
                <AddFlex gap="10px" wrap="wrap">
                  {offering.internationalCurrencies?.map((currency, index) => (
                    <SuccessChip
                      successChipText={currency}
                      borderRadius={"4px"}
                      showIcon={currency !== Currency.USD}
                      setIsConfirmActionDialogOpen={() => {}}
                      icon={
                        <DeleteOutline
                          onClick={() => {
                            handleDeleteInternationalPrice(currency);
                            handleSetOffering(
                              `internationalCurrencies.delete.${index}`,
                              currency
                            );
                          }}
                          sx={{ color: "green", fontSize: "16px" }}
                        />
                      }
                    />
                  ))}
                </AddFlex>
              </AddFlex>
            }
            maxSubTextWidth={"100%"}
          />
          <Gap />
          <AddFlex
            gap="10px"
            alignItems="flex-end"
            flexDirection="column"
            width="25%"
          >
            <IOSSwitch
              checked={offering.internationalCurrencies}
              onChange={(e) => {
                if (isNullOrUndefined(offering.internationalCurrencies)) {
                  if (offering.primaryCurrency === Currency.USD) {
                    handleSetOffering("internationalCurrencies", []);
                  } else {
                    handleAddInternationalCurrency(Currency.USD);
                    handleSetOffering("internationalCurrencies", [
                      Currency.USD,
                    ]);
                  }
                } else {
                  handleRemoveInternationalCurrenciesFromPricing();
                  handleSetOffering("internationalCurrencies", null);
                }
              }}
            />
            {offering.internationalCurrencies && (
              <Select
                onChange={(e) => {
                  if (
                    offering.internationalCurrencies?.includes(e.target.value)
                  )
                    return;
                  handleAddInternationalCurrency(e.target.value);
                  handleSetOffering(
                    "internationalCurrencies.push",
                    e.target.value
                  );
                }}
                size="small"
                // placeholder="Add Currency"
                // defaultValue="Add Currency"
                value={"Add Currency"}
                sx={{ width: "100%" }}
              >
                <MenuItem value="Add Currency">Add Currency</MenuItem>
                {internationalCurrencies.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            )}
          </AddFlex>
        </AddFlex>
      </AddFlex>
      {/* <Dialog
        open={showAlertDialog}
        PaperProps={{
          sx: {
            width: "30vw",
          },
        }}
        onClose={() => {
          setShowAlertDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are You Sure ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This offering will be UNPUBLISHED
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowAlertDialog(false);
            }}
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleCurrencyAlertSuccess(selectedPrimaryCurrency);
            }}
          >
            {"Proceed"}
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}

export default CurrencySettingsForOffering;
