import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { Checkbox } from "@mui/material";
import { headerTextColor } from "../constants";

function OnboardingIconMainTextSubTextCard({
  mainText,
  isSelected,
  subText,
  image,
  onChange,
}) {
  return (
    <AddFlex
      gap="20px"
      alignItems="center"
      padding="20px"
      borderRadius="4px"
      width="100%"
      style={{
        boxShadow: "0 0 8px 0.5px rgba(0,0,0,0.20)",
        transition: "none",
      }}
    >
      <Checkbox checked={isSelected} sx={{ padding: 0 }} onChange={onChange} />
      <img
        style={{ width: "36px", height: "36px" }}
        src={image}
        alt="growezy-img"
      />
      <AddFlex gap="4px" flexDirection="column">
        <CustomText color="black" fontSize="16px" fontWeight="500">
          {mainText}
        </CustomText>
        <CustomText fontSize="14px" color={headerTextColor}>
          {subText}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
}

export default OnboardingIconMainTextSubTextCard;
