import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomInputArea,
  CustomText,
  Group,
  PrimaryActionButton,
} from "../reusableStyles";
import PhotoUpload from "../Customization/PhotoUpload";
import ImageHolderComponent from "../CreateOffering/ImageHolder";
import {
  FeaturesKeys,
  generateUUID,
  getOfferings,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import Tags from "./Tags";
import { useDispatch } from "react-redux";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { UpdateFeature } from "../Api/ApiEndPoints";
import { Close } from "@mui/icons-material";
import {
  THUNK_API_STATES,
  TestimonialTabs,
  inputHeight,
  primaryTextColor,
} from "../constants";
import { CircularProgress, MenuItem, Rating, Select } from "@mui/material";
import useWindowSize from "../Hooks/useWindowSize";
import { useSelector } from "react-redux";
import TestimonialPhotoUpload from "../Customization/TestimonialPhotoUpload";
import TextEditor from "../CreateOffering/TextEditor";

function TestimonialForm({ closeDrawer, testimonial }) {
  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [offerings, setOfferings] = useState([]);
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const [testimonialData, setTestimonialData] = useState();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const handleChangeTestimonialData = (key, value) => {
    setTestimonialData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const getImageFile = (isMultiple, files) => {
    if (files) {
      setFiles(files);
      handleChangeTestimonialData("imageGallery", files);
    }
  };

  const addTestimonial = (e) => {
    e.preventDefault();
    setIsApiLoading(true);
    dispatch(
      userFeatureApi({
        payload: {
          action: testimonial ? "update" : "addnew",
          itemData: testimonialData,
          featureId: FeaturesKeys.testimonial.id,
        },
        endpoint: UpdateFeature,
      })
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        setIsApiLoading(false);
        closeDrawer();
      }
    });
  };

  useEffect(() => {
    if (testimonial) {
      setTestimonialData(testimonial);
      if (!isArrayNullOrUndefined(testimonial.imageGallery))
        setFiles(testimonial.imageGallery);
    } else {
      setTestimonialData({
        id: generateUUID(),
        source: TestimonialTabs.SELF,
        date: new Date(),
      });
    }
    const offerings = getOfferings(features);
    setOfferings(offerings);
    setLoading(false);
  }, []);

  return (
    testimonialData && (
      <>
        <AddFlex
          position="fixed"
          top="0px"
          right="0"
          alignItems="center"
          justify="space-between"
          width={isMobileView() ? "100%" : "35vw"}
          padding="5px "
          backgroundColor="white"
          style={{ zIndex: "1111", boxShadow: "0 0 0px 2px rgba(0,0,0,0.05)" }}
        >
          <CustomText
            style={{ flexShrink: "0" }}
            color={primaryTextColor}
            margin="10px"
            fontSize="16px"
          >
            {isNullOrUndefined(testimonial)
              ? "Add Testimonial"
              : "Update Testimonial"}
          </CustomText>
          <Close onClick={closeDrawer} />
        </AddFlex>
        <form
          style={{
            marginTop: "30px",
            padding: "30px",
            position: "relative",
            paddingBottom: "80px",
          }}
          onSubmit={addTestimonial}
        >
          <CustomInput
            placeholder={"Eg: Jhon Doe"}
            label={"Name"}
            style={{ margin: "0px 0 15px 0" }}
            value={testimonialData.name}
            onChange={(text) => handleChangeTestimonialData("name", text)}
          />
          <CustomInput
            placeholder={"Eg: Jhon Doe"}
            label={"Occupation"}
            style={{ margin: "0px 0 15px 0" }}
            value={testimonialData.occupation}
            onChange={(text) => handleChangeTestimonialData("occupation", text)}
          />
          <CustomInput
            placeholder={"Eg: Jhon Doe"}
            label={"Workplace"}
            style={{ margin: "0px 0 15px 0" }}
            value={testimonialData.workplace}
            onChange={(text) => handleChangeTestimonialData("workplace", text)}
          />

          <label for="feeback">Feedback</label>
          <TextEditor
            value={testimonialData.msg}
            getTextEditorInput={(text) =>
              handleChangeTestimonialData("msg", text)
            }
          />

          <AddFlex flexDirection="column">
            <label for="rating">Rating</label>
            <Rating
              size="small"
              id="rating"
              value={testimonialData.rating}
              precision={0.5}
              onChange={(event, newValue) => {
                handleChangeTestimonialData("rating", newValue);
              }}
              sx={{ margin: "8px 0 15px 0" }}
            />
          </AddFlex>
          <Tags
            selectedTags={testimonialData.tags}
            onChange={(tags) => {
              handleChangeTestimonialData("tags", tags);
            }}
          />
          <AddFlex flexDirection="column" marginBottom="20px  ">
            <label>Service/Offering</label>
            <Select
              value={testimonialData.offering?.id}
              onChange={(e) => {
                const offering = offerings.find(
                  (offering) => offering.id === e.target.value
                );
                handleChangeTestimonialData("offering", offering);
              }}
              sx={{ marginTop: "8px", height: inputHeight }}
            >
              {offerings.map((offering) => (
                <MenuItem value={offering.id}>
                  <CustomText color="black">{offering.name}</CustomText>
                </MenuItem>
              ))}
            </Select>
          </AddFlex>
          <div>
            <label>Upload Photo</label>
            <TestimonialPhotoUpload
              margin={"8px 0 15px 0"}
              photoURL={testimonialData.photoUrl}
              handleGetFile={(photo) =>
                handleChangeTestimonialData("photoUrl", photo)
              }
            />
          </div>
          <ImageHolderComponent
            isMultiple={true}
            showHeader={false}
            headerTitleText={"Drop a pic"}
            headerSubTitleText={"Let your photos tell the story"}
            handleGetFiles={getImageFile}
            files={files}
            handleRemoveFile={(isMultiple, index) => {
              setFiles((prev) => prev.filter((_, _index) => _index !== index));
            }}
          />

          <AddFlex
            position="fixed"
            bottom="0px"
            right="0"
            width={isMobileView() ? "100%" : "35vw"}
            padding="10px 30px"
            backgroundColor="white"
            style={{
              zIndex: "1111",
              boxShadow: "0 0 0px 4px rgba(0,0,0,0.05)",
            }}
          >
            <PrimaryActionButton margin="0 auto" type="submit">
              {isApiLoading ? (
                <CircularProgress size={14} sx={{ color: "white" }} />
              ) : isNullOrUndefined(testimonial) ? (
                "Add Testimonial"
              ) : (
                "Update Testimonial"
              )}
            </PrimaryActionButton>
          </AddFlex>
        </form>
      </>
    )
  );
}

export default TestimonialForm;
