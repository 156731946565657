import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import {
  AddFlex,
  CustomButton,
  CustomInput,
  CustomInputArea,
  CustomSimpleInput,
  InputLabel,
  PrimaryActionButton,
  TextAreaType,
} from "../reusableStyles";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DropdownTypeProperties from "./FontTypeProperties/DropdownTypeProperties";
import DecisionBoxTypeProperties from "./FontTypeProperties/DecisionBoxTypeProperties";
import CheckBoxTypeProperties from "./FontTypeProperties/CheckBoxTypeProperties";
import RadioTypeProperties from "./FontTypeProperties/RadioTypeProperties";
import SingleLineTypeProperties from "./FontTypeProperties/SingleLineTypeProperties";
import TextAreaInputProperties from "./FontTypeProperties/TextAreaInputProperties";
import SliderTypeProperties from "./FontTypeProperties/SliderTypeProperties";
import { useDispatch } from "react-redux";
import {
  getApiCallParams,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  isStringNullOrUndefined,
} from "../utils";
import { usersApiCall } from "../Store/userSlice";
import { UserFormsCrudApi } from "../Api/ApiEndPoints";
import { Checkbox, Drawer, FormControlLabel, FormLabel } from "@mui/material";
import GoogleMeetTypeProperties from "./FontTypeProperties/GoogleMeetTypeProperties";
import PhoneCallTypeProperties from "./FontTypeProperties/PhoneCallTypeProperties";
import FormHeaderProperties from "./FontTypeProperties/FormHeaderProperties";
import { primaryActionColor, primaryTextColor } from "../constants";
import ImageTypeProperties from "./FontTypeProperties/ImageTypeProperties";
import FileTypeUploadProperties from "./FontTypeProperties/FileTypeUploadProperties";

export const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  margin-bottom: 5px;
  width: 90%;
  color: black;
`;
export const Instructions = styled.div`
  font-size: 14px;
  color: #101828;
  width: 90%;
  margin-top: 8px;
  margin-bottom: 18px;
  line-height: 1.3;
  font-weight: 300;
`;

const PropertiesHeader = styled(AddFlex)`
  background-color: white;
  justify-content: space-between;
  align-items: center;
  color: black;
  border-bottom: 1px solid #d0d5dd;
  padding: 10px 20px;
  /* margin-bottom: 20px; */
`;

const PropertiesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

function FormSidebar({
  drawerOpen,
  form,
  setDrawerOpen,
  setSelectedField,
  formField,
  setNameContainerClicked,
}) {
  const [data, setData] = useState(formField.data);
  const dispatch = useDispatch();
  const getPropertyComponent = (type) => {
    switch (type) {
      case "input":
        return <SingleLineTypeProperties />;
      case "textarea":
        return <TextAreaInputProperties />;
      case "slider":
        return <SliderTypeProperties />;
      case "checkbox":
        return <CheckBoxTypeProperties />;
      case "radio":
        return <RadioTypeProperties />;
      case "dropdown":
        return <DropdownTypeProperties />;
      case "date":
        return null;
      case "decisionbox":
        return <DecisionBoxTypeProperties />;
      case "googlemeet":
        return <GoogleMeetTypeProperties />;
      case "phonecall":
        return <PhoneCallTypeProperties />;
      case "image":
        return <ImageTypeProperties />;
      case "file-upload":
        return <FileTypeUploadProperties />;
    }
  };

  const getErrorsForField = (type) => {
    const arr = [];
    if (data.label.length === 0) {
      arr.push("Name is empty");
    }

    switch (type) {
      case "input":
        // validation for single input
        break;
      case "textarea":
        // validation for single input
        break;
      case "slider":
        // validation for slider input
        const min = Number(data.min);
        const max = Number(data.max);
        if (
          isStringNullOrUndefined(data.min) ||
          isStringNullOrUndefined(data.max) ||
          min >= max
        ) {
          arr.push("Min and max values are not correct");
        }
        break;
      case "checkbox":
        // validation for checkbox input
        var filtered = data.choices.filter((choice) =>
          isStringNullOrUndefined(choice.label)
        );
        console.log(filtered, type);
        if (!isArrayNullOrUndefined(filtered)) {
          arr.push("Some choices are empty");
        }
        break;
      case "radio":
        // validation for radio input
        var filtered = data.choices.filter((choice) =>
          isStringNullOrUndefined(choice.label)
        );
        if (!isArrayNullOrUndefined(filtered)) {
          arr.push("Some choices are empty");
        }
        break;
      case "dropdown":
        // validation for dropdown input
        var filtered = data.choices.filter((choice) =>
          isStringNullOrUndefined(choice.label)
        );
        if (!isArrayNullOrUndefined(filtered)) {
          arr.push("Some choices are empty");
        }
        break;
      case "date":
        // validation for date input
        break;
      case "decisionbox":
        // validation for decision box input
        break;
      case "googlemeet":
      // validation for googlemeet input
      case "phonecall":
        if (
          isNullOrUndefined(data.duration) ||
          (Number(data.duration) <= 0 && data.label.length !== 0)
        ) {
          arr.push("Duration is not set");
        }
    }
    return arr;
  };
  return (
    <Drawer
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false);
        setSelectedField(null);
        setNameContainerClicked(false);
      }}
      anchor="right"
      PaperProps={{
        sx: {
          width: "500px",
        },
      }}
    >
      <PropertiesHeader>
        <div style={{ fontSize: "18px" }}>Properties</div>
        <IconButton
          onClick={() => {
            setDrawerOpen(false);
            setSelectedField(null);
            setNameContainerClicked(false);
          }}
        >
          <ClearOutlinedIcon />
        </IconButton>
      </PropertiesHeader>
      <PropertiesContent style={{ overflow: "scroll", paddingBottom: "80px" }}>
        <TextAreaType
          label={"Field Label"}
          height={50}
          value={data.label}
          onChange={(text) => {
            setData({ ...data, label: text });
          }}
          margin={"0 0 20px 0"}
        />
        {!formField.data.readOnly && (
          <>
            <TextAreaType
              label={"Instructions"}
              value={data.instructions}
              height={150}
              style={{ marginTop: "12px", marginBottom: "20px" }}
              onChange={(text) => {
                setData({
                  ...data,
                  instructions: text,
                });
              }}
            />
          </>
        )}
        {!isNullOrUndefined(getPropertyComponent(formField.type)) &&
          React.cloneElement(getPropertyComponent(formField.type), {
            data: data,
            setData: setData,
          })}
        {formField.type !== "googlemeet" &&
          formField.type !== "phonecall" &&
          formField.type !== "header" &&
          !formField.data.readOnly && (
            <AddFlex flexDirection="column">
              <FormControlLabel
                sx={{ marginBottom: "20px" }}
                control={
                  <Checkbox
                    sx={{ padding: 0, margin: "0 7px" }}
                    checked={data.mandatory}
                    onChange={(e) =>
                      setData({ ...data, mandatory: e.target.checked })
                    }
                  />
                }
                label="Is Required *"
              />
            </AddFlex>
          )}
      </PropertiesContent>

      <AddFlex
        justify="center"
        backgroundColor="white"
        style={{
          position: "absolute",
          padding: "15px",
          bottom: 0,
          width: "100%",
          height: "max-content",
          borderTop: "1px solid #d0d5dd",
        }}
      >
        <PrimaryActionButton
          color={primaryActionColor}
          buttonType="solid"
          style={{ width: "40%" }}
          onClick={() => {
            dispatch(
              usersApiCall(
                getApiCallParams(UserFormsCrudApi, {
                  formId: form.id,
                  formField: {
                    ...formField,
                    errors: getErrorsForField(formField.type),
                    data: data,
                  },
                  fieldUpdatetype: "update",
                  formUpdatetype: "update",
                })
              )
            );
            setDrawerOpen(false);
          }}
        >
          Save
        </PrimaryActionButton>
      </AddFlex>
    </Drawer>
  );
}

export default FormSidebar;
