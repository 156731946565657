import React from "react";
import TestimonialCard from "./TestimonialCard";
import Masonry from "react-masonry-css";
import { AddFlex } from "../reusableStyles";
import useWindowSize from "../Hooks/useWindowSize";

function TestimonialsHolder({
  testimonials,
  isOfferingDialog,
  selectedTestimonials,
  onTestimonialClicked,
  isDarkView,
}) {
  const { isMobileView } = useWindowSize();
  const getTextimonials = () => {
    return testimonials?.map((testimonial) => (
      <div>
        <TestimonialCard
          testimonialData={testimonial}
          isOfferingDialog={isOfferingDialog}
          isSelected={selectedTestimonials?.includes(testimonial.id)}
          getTestimonialId={onTestimonialClicked}
        />
      </div>
    ));
  };
  const breakpointColumnsObj = {
    default: 3,
    1200: 2,
    1000: 3,
    950: 2,
    600: 1,
  };
  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {getTextimonials()}
      </Masonry>
      {isMobileView() && <AddFlex padding="0 0 30vh 0"></AddFlex>}
    </>
  );
}

export default TestimonialsHolder;
