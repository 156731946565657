import {
  AddFlex,
  CustomText,
  SecondaryActionButton,
} from "../../reusableStyles";
import { Currency, formatPrice, isNullOrUndefined } from "../../utils";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import styled from "styled-components";

const StyledFeaturesList = styled(AddFlex)`
  ul {
    padding: 0 0 0 20px;
  }
`;

function SubscriptionPlanCard({ plan, onPlanSelected, isMonthly }) {
  const { name, subText, isFree, prices, featureText } = plan;
  return (
    <AddFlex
      width="30%"
      flexDirection="column"
      borderRadius="12px"
      style={{ boxShadow: "0px 0px 12px 1px rgba(0, 0, 0, 0.1)" }}
    >
      <AddFlex
        flexDirection="column"
        gap="6px"
        backgroundColor={primaryActionColorLight}
        padding="20px"
        borderRadius="12px 12px 0 0"
      >
        <CustomText fontSize="24px" color={primaryActionColor} fontWeight="600">
          {name}
        </CustomText>
        <CustomText
          style={{ letterSpacing: "0.12px" }}
          fontSize="12px"
          color={primaryActionColor}
          fontWeight="400"
        >
          {subText}
        </CustomText>
        <AddFlex margin="15px 0 0 0" flexDirection="column">
          {!isNullOrUndefined(prices) && !isFree ? (
            <CustomText fontSize="12px" color={primaryActionColor}>
              Starting at{" "}
              <span style={{ textDecoration: "line-through" }}>
                {formatPrice(
                  prices[isMonthly ? "monthly" : "yearly"][Currency.INR][
                    "strike"
                  ],
                  [Currency.INR]
                )}
              </span>
            </CustomText>
          ) : (
            <CustomText fontSize="12px" color={"transparent"}>
              Starting
            </CustomText>
          )}
          <CustomText
            fontWeight="600"
            color={primaryActionColor}
            fontSize="22px"
          >
            {isFree
              ? "FREE"
              : formatPrice(
                  prices[isMonthly ? "monthly" : "yearly"][Currency.INR][
                    "main"
                  ],
                  [Currency.INR]
                )}
          </CustomText>
        </AddFlex>
      </AddFlex>
      <StyledFeaturesList
        grow="1"
        dangerouslySetInnerHTML={{
          __html: featureText,
        }}
        padding="20px"
        flexDirection="column"
      ></StyledFeaturesList>
      <SecondaryActionButton margin="20px" onClick={onPlanSelected}>
        Choose {name}
      </SecondaryActionButton>
    </AddFlex>
  );
}

export default SubscriptionPlanCard;
