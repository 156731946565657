import { Dialog } from "@mui/material";
import { THUNK_API_STATES } from "../constants";
import {
  AddFlex,
  CustomInput,
  CustomText,
  PrimaryActionButton,
} from "../reusableStyles";
import { useState } from "react";
import { Add, Close } from "@mui/icons-material";
import {
  generateUUID,
  getApiCallParams,
  isArrayNullOrUndefined,
} from "../utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { UpdateTestimonialTags } from "../Api/ApiEndPoints";
import TagsSelect from "./TagsSelect";

function Tags({ selectedTags, onChange }) {
  const [newTag, setNewTag] = useState({ id: generateUUID() });
  const [isAddTagClicked, setIsAddTagClicked] = useState(false);
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const [openAddTagDialog, setOpenAddTagDialog] = useState(false);
  const dispatch = useDispatch();

  const addTestimonialTag = () => {
    dispatch(
      userFeatureApi(
        getApiCallParams(UpdateTestimonialTags, {
          tag: newTag,
        })
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        const tags = isArrayNullOrUndefined(selectedTags)
          ? [newTag]
          : [...selectedTags, newTag];
        onChange(tags);
        setOpenAddTagDialog(false);
      }
    });
  };

  return (
    <>
      <CustomText margin="0 0 10px 0" color="black">
        Tags
      </CustomText>
      <AddFlex
        padding="8px 12px"
        border="1px solid #EAECF0"
        borderRadius="8px"
        alignItems="center"
        style={{ gap: "9px" }}
        margin="0 0 20px 0"
      >
        <AddFlex wrap="wrap" grow="1" style={{ gap: "5px" }}>
          {isArrayNullOrUndefined(selectedTags) ? (
            <CustomText>Add Tags</CustomText>
          ) : (
            selectedTags.map((selectedTag) => (
              <AddFlex
                padding="4px 6px"
                gap="10px"
                backgroundColor="#F8EED4"
                key={selectedTag.id}
                borderRadius="4px"
              >
                <CustomText color="black">{selectedTag.name}</CustomText>
              </AddFlex>
            ))
          )}
        </AddFlex>
        {!isAddTagClicked ? (
          <Add
            onClick={() => setIsAddTagClicked((prev) => !prev)}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <Close
            onClick={() => setIsAddTagClicked((prev) => !prev)}
            style={{ cursor: "pointer" }}
          />
        )}
      </AddFlex>
      {isAddTagClicked && (
        <TagsSelect
          selectedTags={selectedTags}
          setOpenAddTagDialog={setOpenAddTagDialog}
          onChange={(tags) => {
            onChange(tags);
          }}
        />
      )}
      <Dialog
        onClose={() => setOpenAddTagDialog((prev) => !prev)}
        open={openAddTagDialog}
        fullWidth
      >
        <AddFlex padding="20px" flexDirection="column">
          <CustomInput
            label={"Add Tag Name"}
            placeholder={"Eg: Yoga batch client"}
            onChange={(text) =>
              setNewTag((prev) => {
                return { ...prev, name: text };
              })
            }
          />
          <PrimaryActionButton
            onClick={addTestimonialTag}
            margin="15px 0 0 0"
            style={{ width: "30%" }}
          >
            Add Tag
          </PrimaryActionButton>
        </AddFlex>
        <Close
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            fontSize: "16px",
            cursor: "pointer",
          }}
          onClick={() => setOpenAddTagDialog((prev) => !prev)}
        />
      </Dialog>
    </>
  );
}

export default Tags;
