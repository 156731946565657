import React, { useState } from "react";
import { AddFlex } from "../reusableStyles";
import Tabs from "../Tabs";
import useWindowSize from "../Hooks/useWindowSize";
import Policy from "./Components/Policy";
import { useDispatch } from "react-redux";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { UpdateFeature } from "../Api/ApiEndPoints";
import { FeaturesKeys } from "../utils";
import { useSearchParams } from "react-router-dom";
import { BoxedTabs } from "./OfferingsPages/OfferingPagesTabHeader";

function PageSettings() {
  const { windowSize } = useWindowSize();
  const [searchParams] = useSearchParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();

  const handleSubmit = (type, text) => {
    const offering = {};
    dispatch(
      userFeatureApi({
        payload: {
          action: "update",
          itemData: offering,
          featureId:
            FeaturesKeys[searchParams.get("pageType").toLowerCase()].id,
        },
        endpoint: UpdateFeature,
      })
    );
  };
  const getTermsAndConditions = () => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <>
            <Policy
              headerTitle={"Terms And Conditions"}
              headerDescription={
                "Your customers will have to agree to these terms and conditions before they purchase"
              }
              handleSubmit={(text) => {
                handleSubmit("tnc", text);
              }}
            />
          </>
        );
      case 1:
        return (
          <Policy
            headerTitle={"Return Policy"}
            headerDescription={
              "Your customers will have to agree to this return policy before they purchase"
            }
            handleSubmit={(text) => {
              handleSubmit("return", text);
            }}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <AddFlex flexDirection="column" padding={0}>
      <BoxedTabs
        tabs={["Terms and conditions", "Return Policy"]}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={(index) => setSelectedTabIndex(index)}
      />
      <AddFlex
        flexDirection="column"
        margin="30px 0"
        width={windowSize.screenWidth < 1000 ? "100%" : "70%"}
      >
        {getTermsAndConditions()}
      </AddFlex>
    </AddFlex>
  );
}

export default PageSettings;
