import React from "react";
import {
  FeaturesKeys,
  OfferingTypes,
  getDefault,
  getDefaultCourse,
  getDefaultWebinar,
  getSubscriptionOffering,
  getTravelDefaultOffering,
} from "../utils";
import CreateOfferingDialogCard from "./OfferingsPages/CreateOfferingDialogCard";
import {
  AddFlex,
  Arrow,
  CustomText,
  Gap,
  SecondaryActionButton,
} from "../reusableStyles";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { UpdateFeature } from "../Api/ApiEndPoints";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { NEW_PAGE_ROUTE } from "../OnboardingPage/routeNames";
import { headerTextColor } from "../constants";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";

function CreateOfferingDrawerContent({ closeDialog }) {
  return (
    <>
      <AddFlex
        flexDirection="column"
        alignItems="center"
        padding="20px 15px 8px 15px"
      >
        <CustomText fontSize="20px" fontWeight="500" color="black">
          Add New Offering
        </CustomText>
        <div
          style={{
            width: "72px",
            height: "2px",
            backgroundColor: "#F4900C",
            margin: "6px 0 0 0",
          }}
        ></div>
      </AddFlex>
      <AddFlex
        flexDirection="column"
        justify="center"
        alignItems="center"
        padding="22px"
        gap="22px"
      >
        {OfferingTypes.map((offering) => (
          <AddFlex
            flexDirection="column"
            padding="20px"
            border="1px solid #D0D5DD"
            width="100%"
            style={{
              gap: "24px",
              flexShrink: 0,
              boxShadow: "0px 0px 5px 1px rgba(52, 64, 84, 0.15)",
            }}
            borderRadius="16px"
          >
            <CreateOfferingDrawerCard offering={offering} />
          </AddFlex>
        ))}
      </AddFlex>
      <Close
        onClick={closeDialog}
        sx={{
          position: "absolute",
          cursor: "pointer",
          top: "20px",
          right: "68px",
        }}
      />
    </>
  );
}

export default CreateOfferingDrawerContent;

const CreateOfferingDrawerCard = ({ offering }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  const getDefaultOffering = () => {
    const commission = {
      isCommissionIncluded:
        UserAdditionalDetails.commission.isCommissionIncluded,
    };
    switch (offering.pageType) {
      case FeaturesKeys.course.name:
        return getDefaultCourse(
          commission,
          UserAdditionalDetails.isConvenienceIncluded
        );
      case FeaturesKeys.event.name:
        return getDefault(
          commission,
          UserAdditionalDetails.isConvenienceIncluded
        );
      case FeaturesKeys.webinar.name:
        return getDefaultWebinar(
          commission,
          UserAdditionalDetails.isConvenienceIncluded
        );
      case FeaturesKeys.travel.name:
        return getTravelDefaultOffering(
          commission,
          UserAdditionalDetails.isConvenienceIncluded
        );
      case FeaturesKeys.subscription.name:
        return getSubscriptionOffering(
          commission,
          UserAdditionalDetails.isConvenienceIncluded
        );
      default:
        return {};
    }
  };
  const handleCreateNewOffering = () => {
    const defaultOffering = getDefaultOffering();
    dispatch(
      userFeatureApi({
        payload: {
          action: "addnew",
          itemData: defaultOffering,
          featureId: FeaturesKeys[offering.pageType.toLowerCase()].id,
        },
        endpoint: UpdateFeature,
      })
    ).then(() => {
      const queryParams = new URLSearchParams({
        pageType: offering.pageType,
      }).toString();
      navigate(
        NEW_PAGE_ROUTE.replace(":offeringId", defaultOffering.id) +
          `?${queryParams}`
      );
    });
  };
  return (
    <>
      <AddFlex flexDirection="column">
        <CustomText
          textAlign="center"
          fontSize="18px"
          fontWeight="500"
          color="black"
        >
          {offering.name}
        </CustomText>
        <CustomText
          textAlign="center"
          fontSize="12px"
          fontWeight="400"
          color="black"
          margin="12px 0 0 0"
        >
          {offering.description}
        </CustomText>
      </AddFlex>
      <AddFlex flexDirection="column">
        <CustomText
          margin="0 0 11px 0"
          color={headerTextColor}
          fontWeight="500"
        >
          Features
        </CustomText>
        {offering.features.map((feature, index) => (
          <AddFlex>
            <CustomText
              fontSize="14px"
              color="black"
              style={{ flexShrink: 0 }}
              margin="0 5px 0 0"
              fontWeight="300"
            >
              {index + 1}.{" "}
            </CustomText>
            <CustomText fontSize="14px" fontWeight="300" color="black">
              {feature}
            </CustomText>
          </AddFlex>
        ))}
      </AddFlex>
      <SecondaryActionButton
        onClick={handleCreateNewOffering}
        style={{ margin: "0 auto", borderRadius: "24px" }}
      >
        Create Offering <Arrow>→</Arrow>
      </SecondaryActionButton>
    </>
  );
};
