import React, { useState } from "react";
import {
  AddFlex,
  CustomInputLabel,
  PrimaryActionButton,
} from "../../../reusableStyles";
import {
  generateUUID,
  getCurrentTime,
  getToday,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../../utils";
import { CircularProgress, Divider } from "@mui/material";
import FromToDates from "./FromToDates";
import useWindowSize from "../../../Hooks/useWindowSize";
import TimeSlotComponent from "../TimeSlotComponent";
import ScheduleRepeatingBlockHeader from "./ScheduleRepeatingBlockHeader";
import { TertiaryButton } from "./CoursesSchedule";
import Weekdays from "./Weekdays";
import StopBookingsCheckbox from "../StopBookingsCheckbox";
import { addDays } from "date-fns";
import {
  CheckIsMeetingCreatedForSlot,
  CheckOverlappingTimeSlots,
  DeleteZoomMeeting,
  UpdateTimeSlotsInZoom,
} from "../../../Api/ApiEndPoints";
import { callNonStoreApi } from "../../../NonStoreApis";
import ButtonWithAlertDialog from "../ButtonWithAlertDialog";
import { enqueueSnackbar } from "notistack";
import moment from "moment-timezone";

function CourseScheduleComponentExpanded({
  isOnline,
  scheduleDetail,
  shouldUpdateZoom,
  handleSetOffering,
  index,
  setIsExpanded,
  dateString,
  duration,
  handleAddNewDate,
  timezone,
}) {
  const { isMobileView } = useWindowSize();
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);
  const [newSchedule, setNewSchedule] = useState(
    scheduleDetail
      ? JSON.parse(JSON.stringify(scheduleDetail))
      : handleGetDefaultDetail()
  );

  function getDefaultScheduleDetails() {
    return {
      dateId: generateUUID(),
      date: getToday(),
      timeSlots: [
        {
          id: generateUUID(),
          time: getCurrentTime(),
          tickets: [],
          totalSeats: -1,
        },
      ],
    };
  }

  function handleGetDefaultDetail() {
    const date = moment.tz(timezone).startOf("day");
    const newSchedule = getDefaultScheduleDetails();
    newSchedule.date = date.clone().utc().format();
    newSchedule.endDate = date.clone().add(7, "days").utc().format();
    newSchedule.weekdays = [0, 0, 0, 0, 0, 0, 0];
    return newSchedule;
  }

  const handleUpdateSchedule = (key, value) => {
    setNewSchedule((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAddSession = () => {
    var newTimeSlot = {
      id: generateUUID(),
      time: getCurrentTime(),
      tickets: [],
    };
    setNewSchedule((prev) => ({
      ...prev,
      timeSlots: [...prev.timeSlots, newTimeSlot],
    }));
  };

  const handleUpdateSessionTime = (timeSlotIndex, key, value) => {
    return setNewSchedule((prev) => ({
      ...prev,
      timeSlots: prev.timeSlots.map((timeSlot, index) => {
        if (index === timeSlotIndex) {
          return {
            ...timeSlot,
            [key]: value,
          };
        } else {
          return timeSlot;
        }
      }),
    }));
  };
  const handleDeleteTimeSlot = async (timeSlotIndex) => {
    const timeSlot = newSchedule.timeSlots[timeSlotIndex];
    const response = await callNonStoreApi(
      CheckIsMeetingCreatedForSlot.replace(":slotId", timeSlot.id)
    );

    const { isMeetingPresent, meeting } = response.data;
    if (isMeetingPresent) {
      await callNonStoreApi(
        DeleteZoomMeeting.replace(":meetingId", meeting.data.id)
      );
    }

    setNewSchedule((prev) => ({
      ...prev,
      timeSlots: prev.timeSlots.filter(
        (timeSlot, index) => index !== timeSlotIndex
      ),
    }));

    return;
  };

  const handleUpdateScheduleInParent = async () => {
    setLoading(true);
    if (isNullOrUndefined(index) || index === -1) {
      handleAddNewDate(newSchedule);
    } else {
      const isDateChanged =
        newSchedule.date !== scheduleDetail.date ||
        newSchedule.endDate !== scheduleDetail.endDate;
      if (shouldUpdateZoom) {
        for (
          let timeSlotIndex = 0;
          timeSlotIndex < newSchedule.timeSlots.length;
          timeSlotIndex++
        ) {
          const timeSlot = newSchedule.timeSlots[timeSlotIndex];
          const response = await callNonStoreApi(
            CheckIsMeetingCreatedForSlot.replace(":slotId", timeSlot.id)
          );
          const { isMeetingPresent } = response.data;
          if (
            isMeetingPresent &&
            (timeSlot.time !== scheduleDetail.timeSlots[timeSlotIndex]?.time ||
              isDateChanged)
          ) {
            try {
              const response = await callNonStoreApi(UpdateTimeSlotsInZoom, {
                meeting_id: timeSlot.meetingData.id,
                startDate: newSchedule.date,
                endDate: newSchedule.endDate,
                startTime: timeSlot.time,
              });
            } catch (error) {
              console.error(error);
              enqueueSnackbar(
                "Meeting update in zoom failed, please contact support.",
                {
                  variant: "error",
                }
              );
            }
          }
        }
      }
      handleSetOffering(`scheduleDetails.${index}`, newSchedule);
    }
    setIsExpanded(false);
    setLoading(false);
  };

  const handleUpdateWeekdays = (index, value) => {
    setNewSchedule((prev) => ({
      ...prev,
      weekdays: prev.weekdays.map((v, _index) => {
        if (_index === index) {
          return value;
        }
        return v;
      }),
    }));
  };

  const handleSaveButtonClicked = async () => {
    if (!isOnline) {
      handleUpdateScheduleInParent();
      return false;
    }
    newSchedule.duration = duration;
    const response = await callNonStoreApi(CheckOverlappingTimeSlots, {
      schedule: newSchedule,
    });
    if (response.data.hasOverlap) {
      setDescription(response.data.error.join(" "));
      return true;
    }
    handleUpdateScheduleInParent();
    return false;
  };

  return (
    <AddFlex
      style={{
        border: "1px solid #ececec",
        boxShadow: "0 0 1px 0 rgba(0,0,0,0.2)",
      }}
      borderRadius="12px"
      flexDirection="column"
      backgroundColor="white"
      height="80vh"
    >
      <ScheduleRepeatingBlockHeader
        index={index}
        datesString={dateString}
        date={newSchedule.date}
        endDate={newSchedule.endDate}
        setIsExpanded={setIsExpanded}
        handleSetOffering={handleSetOffering}
      />
      <Divider />
      <AddFlex
        gap="20px"
        padding="30px"
        style={{ overflow: "scroll", paddingBottom: "80px", height: "100%" }}
        flexDirection="column"
      >
        <FromToDates
          handleUpdateSchedule={handleUpdateSchedule}
          scheduleDetail={newSchedule}
          showEndDate={true}
          months={1}
          timezone={timezone}
        />
        <Weekdays
          scheduleIndex={index}
          weekdays={newSchedule.weekdays}
          callback={handleUpdateWeekdays}
          handleSetOffering={handleSetOffering}
        />
        <div>
          <CustomInputLabel color="black">Add Timeslots</CustomInputLabel>
          <AddFlex
            wrap="wrap"
            style={{ width: isMobileView() ? "100%" : "50%" }}
          >
            {isArrayNullOrUndefined(newSchedule.timeSlots) ? (
              <TertiaryButton
                buttonName={"Add Timeslot"}
                onClick={handleAddSession}
              />
            ) : (
              newSchedule.timeSlots.map((slot, timeSlotIndex) => (
                <>
                  <TimeSlotComponent
                    key={slot.id}
                    handleAddSession={handleAddSession}
                    handleDeleteTimeSlot={handleDeleteTimeSlot}
                    handleUpdateSessionTime={handleUpdateSessionTime}
                    slot={slot}
                    showDeleteIcon={newSchedule.timeSlots.length > 1}
                    timeSlotIndex={timeSlotIndex}
                    scheduleDetail={newSchedule}
                  />
                </>
              ))
            )}
          </AddFlex>
        </div>
        {!isNullOrUndefined(index) && (
          <StopBookingsCheckbox
            stopBookings={newSchedule.stopBookings}
            handleUpdateSchedule={handleUpdateSchedule}
          />
        )}
      </AddFlex>
      <AddFlex
        style={{
          boxShadow: "0 0 4px 2px rgba(0,0,0,0.05)",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
        }}
        padding="10px"
        backgroundColor="white"
      >
        <ButtonWithAlertDialog
          component={
            <PrimaryActionButton
              onClick={() => {
                return handleSaveButtonClicked();
              }}
              margin="0 auto"
            >
              {loading ? (
                <CircularProgress sx={{ color: "white" }} size={14} />
              ) : (
                "Save"
              )}
            </PrimaryActionButton>
          }
          buttons={[
            {
              title: "Proceed Anyway",
              action: () => {
                handleUpdateScheduleInParent();
              },
            },
          ]}
          title={"Are You Sure?"}
          description={description}
        />
      </AddFlex>
    </AddFlex>
  );
}

export default CourseScheduleComponentExpanded;
