import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  AddFlex,
  ContentHeader,
  CustomInput,
  CustomText,
  Group,
  PrimaryActionButton,
} from "../reusableStyles";
import { CircularProgress, Divider, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import { getApiCallParams, isNullOrUndefined } from "../utils";
import {
  UpdateContactDetails,
  UpdateUserAdditionalDetails,
} from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { THUNK_API_STATES } from "../constants";
import AdvancedTabToggleTab from "../CreateOffering/AdvancedTabToggleTab";

function ContactDetails({ countries }) {
  const { apiUserData } = useSelector((state) => state.user);
  const [userAdditionalDetails, setUserAdditionalDetails] = useState(
    apiUserData.UserAdditionalDetails
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    setIsLoading(true);
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateContactDetails, userAdditionalDetails)
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        enqueueSnackbar("Contact details saved", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      } else {
        enqueueSnackbar("Something went wrong, please try again", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 6000,
        });
      }
      setIsLoading(false);
    });
  };

  const handleBookingRecieveUpdatesOnWhatsApp = (checked) => {
    if (isNullOrUndefined(userAdditionalDetails.userWhatsappNumber)) {
      return enqueueSnackbar("Please enter whatsapp number");
    }
    setUserAdditionalDetails((prev) => ({
      ...prev,
      recieveBookingUpdatesOnWhatsapp: checked,
    }));
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateUserAdditionalDetails, {
          key: "recieveBookingUpdatesOnWhatsapp",
          value: checked,
        })
      )
    );
  };

  const getUserCountryCode = (phoneNumber) => {
    return phoneNumber?.split("-")[0];
  };
  const getPhoneNumber = (phoneNumber) => {
    return phoneNumber?.split("-")[1];
  };
  const handleChangeContactDetails = (key, value) => {
    if (key === "userWhatsaapCountryCode") {
      value =
        value + "-" + userAdditionalDetails?.userWhatsappNumber?.split("-")[1];
      key = "userWhatsappNumber";
    } else if (key === "userWhatsappNumber") {
      value =
        userAdditionalDetails?.userWhatsappNumber?.split("-")[0] + "-" + value;
    } else if (key === "userPhoneCountryCode") {
      value =
        value + "-" + userAdditionalDetails?.userPhoneNumber?.split("-")[1];
      key = "userPhoneNumber";
    } else {
      value =
        userAdditionalDetails?.userPhoneNumber?.split("-")[0] + "-" + value;
    }

    setUserAdditionalDetails({
      ...userAdditionalDetails,
      [key]: value,
    });
  };
  return (
    <>
      <AddFlex
        padding="20px"
        backgroundColor="white"
        borderRadius="12px"
        style={{ height: "max-content", width: "100%" }}
        flexDirection="column"
      >
        <AddFlex flexDirection="column" width="50%">
          <AddFlex flexDirection="column" margin="0 0 24px 0">
            <ContentHeader>Phone Number</ContentHeader>
            <AddFlex alignItems="center">
              <Select
                value={getUserCountryCode(
                  userAdditionalDetails?.userPhoneNumber
                )}
                sx={{
                  height: "44px",
                  margin: "0 14px 0 0",
                  width: "96px",
                  padding: "0",
                }}
                onChange={(e) =>
                  handleChangeContactDetails(
                    "userPhoneCountryCode",
                    e.target.value
                  )
                }
              >
                {countries.map((country) => (
                  <MenuItem value={"+" + country?.phone}>
                    <AddFlex alignItems="center">
                      <CustomText margin="0 10px 0 0">
                        {country.emoji}
                      </CustomText>
                      <CustomText>{"+" + country.phone}</CustomText>
                    </AddFlex>
                  </MenuItem>
                ))}
              </Select>
              <CustomInput
                margin="0px"
                // maxLength={12}
                // type={"number"}
                inputStyle={{ margin: 0 }}
                value={getPhoneNumber(userAdditionalDetails?.userPhoneNumber)}
                onChange={(text) =>
                  handleChangeContactDetails("userPhoneNumber", text)
                }
              />
            </AddFlex>
          </AddFlex>
          <AddFlex flexDirection="column" margin="0 0 24px 0">
            <ContentHeader>WhatsApp Number</ContentHeader>
            <AddFlex margin="8px 0 0 0">
              <Select
                sx={{
                  height: "44px",
                  margin: "0 14px 0 0",
                  width: "96px",
                  padding: "0",
                }}
                onChange={(e) =>
                  handleChangeContactDetails(
                    "userWhatsaapCountryCode",
                    e.target.value
                  )
                }
                value={getUserCountryCode(
                  userAdditionalDetails?.userWhatsappNumber
                )}
              >
                {countries.map((country) => (
                  <MenuItem value={"+" + country.phone}>
                    <AddFlex>
                      <CustomText margin="0 10px 0 0">
                        {country.emoji}
                      </CustomText>
                      <CustomText>{"+" + country.phone}</CustomText>
                    </AddFlex>
                  </MenuItem>
                ))}
              </Select>
              <CustomInput
                value={getPhoneNumber(
                  userAdditionalDetails?.userWhatsappNumber
                )}
                inputStyle={{ margin: 0 }}
                margin="0"
                maxLength={10}
                type={"number"}
                onChange={(text) =>
                  handleChangeContactDetails("userWhatsappNumber", text)
                }
              />
            </AddFlex>
          </AddFlex>
          <AdvancedTabToggleTab
            checked={Boolean(
              userAdditionalDetails.recieveBookingUpdatesOnWhatsapp
            )}
            tabName={"Sync bookings to whatsapp"}
            margin={"0px 0 24px 0"}
            onChange={(e) => {
              handleBookingRecieveUpdatesOnWhatsApp(e.target.checked);
            }}
          ></AdvancedTabToggleTab>
          <PrimaryActionButton onClick={handleSubmit}>
            {isLoading ? (
              <CircularProgress size={14} sx={{ color: "white" }} />
            ) : (
              "Save changes"
            )}
          </PrimaryActionButton>
        </AddFlex>
      </AddFlex>
    </>
  );
}

export default ContactDetails;
