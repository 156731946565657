import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { primaryActionColor, primaryTextColor } from "../constants";
import SuccessChip from "./SuccessChip";
import InactiveChip from "./InactiveChip";
import { AddFlex, CustomText, Group, Icon } from "../reusableStyles";
import zoomLogo from "../ImageAssets/zoom.png";
import razorpayLogo from "../ImageAssets/razorpay_logo.png";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { CheckCircleOutline } from "@mui/icons-material";
import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
import { isNullOrUndefined } from "../utils";
import Meetlogo from "../ImageAssets/meet_logo.png";
import useWindowSize from "../Hooks/useWindowSize";
export const StyledImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

function IntegrationCardItem({ item, onItemClicked }) {
  const { isMobileView } = useWindowSize();
  const getLogo = () => {
    switch (item.id) {
      case "zoom":
        return <StyledImage src={zoomLogo} />;
      case "custom_domain":
        return (
          <LanguageOutlinedIcon
            sx={{ height: "40px", width: "40px", color: primaryActionColor }}
          />
        );
      case "google_calendar":
        return (
          <Icon style={{ height: "40px", width: "40px" }} src={Meetlogo} />
        );
      case "razorpay_link":
        return (
          <StyledImage
            src={razorpayLogo}
            style={{ width: "100px", height: "40px" }}
          />
        );
    }
  };

  const isRazorPay = () => {
    return item.id === "razorpay_link" && !isNullOrUndefined(item.extraData);
  };

  return (
    <Group
      padding="20px 24px"
      marginTop="0"
      boxShadow="none"
      style={{
        width: isMobileView() ? "100%" : "30%",
        flexShrink: 0,
        cursor: "pointer",
        position: "relative",
        maxWidth: "373px",
        border: "1px solid #ececec",
        marginLeft: isMobileView() && 0,
      }}
      onClick={() => onItemClicked(item)}
    >
      <AddFlex flexDirection="column" alignItems="flex-start">
        <AddFlex width="100%" alignItems="center" justify="space-between">
          {getLogo()}
          {!isNullOrUndefined(item.isActivated) && item.isActivated ? (
            <>
              <SuccessChip
                successChipText={item.cta_activated}
                showIcon={true}
                icon={
                  <CheckCircleOutline
                    sx={{ fontSize: "20px", color: "#067647" }}
                  />
                }
              />
            </>
          ) : (
            <>
              <InactiveChip
                chipName={"Connect Now"}
                icon={<PowerOutlinedIcon fontSize="12px" />}
                showIcon={true}
                action={() => onItemClicked(item)}
              />
            </>
          )}
        </AddFlex>
        <AddFlex flexDirection="column" margin="15px 0 15px 0">
          <CustomText
            color="black"
            fontSize="20px"
            fontWeight="600"
            margin="3px 0 3px 0px"
          >
            {item.name}
          </CustomText>
          <CustomText color="grey" fontSize="14px" margin="3px 0 10px 0px">
            {item.description}
          </CustomText>
          <AddFlex justify="space-between">
            {/* /integrations/zoom/documentation */}
            {/* "https://marketplace.zoom.us/user/installed" */}
            {/* <StyledLink to={item.documentationPath}>How to Connect?</StyledLink> */}
            {item.isActivated && (
              <>
                <CustomText
                  onClick={() => onItemClicked(item)}
                  style={{
                    color: primaryTextColor,
                    fontSize: "14px",
                    borderBottom: "1px solid #000000",
                  }}
                >
                  Manage
                </CustomText>

                {isRazorPay() && (
                  <CustomText
                    onClick={() => onItemClicked(item)}
                    style={{
                      color: primaryTextColor,
                      fontSize: "14px",
                      borderBottom: "1px solid #000000",
                    }}
                  >
                    {
                      item.extraData.razorpaySubmerchantActivationStatus
                        .activationStatus
                    }
                    <br></br>
                    {
                      item.extraData.razorpaySubmerchantActivationStatus
                        .activationProgress
                    }
                  </CustomText>
                )}
              </>
            )}
          </AddFlex>
        </AddFlex>
      </AddFlex>
    </Group>
  );
}

export default IntegrationCardItem;
