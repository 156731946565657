import React from "react";
import { AddFlex, CustomInput } from "../../../reusableStyles";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import { Add, DeleteOutlined } from "@mui/icons-material";
import { headerTextColor } from "../../../constants";
import { resetPricingIndexesAction } from "../../../Store/appTempSlice";
import { useDispatch } from "react-redux";
import { generateUUID, isArrayNullOrUndefined } from "../../../utils";
import useWindowSize from "../../../Hooks/useWindowSize";
import { TertiaryButton } from "./CoursesSchedule";

function OneonOneSchedule({ offering, handleSetOffering }) {
  const dispatch = useDispatch();
  const { isMobileView } = useWindowSize();
  const handleAddDuration = () => {
    var newDuration = {
      id: generateUUID(),
      durationInMins: 60,
      tickets: [],
    };
    handleSetOffering(`scheduleDetails.push`, newDuration);
  };
  return (
    <div>
      <div style={{ margin: "12px 0" }}></div>
      <AddFlex wrap="wrap" style={{ width: isMobileView() ? "100%" : "50%" }}>
        {isArrayNullOrUndefined(offering.scheduleDetails) ? (
          <TertiaryButton
            buttonName={"Add Duration"}
            onClick={handleAddDuration}
          />
        ) : (
          offering.scheduleDetails?.map((scheduleDetail, index) => (
            <AddFlex
              flexDirection="row"
              alignItems="center"
              margin="0 0 10px 0"
            >
              <CustomInput
                widget={
                  <MoreTimeOutlinedIcon
                    sx={{ color: "#667085", fontSize: "22px" }}
                  />
                }
                width={"100%"}
                value={scheduleDetail.durationInMins}
                placeholder={"Enter duration in minutes"}
                onChange={(text) => {
                  handleSetOffering(
                    `scheduleDetails.${index}.durationInMins`,
                    text
                  );
                  return;
                }}
              />
              {offering.scheduleDetails.length > 1 && (
                <DeleteOutlined
                  sx={{
                    margin: "0px 0 0 15px",
                    fontSize: "22px",
                    color: headerTextColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(resetPricingIndexesAction());
                    handleSetOffering(`scheduleDetails.delete.${index}`, null);
                  }}
                />
              )}
              {offering.scheduleDetails.length - 1 === index && (
                <Add
                  sx={{
                    margin: "0px 0 0 15px",
                    fontSize: "22px",
                    color: headerTextColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleAddDuration();
                    dispatch(resetPricingIndexesAction());
                  }}
                />
              )}
            </AddFlex>
          ))
        )}
      </AddFlex>
    </div>
  );
}

export default OneonOneSchedule;
