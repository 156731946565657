import React, { useState } from "react";
import {
  AddFlex,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import styled from "styled-components";
import {
  inputHeight,
  primaryActionColor,
  actionButtomBroderRadius,
} from "../constants";
import { callNonStoreApi } from "../NonStoreApis";
import { useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import { DASHBOARD_ROUTE } from "./routeNames";
import { UserOnboardingApi } from "../Api/ApiEndPoints";
import { isNullOrUndefined } from "../utils";
import { enqueueSnackbar } from "notistack";
import theme from "../theme";
import { ChevronLeft } from "@mui/icons-material";

const StyledButton = styled.button`
  height: ${inputHeight};
  background-color: ${(props) => (props.isErr ? "grey" : primaryActionColor)};
  border-radius: ${actionButtomBroderRadius};
  border: none;
  outline: none;
  width: 35vw;
  color: white;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  /* margin-bottom: 40px; */
`;

function OnboardingFooter({
  newUser,
  stage,
  setStage,
  isErr,
  isCheckingUsernameAvailability,
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onClick = async () => {
    try {
      setLoading(true);
      await callNonStoreApi(UserOnboardingApi, newUser);
      setLoading(false);
      navigate(DASHBOARD_ROUTE);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something went wrong", { variant: "error" });
      console.log(error);
    }
  };
  return (
    <AddFlex
      position="fixed"
      bottom="0"
      left="0"
      width="100vw"
      //   height="5vh"
      backgroundColor="white"
      padding="15px 15px"
      alignItems="center"
      justify="center"
      style={{ boxShadow: "0 -3px 4px rgba(0,0,0,.1)" }}
    >
      <AddFlex alignItems="center" margin="0 auto" width="20%">
        {stage !== 0 && (
          <AddFlex
            gap="5px"
            alignItems="center"
            justify="center"
            padding="10px"
            style={{
              color: primaryActionColor,
              fontWeight: "600",
              fontSize: "16px",
              cursor: "pointer",
            }}
            grow="1"
            onClick={() => {
              setStage((prev) => prev - 1);
            }}
          >
            Back
          </AddFlex>
        )}
        <PrimaryActionButton
          style={{ fontWeight: "600", flexGrow: 1 }}
          padding="10px"
          // isErr={
          //   isErr ||
          //   newUser.userName.length === 0 ||
          //   newUser.name?.length === 0 ||
          //   isCheckingUsernameAvailability
          // }
          backgroundColor={
            (isErr ||
              isNullOrUndefined(newUser.userName) ||
              newUser.userName.length === 0 ||
              isNullOrUndefined(newUser.Name) ||
              newUser.Name.length === 0 ||
              isCheckingUsernameAvailability) &&
            "grey"
          }
          disabled={
            isErr ||
            isNullOrUndefined(newUser.userName) ||
            newUser.userName.length === 0 ||
            isNullOrUndefined(newUser.Name) ||
            newUser.Name.length === 0 ||
            isCheckingUsernameAvailability
          }
          onClick={() => {
            if (stage < 3) {
              setStage((prev) => prev + 1);
            } else {
              onClick();
            }
          }}
        >
          {loading ? (
            <CircularProgress
              size={20}
              style={{ color: theme.palette.custom.circularProgress }}
            />
          ) : (
            "Next"
          )}
        </PrimaryActionButton>
      </AddFlex>
    </AddFlex>
  );
}

export default OnboardingFooter;
