import styled from "styled-components";
import {
  primaryActionColor,
  primaryActionColorLight,
  primaryTextColor,
} from "../constants";
import { AddFlex, CustomText } from "../reusableStyles";
import { generateUUID, handleImageUpload, isNullOrUndefined } from "../utils";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Photo } from "@mui/icons-material";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { AddImagesToGallery } from "../Api/ApiEndPoints";
import { useDispatch } from "react-redux";
import useWindowSize from "../Hooks/useWindowSize";

const Video = styled.video`
  transition: all 0.3s ease-in-out;
  padding: ${({ isSelected }) => (isSelected ? "5px" : "0px")};
  border-radius: 4px;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border: ${({ isSelected }) =>
    isSelected ? `4px solid ${primaryActionColor}` : "4px solid #ececec"};
  &:hover {
    border: ${({ isSelected }) =>
      isSelected
        ? `4px solid ${primaryActionColor}`
        : `4px solid ${primaryActionColorLight}`};
    padding: 5px;
  }
`;

const Image = styled.img`
  transition: all 0.3s ease-in-out;
  padding: ${({ isSelected }) => (isSelected ? "5px" : "0px")};
  border-radius: 4px;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border: ${({ isSelected }) =>
    isSelected ? `4px solid ${primaryActionColor}` : "4px solid #ececec"};
  &:hover {
    border: ${({ isSelected }) =>
      isSelected
        ? `4px solid ${primaryActionColor}`
        : `4px solid ${primaryActionColorLight}`};
    padding: 5px;
  }
`;

const FileNameText = styled(CustomText)`
  text-align: center;
  letter-spacing: ${(props) => props.letterSpacing};
  display: -webkit-box;
  line-height: 16px;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
`;
const GalleryViewBody = ({
  images,
  isMultiple,
  setImagesArray,
  handleSelectDeselectImages,
  selectedImages,
}) => {
  const { windowSize } = useWindowSize();
  return (
    <AddFlex
      padding="15px 30px"
      wrap="wrap"
      alignItems={windowSize.screenWidth < 1000 ? "center" : "flex-start"}
    >
      {images.map((image, index) => (
        <div
          style={{
            margin: "0 8px 30px 0",
            width: windowSize.screenWidth < 1000 ? "150px" : "150px",
            height: windowSize.screenWidth < 1000 ? "150px" : "160px",
          }}
        >
          {image.id === "AddNew" ? (
            <AddNewImage
              isMultiple={isMultiple}
              imagesArray={images}
              setImagesArray={setImagesArray}
            />
          ) : (
            <AddFlex flexDirection="column" align="center">
              {image.isVideo ? (
                <Video
                  src={image.src}
                  autoPlay={false}
                  muted={false}
                  isSelected={selectedImages?.some(
                    (_image) => _image.id === image.id
                  )}
                  style={{ width: "100%", aspectRatio: 1, objectFit: "cover" }}
                  onClick={() => handleSelectDeselectImages(image, isMultiple)}
                />
              ) : (
                <Image
                  key={image.id}
                  src={
                    image.type === "application/pdf"
                      ? "https://firebasestorage.googleapis.com/v0/b/growezy-public.appspot.com/o/pdfIcon.png?alt=media&token=e13e8e8a-d3bf-41e0-9135-c5b4a10e0ff0"
                      : image.src
                  }
                  isSelected={selectedImages?.some(
                    (_image) => _image.id === image.id
                  )}
                  style={{
                    objectFit: image.type === "application/pdf" && "contain",
                  }}
                  onClick={() => handleSelectDeselectImages(image, isMultiple)}
                />
              )}
              <FileNameText margin="10px 0 0 0" color="black">
                {isNullOrUndefined(image.name)
                  ? `FILE ${index + 1}`
                  : image.name.toUpperCase()}
              </FileNameText>
            </AddFlex>
          )}
        </div>
      ))}
    </AddFlex>
  );
};

export default GalleryViewBody;

const AddNewImage = ({ isMultiple, imagesArray, setImagesArray }) => {
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  //   const { setUserFeatureData } = useUser();
  //   const { addImagesToGallery } = useFeatureServices();
  const { enqueueSnackbar } = useSnackbar();
  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".png", ".svg"],
    },
    maxSize: 1048576, //limit to 1MB
    multiple: isMultiple,
    onDrop: (acceptedFiles, rejectedFiles) => {
      handleGetFiles(acceptedFiles, rejectedFiles);
    },
  });

  const handleGetFiles = async (acceptedFiles, rejectedFiles) => {
    try {
      if (loading) return;
      setIsLoading(true);
      if (rejectedFiles.length > 0) {
        enqueueSnackbar("Max File size allowed 1MB", {
          variant: "error",
          hideIconVariant: true,
        });
        throw new Error("Max File size allowed 1MB");
      }
      //upload files to firebase.
      const imgUrls = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        imgUrls.push({
          id: generateUUID(),
          uploaded: true,
          src: await handleImageUpload(acceptedFiles[i], "gallery_view"),
        });
      }
      //upload URLs to DB.
      setImagesArray([...imagesArray, ...imgUrls]);
      const newImages = [...imagesArray, ...imgUrls];
      dispatch(
        userFeatureApi({
          payload: {
            newImages: newImages,
          },
          endpoint: AddImagesToGallery,
        })
      ).then((actionResult) => {
        if (actionResult.meta.requestStatus === "fulfilled") {
          // Handle success
          enqueueSnackbar("Your Images Has been Saved.", {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        }
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <AddFlex
      flexDirection="column"
      width="150px"
      height="150px"
      padding="5px"
      align="center"
      justify="center"
      backgroundColor="white"
      borderRadius="4px"
      style={{ boxShadow: `0px 0px 4px 1px ${primaryActionColorLight}` }}
      {...getRootProps({ className: "dropzone" })}
    >
      <input {...getInputProps()} />

      {loading ? (
        <CircularProgress
          size={16}
          color="info"
          style={{ color: primaryActionColor }}
        />
      ) : (
        <Photo />
      )}
      <CustomText margin="10px 0" color={primaryTextColor}>
        Upload New Image
      </CustomText>
    </AddFlex>
  );
};
