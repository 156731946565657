import React from "react";
import { AddFlex } from "../../../reusableStyles";
import OfferingCardHeader from "./OfferingCardHeader";
import OfferingCardBody from "./OfferingCardBody";
import OfferingCardFooter from "./OfferingCardFooter";
import useWindowSize from "../../../Hooks/useWindowSize";
import {
  Currency,
  FeaturesKeys,
  Status,
  formatPrice,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../../utils";

function OfferingCard({
  revenueStats,
  offering,
  ticketWiseStats,
  featureKey,
  showSalesDetails,
}) {
  const { isMobileView } = useWindowSize();
  const getPriceText = () => {
    let priceText = "";
    if (featureKey.id === FeaturesKeys.travel.id) {
      priceText =
        "From " +
        formatPrice(
          offering?.scheduleDetails[0]?.tickets[0]?.prices[
            offering.primaryCurrency
          ],
          offering.primaryCurrency
        );
    } else if (featureKey.id === FeaturesKeys.oneonone.id) {
      priceText =
        "From " +
        formatPrice(
          isNullOrUndefined(offering?.scheduleDetails[0]?.tickets)
            ? 0
            : offering?.scheduleDetails[0]?.tickets[0]?.prices[
                offering.primaryCurrency
              ],
          offering.primaryCurrency
        );
    } else {
      priceText =
        "From " +
        formatPrice(
          offering?.scheduleDetails[0]?.timeSlots[0]?.tickets[0]?.prices[
            offering.primaryCurrency
          ],
          offering.primaryCurrency
        );
    }
    return priceText;
  };
  const getStartTimeSlot = () => {
    if (isArrayNullOrUndefined(offering.scheduleDetails)) return "";
    if (
      FeaturesKeys.travel.id === featureKey.id ||
      FeaturesKeys.oneonone.id === featureKey.id
    )
      return;
    return offering.scheduleDetails[0].timeSlots[0]?.time;
  };
  const getRevenue = () => {
    if (isNullOrUndefined(revenueStats?.revenue)) return 0;
    const revenue = revenueStats.revenue[offering.id]?.totalRevenue
      ? revenueStats.revenue[offering.id]?.totalRevenue
      : 0;
    return formatPrice(revenue, Currency.INR);
  };

  const getTotalSold = () => {
    if (isNullOrUndefined(revenueStats?.revenue)) return 0;
    return revenueStats.revenue[offering.id]?.totalSales
      ? revenueStats.revenue[offering.id]?.totalSales
      : 0;
  };
  return (
    offering &&
    !isNullOrUndefined(offering.scheduleDetails) && (
      <AddFlex
        flexDirection="column"
        width={isMobileView() ? "100%" : "30%"}
        padding="20px 16px"
        borderRadius="8px"
        gap="16px"
        backgroundColor="white"
        style={{ boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.08)" }}
      >
        <OfferingCardHeader
          featureKey={featureKey}
          offering={offering}
          isPublished={
            offering.status === Status.LIVE ||
            offering.status === Status.PUBLISHED
          }
        />
        <OfferingCardBody
          featureKey={featureKey}
          name={offering.name}
          offeringId={offering.id}
          scheduleDetails={offering.scheduleDetails}
          coverImage={offering.coverImage}
          priceText={getPriceText()}
          revenueStats={revenueStats}
          startDate={offering.scheduleDetails[0]?.date}
          endDate={offering.scheduleDetails[0]?.endDate}
          startTimeSlot={getStartTimeSlot()}
          revenue={getRevenue()}
          sold={getTotalSold()}
          isSaleDetailsClicked={showSalesDetails}
        />

        {/* {isOfferingPublishable(offering, featureKey.name.toLowerCase()) && (
          <Gap />
        )} */}
        <OfferingCardFooter
          offering={offering}
          sold={getTotalSold()}
          featureKey={featureKey}
        />
      </AddFlex>
    )
  );
}

export default OfferingCard;
