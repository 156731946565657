import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FeaturesKeys,
  Status,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import customClientAxios from "../customClientAxios";
import { AddTestimonialToWallOfLove } from "../Api/ApiEndPoints";
// import { getApiCall } from "./utils";

const initialState = {
  isLoading: {},
  userFeatures: null,
  error: {},
};

export const userFeatureApi = createAsyncThunk(
  "user/usersFeaturesApiCall",
  async ({ endpoint, payload, extraHeaders }) => {
    if (isNullOrUndefined(payload)) {
      return customClientAxios().get(endpoint, {
        headers: extraHeaders,
      });
    }
    return customClientAxios().post(endpoint, payload, {
      headers: extraHeaders,
    });
  }
);

const updateFeatureData = (state, action) => {
  state.userFeatures.features = action.payload;
  Object.values(state.userFeatures.features).map((value) => {
    if (isArrayNullOrUndefined(value.items)) return value;
    value.unPublishedItems = value.items.filter((item) => {
      return (
        item.status === Status.CREATED ||
        item.status === Status.DRAFT ||
        item.status === Status.UNPUBLISHED
      );
    });
    value.publishedItems = value.items.filter((item) => {
      return item.status === Status.LIVE || item.status === Status.PUBLISHED;
    });
    return value;
  });
};

const userFeaturesSlice = createSlice({
  name: "userFeatures",
  initialState,
  reducers: {
    updateFeatureDataAction: updateFeatureData,
  },
  extraReducers: (builder) => {
    builder.addCase(userFeatureApi.pending, (state, action) => {
      state.isLoading[action.meta.arg.endpoint] = true;
    });
    builder.addCase(userFeatureApi.fulfilled, (state, action) => {
      // state.userFeatures = action.payload;
      if (action.meta.arg.endpoint === AddTestimonialToWallOfLove) {
        state.userFeatures.features[FeaturesKeys.testimonial.id].items =
          state.userFeatures.features[FeaturesKeys.testimonial.id].items.map(
            (item) => {
              if (item.id === action.payload.data.testimonialId) {
                item.isWallOfLove = action.payload.data.isWallOfLove;
              }
              return item;
            }
          );
        state.isLoading[action.meta.arg.endpoint] = false;
        state.error[action.meta.arg.endpoint] = null;
        return;
      }
      if (!isNullOrUndefined(action.payload.data.isPartialUpdate)) {
        state.userFeatures = {
          ...state.userFeatures,
          ...action.payload.data.res,
        };
      } else {
        state.userFeatures = action.payload.data;
      }
      if (!isNullOrUndefined(state.userFeatures.features)) {
        Object.values(state.userFeatures.features).map((value) => {
          if (isArrayNullOrUndefined(value.items)) return value;
          value.unPublishedItems = value.items.filter((item) => {
            return (
              item.status === Status.CREATED ||
              item.status === Status.DRAFT ||
              item.status === Status.UNPUBLISHED
            );
          });
          value.archived = value.items.filter((item) => {
            return (
              item.status === Status.DELETED || item.status === Status.ARCHIVED
            );
          });
          value.publishedItems = value.items.filter((item) => {
            return (
              item.status === Status.LIVE || item.status === Status.PUBLISHED
            );
          });
          return value;
        });
      }
      state.isLoading[action.meta.arg.endpoint] = false;
      state.error[action.meta.arg.endpoint] = null;
      // if (action.payload.lastUpdatedVersion > state.users.lastUpdatedVersion) {
      //   state.users = action.payload;
      // }
    });
    builder.addCase(userFeatureApi.rejected, (state, action) => {
      // state.userFeatures = null;
      state.isLoading[action.meta.arg.endpoint] = false;
      state.error[action.meta.arg.endpoint] = action.error.message;
    });
  },
});

export const { updateFeatureDataAction } = userFeaturesSlice.actions;

export default userFeaturesSlice.reducer;
