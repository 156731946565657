import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { AddFlex, CustomText } from "../../reusableStyles";
import TimeSlot from "./SlotCard";
import { CircularProgress } from "@mui/material";
import { usersApiCall } from "../../Store/userSlice";
import {
  UpldateUserCalendars,
  UserAvailabilityApi,
} from "../../Api/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, isNullOrUndefined } from "../../utils";
import { primaryActionColor } from "../../constants";
import { enqueueSnackbar } from "notistack";
import { callNonStoreApi } from "../../NonStoreApis";
import { setUserCalendarsAction } from "../../Store/appTempSlice";

const ScheduleContainer = styled.div`
  width: 60%;
  border: 1px solid #f2f6f9;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background: ${(props) => (props.buttonBg ? props.buttonBg : "black")};
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  /* margin-top: 20px; */
`;

const Slots = ({ _schedule }) => {
  const userSliceData = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { apiUserData } = useSelector((state) => state.user);
  const [schedule, setSchedule] = useState(_schedule.schedule);

  const handleApplyToAll = (times) => {
    setSchedule({
      monday: times,
      tuesday: times,
      wednesday: times,
      thursday: times,
      friday: times,
      saturday: times,
      sunday: times,
    });
  };

  const handleDayChange = (day, intervalIndex, field, value) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        intervals: prev[day].intervals.map((interval, index) => {
          if (index === intervalIndex) {
            return { ...interval, [field]: value };
          } else {
            return interval;
          }
        }),
      },
    }));
  };
  const handleToggleAvailablity = (day, isAvailable) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        available: isAvailable,
      },
    }));
  };
  const handleDeleteDay = (day) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        available: false,
      },
    }));
  };

  const handleAddInterval = (day) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        intervals: [{ start: "09:00", end: "17:00" }, ...prev[day].intervals],
      },
    }));
  };

  const handleRemoveInterval = (day, intervalIndex) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        intervals: prev[day].intervals.filter((_, index) => {
          return index !== intervalIndex;
        }),
      },
    }));
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const response = await callNonStoreApi(UpldateUserCalendars, {
        data: schedule,
        path: `calendars.${_schedule.id}.schedule`,
      });
      dispatch(setUserCalendarsAction(response.data));
      enqueueSnackbar("Successfully updated schedule", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  //use effect.
  /**Populate data */
  useEffect(() => {
    setSchedule(_schedule.schedule);
  }, [_schedule]);

  return (
    <ScheduleContainer>
      <AddFlex
        justify="space-between"
        alignItems="center"
        padding="20px"
        style={{ borderBottom: "1px solid #f2f6f9" }}
      >
        <CustomText color="black" fontWeight="500" fontSize="22px">
          Customise your availablity
        </CustomText>
        <StyledButton buttonBg={primaryActionColor} onClick={handleSave}>
          {userSliceData.isLoading[UserAvailabilityApi] && (
            <CircularProgress
              size={18}
              sx={{ color: "white", margin: "0 8px 0 0" }}
            />
          )}
          <CustomText color="white">Save</CustomText>
        </StyledButton>
      </AddFlex>
      {Object.keys(schedule).map((day, index) => (
        <TimeSlot
          isFirst={index === 0}
          key={day}
          day={capitalizeFirstLetter(day)}
          handleAddInterval={handleAddInterval}
          handleRemoveInterval={handleRemoveInterval}
          handleToggleAvailablity={handleToggleAvailablity}
          handleApplyToAll={handleApplyToAll}
          isLast={index === Object.keys(schedule).length - 1}
          times={schedule[day]}
          onChange={handleDayChange}
          onDelete={handleDeleteDay}
        />
      ))}
    </ScheduleContainer>
  );
};

export default Slots;
