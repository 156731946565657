import React from "react";
import { AddFlex } from "../reusableStyles";
import { Item, Parent } from "./ReportsSummaryTableRow";

function ReportSummaryTableHeader({ columns }) {
  return (
    <Parent
      style={{
        border: "1px solid #ececec",
      }}
    >
      {columns.map((column, index) => {
        return (
          <Item
            percent={column.spacing}
            fontWeight={"500"}
            style={{
              borderRight: index !== columns.length - 1 && "2px solid #ececec",
            }}
          >
            {column.title}
          </Item>
        );
      })}
    </Parent>
  );
}

export default ReportSummaryTableHeader;
