import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  AddFlex,
  CustomText,
  SecondaryActionButton,
} from "../../../../reusableStyles";
import bg from "../../../../ImageAssets/sharable_card_with_QR_bg.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  CalendarMonth,
  CopyAll,
  CopyAllOutlined,
  Restore,
} from "@mui/icons-material";
import {
  getDuration,
  getFrontendBaseURL,
  getTimeInTwelveHourForm,
  handleCopyToClipboard,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../../../utils";
import { addDays, format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import html2canvas from "html2canvas";
import useWindowSize from "../../../../Hooks/useWindowSize";
import Card from "./Card";
import InactiveChip from "../../../Components/InactiveChip";

function CardWithQR({ offering, featureKey, templatesWithQRJSON, withQR }) {
  const [loading, setLoading] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [loadedCount, setLoadedCount] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const { isMobileView } = useWindowSize();

  const componentRef = useRef();
  const {
    name,
    scheduleDetails,
    locationDetails,
    isOnline,
    hours,
    mins,
    isMultiday,
    days,
    coverImage,
  } = offering;
  const { photoURL, userName, Name } = useSelector(
    (state) => state.user.apiUserData
  );
  const handleImageLoad = () => {
    setLoadedCount((current) => current + 1);
  };

  // Check if all images are loaded
  useEffect(() => {
    if (imageCount > 0 && loadedCount === imageCount) {
      setAllImagesLoaded(true);
    }
  }, [loadedCount, imageCount]);

  useEffect(() => {
    const images = componentRef.current.getElementsByTagName("img");
    setImageCount(images.length);
    Array.from(images).forEach((image) => {
      if (image.complete) {
        handleImageLoad();
      } else {
        image.onload = handleImageLoad;
        image.onerror = handleImageLoad; // Treat error as loaded for simplicity
      }
    });
  }, []);

  const downloadImage = () => {
    if (componentRef.current) {
      setLoading(true);
      html2canvas(componentRef.current, {
        scale: 3,
        imageTimeout: 0,
        useCORS: true,
        backgroundColor: "transparent",
      })
        .then((canvas) => {
          const image = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.download = `${offering.name}.png`;
          link.href = image;
          link.click();
          enqueueSnackbar("Image Downloaded", {
            variant: "success",
            hideIconVariant: "true",
          });
        })
        .catch((error) => {
          console.error("Failed to capture:", error);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            hideIconVariant: "true",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("Images not loaded");
    }
  };

  return (
    <>
      <AddFlex
        ref={componentRef}
        position="relative"
        flexDirection="column"
        alignItems="center"
        width={"350px"}
        gap="21px"
        borderRadius="32px"
        margin="0 auto"
      >
        <Card
          pageType={featureKey.name}
          offering={offering}
          userProfileImage={photoURL}
          userName={userName}
          name={Name}
          colors={templatesWithQRJSON}
          withQR={withQR}
        />
      </AddFlex>
      <AddFlex
        flexDirection="column"
        alignItems="center"
        gap="12px"
        style={{ width: "50%", margin: "0 auto" }}
      >
        <SecondaryActionButton
          onClick={downloadImage}
          style={{ width: "100%" }}
        >
          {loading ? "Downloading..." : "Download"}
        </SecondaryActionButton>
        <CustomText>OR</CustomText>
        <InactiveChip
          chipName={"Copy  Link"}
          action={() => {
            handleCopyToClipboard(
              `${getFrontendBaseURL(userName)}${offering.urlKey}`
            ).then(() => {
              enqueueSnackbar("Offering link copied", {
                variant: "success",
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom",
                },
              });
            });
          }}
          showIcon={true}
          icon={
            <CopyAllOutlined sx={{ fontSize: "18px", cursor: "pointer" }} />
          }
        />
      </AddFlex>
    </>
  );
}

export default CardWithQR;
