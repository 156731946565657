import React, { useState } from "react";
import { AddFlex, CustomInput, InputLabel } from "../../reusableStyles";
import { MenuItem, Select } from "@mui/material";
import UserCalendars from "./UserCalendars";

function CallbackAndMeetCustomiseDialog({ data, setData }) {
  const [selectedValue, setSelectedValue] = useState(data?.expiry);

  return (
    <AddFlex margin="20px 0 0 0" flexDirection="column" gap="12px" width="100%">
      <CustomInput
        label={"Select the call Duration for this appointment !"}
        placeholder={"Enter in minutes"}
        type={"number"}
        widget={"Mins"}
        value={data?.duration}
        onChange={(text) => {
          setData((prev) => {
            return {
              ...prev,
              duration: text,
            };
          });
        }}
      />
      <AddFlex flexDirection="column">
        <InputLabel>Select Calendar</InputLabel>
        <UserCalendars
          value={data?.calendarId}
          onChange={(calendarId) => {
            setData((prev) => {
              return {
                ...prev,
                calendarId: calendarId,
              };
            });
          }}
        />
      </AddFlex>

      {/* <InputLabel style={{ margin: 0 }}>
        Till when you would like to take these calls ?
      </InputLabel>
      <Select
        value={selectedValue}
        onChange={(e) => {
          setSelectedValue(e.target.value);
          setData((prev) => {
            return {
              ...prev,
              expiry: e.target.value, //no of days before the event
            };
          });
        }}
        sx={{ height: "40px" }}
      >
        <MenuItem value={2}>Stop 2 days before</MenuItem>
        <MenuItem value={1}>stop 1 day before </MenuItem>
        <MenuItem value={0}>Allow till the event day</MenuItem>
      </Select> */}
    </AddFlex>
  );
}

export default CallbackAndMeetCustomiseDialog;
