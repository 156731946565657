import React from "react";
import { getTimeInTwelveHourForm, isNullOrUndefined } from "../../utils";
import { AddFlex, CustomText } from "../../reusableStyles";
import { AddMargin } from "../../CreateOffering/Components/AddPlanForm";
import { TimeSlotHolder } from "../../CreateOffering/Components/TImeSlotsHolder";

const SelectedEventsAndTimeSlots = ({ offeringsPayload }) => {
  const handleView = () => {
    if (isNullOrUndefined(offeringsPayload)) return <></>;
    if (offeringsPayload.isAllSelected) {
      return (
        <CustomText
          color="green"
          margin="0px 0 0 0"
          fontSize="16px"
          fontWeight="bold"
        >
          COUPON APPLICABLE TO ALL OFFERINGS & TIME SLOTS
        </CustomText>
      );
    } else {
      return (
        <AddMargin margin="10px 0 0 0">
          {offeringsPayload.selectedOfferings.length > 0 && (
            <CustomText color="green" margin="0px 0 10px 0" fontWeight="bold">
              COUPON WILL ONLY BE APPLICABLE TO THE BELOW OFFERINGS AND TIME
              SLOTS
            </CustomText>
          )}
          {offeringsPayload.selectedOfferings.map((offering) => (
            <AddFlex flexDirection="column" margin="0px 0 10px 0">
              <CustomText margin="0 0 8px 0" fontSize="12px" color="black">
                {offering.name}
              </CustomText>
              <AddFlex>
                {offering.isAllTimeSlotsSelected ? (
                  <TimeSlotHolder
                    isSelected={true}
                    onClick={() => {}}
                    style={{ maxWidth: "120px" }}
                  >
                    ALL SLOTS
                  </TimeSlotHolder>
                ) : (
                  offering.timeSlots?.map((timeSlot, index) => (
                    <TimeSlotHolder isSelected={true} onClick={() => {}}>
                      {getTimeInTwelveHourForm(timeSlot.time)}
                    </TimeSlotHolder>
                  ))
                )}
              </AddFlex>
            </AddFlex>
          ))}
        </AddMargin>
      );
    }
  };
  return <>{handleView()}</>;
};

export default SelectedEventsAndTimeSlots;
