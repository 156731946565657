import React, { useState } from "react";
import { AddFlex, CustomInput } from "../../reusableStyles";
import ReactDatePicker from "react-datepicker";
import { get24HourTime, getTimeInTwelveHourForm } from "../../utils";
import { headerTextColor } from "../../constants";
import { Add, DeleteOutlined } from "@mui/icons-material";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import {
  resetPricingIndexesAction,
  sortScheduleDetailsAction,
} from "../../Store/appTempSlice";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

function TimeSlotComponent({
  slot,
  handleUpdateSessionTime,
  timeSlotIndex,
  scheduleDetail,
  showDeleteIcon,
  handleDeleteTimeSlot,
  handleAddSession,
}) {
  const [isTimeSlotDeleting, setIsTimeSlotDeleting] = useState(false);
  const dispatch = useDispatch();
  return (
    <AddFlex alignItems="center" marginBottom="12px">
      <ReactDatePicker
        showTimeSelectOnly
        timeIntervals={15}
        value={getTimeInTwelveHourForm(slot.time)}
        onChange={(date) => {
          // handleSetTime(date);
          const time = get24HourTime(date);
          handleUpdateSessionTime(timeSlotIndex, "time", time);
        }}
        showTimeSelect
        customInput={
          <CustomInput
            widget={
              <MoreTimeOutlinedIcon
                sx={{ color: "#667085", fontSize: "22px" }}
              />
            }
            width={"100%"}
            placeholder={"Enter time in 24Hr form"}
            // onChange={(text) => {
            //   return;
            // }}
          />
        }
      />

      {showDeleteIcon &&
        (isTimeSlotDeleting ? (
          <CircularProgress size={16} sx={{ margin: "0px 0 0 15px" }} />
        ) : (
          <DeleteOutlined
            sx={{
              margin: "0px 0 0 15px",
              fontSize: "22px",
              color: headerTextColor,
              cursor: "pointer",
            }}
            onClick={async () => {
              setIsTimeSlotDeleting(true);
              await handleDeleteTimeSlot(timeSlotIndex);
              dispatch(resetPricingIndexesAction());
              setIsTimeSlotDeleting(false);
              // handleSetOffering(
              //   `scheduleDetails.${index}.timeSlots.delete.${timeSlotIndex}`,
              //   null
              // );
            }}
          />
        ))}
      {scheduleDetail.timeSlots.length - 1 === timeSlotIndex && (
        <Add
          sx={{
            margin: "0px 0 0 15px",
            fontSize: "22px",
            color: headerTextColor,
            cursor: "pointer",
          }}
          onClick={() => {
            handleAddSession();
            dispatch(resetPricingIndexesAction());
            // dispatch(sortScheduleDetailsAction());
          }}
        />
      )}
    </AddFlex>
  );
}

export default TimeSlotComponent;
