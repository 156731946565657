import React, { useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../../reusableStyles";
import { Backdrop, Dialog } from "@mui/material";
import GalleryComponent from "../../CreateOffering/GalleryComponent";
import PhotoUpload from "../../Customization/PhotoUpload";

function ImageTypeProperties({ data, setData }) {
  const [openGallery, setOpenGallery] = useState(false);
  const [imageName, setImageName] = useState(null);
  return (
    <AddFlex flexDirection="column">
      <CustomText>{imageName}</CustomText>
      <PhotoUpload
        photoURL={data.photoURL}
        handleGetFile={(photoURL) => {
          setData((prev) => ({ ...prev, photoURL }));
        }}
      />
      {/* <PrimaryActionButton
        onClick={() => {
          setOpenGallery(true);
        }}
      >
        {" "}
        Select Image
      </PrimaryActionButton> */}
      <Backdrop
        open={openGallery}
        style={{ position: "fixed", top: 0, left: 0, zIndex: 1111111 }}
        onClick={() => {
          setOpenGallery(false);
        }}
      >
        <GalleryComponent
          isMultiple={false}
          open={openGallery}
          prevSelectedImages={null}
          closeBackdrop={() => setOpenGallery(false)}
          handleGetSelectedImages={(file) => console.log(file)}
        />
      </Backdrop>
    </AddFlex>
  );
}

export default ImageTypeProperties;
