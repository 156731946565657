import React from "react";
import { AddFlex, CustomInput, CustomText } from "../reusableStyles";
import { useSelector } from "react-redux";

function OnboardingHeader({ stage, displayName }) {
  const getStageHeaderText = () => {
    switch (stage) {
      case 0:
        return {
          main: `Hello ${displayName ? displayName + "," : "!"}`,
          sub: "Just a few seconds and you'll be ready to grow!",
        };
      case 1:
        //category
        return {
          main: `Tell us a little about your business`,
          sub: "What category best describes you.",
        };
      case 2:
        // services
        return {
          main: `What services do you plan to offer ?`,
          sub: "This will help us customise the software for you !",
        };
      case 3:
        // usage
        return {
          main: `How do you plan to use Growezy ?`,
          sub: "Selecting any applicable features for your business helps us provide more personalised recommendations for your growth journey. ",
        };
      default:
        return <></>;
    }
  };
  return (
    <AddFlex
      margin="0 0 30px 0"
      flexDirection="column"
      justify="center"
      alignItems="center"
    >
      <CustomText fontSize="24px" fontWeight="bold" color="black">
        {getStageHeaderText(stage).main}
      </CustomText>
      <CustomText
        fontSize="16px"
        textAlign="center"
        color="black"
        margin="8px 0 0 0"
      >
        {getStageHeaderText(stage).sub}
      </CustomText>
    </AddFlex>
  );
}

export default OnboardingHeader;
