import { useSnackbar } from "notistack";
import React, { useState } from "react";
import useUsernameAvailabilityCheck from "../CustomHooks/useUsernameAvailabilityCheck";
import { AddFlex, CustomInputLabel } from "../reusableStyles";
import ProfileInfoUsernameAndURL from "./ProfileInfoUsernameAndURL";
import ProfileInfoHeader from "./ProfileInfoHeader";
import UserBio from "./UserBio";
import PhotoUpload from "./PhotoUpload";
import { useDispatch } from "react-redux";
import { getApiCallParams } from "../utils";
import { usersApiCall } from "../Store/userSlice";
import { UpdateUser } from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import AboutSection from "./AboutSection";
import ColorPickerInput from "./ColorCustomisations";

function ProfileInfo() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { apiUserData } = useSelector((state) => state.user);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userData, setUserData] = useState(apiUserData);

  const handleSetUserData = (key, value) => {
    setUserData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAbout = (text) => {
    setUserData((prev) => ({
      ...prev,
      UserAdditionalDetails: {
        ...prev.UserAdditionalDetails,
        about: text,
      },
    }));
  };
  const { isAvailable, loading, isErr, errMsg, usernameSuggestions } =
    useUsernameAvailabilityCheck(apiUserData.userName, userData.userName);

  const handleChangePhotoURL = (URL) => {
    handleSetUserData("photoURL", URL);
  };

  const handleChangeBio = (text) => {
    handleSetUserData("Bio", text);
  };
  const handleChangeName = (text) => {
    handleSetUserData("Name", text);
  };

  const handleChangeUsername = (text) => {
    handleSetUserData("userName", text);
  };

  const handleUpdateProfile = async () => {
    let _userData = userData;
    if (loading) return;
    if (userData.userName !== apiUserData.userName) {
      if (isAvailable) {
        _userData = {
          ...userData,
          userName: userData.userName.toLowerCase(),
        };
      } else {
        //snackbar for not available username

        enqueueSnackbar(
          "This username is already taken, please try another one",
          {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 6000,
          }
        );
        return;
      }
    }
    setIsUpdating(true);

    dispatch(usersApiCall(getApiCallParams(UpdateUser, _userData))).then(
      (actionResult) => {
        if (actionResult.meta.requestStatus === "fulfilled") {
          // Handle success

          enqueueSnackbar("Profile updated successfully", {
            variant: "success",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          setIsUpdating(false);
        } else {
          // Handle failure
          enqueueSnackbar("Something went wrong please try again", {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          console.error("Action failed", actionResult.error);
        }
      }
    );
  };

  return (
    <AddFlex margin={"30px 0"} flexDirection="column">
      <ProfileInfoHeader
        isUpdating={isUpdating}
        onSubmit={handleUpdateProfile}
      />
      <AddFlex flexDirection="column">
        <ProfileInfoUsernameAndURL
          username={userData.userName}
          name={userData.Name}
          isUsernameAvailable={isAvailable}
          handleChangeName={handleChangeName}
          isUsernameAvailabilityChecking={loading}
          errMessage={errMsg}
          isErr={isErr}
          usernameSuggestions={usernameSuggestions}
          handleChangeUsername={handleChangeUsername}
        />
        <CustomInputLabel>Profile Image</CustomInputLabel>
        <PhotoUpload
          photoURL={userData.photoURL}
          handleGetFile={handleChangePhotoURL}
          isProfileImage={true}
        />
        <UserBio bio={userData.Bio} handleBioChange={handleChangeBio} />
        <AboutSection
          about={userData.UserAdditionalDetails.about}
          handleAbout={handleAbout}
        />
      </AddFlex>
    </AddFlex>
  );
}
export default ProfileInfo;
