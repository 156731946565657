import { Backdrop } from "@mui/material";
import React, { useState } from "react";
import GalleryComponent from "./CreateOffering/GalleryComponent";
import { AddFlex, InputLabel, PrimaryActionButton } from "./reusableStyles";
import { isNullOrUndefined } from "./utils";

function OpenGalleryButton({
  component,
  buttonText,
  label,
  files,
  selectedComponent,
  handleGetFiles,
  isMultiple,
}) {
  const [openGallery, setOpenGallery] = useState(false);
  const handleOpenGallery = () => {
    setOpenGallery(true);
  };
  return (
    <AddFlex>
      {isNullOrUndefined(component) ? (
        <AddFlex
          flexDirection="column"
          onClick={() => {
            handleOpenGallery();
          }}
          gap="8px"
        >
          <InputLabel style={{ margin: 0 }}>{label}</InputLabel>
          {selectedComponent}
          <PrimaryActionButton style={{ width: "max-content" }}>
            {buttonText}
          </PrimaryActionButton>
        </AddFlex>
      ) : (
        React.cloneElement(component, {
          onClick: () => {
            handleOpenGallery();
          },
        })
      )}
      <Backdrop
        open={openGallery}
        style={{ position: "fixed", top: 0, left: 0, zIndex: 1111111 }}
        onClick={() => {
          setOpenGallery(false);
        }}
      >
        <GalleryComponent
          isMultiple={isMultiple}
          open={openGallery}
          prevSelectedImages={files}
          closeBackdrop={() => setOpenGallery(false)}
          handleGetSelectedImages={handleGetFiles}
        />
      </Backdrop>
    </AddFlex>
  );
}

export default OpenGalleryButton;
