import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isNullOrUndefined } from "../utils";

function MenuButton({ buttonComponent, buttonName, style, menuItems }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (action, args) => {
    if (isNullOrUndefined(args)) {
      await action();
      setAnchorEl(null);
      return;
    }
    const [arg1] = args;
    await action(arg1);
    setAnchorEl(null);
  };
  return (
    <>
      {buttonComponent ? (
        React.cloneElement(buttonComponent, {
          onClick: handleClick,
        })
      ) : (
        <PrimaryActionButton
          padding="10px"
          margin="0px 5px 0 0"
          borderRadius="8px"
          minWidth="130px"
          border={`none`}
          onClick={handleClick}
          style={style}
        >
          <AddFlex justify="center" width="100%">
            <CustomText color="white">{buttonName}</CustomText>
            <KeyboardArrowDownIcon sx={{ padding: "0px", fontSize: "18px" }} />
          </AddFlex>
        </PrimaryActionButton>
      )}
      {anchorEl && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: anchorEl && anchorEl.clientWidth,
            },
          }}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
        >
          {menuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.id}
              onClick={() => {
                handleClose(menuItem.action, menuItem.actionArgs);
              }}
            >
              {menuItem.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}

export default MenuButton;
