import { Warning } from "@mui/icons-material";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { AddFlex, CustomText } from "../../reusableStyles";
import React from "react";

function EmptyCaseUIComponent({ icon, height, emptyDescription, emptyTitle }) {
  return (
    <AddFlex
      flexDirection="column"
      justify="center"
      alignItems="center"
      height={height ? height : "30vh"}
    >
      <AddFlex
        backgroundColor={primaryActionColorLight}
        justify="center"
        alignItems="center"
        borderRadius="100%"
        style={{ width: "46px", height: "46px" }}
        color={primaryActionColor}
      >
        {icon ? React.cloneElement(icon) : <Warning />}
      </AddFlex>
      <AddFlex flexDirection="column" alignItems="center" margin="16px 0 0 0">
        <CustomText size="16px" fontWeight="600" color="black">
          {emptyTitle ? emptyTitle : "No Users Found"}
        </CustomText>
        <AddFlex margin="6px 0 0 0" style={{ maxWidth: "350px" }}>
          <CustomText textAlign="center">{emptyDescription}</CustomText>
        </AddFlex>
      </AddFlex>
    </AddFlex>
  );
}

export default EmptyCaseUIComponent;
