import React, { useEffect, useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import Filter from "@mui/icons-material/FilterList";
import { Checkbox, Drawer } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { FeaturesKeys, getOfferings } from "../utils";
import useWindowSize from "../Hooks/useWindowSize";
function TestimonialFilters({ setFilters, filters, onApplyClicked }) {
  const [offerings, setOfferings] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const { isMobileView } = useWindowSize();
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  useEffect(() => {
    const offerings = getOfferings(features);
    setOfferings(offerings);
  }, []);

  const handleToggleCheckbox = (filter) => {
    if (filters.some((_filter) => _filter.id === filter.id)) {
      setFilters((prevFilters) =>
        prevFilters.filter((_filter) => _filter.id !== filter.id)
      );
    } else {
      setFilters((prev) => [...prev, filter]);
    }
  };
  return (
    <>
      <AddFlex
        alignItems="center"
        style={{ gap: "5px", cursor: "pointer" }}
        padding="10px"
        border="1px solid #dddddd"
        borderRadius="4px"
        onClick={() => setOpenFilterDrawer((prev) => !prev)}
      >
        <CustomText fontSize="14px" color="black">
          Filters
        </CustomText>
        <Filter sx={{ fontSize: "14px" }} />
      </AddFlex>
      <Drawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer((prev) => !prev)}
        anchor="right"
        PaperProps={{
          sx: {
            width: isMobileView() ? "100vw" : "25vw",
          },
        }}
      >
        <AddFlex
          alignItems="center"
          justify="space-between"
          padding="10px 15px"
          style={{ borderBottom: "1px solid #dddddd" }}
        >
          <CustomText color="black">Filters</CustomText>
          <Close onClick={() => setOpenFilterDrawer((prev) => !prev)} />
        </AddFlex>
        <AddFlex padding="10px 15px" flexDirection="column">
          <CustomText margin="15px 0" color="black" fontWeight="600">
            Tags
          </CustomText>
          {features[FeaturesKeys.testimonial.id]?.tags?.map((tag) => (
            <AddFlex key={tag.id}>
              <Checkbox
                sx={{ padding: "0px" }}
                checked={filters.some((filter) => filter.id === tag.id)}
                onClick={() => handleToggleCheckbox(tag)}
              />
              <CustomText color="black" margin="0 5px">
                {tag.name}
              </CustomText>
            </AddFlex>
          ))}
        </AddFlex>
        <AddFlex padding="10px 15px" flexDirection="column">
          <CustomText margin="15px 0" color="black" fontWeight="600">
            Offerings
          </CustomText>
          {offerings.map((offering) => (
            <AddFlex key={offering.id}>
              <Checkbox
                sx={{ padding: "0px" }}
                checked={filters.some((filter) => filter.id === offering.id)}
                onClick={() => handleToggleCheckbox(offering)}
              />
              <CustomText color="black" margin="0 5px">
                {offering.name}
              </CustomText>
            </AddFlex>
          ))}
        </AddFlex>
        <AddFlex
          position="fixed"
          bottom="0"
          right="0"
          width={isMobileView() ? "100vw" : "25vw"}
          padding="10px 15px"
          backgroundColor="white"
          style={{ boxShadow: "0 0 0 2px rgba(0,0,0,0.05)" }}
          onClick={onApplyClicked}
        >
          <PrimaryActionButton margin="0 auto">Apply</PrimaryActionButton>
        </AddFlex>
      </Drawer>
    </>
  );
}

export default TestimonialFilters;
