import { useState } from "react";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import { primaryActionColor } from "../constants";
import { AddFlex, CustomText, Group } from "../reusableStyles";
import {
  formatPrice,
  generateUUID,
  getFormatedDateType1,
  handleCopyToClipboard,
  isNullOrUndefined,
} from "../utils";
import EventBillDetails from "./EventBillDetails";
import { ContentCopyOutlined } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

export const getPaymentMode = (transaction) => {
  if (
    isNullOrUndefined(transaction.data.gatewayCode) ||
    transaction.data.gatewayCode === "phonepe"
  ) {
    return transaction.paymentDetails?.data.paymentInstrument.type;
  } else {
    if (isNullOrUndefined(transaction.paymentDetails?.payment_method_types))
      return "";
    return transaction.paymentDetails?.payment_method_types[0];
  }
};

function TransactionHistoryCard({ transaction }) {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const getPrice = (amount, qty) => {
    return amount * qty;
  };
  const getSubProfileNames = (subProfileArr) => {
    var names = "";
    for (const subProfile of subProfileArr) {
      names += subProfile.name;
    }
    return names;
  };
  const getOrdersSubProfileWise = () => {
    const subProfileWiseList = [];
    subProfileWiseList.push(
      ...transaction.data.customerOrder.flatMap((order) => {
        return order.customerDetails.map((customer) => {
          return {
            ...customer,
            filterId: generateUUID(),
            id: transaction.data.id,
            ticketPrice: order.ticketPrice,
            ticketName: order.ticketName,
          };
        });
      })
    );
    return subProfileWiseList;
  };

  return (
    <Group
      marginTop="0"
      margin="0 0 10px 0"
      padding="5px"
      boxShadow="none"
      style={{
        border: "1px solid #ececec",
      }}
    >
      <AddFlex flexDirection="column" padding="8px">
        <AddFlex
          style={{ paddingBottom: "5px", borderBottom: "0.2px solid #ececec" }}
          alignItems="flex-start"
          justify="space-between"
        >
          <AddFlex alignItems="center" gap="5px">
            <CustomText fontSize="14px" color="black">
              {transaction.transactionId}
            </CustomText>
            <ContentCopyOutlined
              onClick={async (e) => {
                e.stopPropagation();
                handleCopyToClipboard(transaction.transactionId).then(() => {
                  enqueueSnackbar("Order ID copied to clipboard", {
                    variant: "success",
                  });
                });
              }}
              sx={{
                fontSize: "14px",
                color: primaryActionColor,
                cursor: "pointer",
              }}
            />
          </AddFlex>
          <CustomText>
            {transaction.saleDate &&
              getFormatedDateType1(new Date(transaction.saleDate))}
          </CustomText>
        </AddFlex>
        <CustomText
          margin="15px 0 0 0"
          fontSize="16px"
          fontWeight="400"
          color="black"
        >
          {transaction.data.featureDetails.name}
        </CustomText>
        <AddFlex flexDirection="column" margin="5px 0 0 0">
          {getOrdersSubProfileWise().map((item) => (
            <AddFlex
              margin="5px 0 0 0"
              alignItems="flex-start"
              justify="space-between"
            >
              <AddFlex>
                <CustomText
                  fontSize="16px"
                  fontWeight="normal"
                  color="#4F4F4F"
                  margin="0 10px 0 0"
                >
                  {item.name}
                </CustomText>
                <CustomText fontWeight="normal" color="#4F4F4F">
                  {item.ticketName}
                </CustomText>
              </AddFlex>
              <CustomText fontWeight="normal" fontSize="16px" color="#4F4F4F">
                {formatPrice(
                  item.ticketPrice,
                  transaction.data.paymentBreakdown.currency
                )}
              </CustomText>
            </AddFlex>
          ))}
        </AddFlex>
        {isShowDetails && (
          <AddMargin margin="0px 0">
            <EventBillDetails
              paymentBreakdown={transaction.data.paymentBreakdown}
              paymentMode={getPaymentMode(transaction)}
              paymentGateway={transaction.data.gatewayCode}
              currency={transaction.data.paymentBreakdown.currency}
              payoutAmount={transaction.data.payoutAmount}
            />
          </AddMargin>
        )}
        <CustomText
          color={primaryActionColor}
          fontWeight="500"
          margin="10px 0 0 0"
          onClick={() => {
            setIsShowDetails((prev) => !prev);
          }}
          style={{ textDecoration: "underline" }}
        >
          {isShowDetails ? "Hide less" : "View Details"}
        </CustomText>
      </AddFlex>
    </Group>
  );
}

export default TransactionHistoryCard;
