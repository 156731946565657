import React, { useEffect, useState } from "react";
import { AddFlex, PrimaryActionButton } from "../reusableStyles";
import useWindowSize from "../Hooks/useWindowSize";
import AddTestimonialButton from "./AddTestimonialButton";
import TestimonialsHeader from "./TestimonialsHeader";
import { useSelector } from "react-redux";
import {
  FeaturesKeys,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import TestimonialsHolder from "./TestimonialsHolder";
import { TestimonialTabs } from "../constants";
import WallOfLove from "./WallofLove";

function TestimonialsTab() {
  const { isMobileView } = useWindowSize();
  const [testimonials, setTestimonials] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TestimonialTabs.ALL);
  const _testimonials = useSelector(
    (state) =>
      state.userFeatures.userFeatures.features[FeaturesKeys.testimonial.id]
        ?.items
  );

  useEffect(() => {
    setSelectedTab(selectedTab);
  }, []);

  const setAllTestimonials = () => {
    setTestimonials(_testimonials);
  };

  const handleMainTestimonialFilter = (selectedTab) => {
    if (selectedTab === TestimonialTabs.WallOfLove) {
      setTestimonials(
        _testimonials?.filter(
          (testimonial) => testimonial.isWallOfLove === true
        )
      );
    } else if (selectedTab === TestimonialTabs.ALL) {
      setAllTestimonials();
    } else if (selectedTab === TestimonialTabs.SELF) {
      setTestimonials(
        _testimonials?.filter(
          (testimonial) => testimonial.source === TestimonialTabs.SELF
        )
      );
    } else {
      setTestimonials(
        _testimonials?.filter(
          (testimonial) => testimonial.source === TestimonialTabs.PUBLIC
        )
      );
    }
    setSelectedTab(selectedTab);
  };

  const handleSearchFilter = (searchText) => {
    if (searchText.length === 0) {
      setAllTestimonials();
      return;
    }
    setTestimonials((prev) =>
      prev.filter((testimonial) => {
        if (
          isNullOrUndefined(testimonial.msg) &&
          isNullOrUndefined(testimonial.name)
        ) {
          return false;
        } else if (isNullOrUndefined(testimonial.name)) {
          return testimonial.msg.toLowerCase().includes(searchText);
        } else if (isNullOrUndefined(testimonial.msg)) {
          return testimonial.name.toLowerCase().includes(searchText);
        }
        return (
          testimonial.msg.toLowerCase().includes(searchText) ||
          testimonial.name.toLowerCase().includes(searchText)
        );
      })
    );
  };

  function filterArray(firstArray, secondArray) {
    // Create a Set of ids from the first array for efficient lookup
    const firstArrayIds = new Set(firstArray.map((item) => item.id));

    // Filter the second array based on the condition
    const filteredArray = secondArray.filter((element) => {
      // Check if the offering.id exists in the firstArrayIds

      if (
        !isNullOrUndefined(element.offering) &&
        firstArrayIds.has(element.offering.id)
      ) {
        return true;
      }

      // Check if any of the tags' ids exist in the firstArrayIds
      return (
        !isArrayNullOrUndefined(element.tags) &&
        element.tags.some((tag) => firstArrayIds.has(tag.id))
      );
    });

    return filteredArray;
  }

  const handleApplyExtraFilters = (extraFilters) => {
    if (isArrayNullOrUndefined(extraFilters)) {
      return setAllTestimonials();
    }
    const filteredTestimonials = filterArray(extraFilters, testimonials?.items);
    setTestimonials(filteredTestimonials);
  };

  useEffect(() => {
    setAllTestimonials(_testimonials);
  }, [_testimonials]);
  return (
    <AddFlex
      flexDirection="column"
      padding={isMobileView() ? "0 10px" : "0 48px"}
    >
      <TestimonialsHeader
        selectedTab={selectedTab}
        handleMainTestimonialFilter={handleMainTestimonialFilter}
        handleSearchFilter={(text) => handleSearchFilter(text.toLowerCase())}
        handleApplyExtraFilters={handleApplyExtraFilters}
      />
      {!isMobileView() && <AddTestimonialButton />}
      {testimonials?.length > 0 &&
      selectedTab === TestimonialTabs.WallOfLove ? (
        <WallOfLove
          testimonials={_testimonials.filter(
            (testimonial) => testimonial.isWallOfLove === true
          )}
        />
      ) : (
        <TestimonialsHolder testimonials={testimonials} />
      )}

      {isMobileView() && (
        <AddFlex
          position="fixed"
          bottom="0px"
          left="0px"
          width="100%"
          padding="10px"
          backgroundColor="white"
          style={{
            zIndex: "1111",
            boxShadow: "0 0 0px 4px rgba(0,0,0,0.05)",
          }}
        >
          <AddTestimonialButton />
        </AddFlex>
      )}
    </AddFlex>
  );
}

export default TestimonialsTab;
