import React, { useState } from "react";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  Gap,
  SecondaryActionButton,
} from "../reusableStyles";
import { convertToFixedPointDecimal, isNullOrUndefined } from "../utils";
import { Dialog } from "@mui/material";

function TopCitiesStats({ totalViews, cityStats }) {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <AddFlex
        backgroundColor="white"
        flexDirection="column"
        padding="18px"
        width="50%"
        style={{
          borderRadius: "10px",
          gap: "15px",
          marginTop: "20px",
          boxShadow: "0px 0px 10px 1px #E7E9ED",
        }}
      >
        <AddFlex>
          <CustomText fontSize="16px" color="#000" fontWeight="500">
            Top Cities
          </CustomText>
          <Gap />
          {cityStats?.length > 4 && (
            <SecondaryActionButton
              onClick={() => {
                setOpenDialog((prev) => !prev);
              }}
              style={{ padding: 0, border: "none" }}
            >
              View more
            </SecondaryActionButton>
          )}
        </AddFlex>

        {isNullOrUndefined(cityStats) ? (
          <div style={{ alignSelf: "center" }}>No Data </div>
        ) : (
          <CityStats stats={cityStats.slice(0, 4)} totalViews={totalViews} />
        )}
      </AddFlex>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog((prev) => !prev);
        }}
        fullWidth
        PaperProps={{
          sx: {
            padding: "20px",
            borderRadius: "20px",
            height: "60vh",
          },
        }}
      >
        <DialogHeader
          mainText={"All Cities"}
          onClose={() => {
            setOpenDialog((prev) => !prev);
          }}
        />
        <AddFlex flexDirection="column" gap="10px" margin="20px 0 0 0">
          <CityStats stats={cityStats} totalViews={totalViews} />
        </AddFlex>
      </Dialog>
    </>
  );
}

export default TopCitiesStats;

export const CityStats = ({ stats, margin, totalViews }) => {
  return stats.map((item, index) => (
    <AddFlex style={{ gap: "3px" }}>
      <CustomText fontSize="16px" color="#333">
        {index + 1}.
      </CustomText>
      <AddFlex flexDirection="column" style={{ gap: "4px", flexGrow: 1 }}>
        <CustomText fontSize="16px" color="#333">
          {item.name}
        </CustomText>
        <AddFlex style={{ gap: "20px" }}>
          <CustomText fontSize="14px" color="#888">
            {item.views} views
          </CustomText>

          <CustomText fontSize="14px" color="#888">
            {item.clicks} clicks
          </CustomText>
        </AddFlex>
      </AddFlex>
      <CustomText
        fontSize="14px"
        style={{ alignSelf: "center", marginBottom: "3px" }}
      >
        {parseFloat((item.views * 100) / totalViews).toFixed(2)}%
      </CustomText>
    </AddFlex>
  ));
};
