import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { OfferingTypes } from "../utils";
import CreateOfferingDialogCard from "./OfferingsPages/CreateOfferingDialogCard";
import Close from "@mui/icons-material/Close";

function CreateOfferingDialogContent({ closeDialog }) {
  return (
    <>
      <AddFlex
        flexDirection="column"
        alignItems="center"
        padding="20px 15px 8px 15px"
      >
        <CustomText fontSize="20px" fontWeight="500" color="black">
          Add New Offering
        </CustomText>
        <div
          style={{
            width: "72px",
            height: "2px",
            backgroundColor: "#F4900C",
            margin: "6px 0 0 0",
          }}
        ></div>
      </AddFlex>
      <AddFlex justify="center" wrap="wrap" padding="22px">
        {OfferingTypes.map((offering) => (
          <AddFlex
            flexDirection="column"
            width="30%"
            padding="20px"
            border="1px solid #D0D5DD"
            style={{
              gap: "24px",
              flexShrink: 0,
              boxShadow: "0px 0px 5px 1px rgba(52, 64, 84, 0.15)",
            }}
            marginRight="22px"
            marginBottom="22px"
            borderRadius="16px"
          >
            <CreateOfferingDialogCard offering={offering} />
          </AddFlex>
        ))}
      </AddFlex>
      <Close
        onClick={closeDialog}
        sx={{
          position: "absolute",
          cursor: "pointer",
          top: "20px",
          right: "68px",
        }}
      />
    </>
  );
}

export default CreateOfferingDialogContent;
