import React, { useState } from "react";
import { CustomText, DialogHeader } from "../../reusableStyles";
import useWindowSize from "../../Hooks/useWindowSize";
import { format } from "date-fns";
import {
  FeaturesKeys,
  defaultPlanColorCode,
  generateUUID,
  getTimeInTwelveHourForm,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  planColorCodes,
} from "../../utils";
import { Dialog, Drawer } from "@mui/material";
import PlanForm from "./AddPlanForm";
import { Add } from "@mui/icons-material";
import { StyledButton } from "./ScheduleComponents/EventOrWorkshopSchedule";
import PlanHolderHeader from "./PlanHolderHeader";
import PlanHolderPlans from "./PlanHolderPlans";
import SubscriptionPlanForm from "./SubscriptionPlanForm";
import { useSearchParams } from "react-router-dom";
import { TertiaryButton } from "./ScheduleComponents/CoursesSchedule";
function PlanHolder({
  offering,
  selectedDateIndex,
  selectedTimeSlotIndex,
  handleSetOffering,
}) {
  const { isMobileView, windowSize } = useWindowSize();
  const [plan, setPlan] = useState(undefined);
  const [isAddPlan, setIsAddPlan] = useState(false);
  const [searchParams] = useSearchParams();

  const [addTicketsToAllSlots, setAddTicketsToAllSlots] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleGetPlanForm = (pageType) => {
    switch (pageType) {
      case FeaturesKeys.subscription.name: {
        return (
          <SubscriptionPlanForm
            plan={plan}
            offering={offering}
            isAddPlan={isAddPlan}
            index={getPlanIndex(plan)}
            updatePlans={handleUpdatePlans}
            closeDialog={() => {
              setIsAddPlan(false);
              setIsDialogOpen(false);
              // setPlan(null);
            }}
          />
        );
      }
      default:
        return (
          <PlanForm
            plan={plan}
            offering={offering}
            isAddPlan={isAddPlan}
            pageType={pageType}
            index={getPlanIndex(plan)}
            updatePlans={handleUpdatePlans}
            closeDialog={() => {
              setIsAddPlan(false);
              setIsDialogOpen(false);
              // setPlan(null);
            }}
          />
        );
    }
  };

  const getTotalSeatsForTimeSlot = (previousTickets, newPlan) => {
    if (newPlan.totalSeats === -1) {
      return -1;
    }
    if (isArrayNullOrUndefined(previousTickets)) {
      return newPlan.totalSeats;
    }
    let sum = 0;
    for (let ticket of previousTickets) {
      if (ticket.totalSeats === -1) {
        sum = -1;
        break;
      }
      sum += ticket.totalSeats;
    }
    if (sum === -1) {
      return -1;
    } else {
      return sum + newPlan.totalSeats;
    }
  };
  const handleAddPlanToAllSlots = (plan) => {
    setAddTicketsToAllSlots(true);
    const newPlan = { ...plan };
    delete newPlan.isAddedToAllSlots;
    for (let [dateIndex, date] of offering.scheduleDetails.entries()) {
      for (let [timeSlotIndex, timeSlot] of date.timeSlots.entries()) {
        if (
          handleCheckIsPlanInTimeSlotPresent(
            dateIndex,
            timeSlotIndex,
            newPlan.ticketName,
            -1
          )
        ) {
          return { error: "Cannot have plans with same name" };
        }
      }
    }

    const new_dates = offering.scheduleDetails.map((date, dateIndex) => {
      const newDateObj = JSON.parse(
        JSON.stringify({
          ...date,
          timeSlots: date.timeSlots.map((timeslot, timeSlotIndex) => {
            return {
              ...timeslot,
              totalSeats: getTotalSeatsForTimeSlot(timeslot.tickets, newPlan),
              tickets: [
                ...timeslot.tickets,
                { ...newPlan, id: generateUUID() },
              ], // or use a more robust method for generating IDs
            };
          }),
        })
      );
      return newDateObj;
    });
    handleSetOffering("scheduleDetails", new_dates);
    return { error: null };
  };

  const handleCheckIsPlanInTimeSlotPresent = (
    selectedDateIndex,
    timeSlotIndex,
    planName,
    updateIndex
  ) => {
    const plansInTimeSlot =
      offering.scheduleDetails[selectedDateIndex].timeSlots[timeSlotIndex]
        .tickets;
    for (let i = 0; i < plansInTimeSlot.length; i++) {
      if (i === updateIndex) continue;
      if (
        plansInTimeSlot[i].ticketName.toLowerCase() === planName.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  };

  const adjustTicketAvailability = (plan, updateIndex) => {
    let totalSeats = 0,
      tickets;
    // delete case
    if (isNullOrUndefined(plan)) {
      tickets = offering.scheduleDetails[selectedDateIndex].timeSlots[
        selectedTimeSlotIndex
      ].tickets.filter((ticket, index) => index !== updateIndex);
    } else {
      // add and update case
      if (updateIndex !== -1) {
        tickets = offering.scheduleDetails[selectedDateIndex].timeSlots[
          selectedTimeSlotIndex
        ].tickets.map((ticket, index) =>
          index === updateIndex ? plan : ticket
        );
      } else {
        tickets = [
          ...offering.scheduleDetails[selectedDateIndex].timeSlots[
            selectedTimeSlotIndex
          ].tickets,
          plan,
        ];
      }
    }

    if (tickets.some((ticket) => ticket.totalSeats === -1)) return -1;
    for (let ticket of tickets) {
      totalSeats += ticket.totalSeats;
    }
    return totalSeats;
  };

  const handleUpdatePlans = (action, updateIndex, newEventPlan) => {
    if (newEventPlan.isAddedToAllSlots && action === "addnew") {
      const response = handleAddPlanToAllSlots(newEventPlan);
      if (response.error) return { error: response.error };
      return { error: null };
    }
    const timeSlots = [
      ...offering.scheduleDetails[selectedDateIndex].timeSlots,
    ];

    if (
      handleCheckIsPlanInTimeSlotPresent(
        selectedDateIndex,
        selectedTimeSlotIndex,
        newEventPlan.ticketName,
        updateIndex
      )
    ) {
      return { error: "Cannot have plans with same name" };
    }
    //to update the total number of seats
    // handleUpdateTotalSeats(action, newEventPlan, updateIndex);
    let timeSlotSeats;
    if (action === "addnew") {
      let colorCode;
      if (
        isNullOrUndefined(
          planColorCodes[timeSlots[selectedTimeSlotIndex].tickets.length]
        )
      ) {
        colorCode = defaultPlanColorCode;
      } else {
        colorCode =
          planColorCodes[timeSlots[selectedTimeSlotIndex].tickets.length];
      }
      newEventPlan = { ...newEventPlan, planColorCodes: colorCode };

      handleSetOffering(
        `scheduleDetails.${selectedDateIndex}.timeSlots.${selectedTimeSlotIndex}.tickets.push`,
        newEventPlan
      );
      timeSlotSeats = adjustTicketAvailability(newEventPlan, updateIndex);
    } else if (action === "update") {
      const prevPlan =
        offering.scheduleDetails[selectedDateIndex].timeSlots[
          selectedTimeSlotIndex
        ].tickets[updateIndex];
      handleSetOffering(
        `scheduleDetails.${selectedDateIndex}.timeSlots.${selectedTimeSlotIndex}.tickets.${updateIndex}`,
        newEventPlan
      );

      timeSlotSeats = adjustTicketAvailability(newEventPlan, updateIndex);
    } else {
      const prevPlan =
        offering.scheduleDetails[selectedDateIndex].timeSlots[
          selectedTimeSlotIndex
        ].tickets[updateIndex];
      handleSetOffering(
        `scheduleDetails.${selectedDateIndex}.timeSlots.${selectedTimeSlotIndex}.tickets.delete.${updateIndex}`,
        newEventPlan
      );
      timeSlotSeats = adjustTicketAvailability(null, updateIndex);
    }
    handleSetOffering(
      `scheduleDetails.${selectedDateIndex}.timeSlots.${selectedTimeSlotIndex}.totalSeats`,
      timeSlotSeats
    );
    return { error: null };
  };

  const getPlanIndex = (plan) => {
    return offering.scheduleDetails[selectedDateIndex].timeSlots[
      selectedTimeSlotIndex
    ]?.tickets.indexOf(plan);
  };

  return (
    offering.scheduleDetails[selectedDateIndex] && (
      <>
        <PlanHolderHeader
          headerText={
            <>
              <b>Add Tickets</b> ( For Session{" "}
              {offering.scheduleDetails[selectedDateIndex].date &&
                format(
                  new Date(offering.scheduleDetails[selectedDateIndex].date),
                  "MMM dd"
                )}
              ,{" "}
              {getTimeInTwelveHourForm(
                offering.scheduleDetails[selectedDateIndex].timeSlots[
                  selectedTimeSlotIndex
                ]?.time
              )}
              )
            </>
          }
        />
        <PlanHolderPlans
          handleUpdatePlans={handleUpdatePlans}
          setPlan={(plan) => {
            setIsDialogOpen(true);
            setPlan(plan);
          }}
          tickets={
            offering.scheduleDetails[selectedDateIndex].timeSlots[
              selectedTimeSlotIndex
            ]?.tickets
          }
        />
        <div>
          <TertiaryButton
            buttonName={"Add Ticket"}
            onClick={() => {
              setIsAddPlan(true);
              setIsDialogOpen(true);
            }}
          />
        </div>
        {isMobileView() ? (
          <Drawer
            PaperProps={{
              sx: {
                height: windowSize.screenHeight * 0.8 + "px",
                borderRadius: "12px",
              },
            }}
            onClose={() => {
              setIsDialogOpen(false);
              setPlan(null);
            }}
            open={!isNullOrUndefined(plan) || isDialogOpen}
            anchor="bottom"
          >
            {handleGetPlanForm(searchParams.get("pageType"))}
          </Drawer>
        ) : (
          <Dialog
            PaperProps={{
              sx: {
                width: "45vw",
                height: "80vh",
                padding: "20px",
                borderRadius: "32px",
              },
            }}
            onClose={() => {
              setIsDialogOpen(false);
              setPlan(null);
            }}
            fullWidth
            open={isDialogOpen}
          >
            {/* <PlanForm
            plan={plan}
            index={getPlanIndex(plan)}
            updatePlans={handleUpdatePlans}
            closeDialog={() => {
              setIsDialogOpen(false);
              setPlan(null);
            }}
          /> */}

            {handleGetPlanForm(searchParams.get("pageType"))}
          </Dialog>
        )}
      </>
    )
  );
}

export default PlanHolder;
