import React, { useState } from "react";
import ClientKYCInformation from "./ClientKYCInformation";
import useWindowSize from "../Hooks/useWindowSize";
import ContactDetails from "./ContactDetails";
import { countries } from "countries-list";
import Tabs from "../Tabs";
import CurrentSubscriptionPlanCard from "./Subscriptions/CurrentPlanCard";
import SubscriptionPage from "./Subscriptions/SubscriptionPage";
import { AddFlex, ContentHeader, CustomText } from "../reusableStyles";
import VerticalNav from "./VerticalNav";
import { Divider } from "@mui/material";
import KYC from "./KYC";
import KYCParent from "./KYCParent";
import BankParent from "./BankParent";
import GSTParent from "./GSTParent";
import PaymentsParent from "./PaymentsParent";
import CommissionParent from "./CommissionParent";
import SuperAdmin from "./SuperAdmin";
import { useSelector } from "react-redux";
import { admins, handleOpenInNew, logout } from "../utils";
import { getStoreRef } from "../Store/persiststore";

const TAB_ID = {
  subscription: "subscription",
  kyc: "kyc",
  payments: "payments",
  tax: "tax",
  contactDetils: "contact_details",
  commissionDetails: "comission_details",
  support: "contact_growezy_support",
  logout: "logout",
  superAdmin: "super_admin",
};

function AccountCenter() {
  const { windowSize } = useWindowSize();
  const [selectedTab, setSelectedTabIndex] = useState({
    index: 0,
    id: "subscription",
  });

  const { userId } = useSelector((state) => state.user.apiUserData);
  const { data } = useSelector((state) => state.appTemp);

  const getTabs = () => {
    let tabs = [
      { name: "Subscription", id: TAB_ID.subscription },
      { name: "KYC", id: TAB_ID.kyc },
      { name: "Payments", id: TAB_ID.payments },
      { name: "Tax", id: TAB_ID.tax },
      { name: "Contact Details", id: TAB_ID.contactDetils },
      { name: "Comission Details", id: TAB_ID.commissionDetails },
      {
        name: "Contact Support",
        id: TAB_ID.support,
        action: () => {
          handleOpenInNew("https://official.growezy.club/form/contact-us");
        },
      },
      {
        name: "Logout",
        id: TAB_ID.logout,
        action: () => {
          logout(getStoreRef());
        },
      },
    ];
    if (
      Object.values(admins).includes(userId) ||
      Object.values(admins).includes(data.loginId)
    ) {
      tabs.push({ name: "Super Admin", id: TAB_ID.superAdmin });
    }
    return tabs;
  };
  const tabs = getTabs();

  return (
    <AddFlex margin="20px 0" height="100%" gap="32px" padding="0 48px">
      <VerticalNav
        tabs={tabs}
        margin={"0 48px 0 48px"}
        selectedTabIndex={selectedTab.index}
        setSelectedTabIndex={(index, tab) =>
          setSelectedTabIndex({
            index,
            id: tab.id,
          })
        }
      />

      <AddFlex
        style={{
          flexGrow: 1,
          height: "90%",
          overflow: "scroll",
        }}
      >
        {(() => {
          switch (selectedTab.id) {
            case TAB_ID.subscription: {
              return <SubscriptionPage />;
            }
            case TAB_ID.kyc: {
              return (
                <>
                  <KYCParent />
                </>
              );
            }
            case TAB_ID.payments: {
              return (
                <>
                  <PaymentsParent />
                </>
              );
            }
            case TAB_ID.tax: {
              return (
                <>
                  <GSTParent windowSize={windowSize} />
                </>
              );
            }

            case TAB_ID.contactDetils: {
              let _data = Object.values(countries);
              _data.sort((a, b) =>
                parseFloat(a.phone) > parseFloat(b.phone) ? 1 : -1
              );
              return <ContactDetails countries={_data} />;
            }
            case TAB_ID.commissionDetails: {
              return <CommissionParent />;
            }
            case TAB_ID.superAdmin: {
              return <SuperAdmin />;
            }
            default: {
              <p>Opps</p>;
            }
          }
        })()}
      </AddFlex>
    </AddFlex>
  );
}

export default AccountCenter;
