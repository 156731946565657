import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  DialogHeader,
  Gap,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import TextEditor from "../CreateOffering/TextEditor";
import {
  Delete,
  Edit,
  Mail,
  MailOutlineOutlined,
  WhatsApp,
} from "@mui/icons-material";
import { Checkbox, CircularProgress, Divider, Drawer } from "@mui/material";
import { primaryActionColor } from "../constants";
import { callNonStoreApi } from "../NonStoreApis";
import {
  BroadcastToMembers,
  FetchMembersForBroadcast,
} from "../Api/ApiEndPoints";
import { useSearchParams } from "react-router-dom";
import {
  extractTextFromRichText,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  isStringNullOrUndefined,
} from "../utils";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import OpenGalleryButton from "../OpenGalleryButton";
import SuccessChip from "../Integrations/SuccessChip";
import EmptyCaseUIComponent from "../Pages/Components/EmptyCaseUIComponent";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";

const channels = [
  // {
  //   id: "email",
  //   name: "Email",
  //   icon: (
  //     <MailOutlineOutlined
  //       sx={{ fontSize: "18px", color: primaryActionColor }}
  //     />
  //   ),
  // },
  {
    id: "whatsapp",
    name: "Whatsapp",
    icon: <WhatsApp sx={{ fontSize: "18px", color: "green" }} />,
  },
];

function BrodcastMain() {
  const [members, setMembers] = useState([]);
  const { Name } = useSelector((state) => state.user.apiUserData);
  const [payload, setPayload] = useState({
    message: "",
    subject: "",
    selectedMembers: [],
    selectedChannels: ["whatsapp"],
  });
  const [searchParams] = useSearchParams();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isSendingBroadcast, setIsSendingBroadcast] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const handleSetPayload = (key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }));
  };

  const handleSetSelectedChannels = (channel) => {
    if (payload.selectedChannels.includes(channel)) {
      return handleSetPayload(
        "selectedChannels",
        payload.selectedChannels.filter((item) => item !== channel)
      );
    }
    return handleSetPayload("selectedChannels", [
      ...payload.selectedChannels,
      channel,
    ]);
  };

  const handleSetSelectAllMembers = (members) => {
    return handleSetPayload("selectedMembers", members);
  };

  const handleSetSelectedMembers = (member) => {
    if (payload.selectedMembers.includes(member)) {
      return handleSetPayload(
        "selectedMembers",
        payload.selectedMembers.filter(
          (item) => item.customerId !== member.customerId
        )
      );
    }
    return handleSetPayload("selectedMembers", [
      ...payload.selectedMembers,
      member,
    ]);
  };

  const handleFetchMembersForBroadcast = async () => {
    try {
      const response = await callNonStoreApi(FetchMembersForBroadcast, {
        featureItemId: searchParams.get("fid"),
      });
      setMembers(response.data.res);
      setPayload({
        message: "",
        subject: "",
        selectedMembers: response.data.res,
        selectedChannels: ["whatsapp"],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBroadcastToMembers = async () => {
    try {
      if (isSendingBroadcast) return;
      setIsSendingBroadcast(true);
      if (
        isStringNullOrUndefined(payload.subject) ||
        isStringNullOrUndefined(extractTextFromRichText(payload.message)) ||
        isArrayNullOrUndefined(payload.selectedChannels) ||
        isArrayNullOrUndefined(payload.selectedMembers)
      ) {
        return enqueueSnackbar("Please fill all the details", {
          variant: "error",
        });
      }
      const response = await callNonStoreApi(BroadcastToMembers, {
        payload,
        clientName: Name,
      });
      setIsMessageSent(true);
      return enqueueSnackbar("Message broadcasted successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    } finally {
      setIsSendingBroadcast(false);
    }
  };

  useEffect(() => {
    if (!isMessageSent) handleFetchMembersForBroadcast();
  }, [isMessageSent]);

  return isMessageSent ? (
    <EmptyCaseUIComponent
      emptyTitle={"Message sent successfully"}
      icon={<DoneAllOutlinedIcon />}
      emptyDescription={
        <PrimaryActionButton
          onClick={() => {
            setIsMessageSent(false);
          }}
        >
          Send another message
        </PrimaryActionButton>
      }
    />
  ) : (
    <>
      <AddFlex gap="20px" flexDirection="column" width="60%" margin="30px auto">
        <AddFlex alignItems="center">
          <CustomText color="black" fontSize="24px" fontWeight="500">
            New Message
          </CustomText>
          <Gap />
          <PrimaryActionButton
            style={{
              width: "165px",
              height: "35px",
              borderRadius: "25px",
            }}
            disabled={isSendingBroadcast}
            onClick={handleBroadcastToMembers}
          >
            {isSendingBroadcast ? (
              <CircularProgress sx={{ color: "white" }} size={16} />
            ) : (
              "Send Message"
            )}
          </PrimaryActionButton>
        </AddFlex>
        <AddFlex>
          <AddFlex alignItems="center" gap="10px" width="100%">
            To:
            <AddFlex
              padding="5px 10px"
              borderRadius="25px"
              alignItems="center"
              border="1px solid grey"
              style={{ fontSize: "14px", cursor: "pointer" }}
              gap="7px"
              onClick={() => {
                setOpenDrawer(true);
              }}
            >
              Members ({payload.selectedMembers.length})
              <Divider
                orientation="vertical"
                sx={{ flexShrink: 0, height: "18px" }}
              />
              <SecondaryActionButton textOnly color="black">
                <Edit sx={{ fontSize: "14px" }} />
              </SecondaryActionButton>
            </AddFlex>
          </AddFlex>
        </AddFlex>
        <AddFlex gap="8px" flexDirection="column">
          <CustomText color="black" fontSize="14px">
            Send Via:{" "}
          </CustomText>
          <AddFlex gap="15px">
            {channels.map((channel) => (
              <ChanelCard
                channel={channel}
                isSelceted={payload.selectedChannels.includes(channel.id)}
                onClick={handleSetSelectedChannels}
              />
            ))}
          </AddFlex>
        </AddFlex>
        <CustomInput
          label={"Subject"}
          value={payload.subject}
          placeholder={"Write what this message is about"}
          onChange={(text) => {
            handleSetPayload("subject", text);
          }}
        />
        <TextEditor
          value={payload.message}
          label="Body"
          placeholder="Your message goes here.."
          getTextEditorInput={(text) => {
            handleSetPayload("message", text);
          }}
        />

        <OpenGalleryButton
          label={"Add a document (optional)"}
          selectedComponent={
            payload.attachment && (
              <SuccessChip
                style={{ margin: "0px 0", width: "max-content" }}
                successChipText={payload.attachment.name}
                showIcon={true}
                disableAction={true}
                icon={
                  <Delete
                    sx={{
                      color: "#047647",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPayload((prev) => ({ ...prev, attachment: null }));
                    }}
                  />
                }
              />
            )
          }
          buttonText={"Click to upload"}
          isMultiple={false}
          handleGetFiles={(isMultiple, files) => {
            setPayload((prev) => ({
              ...prev,
              attachment: { name: files[0].name, src: files[0].src },
            }));
          }}
        />
      </AddFlex>
      <Drawer
        anchor="right"
        open={openDrawer}
        PaperProps={{
          sx: {
            width: "35vw",
          },
        }}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <DialogHeader
          mainText={"Members"}
          subText={
            "Select members for which you want to broadcast this message"
          }
          onClose={() => {
            setOpenDrawer(false);
          }}
          style={{ padding: "10px", borderBottom: "1px solid #ececec" }}
        />
        <AddFlex
          padding="20px 20px 100px 20px"
          gap="15px"
          style={{ overflow: "scroll" }}
          flexDirection="column"
        >
          <AddFlex gap="8px" marginBottom="20px" alignItems="center">
            <Checkbox
              sx={{ padding: "0px" }}
              checked-={payload.selectedMembers.length === members.length}
              size="small"
              onChange={(e) => {
                if (e.target.checked === true) {
                  handleSetSelectAllMembers(members);
                } else {
                  handleSetSelectAllMembers([]);
                }
              }}
            />
            <CustomText fontSize="14px" color="black">
              Select All
            </CustomText>
          </AddFlex>
          {members.map((member) => (
            <>
              <MemberCard
                member={member}
                isSelected={payload.selectedMembers.some(
                  (_member) => _member.customerId === member.customerId
                )}
                onClick={(member) => {
                  handleSetSelectedMembers(member);
                }}
              />
              <Divider />
            </>
          ))}
        </AddFlex>
        <AddFlex
          position="absolute"
          bottom="0px"
          style={{ width: "100%" }}
          backgroundColor="white"
          padding="10px"
          alignItems="center"
          justify="center"
        >
          <PrimaryActionButton
            onClick={() => {
              setOpenDrawer(false);
            }}
          >
            Submit
          </PrimaryActionButton>
        </AddFlex>
      </Drawer>
    </>
  );
}

export default BrodcastMain;

const ChanelCard = ({ channel, isSelceted, onClick }) => {
  return (
    <AddFlex
      flexDirection="column"
      padding="10px"
      gap="10px"
      borderRadius="8px"
      style={{
        width: "25%",
        border: isSelceted
          ? `1px solid ${primaryActionColor}`
          : "1px solid #CFCECA",
        cursor: "pointer",
      }}
      transition="none"
      onClick={() => {
        onClick(channel.id);
      }}
    >
      <AddFlex alignItems="center">
        {channel.icon}
        <Gap />
        <Checkbox size="small" checked={isSelceted} sx={{ padding: "0px" }} s />
      </AddFlex>
      <CustomText color="black" fontSize="16px" fontWeight="500">
        {channel.name}
      </CustomText>
    </AddFlex>
  );
};

const MemberCard = ({ member, isSelected, onClick }) => {
  return (
    <AddFlex
      gap="8px"
      alignItems="flex-start"
      onClick={() => {
        onClick(member);
      }}
    >
      <Checkbox checked={isSelected} sx={{ padding: "0px" }} size="small" />
      <AddFlex gap="5px" flexDirection="column">
        <CustomText color="black">{member.name}</CustomText>
        <CustomText fontSize="14px">
          {member.email} | {member.number}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
};
