import React, { useEffect, useState } from "react";
import { AddMargin } from "./AddPlanForm";
import {
  AddFlex,
  CustomInput,
  CustomInputLabel,
  CustomText,
  FieldLabelText,
  PrimaryActionButton,
} from "../../reusableStyles";
import { IOSSwitch } from "../../CustomSwitch";
import { inputHeight, primaryTextColor } from "../../constants";
import {
  Currency,
  convertToFixedPointDecimal,
  generateUUID,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  isNullOrZero,
} from "../../utils";
import { CircularProgress, Divider, MenuItem, Select } from "@mui/material";
import { Close, Warning } from "@mui/icons-material";
import styled from "styled-components";
import DynamicPricingComponent from "./DynamicPricingComponent";

const AddPlanMain = styled.div`
  z-index: 11111111;
`;

function SubscriptionPlanForm({
  plan,
  index,
  isAddPlan,
  offering,
  updatePlans,
  closeDialog,
  plansLength,
}) {
  const [action, setAction] = useState("");
  const [err, setErr] = useState(null);
  const [isPlanAdding, setIsPlanAdding] = useState(false);
  const [newBatchPlan, setNewBatchPlan] = useState({
    id: generateUUID(),
    durationType: "months",
    duration: 1,
    active: true,
    prices: getPrices(),
    isFree: false,
    active: true,
  });

  function getPrices() {
    const prices = {};
    prices[offering.primaryCurrency] = 0;
    offering.internationalCurrencies?.forEach((currency) => {
      prices[currency] = 0;
    });
    return prices;
  }

  const handleUpdateNewBatchPlan = (batchPlanKey, batchPlanValue) => {
    if (!isNullOrUndefined(err)) setErr(null);
    setNewBatchPlan({ ...newBatchPlan, [batchPlanKey]: batchPlanValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPlanAdding(true);
    const plan = newBatchPlan;
    //if only inr
    if (!plan.isFree) {
      if (plan.totalSeats !== -1 && plan.totalSeats <= 0) {
        setErr("Enter a valid value for the total seats");
        setIsPlanAdding(false);
        return;
      }
      if (isArrayNullOrUndefined(Object.values(plan.prices))) {
        setErr("Please add pricing");
        setIsPlanAdding(false);
        return;
      }
      if (Object.values(plan.prices).includes(0)) {
        setErr("Please add pricing for all added currencies");
        setIsPlanAdding(false);
        return;
      }
    }

    const response = updatePlans(action, index, {
      ...plan,
      ticketName: `${plan.duration} ${
        plan.duration === 1
          ? plan.durationType.slice(0, plan.durationType.length - 1)
          : plan.durationType
      }`,
    });
    setIsPlanAdding(false);
    if (response.error) {
      setErr(response.error);
    } else {
      setAction("");
      setErr(null);
      closeDialog();
    }
  };

  const isPlanFree = () => {
    const newPrices = newBatchPlan.prices;
    Object.entries(newPrices).forEach(([key, value]) => {
      newPrices[key] = 0;
    });

    setNewBatchPlan({
      ...newBatchPlan,
      isFree: !newBatchPlan["isFree"],
    });
  };

  const handleSetPrices = (prices) => {
    setNewBatchPlan((prev) => ({
      ...prev,
      prices,
    }));
  };

  useEffect(() => {
    if (!isAddPlan && !isNullOrUndefined(plan)) {
      setNewBatchPlan(JSON.parse(JSON.stringify(plan)));
      setAction("update");
    } else {
      setAction("addnew");
    }
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <AddPlanMain>
        <AddFlex padding="0px 0 16px 0">
          <CustomText
            style={{ width: "100%", textAlign: "center" }}
            color="black"
            fontWeight="500"
            fontSize="18px"
          >
            {plan ? "Update plan" : "Add new plan"}
          </CustomText>
          <Close
            style={{
              width: "20%",
              alignItems: "center",
              cursor: "pointer",
              position: "absolute",
              right: "0",
            }}
            onClick={() => {
              setErr(null);
              closeDialog();
            }}
          />
        </AddFlex>
        <Divider />
        <AddFlex flexDirection="column" margin="16px 0px 0 0px">
          <AddMargin margin="10px">
            <FieldLabelText>Batch Duration</FieldLabelText>
            <AddFlex alignItems="center">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={
                  newBatchPlan.durationType
                    ? newBatchPlan.durationType
                    : "sessions"
                }
                onChange={(e) =>
                  handleUpdateNewBatchPlan("durationType", e.target.value)
                }
                sx={{
                  height: "40px",
                  flexGrow: 1,
                  width: "50%",
                  margin: "6px 10px 4px 0",
                  borderRadius: "8px",
                }}
              >
                <MenuItem value={"sessions"}>Sessions/Classes</MenuItem>
                {/* <MenuItem value={"days"}>Days</MenuItem> */}
                <MenuItem value={"months"}>Months</MenuItem>
              </Select>
              <CustomInput
                padding={"5px 10px"}
                placeholder={"Helper text"}
                style={{ height: inputHeight, width: "50%" }}
                type={"number"}
                value={newBatchPlan.duration ? newBatchPlan.duration : ""}
                onChange={(text) => handleUpdateNewBatchPlan("duration", text)}
              />
            </AddFlex>
          </AddMargin>
          <AddFlex alignItems="center" margin="10px">
            <CustomText margin="0 10px 0 0" color={primaryTextColor}>
              Make this plan a free plan
            </CustomText>
            <IOSSwitch checked={newBatchPlan.isFree} onChange={isPlanFree} />
          </AddFlex>
          {!newBatchPlan.isFree && (
            <>
              <CustomInputLabel style={{ margin: "10px 10px 8px 10px" }}>
                Add Price
              </CustomInputLabel>
              <DynamicPricingComponent
                plan={newBatchPlan}
                handleSetPrices={handleSetPrices}
              />
              {/* <AddFlex margin="0 10px 10px 10px">
                <CustomInput
                  required={true}
                  value={
                    isNullOrUndefined(getPricing(Currency.INR))
                      ? ""
                      : getPricing(Currency.INR) / 100
                  }
                  type="number"
                  leftWidget={"INR"}
                  onChange={(text) => handleUserInputForINRPrice(text)}
                  style={{ width: "65%" }}
                />
              </AddFlex>
              <AddFlex flexDirection="column" margin="0 10px 10px 10px">
                <CustomInput
                  required={true}
                  value={
                    isNullOrUndefined(getPricing(Currency.USD))
                      ? ""
                      : getPricing(Currency.USD) / 100
                  }
                  type="number"
                  leftWidget={"USD"}
                  onChange={handleUserInputForUSDPrice}
                  style={{ width: "65%" }}
                />
                <CustomText margin="8px 0 0 0">
                  This price will be shown to your overseas clients.
                </CustomText>
              </AddFlex> */}
            </>
          )}
          <div style={{ grow: "1" }}>
            <AddMargin margin="10px">
              <CustomInput
                label={"Add Discount (%)"}
                value={newBatchPlan.discount ? newBatchPlan.discount : ""}
                type="number"
                min={0}
                max={100}
                placeholder={"Any discount you wish to give."}
                onChange={(text) =>
                  handleUpdateNewBatchPlan("discount", parseFloat(text))
                }
              />
            </AddMargin>
          </div>
          {plansLength > 1 && (
            <div style={{ grow: "1" }}>
              <AddMargin margin="10px">
                <CustomText color={primaryTextColor}>
                  Make This plan visible now
                </CustomText>
                <IOSSwitch
                  checked={newBatchPlan.active}
                  onClick={(e) => {
                    handleUpdateNewBatchPlan("active", e.target.checked);
                  }}
                />
              </AddMargin>
            </div>
          )}
          {err && (
            <AddFlex
              padding="15px 10px"
              borderRadius="4px"
              backgroundColor="#CD1818"
              margin="0 10px"
            >
              <Warning />
              <CustomText color="white" fontWeight="500">
                {err}
              </CustomText>
            </AddFlex>
          )}
          <AddMargin>
            <AddFlex justify="center">
              <PrimaryActionButton
                loading={isPlanAdding}
                disabled={isPlanAdding}
                loaderColor={"white"}
              >
                {isPlanAdding ? (
                  <CircularProgress size={14} sx={{ color: "white" }} />
                ) : plan ? (
                  "Update Plan"
                ) : (
                  "Save Plan"
                )}
              </PrimaryActionButton>
            </AddFlex>
          </AddMargin>
        </AddFlex>
      </AddPlanMain>
    </form>
  );
}

export default SubscriptionPlanForm;
