import React, { useEffect, useRef, useState } from "react";
import { AddFlex, CustomInput, CustomText } from "./reusableStyles";
import { KeyboardArrowDown } from "@mui/icons-material";
import useWindowSize from "./Hooks/useWindowSize";
import styled from "styled-components";
import { primaryActionColor, primaryActionColorLight } from "./constants";
import { isArrayNullOrUndefined } from "./utils";
import { Popover } from "@mui/material";

const StyledHover = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ selectedOptionId, id }) =>
    selectedOptionId === id && "#ecf3ff"};
  padding: 5px 10px;
  border-bottom: 1px solid #ececec;
  cursor: pointer;
  &:hover {
    background-color: ${primaryActionColorLight};
  }
`;

function DropdownComponent({
  label,
  initialValue,
  options,
  footerText,
  widget,
  getInputValue,
  padding,
  marginBottom,
}) {
  // const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [_options, setOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const [inputValue, setInputValue] = useState(initialValue?.name);
  const dropdownRef = useRef();
  const selectedOptionRef = useRef();
  const { windowSize } = useWindowSize();
  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    setOptions(options);
  }, [options]);

  useEffect(() => {
    if (inputValue?.length === 0) {
      setOptions(options);
      return;
    }
  }, [inputValue]);

  useEffect(() => {
    if (isOpen && selectedOptionRef.current) {
      dropdownRef.current.scrollTop = selectedOptionRef.current.offsetTop;
    }
  }, [isOpen]);

  const handleClose = () => {
    setAnchorEl(null);
    // setOptions(options);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClose();
      }
    };

    // Add when the component is opened and remove when closed
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, dropdownRef]);

  return (
    <AddFlex
      flexDirection="column"
      padding={padding}
      marginBottom={marginBottom}
    >
      <CustomInput
        footerText={footerText}
        label={label}
        leftWidget={selectedOption?.icon}
        widget={widget ? widget : <KeyboardArrowDown />}
        value={inputValue}
        onClick={(e) => {
          //   setOptions(options);
          if (isArrayNullOrUndefined(options)) return;
          // setIsOpen(true);
          setAnchorEl(e.currentTarget);
        }}
        style={{ cursor: "pointer" }}
        onChange={(text) => {
          if (isArrayNullOrUndefined(options)) return;
          setInputValue(text);
          setOptions(() =>
            options?.filter((option) =>
              option.name?.toLowerCase().includes(inputValue?.toLowerCase())
            )
          );
        }}
      />
      <Popover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            minWidth: anchorEl && anchorEl.clientWidth,
          },
        }}
        // sx={{ minWidth: anchorEl && anchorEl.clientWidth }}
        // MenuListProps={{
        //   "aria-labelledby": "basic-button",

        // }}
      >
        {anchorEl &&
          _options?.map((option) => (
            <StyledHover
              onClick={() => {
                if (option.callback) {
                  return option.callback(option);
                }
                setInputValue(option.name);
                setSelectedOption(option);
                getInputValue(option);
                // setIsOpen(null);
                setOptions(options);
                setAnchorEl(null);
              }}
              id={option.id}
              selectedOptionId={selectedOption?.id}
              ref={selectedOption?.id === option.id ? selectedOptionRef : null}
              padding="10px"
            >
              {option.icon}
              <AddFlex gap="5px" margin="10px" flexDirection="column">
                <CustomText fontSize="14px" color={"black"}>
                  {option.name}
                </CustomText>
                {option.subText && (
                  <CustomText fontSize="12px">{option.subText}</CustomText>
                )}
              </AddFlex>
            </StyledHover>
          ))}
      </Popover>
    </AddFlex>
  );
}

export default DropdownComponent;
