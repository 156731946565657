import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import { Checkbox, CircularProgress } from "@mui/material";
import { Edit } from "@mui/icons-material";
import TextEditor from "./TextEditor";
import PhotoUpload from "../Customization/PhotoUpload";
import { callNonStoreApi } from "../NonStoreApis";
import { AddUpdateHost } from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { isNullOrUndefined } from "../utils";

function HostDetailsHostData({ selectedHosts, onSubmit, hosts, setHosts }) {
  const [toggleView, setToggleView] = useState(false);
  const [hostData, setHostData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [_selectedHosts, setSelectedHosts] = useState(selectedHosts || []);

  const handleChangeHostData = (key, value) => {
    return setHostData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAddOrUpdateHostData = async () => {
    try {
      setIsLoading(true);
      const response = await callNonStoreApi(AddUpdateHost, { hostData });
      setHosts(response.data.hosts);
      setSelectedHosts([]);
      setToggleView(false);
      setHostData({});
      enqueueSnackbar("Host details updated", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("something went wrong", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedHost = (host) => {
    if (_selectedHosts.some((_host) => _host.id === host.id)) {
      // remove selectedHost
      setSelectedHosts((prev) =>
        prev.filter((prevHost) => prevHost.id !== host.id)
      );
    } else {
      setSelectedHosts((prev) => [...prev, host]);
    }
  };
  return (
    <>
      {toggleView ? (
        <AddFlex
          grow={1}
          margin="10px 0 0 0"
          padding="20px"
          flexDirection="column"
          gap="10px"
          transition="none"
        >
          <CustomInput
            label={"Host name"}
            value={hostData.name}
            placeholder={"Eg: Jhon Doe"}
            onChange={(text) => {
              handleChangeHostData("name", text);
            }}
          />
          <TextEditor
            label={"About Host"}
            value={hostData.description}
            getTextEditorInput={(text) => {
              handleChangeHostData("description", text);
            }}
          />

          <PhotoUpload
            photoURL={hostData.photoURL}
            handleGetFile={(src) => {
              handleChangeHostData("photoURL", src);
            }}
          />
        </AddFlex>
      ) : (
        <AddFlex
          transition="none"
          style={{ overflow: "sroll" }}
          grow={1}
          flexDirection="column"
        >
          {hosts?.map((host) => {
            return (
              <AddFlex
                padding="10px"
                gap="5px"
                style={{ width: "100%", borderBottom: "1px solid #ececec" }}
                alignItems="flex-start"
              >
                <Checkbox
                  size="small"
                  checked={_selectedHosts.some((_host) => _host.id === host.id)}
                  sx={{ padding: 0 }}
                  onClick={() => {
                    handleSelectedHost(host);
                  }}
                />
                <AddFlex
                  style={{ width: "100%" }}
                  gap="5px"
                  flexDirection="column"
                >
                  <AddFlex alignItems="center" gap="10px">
                    <CustomText color="black" fontSize="16px">
                      {host.name}
                    </CustomText>
                    <Edit
                      onClick={() => {
                        setToggleView(true);
                        setHostData(host);
                      }}
                      sx={{ fontSize: "14px", cursor: "pointer" }}
                    />
                  </AddFlex>
                  <CustomText
                    fontSize="12px"
                    style={{ wordWrap: "break-word", width: "80%" }}
                    lineClamp={2}
                    dangerouslySetInnerHTML={{
                      __html: host.description,
                    }}
                  />
                </AddFlex>
              </AddFlex>
            );
          })}
        </AddFlex>
      )}
      {toggleView ? (
        <AddFlex
          gap="10px"
          justify="center"
          style={{ padding: "10px", borderTop: "1px solid #ececec" }}
        >
          <SecondaryActionButton
            onClick={() => {
              setToggleView(false);
            }}
          >
            Cancel
          </SecondaryActionButton>
          <PrimaryActionButton
            onClick={async () => {
              await handleAddOrUpdateHostData();
            }}
          >
            {isLoading ? (
              <CircularProgress size={14} sx={{ color: "white" }} />
            ) : hostData.id ? (
              "Update host"
            ) : (
              "Add Host"
            )}
          </PrimaryActionButton>
        </AddFlex>
      ) : (
        <AddFlex
          gap="10px"
          justify="center"
          style={{ padding: "10px", borderTop: "1px solid #ececec" }}
        >
          <SecondaryActionButton
            onClick={() => {
              setToggleView(true);
            }}
          >
            Add Host
          </SecondaryActionButton>
          <PrimaryActionButton
            onClick={() => {
              onSubmit(_selectedHosts);
            }}
          >
            Done
          </PrimaryActionButton>
        </AddFlex>
      )}
    </>
  );
}

export default HostDetailsHostData;
