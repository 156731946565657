import React from "react";
import { CustomText } from "../../../reusableStyles";
import styled from "styled-components";
import { FeaturesKeys } from "../../../utils";
import { headerTextColor } from "../../../constants";
import SessionHours from "./SessionHours";
import ScheduleComponentCollapsed from "./ScheduleComponentCollapsed";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  outline: none;
  color: grey;
  border: 1px solid #475467;
  border-radius: 4px;
  padding: 4px 12px;
  margin: 0px 0 0 0;
  min-width: 130px;
`;

function EventOrWorkshopSchedule({ offering, handleSetOffering }) {
  return (
    <>
      <SessionHours offering={offering} handleSetOffering={handleSetOffering} />
      <CustomText
        margin="24px 0"
        color={headerTextColor}
        fontWeight="600"
        style={{ lineHeight: "24px" }}
      >
        Add Dates & Time Slots
      </CustomText>
      <ScheduleComponentCollapsed
        offering={offering}
        offeringTypeId={FeaturesKeys.event.id}
        handleSetOffering={handleSetOffering}
      />
    </>
  );
}

export default EventOrWorkshopSchedule;
