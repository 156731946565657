import React, { useEffect, useState } from "react";
import { AddFlex, PrimaryActionButton } from "../../reusableStyles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  AddEditCouponCode,
  DeleteCoupon,
  FetchAllCoupons,
} from "../../Api/ApiEndPoints";
import EventCouponCodeCard from "../../CouponCodeCard";
import useWindowSize from "../../Hooks/useWindowSize";
import MenuButton from "../../Links/MenuButton";
import { FeaturesKeys, isNullOrUndefined } from "../../utils";
import { ADD_COUPON } from "../../OnboardingPage/routeNames";

function MarketingTab() {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();
  //   const { fetchAllCoupons, addEditCouponCode, deleteCouponCode } =
  //     useFeatureServices();
  const data = useSelector((state) => state.user.apiUserData);
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  //   const { data } = useUser();
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);

  const handleGetAllCoupons = async () => {
    try {
      const response = await callNonStoreApi(FetchAllCoupons);
      setLoading(false);
      setCoupons(response.data.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const formatResponseData = (doc) => {
    let coupons;
    if (isNullOrUndefined(doc)) {
      coupons = [];
    } else {
      coupons = Object.entries(doc).flatMap(([key, valueArray]) =>
        valueArray.coupons?.map((item) => ({ ...item, pageTypeId: key }))
      );
    }
    return coupons;
  };
  const handleEnableDisableCoupon = async (couponData) => {
    try {
      const response = await callNonStoreApi(AddEditCouponCode, couponData);
      const doc = response.data.data.data;
      const coupons = formatResponseData(doc);
      setCoupons(coupons);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCoupon = async (body) => {
    try {
      const response = await callNonStoreApi(DeleteCoupon, body);
      const doc = response.data.data;
      const coupons = formatResponseData(doc);
      setCoupons(coupons);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetAllCoupons();
  }, []);

  const getMenuItems = () => {
    if (isNullOrUndefined(features)) {
      return [];
    }
    const featuresWithOfferings = Object.entries(features)
      .filter(([key, value]) => value.items?.length > 0)
      .map(([key, value]) => key);
    return Object.entries(FeaturesKeys)
      .filter(
        ([key, value]) =>
          featuresWithOfferings.includes(value.id) &&
          value.id !== FeaturesKeys.testimonial.id
      )
      .map(([key, value]) => ({
        name: value.name,
        featureId: value.id,
        id: key,
        action: () => {
          navigate(ADD_COUPON.replace(":featureId", value.id));
        },
      }));
  };
  return (
    <AddFlex
      flexDirection="column"
      margin={isMobileView() ? "20px" : "0px 48px"}
    >
      <MenuButton
        buttonName={"Create a promo code"}
        menuItems={getMenuItems()}
        // onClick={() => navigate(Add_New_Coupon_Events)}
        style={{
          alignSelf: "flex-end",
          position: "fixed",
          top: "15px",
          right: isMobileView() ? "20px" : "48px",
        }}
        margin="0"
      />

      <AddFlex
        flexDirection={isMobileView() && "column"}
        margin="27px 0 0 0"
        wrap="wrap"
      >
        {coupons.map((coupon) =>
          coupon.isCreatedForLead ? (
            <></>
          ) : (
            <EventCouponCodeCard
              coupon={coupon}
              user={data}
              handleDeleteCoupon={handleDeleteCoupon}
              handleEnableDisableCoupon={handleEnableDisableCoupon}
            />
          )
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default MarketingTab;
