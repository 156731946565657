import React, { useState } from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { format } from "date-fns";
import { headerTextColor, primaryActionColor } from "../../../constants";
import { Divider } from "@mui/material";
import styled from "styled-components";
import { isNullOrUndefined } from "../../../utils";
import { TicketSalesData } from "./EventWorkshopCourseWebinarDetailsCard";

const StyledDateChip = styled.div`
  padding: 4px 12px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isSelected ? "transparent" : "#F6F6F6"};
  border: ${(props) =>
    props.isSelected ? "1px solid " + primaryActionColor : "1px solid #D0D5DD"};
  color: ${(props) => props.color};
  cursor: pointer;
`;

function TravelSaleDetailsCard({
  scheduleDetails,
  setSelectedDateIndex,
  selectedDateIndex,
  ticketWiseStats,
  offeringId,
}) {
  const getTotalSalesInTicket = (date) => {
    let sold = 0;
    date.tickets.forEach((ticket) => {
      if (!isNullOrUndefined(ticket.totalSold)) {
        sold += sold + ticket.totalSold;
      }
    });
    return sold;
  };
  const getTotalSold = (date) => {
    let sold = { totalSeats: 0, sold: 0 };
    if (!isNullOrUndefined(date.totalSeats) && date.totalSeats !== -1) {
      sold.totalSeats += sold.totalSeats + date.totalSeats;
    }

    sold.sold = getTotalSalesInTicket(date);

    if (sold.totalSeats !== 0) {
      return `${sold.sold} of ${sold.totalSeats}`;
    }
    return `${sold.sold} Sold`;
  };
  const getSalesPercentage = (date) => {
    let totalSeats = date.totalSeats;
    const totalSold = getTotalSalesInTicket(date);

    if (isNullOrUndefined(totalSeats) || totalSeats === -1) {
      return 100;
    }
    return ((totalSeats - totalSold) / totalSeats) * 100;
  };
  const getChipColor = (date) => {
    if (getSalesPercentage(date) > 30) {
      return {
        color: "#337648",
        border: "1px solid #d1f8e2",
        backgroundColor: "#ecfdf3",
      };
    } else if (getSalesPercentage(date) === 0) {
      return {
        color: "#606978",
        border: "1px solid #EAECF1",
        backgroundColor: "#f9fafb",
      };
    }
    return {
      color: "#9a6d3b",
      border: "1px solid #fdf1da",
      backgroundColor: "#fef8ec",
    };
  };
  return (
    <AddFlex marginTop="16px" flexDirection="column" gap="16px">
      <AddFlex wrap="wrap" gap="10px">
        {scheduleDetails.map((detail, index) => (
          <StyledDateChip
            padding="4px 12px"
            borderRadius="4px"
            color={
              index === selectedDateIndex
                ? getChipColor(detail).color
                : "#8d8d8d"
            }
            style={{
              backgroundColor: getChipColor(detail).backgroundColor,
              border: getChipColor(detail).border,
            }}
            isSelected={index === selectedDateIndex}
            onClick={() => {
              setSelectedDateIndex(index);
            }}
          >
            <CustomText
              color={"inherit"}
              fontWeight="500"
              fontSize="15px"
              style={{ lineHeight: "18px", fontFamily: "Outfit" }}
            >
              {format(new Date(detail.date), "dd MMM")}
            </CustomText>
            <CustomText
              color={"inherit"}
              fontWeight="500"
              fontSize="14px"
              style={{ lineHeight: "18px", fontFamily: "Outfit" }}
            >
              {getTotalSold(detail)} {/**TODO: make this dynamic */}
            </CustomText>
          </StyledDateChip>
        ))}
      </AddFlex>
      <TicketSalesDataTravel
        date={format(
          new Date(scheduleDetails[selectedDateIndex].date),
          "dd MMM"
        )}
        tickets={scheduleDetails[selectedDateIndex].tickets}
        ticketWiseStats={ticketWiseStats}
        offeringId={offeringId}
      />
    </AddFlex>
  );
}

export default TravelSaleDetailsCard;

export const TicketSalesDataTravel = ({ tickets, date }) => {
  return (
    <>
      <CustomText
        color={headerTextColor}
        fontWeight={"600"}
        margin="0px 0 20px 0"
      >
        Sales For {date}
      </CustomText>
      {tickets.map((ticket) => (
        <AddFlex margin="0px 0 12px 0" justify="space-between">
          <CustomText>{ticket.ticketName}</CustomText>
          <CustomText>
            {ticket.netRevenue ? ticket.netRevenue : "--"}
          </CustomText>
        </AddFlex>
      ))}
    </>
  );
};
