import React from "react";
import { AddFlex, CustomInput, InputLabel } from "../../../reusableStyles";
import useWindowSize from "../../../Hooks/useWindowSize";
import { isNullOrUndefined } from "../../../utils";

function SessionHours({ handleSetOffering, offering }) {
  const { isMobileView } = useWindowSize();
  const calculateDurationInMins = (hours, mins) => {
    console.log(hours, mins);
    let _mins = isNaN(mins) ? 0 : mins;
    let _hours = isNaN(hours) ? 0 : hours;
    handleSetOffering("durationInMins", _hours * 60 + _mins);
  };
  return (
    <>
      <InputLabel color="black" style={{ marginTop: "24px" }}>
        How long is each session ?
      </InputLabel>
      <AddFlex
        gap="20px"
        margin="0px 0 0px 0"
        // flexDirection={isMobileView() && "column"}
      >
        <CustomInput
          type="number"
          widget={"Hours"}
          value={offering.hours}
          max={24}
          min={0}
          onChange={(text) => {
            const hours = parseInt(text);
            if (text > 24 || text < 0) {
              return;
            }
            handleSetOffering("hours", hours);
            calculateDurationInMins(hours, parseInt(offering.mins));
          }}
          width={isMobileView() ? "45%" : "35%"}
        />
        <CustomInput
          type="number"
          widget={"Mins"}
          value={offering.mins}
          max={60}
          min={0}
          onChange={(text) => {
            const mins = parseInt(text);
            if (text > 59 || text < 0) {
              return;
            }
            handleSetOffering("mins", mins);
            calculateDurationInMins(parseInt(offering.hours), mins);
          }}
          width={isMobileView() ? "45%" : "35%"}
        />
      </AddFlex>
    </>
  );
}

export default SessionHours;
