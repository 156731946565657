import { format } from "date-fns";
import {
  Currency,
  formatPrice,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../utils";

export const formatSummarySalesForEventCourseWebinar = (sales) => {
  return sales.map((sale) => {
    return {
      "Customer Name": sale.name,
      "Sale Date": format(new Date(sale.saleDate), "dd MMM yyyy"),
      "Customer Number": sale.number,
      "Ticket Name": sale.ticketName,
      "Offering Start Date": format(
        new Date(sale.dateData.start),
        "dd MMM yyyy"
      ),
      "Offering End Date": isNullOrUndefined(sale.dateData.end)
        ? "NA"
        : format(new Date(sale.dateData.end), "dd MMM yyyy"),
      "Time Slot": getTimeInTwelveHourForm(sale.timeSlot),
      "Ticket Price": formatPrice(sale.ticketPrice, Currency.INR),
      "Ticket Price After Discount": formatPrice(
        sale.ticketPriceAfterDiscount,
        Currency.INR
      ),
    };
  });
};

export const formatSummarySalesForTravel = (sales) => {
  return sales.map((sale) => {
    return {
      "Customer Name": sale.name,
      "Sale Date": format(new Date(sale.saleDate), "dd MMM yyyy"),
      "Customer Number": sale.number,
      "Ticket Name": sale.ticketName,
      "Offering Start Date": format(
        new Date(sale.dateData.start),
        "dd MMM yyyy"
      ),
      "Offering End Date": format(new Date(sale.dateData.end), "dd MMM yyyy"),
      "Ticket Price": formatPrice(sale.ticketPrice, Currency.INR),
      "Ticket Price After Discount": formatPrice(
        sale.ticketPriceAfterDiscount,
        Currency.INR
      ),
    };
  });
};
