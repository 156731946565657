import React, { useState } from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { IOSSwitch } from "../../CustomSwitch";
import { Dialog, Drawer } from "@mui/material";
import PageGoalsCustomiseDialog from "./PageGoalsCustomiseDialog";
import useWindowSize from "../../Hooks/useWindowSize";
import { enqueueSnackbar } from "notistack";

const PageGoalCard = ({
  isSelected,
  addedButtons,
  isMobileView,
  onSelect,
  pageGoal,
  button_data,
  handleRemoveCTA,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { windowSize } = useWindowSize();
  return (
    <AddFlex
      margin={isMobileView ? "0 0 20px 0" : "0 20px 20px 0"}
      padding="16px 24px"
      width={isMobileView ? "100%" : "40%"}
      style={{
        border: "1px solid #e7e9ed",
        flexShrink: 0,
      }}
      borderRadius="4px"
      backgroundColor="white"
      alignItems="center"
    >
      <img
        src={pageGoal.image}
        alt={pageGoal.name + "growezy"}
        style={{ width: "36px", height: "36px" }}
      ></img>
      <AddFlex margin="0 18px" flexDirection="column">
        <CustomText fontWeight="600" color="#344054">
          {pageGoal.actionName}
        </CustomText>
        <CustomText
          textDecoration="underline"
          margin="8px 0 0 0"
          fontSize="12px"
          fontWeight="500"
          color={"#475467"}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          {"Customise"}
        </CustomText>
      </AddFlex>
      <div style={{ position: "absolute", top: "20px", right: "20px" }}>
        <IOSSwitch
          checked={isSelected}
          onChange={(e) => {
            if (e.target.checked === true && addedButtons <= 2) {
              setOpenDialog(true);
            } else {
              handleRemoveCTA(pageGoal);
            }
          }}
        />
      </div>
      {isMobileView ? (
        <Drawer
          anchor="bottom"
          open={openDialog}
          PaperProps={{
            sx: {
              height: "70vh",
            },
          }}
        >
          <PageGoalsCustomiseDialog
            pageGoal={pageGoal}
            iconClose={() => {
              setOpenDialog(false);
            }}
            closeDialog={(data, name) => {
              // if (isNullOrUndefined(data)) {
              //   setOpenDialog(false);
              //   return;
              // }
              if (name.trim().length === 0) {
                return enqueueSnackbar("Add a Name to the CTA", {
                  variant: "error",
                });
              }
              const response = onSelect({ ...pageGoal, name, data: data });
              if (response.message === "success") {
                setOpenDialog(false);
              } else {
                enqueueSnackbar(response.error, {
                  variant: "error",
                });
              }
            }}
            button_data={button_data}
            isMobileView={isMobileView}
          />
        </Drawer>
      ) : (
        <Dialog
          open={openDialog}
          onClose={() => {
            // if (isNullOrUndefined(button_data)) {
            //   onSelect(pageGoal);
            // } else {
            //   onSelect(button_data);
            // }
            // setOpenDialog(false);
          }}
          PaperProps={{
            sx: {
              height: windowSize.screenHeight * 0.8 + "px",
              borderRadius: "32px",
              width: "35vw",
            },
          }}
        >
          <PageGoalsCustomiseDialog
            pageGoal={pageGoal}
            iconClose={() => {
              setOpenDialog(false);
            }}
            closeDialog={(data, name) => {
              // if (isNullOrUndefined(data)) {
              //   setOpenDialog(false);
              //   return;
              // }
              if (name.length === 0) {
                return enqueueSnackbar("Add a Name to the CTA", {
                  variant: "error",
                });
              }
              const response = onSelect({ ...pageGoal, name, data: data });
              if (response.message === "success") {
                setOpenDialog(false);
              } else {
                enqueueSnackbar(response.error, {
                  variant: "error",
                });
              }
            }}
            button_data={button_data}
            isMobileView={isMobileView}
          />
        </Dialog>
      )}
    </AddFlex>
  );
};

export default PageGoalCard;
