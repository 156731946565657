import React, { useEffect } from "react";
import { AddFlex } from "../reusableStyles";
import { FeaturesKeys, LeadPipelines, getApiCallParams } from "../utils";
import Pipelines from "./Components/Pipelines";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { leadsApiCall, setCurrentPageDataAction } from "../Store/leadsSlice";
import { FetchLeadsForPage } from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

function LeadsTab() {
  const { offeringId } = useParams();
  const [searchParams] = useSearchParams();
  const userFeaturesData = useSelector(
    (state) => state.userFeatures.userFeatures
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const offeringTypeId =
      FeaturesKeys[searchParams.get("pageType").toLowerCase()].id;
    const pageData = userFeaturesData.features[offeringTypeId].items.find(
      (item) => item.id === offeringId
    );
    dispatch(setCurrentPageDataAction(pageData));
    dispatch(
      leadsApiCall(
        getApiCallParams(FetchLeadsForPage, {
          pageId: offeringId,
        })
      )
    );
  }, []);
  return (
    <AddFlex padding="0px 0px" width="100%" height="100%">
      {LeadPipelines().map((pipeline) => (
        <Pipelines pipeline={pipeline} />
      ))}
    </AddFlex>
  );
}

export default LeadsTab;
