import { CircularProgress } from "@mui/material";
import { CustomText, PrimaryActionButton } from "./reusableStyles";
import { primaryActionColor } from "./constants";
import { isNullOrUndefined } from "./utils";

const AdminCTAButton = ({
  loading,
  buttonText,
  buttonTextColor,
  onClick,
  loaderColor,
  minWidth,
  buttonColor,
  borderRadius,
  border,
  margin,
  justify,
  style,
  disabled,
  padding,
  submitType,
  icon,
  iconColor,
  buttonTextFontFamily,
  buttonFontSize,
}) => {
  return (
    <PrimaryActionButton
      borderRadius={borderRadius ? borderRadius : "5px"}
      type={submitType ? submitType : "submit"}
      justify={justify}
      margin={margin}
      padding={padding}
      disabled={loading ? true : disabled ? disabled : false}
      minWidth={minWidth ? minWidth : "140px"}
      border={border ? border : "none"}
      onClick={(e) => {
        if (isNullOrUndefined(onClick)) return;
        onClick();
      }}
      style={style}
    >
      {loading ? (
        <CircularProgress
          size={16}
          color="info"
          style={{ color: loaderColor ? loaderColor : "white" }}
        />
      ) : (
        <>
          <CustomText
            color={buttonTextColor ? buttonTextColor : "white"}
            fontFamily={buttonTextFontFamily}
            fontWeight="500"
            fontSize={buttonFontSize}
          >
            {buttonText}
          </CustomText>
          {icon}
        </>
      )}
    </PrimaryActionButton>
  );
};

export default AdminCTAButton;
