import React from "react";
import { AddFlex, ContentHeader, CustomText } from "../reusableStyles";
import { Divider } from "@mui/material";
import { BoxedTabs } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import { useSelector } from "react-redux";
import {
  Currency,
  GROWEZY_COMMISSION,
  GROWEZY_MAX_COMMISSION,
  convertToFixedPointDecimal,
  formatPrice,
} from "../utils";

function CommissionCharges({
  getWhoPaysCommission,
  handleChangeWhoPaysCommission,
  style,
}) {
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );

  const getCommission = () => {
    if (UserAdditionalDetails.commission) {
      return UserAdditionalDetails.commission;
    } else {
      return {
        percent: GROWEZY_COMMISSION,
        maxCom: GROWEZY_MAX_COMMISSION,
      };
    }
  };

  const isCommissionFree = () => {
    return getCommission().percent * 100 === 0;
  };
  return (
    <AddFlex
      padding="20px"
      gap="20px"
      borderRadius="12px"
      style={style ? style : { height: "max-content", width: "100%" }}
      flexDirection="column"
      backgroundColor="white"
    >
      <AddFlex flexDirection="column">
        <ContentHeader fontSize="16px" fontWeight="500">
          Choose who pays the Growezy commission.
        </ContentHeader>
        <CustomText>This setting can be changed for each offering.</CustomText>
      </AddFlex>
      <AddFlex gap="15px" alignItems="center">
        <CustomText color="black" style={{ width: "60%" }}>
          {isCommissionFree()
            ? "is 0%"
            : `${getCommission().percent * 100}% or ${formatPrice(
                getCommission().maxCom,
                Currency.INR
              )} (per transaction) which ever is
          lower`}
        </CustomText>
        {!isCommissionFree() && (
          <BoxedTabs
            tabs={["Me", "My Clients"]}
            selectedTabIndex={getWhoPaysCommission()}
            setSelectedTabIndex={(index, elem) => {
              handleChangeWhoPaysCommission(index === 0 ? true : false);
            }}
          />
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default CommissionCharges;
