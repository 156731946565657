import styled from "styled-components";
import { primaryActionColor } from "../../constants";
import { AddFlex } from "../../reusableStyles";

const ToggleWrapper = styled.div`
  position: relative;
  border: 1px solid ${primaryActionColor};
  border-radius: 30px;
  background-color: transparent;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 9px 20px;
  font-size: 13px;
  border: none;
  color: ${primaryActionColor};
  background-color: transparent;
  cursor: pointer;

  transition: background-color 0.2s;
  width: max-content;
  &:focus {
    outline: none;
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0px;
  background-color: rgba(224, 233, 255, 0.55);
  height: 82%;
  width: 50%;
  transition: transform 0.3s ease-out;
  border-radius: 28px;
`;

export default function RoundedBoxedTabs({
  tabs,
  selectedTabIndex,
  setSelectedTabIndex,
}) {
  const calculateTransform = () => {
    return selectedTabIndex === 0 ? "translateX(3%)" : "translateX(97%)";
  };

  return (
    <AddFlex
      alignItems="center"
      justify="center"
      style={{
        overflow: "hidden",
        display: "inline-block",
        width: "max-content",
        position: "relative",
      }}
    >
      <Indicator style={{ transform: calculateTransform() }} />
      <ToggleWrapper>
        {tabs.map((elem, index) => (
          <>
            <ToggleButton
              padding="5px 20px"
              borderRadius="25px"
              // transition="none"
              onClick={() => setSelectedTabIndex(index, elem)}
            >
              {elem.name}
            </ToggleButton>
          </>
        ))}
      </ToggleWrapper>
    </AddFlex>
  );
}
