import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomText,
  Gap,
  InputLabel,
  SecondaryActionButton,
  SelectFormDialogCard,
} from "../../reusableStyles";
import { HeaderColor } from "../CreateOfferingMain";
import { useSelector } from "react-redux";
import { getFormSubline, getSchedulingFieldType } from "../../Forms/FormsTab";
import { isNullOrUndefined } from "../../utils";
import { useNavigate } from "react-router";

function FillAFormCustomiseDialog({ data, setData, isIntake, enableButton }) {
  const formsMap = useSelector((state) => state.user.apiUserData.forms);
  const navigate = useNavigate();
  const getForms = (formsMap) => {
    if (isNullOrUndefined(formsMap)) return [];
    return Object.values(formsMap).filter((form) => {
      if (isIntake) {
        return form.isIntake === true;
      } else {
        return isNullOrUndefined(form.isIntake) || form.isIntake === false;
      }
    });
  };
  const [selectedFormId, setSelectedFormId] = useState(data?.formId);
  useEffect(() => {
    setSelectedFormId(data?.formId);
  }, [data]);
  return (
    <AddFlex margin="16px 0 0 0" flexDirection="column" width="100%">
      <AddFlex alignItems="center" style={{ margin: "0 0 12px 0" }}>
        <InputLabel
          color={HeaderColor}
          style={{ fontWeight: "600", margin: 0, fontSize: "16px" }}
        >
          Select a Form
        </InputLabel>
        <Gap />
        <SecondaryActionButton
          onClick={() => {
            navigate(`/forms?isIntake=${isIntake}`);
          }}
          style={{ border: "none", margin: "0px", padding: 0, fontWeight: 600 }}
        >
          Create New Form
        </SecondaryActionButton>
      </AddFlex>
      <AddFlex flexDirection="column" gap="16px">
        {getForms(formsMap).map((form) => (
          <SelectFormDialogCard
            isSelected={selectedFormId === form.id}
            onClick={() => {
              setSelectedFormId(form.id);
              setData({ formId: form.id });
            }}
          >
            <CustomText
              color="black"
              fontWeight="500"
              fontSize="15px"
              dangerouslySetInnerHTML={{ __html: form.header.data.label }}
            ></CustomText>
            <CustomText fontSize="12px">
              {getFormSubline(form)}{" "}
              {getSchedulingFieldType(form) === -1
                ? ""
                : getSchedulingFieldType(form) === 1
                ? "+ Google Meet"
                : "+ Phone Call"}
            </CustomText>
          </SelectFormDialogCard>
        ))}
      </AddFlex>
    </AddFlex>
  );
}

export default FillAFormCustomiseDialog;
