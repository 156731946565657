import axios from "axios";
import { getBackendBaseURL } from "./axios";
import { isNullOrUndefined, logout } from "./utils";
import { authenticate } from "./firebase-config";

let getStoreRef = () => ({});
// Function to update the placeholder with the actual function
// export const setStoreReferenceFunc = (newGetStateRef) => {
//   getStateRef = newGetStateRef;
// };

export const setStoreRef = (newStoreRef) => {
  getStoreRef = newStoreRef;
};

const customClientAxios = () => {
  const store = getStoreRef();
  const axiosInstance = axios.create({ baseURL: getBackendBaseURL() });
  axiosInstance.interceptors.request.use(
    async (config) => {
      // Modify config to add headers or perform other actions
      config.cache = false;
      config.withCredentials = true;
      config.headers.srcdomain = localStorage.getItem("loginDomain");
      config.headers.requesteduserid =
        store.getState().appTemp.data.requestedUser?.userId;
      // config.headers["userid"] = userSliceData.firebaseUserData.uid;
      // config.headers["userid"] = userSliceData.firebaseUserData.uid;
      // if (isNullOrUndefined(config.headers["authorization"])) {
      //   config.headers["authorization"] = userSliceData.token;
      // }
      return config;
    },
    (error) => {
      // Handle request error
      console.log(error);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { config, response } = error;
      if (response) {
        const retryCount = isNullOrUndefined(config.__retryCount)
          ? 0
          : config.__retryCount;
        switch (response.status) {
          case 401: {
            await logout(getStoreRef());
            setStoreRef(null);
            break;
          }
          default: {
            return Promise.reject(error);
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

const refreshToken = async () => {
  try {
    const user = authenticate.currentUser;
    const _token = await user.getIdToken(true);
    return _token;
  } catch (error) {
    console.log(error, "refreshToken");
  }
};

export default customClientAxios;
