import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import { getApiCallParams, isNullOrUndefined } from "../utils";
import { FetchIntegrationsApi } from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

function useIntegrationHooks(integration, getSuccesMsg, getFailureMsg) {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const apiLoading = useSelector(
    (state) => state.user.isLoading[FetchIntegrationsApi]
  );

  const isIntegrationActivated = () => {
    if (isNullOrUndefined(userState.integrations.integrations)) return false;
    const razorpayIntegrationObj = userState.integrations.integrations.find(
      (item) => item.id === integration
    );
    if (isNullOrUndefined(razorpayIntegrationObj)) return false;
    return razorpayIntegrationObj.isActivated;
  };

  useEffect(() => {
    if (
      (searchParams.size > 0 && searchParams.get("success")) ||
      isNullOrUndefined(userState.integrations.integrations)
    ) {
      dispatch(usersApiCall(getApiCallParams(FetchIntegrationsApi)));
    }
  }, []);

  useEffect(() => {
    if (!apiLoading) return;
    enqueueSnackbar(
      isIntegrationActivated() ? getSuccesMsg() : getFailureMsg(),
      {
        variant: isIntegrationActivated() ? "success" : "error",
      }
    );
  }, [apiLoading]);

  return { apiLoading, isIntegrationActivated };
}

export default useIntegrationHooks;
