import React, { useEffect, useState } from "react";
import { TestimonialTabs } from "../../constants";
import {
  AddFlex,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import { Dialog } from "@mui/material";
import TestimonialsHeader from "../../Testimonials/TestimonialsHeader";
import { useSelector } from "react-redux";
import {
  FeaturesKeys,
  filterArray,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import TestimonialsHolder from "../../Testimonials/TestimonialsHolder";
import useWindowSize from "../../Hooks/useWindowSize";
import TestimonialCard from "../../Testimonials/TestimonialCard";
import { Masonry } from "@mui/lab";
import LeftBorderContentHeader from "../LeftBorderContentHeader";

function OfferingTestimonials({ handleSetOffering, offeringTestimonials }) {
  const [testimonials, setTestimonials] = useState([]);
  const [openTestimonialsDialog, setOpenTestimonialsDialog] = useState(false);
  const [selectedTestimonials, setSelectedTestimonials] =
    useState(offeringTestimonials);
  const { isMobileView } = useWindowSize();
  const { features } = useSelector((state) => state.userFeatures.userFeatures);

  const handleMainTestimonialFilter = (selectedTab) => {
    if (selectedTab === TestimonialTabs.ALL) {
      setAllTestimonials();
    } else if (selectedTab === TestimonialTabs.SELF) {
      setTestimonials(
        features[FeaturesKeys.testimonial.id].items.filter(
          (testimonial) => testimonial.source === TestimonialTabs.SELF
        )
      );
    } else {
      setTestimonials(
        features[FeaturesKeys.testimonial.id].items.filter(
          (testimonial) => testimonial.source === TestimonialTabs.PUBLIC
        )
      );
    }
  };

  const handleSearchFilter = (searchText) => {
    if (searchText.length === 0) {
      setAllTestimonials();
      return;
    }
    setTestimonials((prev) =>
      prev.filter((testimonial) => {
        if (
          isNullOrUndefined(testimonial.msg) &&
          isNullOrUndefined(testimonial.name)
        ) {
          return false;
        } else if (isNullOrUndefined(testimonial.name)) {
          return testimonial.msg.toLowerCase().includes(searchText);
        } else if (isNullOrUndefined(testimonial.msg)) {
          return testimonial.name.toLowerCase().includes(searchText);
        }
        return (
          testimonial.msg.toLowerCase().includes(searchText) ||
          testimonial.name.toLowerCase().includes(searchText)
        );
      })
    );
  };
  // const getFeatures
  const setAllTestimonials = () => {
    if (
      isNullOrUndefined(features[FeaturesKeys.testimonial.id]) ||
      isNullOrUndefined(features[FeaturesKeys.testimonial.id].items)
    )
      return [];
    setTestimonials(features[FeaturesKeys.testimonial.id].items);
  };

  const handleToggleCheckbox = (testimonialId) => {
    if (selectedTestimonials.includes(testimonialId)) {
      setSelectedTestimonials((prevFilters) =>
        prevFilters.filter((_filter) => _filter !== testimonialId)
      );
    } else {
      setSelectedTestimonials((prev) => [...prev, testimonialId]);
    }
  };
  useEffect(() => {
    setAllTestimonials();
  }, []);

  const getTestimonialsLength = () => {
    return features[FeaturesKeys.testimonial.id]?.items.filter((item) =>
      selectedTestimonials.includes(item.id)
    ).length;
  };

  return (
    <>
      <LeftBorderContentHeader
        headerText={"Testimonials"}
        subText={"This is an optional section"}
      />
      <AddFlex flexDirection="column">
        {getTestimonialsLength() > 0 ? (
          <AddFlex
            alignItems="flex-start"
            // padding="10px"
            style={{
              gap: "10px",
            }}
          >
            {/* <Masonry columns={isMobileView() ? 1 : 2} spacing={2}>
              {features[FeaturesKeys.testimonial.id]?.items
                .filter((item) => selectedTestimonials.includes(item.id))
                .map((testimonial) => (
                  <TestimonialCard
                    testimonialData={testimonial}
                    hideIcon={true}
                  />
                ))}
            </Masonry> */}
            {getTestimonialsLength()}{" "}
            {getTestimonialsLength() === 1 ? "Testimonial" : "Testimonials"}{" "}
            Added
          </AddFlex>
        ) : (
          "No Testimonials Added"
        )}
      </AddFlex>
      <AddFlex margin="15px 0 0 0">
        <SecondaryActionButton
          borderRadius="5px"
          onClick={() => setOpenTestimonialsDialog((prev) => !prev)}
        >
          Manage Testimonials
        </SecondaryActionButton>
      </AddFlex>
      <Dialog
        open={openTestimonialsDialog}
        onClose={() => setOpenTestimonialsDialog((prev) => !prev)}
        PaperProps={{
          sx: {
            width: isMobileView() ? "95vw" : "80vw",
            height: "90vh",
            maxWidth: "100vw",
          },
        }}
      >
        <AddFlex
          flexDirection="column"
          padding="0 30px"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <TestimonialsHeader
            handleMainTestimonialFilter={handleMainTestimonialFilter}
            handleSearchFilter={handleSearchFilter}
            hideExtraFilters={true}
          />
        </AddFlex>
        <AddFlex
          height="80%"
          style={{ overflow: "scroll" }}
          justify="center"
          padding="10px 0 0 0"
          margin={isMobileView() ? "10px" : "0px 0 0 30px"}
        >
          <TestimonialsHolder
            testimonials={testimonials}
            isOfferingDialog={true}
            selectedTestimonials={selectedTestimonials}
            onTestimonialClicked={(id) => {
              handleToggleCheckbox(id);
            }}
          />
        </AddFlex>
        <AddFlex
          position="absolute"
          bottom="0px"
          right="0"
          width={"100%"}
          padding="10px 30px"
          backgroundColor="white"
          style={{
            zIndex: "1111",
            boxShadow: "0 0 0px 4px rgba(0,0,0,0.05)",
          }}
        >
          <PrimaryActionButton
            margin="0 auto"
            type="submit"
            onClickCapture={() => {
              handleSetOffering("testimonials.data", selectedTestimonials);
              setOpenTestimonialsDialog((prev) => !prev);
            }}
          >
            Add Testimonials
          </PrimaryActionButton>
        </AddFlex>
      </Dialog>
    </>
  );
}

export default OfferingTestimonials;
