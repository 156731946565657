import React, { useEffect, useState } from "react";
import SectionHeader from "./SectionHeader";
import TimeSlotsHolder from "./TImeSlotsHolder";
import PlanHolder from "./PlanHolder";
import { useSelector } from "react-redux";

function SubscriptionPricing({ offering, errors, handleSetOffering }) {
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(0);
  const { resetPricingIndexes } = useSelector((state) => state.appTemp.data);
  useEffect(() => {
    setSelectedTimeSlotIndex(0);
  }, [resetPricingIndexes]);
  return (
    <div>
      <SectionHeader
        headerText={"Add Pricing / Ticketing"}
        subHeaderText={"Customise multiple pricing / ticketing options"}
        margin={"0 0 24px 0"}
        errorText={errors["pricing"]?.err}
      />
      <TimeSlotsHolder
        offering={offering}
        selectedDateIndex={0}
        selectedTimeSlotIndex={selectedTimeSlotIndex}
        setSelectedTimeSlotIndex={setSelectedTimeSlotIndex}
      />
      <PlanHolder
        offering={offering}
        selectedDateIndex={0}
        selectedTimeSlotIndex={selectedTimeSlotIndex}
        handleSetOffering={handleSetOffering}
      />
    </div>
  );
}

export default SubscriptionPricing;
