import { Divider, MenuItem, Select } from "@mui/material";
import {
  AddFlex,
  CustomInput,
  CustomInputLabel,
  Group,
  PrimaryActionButton,
} from "../reusableStyles";
import styled from "styled-components";
import { isNullOrUndefined } from "../utils";
import CustomCalendar from "../Calendar/Components/DatePickerCustom";
import DatePicker from "react-multi-date-picker";
import DropdownComponent from "../DropdownComponent";

const kycDetailsInputs = [
  {
    id: "kycDetailsInputs_1",
    placeholder: "Enter your first name",
    label: "First Name",
    valueID: "firstName",
    type: "text",
  },
  {
    id: "kycDetailsInputs_2",
    placeholder: "Enter your last name",
    label: "Last Name",
    valueID: "lastName",
    type: "text",
  },
  {
    id: "kycDetailsInputs_3",
    placeholder: "Enter your gender",
    label: "Gender",
    valueID: "gender",
    type: "select",
    attr: [
      { id: "kycDetailsInputs_gender_1", value: "male", title: "Male" },
      { id: "kycDetailsInputs_gender_2", value: "female", title: "Female" },
    ],
  },
  {
    id: "kycDetailsInputs_4",
    placeholder: "Your date of birth",
    label: "Date of birth",
    valueID: "dob",
    type: "calendar",
  },
  {
    id: "kycDetailsInputs_5",
    placeholder: "Pan card number",
    label: "PAN card number",
    valueID: "PAN",
    type: "text",
  },
];

const DateHolder = styled.div`
  width: 206px;
  padding: 5px 5px;
  margin-top: 10px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
`;

function KYC({
  windowSize,
  handleSetKycDetails,
  generalKycDetails,
  onSubmit,
  resetSectionDetails,
  isUpdating,
  handleOpenSnackbar,
}) {
  function validatePAN(panNumber) {
    if (isNullOrUndefined(panNumber)) return true;
    // Remove spaces and convert to uppercase
    panNumber = panNumber.replace(/\s/g, "").toUpperCase();

    // PAN number format: 5 uppercase letters + 4 digits + 1 uppercase letter
    const regex = /^[A-Z]{5}\d{4}[A-Z]$/;

    return regex.test(panNumber);
  }
  function isValidDOB(dateOfBirth) {
    const currentDate = new Date();
    const dob = new Date(dateOfBirth);

    // Calculate the difference in years
    let age = currentDate.getFullYear() - dob.getFullYear();

    // Adjust for month and day differences
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() &&
        currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age >= 18;
  }
  const handelSubmit = () => {
    if (isNullOrUndefined(generalKycDetails)) {
      handleOpenSnackbar("Please enter all KYC details properly", "error");
      return;
    }
    if (
      isNullOrUndefined(generalKycDetails["PAN"]) ||
      !validatePAN(generalKycDetails["PAN"])
    ) {
      handleOpenSnackbar("Please enter a valid PAN Card number.", "error");
      return;
    }
    if (
      isNullOrUndefined(generalKycDetails["dob"]) ||
      !isValidDOB(generalKycDetails["dob"])
    ) {
      handleOpenSnackbar("Minimum age should be 18years.", "error");
      return;
    }
    onSubmit("generalDetails");
  };
  const handleCancel = () => {
    resetSectionDetails("generalDetails");
  };

  const handleGetInput = (kycDetail) => {
    switch (kycDetail.type) {
      case "text":
        return (
          <CustomInput
            label={kycDetail.label}
            value={
              generalKycDetails && generalKycDetails[kycDetail.valueID]
                ? generalKycDetails[kycDetail.valueID]
                : ""
            }
            placeholder={kycDetail.placeholder}
            onChange={(value) =>
              handleSetKycDetails("generalDetails", kycDetail.valueID, value)
            }
          />
        );
      case "select": {
        return (
          <AddFlex flexDirection="column" width="100%">
            {/* <CustomInputLabel>{kycDetail.label}</CustomInputLabel>
            <Select
              sx={{
                width: "100%",
                padding: "0",
                borderRadius: "8px",
                height: "44px",
              }}
              value={
                generalKycDetails && generalKycDetails[kycDetail.valueID]
                  ? generalKycDetails[kycDetail.valueID]
                  : kycDetail.attr[0].value
              }
              onChange={(e) =>
                handleSetKycDetails(
                  "generalDetails",
                  kycDetail.valueID,
                  e.target.value
                )
              }
            >
              {kycDetail.attr.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Select> */}
            <DropdownComponent
              label={"Gender"}
              options={[
                { id: "male", name: "Male" },
                { id: "female", name: "Female" },
              ]}
              getInputValue={(value) =>
                handleSetKycDetails(
                  "generalDetails",
                  kycDetail.valueID,
                  value.name
                )
              }
            />
          </AddFlex>
        );
      }
      case "calendar": {
        return (
          <AddFlex flexDirection="column" width="100%">
            {/* <label>{kycDetail.label}</label> */}
            <DatePicker
              default
              date={
                generalKycDetails && generalKycDetails[kycDetail.valueID]
                  ? generalKycDetails[kycDetail.valueID]
                  : null
              }
              getInputDate={(date) =>
                handleSetKycDetails("generalDetails", kycDetail.valueID, date)
              }
              zIndex={"1111111"}
              render={(value, openCalendar) => (
                <CustomInput
                  label={kycDetail.label}
                  value={value}
                  onClick={openCalendar}
                  footerText={null}
                />
              )}
            />
          </AddFlex>
        );
      }
      default:
        return <></>;
    }
  };
  return (
    <>
      <form className="kyc_information" style={{ width: "100%" }}>
        <AddFlex margin="20px 0" wrap="wrap" gap="24px">
          {kycDetailsInputs.map((kycDetail) => (
            <div style={{ width: "45%" }}> {handleGetInput(kycDetail)}</div>
          ))}
        </AddFlex>
      </form>
      <Divider />
      <PrimaryActionButton
        style={{ width: "max-content", margin: "20px 0 0 auto" }}
        onClick={onSubmit}
      >
        Save Changes
      </PrimaryActionButton>
    </>
  );
}

export default KYC;
