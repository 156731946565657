import { useState } from "react";
import { callNonStoreApi } from "../../NonStoreApis";
import { UpdateStartDateEndDate } from "../../Api/ApiEndPoints";
import {
  AddFlex,
  CustomInput,
  CustomText,
  Gap,
  HeaderWithLeftBorder,
} from "../../reusableStyles";
import { MainTextSubText } from "./SubscriptionSaleRow";
import { addDays, format } from "date-fns";
import DateComponent from "./DateComponent";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import { getSubscriptionEndDate } from "../OfferingsPages/SubscriptionHelper";
import { enqueueSnackbar } from "notistack";
import { isNullOrUndefined } from "../../utils";
import { CalendarMonth, Edit } from "@mui/icons-material";
import { headerTextColor, primaryActionColor } from "../../constants";
import { Divider } from "@mui/material";

export default function ModifySubscription({ sale, updateSubscriptionEntry }) {
  const [loading, setLoading] = useState(false);
  const [datesPayload, setDatesPayload] = useState({
    start: sale.subscriptionStartDate,
    end: getSubscriptionEndDate(sale),
  });
  const handleChangeStartDate = async (e) => {
    try {
      e.stopPropagation();
      setLoading(true);
      const start = datesPayload.start;
      let end = datesPayload.end;
      console.log(start > end);
      if (start > end) {
        end = addDays(new Date(start), 1);
      }
      const response = await callNonStoreApi(UpdateStartDateEndDate, {
        transactionId: sale.id,
        customerId: sale.customerId,
        subscriptionId: sale.subscriptionId,
        newPlanStartDate: start,
        newPlanEndingDate: end,
      });
      if (response) {
        updateSubscriptionEntry({
          key: Object.keys(response.data.sales)[0],
          value: Object.values(response.data.sales)[0],
        });
        enqueueSnackbar("Date has changed successfully");
      }
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    return null;
  };

  return (
    <AddFlex margin="10px 0 18px 0" flexDirection="column" gap="18px">
      <HeaderWithLeftBorder
        header={"Modify Current Plan"}
        headerColor={"black"}
        headerFontSize={"14px"}
      />
      {isNullOrUndefined(sale.durationType) ||
      sale.durationType === "months" ? (
        <AddFlex
          width="100%"
          alignItems="center"
          justify="space-between"
          margin="0 auto"
        >
          <MainTextSubText
            label={"Start Date"}
            value={
              <AddFlex gap="5px">
                <CustomText color="#1c1b1f" fontWeight="600">
                  {format(new Date(sale.subscriptionStartDate), "dd MMM yyyy")}
                </CustomText>
                <DateComponent
                  handleGetDate={(date) => {
                    setDatesPayload((prev) => {
                      return { ...prev, start: date };
                    });
                  }}
                  dateValue={datesPayload.start}
                  onSubmit={handleChangeStartDate}
                  loading={loading}
                  onClose={() => {
                    setDatesPayload((prev) => {
                      return {
                        ...prev,
                        start: null,
                      };
                    });
                  }}
                />
              </AddFlex>
            }
          />
          <OpenInFullOutlinedIcon sx={{ transform: "rotate(45deg)" }} />
          <MainTextSubText
            label={"End Date"}
            value={
              <AddFlex gap="5px">
                <CustomText color="#1c1b1f" fontWeight="600">
                  {format(
                    new Date(getSubscriptionEndDate(sale)),
                    "dd MMM yyyy"
                  )}
                </CustomText>
                <DateComponent
                  handleGetDate={(date) => {
                    setDatesPayload((prev) => {
                      return { ...prev, end: date };
                    });
                  }}
                  dateValue={datesPayload.end}
                  minDate={datesPayload.start}
                  onSubmit={handleChangeStartDate}
                  loading={loading}
                  onClose={() => {
                    setDatesPayload((prev) => {
                      return {
                        ...prev,
                        end: null,
                      };
                    });
                  }}
                />
              </AddFlex>
            }
          />
        </AddFlex>
      ) : (
        <AddFlex>
          <MainTextSubText
            label={"Start Date"}
            value={
              <AddFlex gap="5px">
                <CustomText color="#1c1b1f" fontWeight="600">
                  {format(new Date(sale.subscriptionStartDate), "dd MMM yyyy")}
                </CustomText>
                <DateComponent
                  handleGetDate={(date) => {
                    setDatesPayload((prev) => {
                      return { ...prev, start: date };
                    });
                  }}
                  dateValue={datesPayload.start}
                  onSubmit={handleChangeStartDate}
                  loading={loading}
                  onClose={() => {
                    setDatesPayload((prev) => {
                      return {
                        ...prev,
                        start: null,
                      };
                    });
                  }}
                />
              </AddFlex>
            }
          />
          <Gap />
          <AddFlex
            padding="10px 20px"
            width="30%"
            alignItems="center"
            justify="space-between"
            gap="12px"
            border={`1px solid ${"grey"}`}
            borderRadius="4px"
          >
            {sale.duration} <Divider orientation="vertical" />{" "}
            <Edit sx={{ color: primaryActionColor, fontSize: "18px" }} />
          </AddFlex>
          {/* <CustomInput /> */}
        </AddFlex>
      )}
    </AddFlex>
  );
}
