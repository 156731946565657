import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { isNullOrUndefined } from "../../utils";

export default function ButtonWithAlertDialog({
  component,
  title,
  description,
  buttons,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {React.cloneElement(component, {
        onClick: async (e) => {
          e.stopPropagation();
          if (component.props.onClick) {
            const shouldShow = await component.props.onClick();
            if (shouldShow) {
              handleClickOpen(e);
            }
          } else handleClickOpen(e);
        },
      })}
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "30vw",
          },
        }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          >
            {"Cancel"}
          </Button>
          {buttons.map((button) => (
            <Button
              onClick={async (e) => {
                e.stopPropagation();
                await button.action();
                handleClose();
              }}
            >
              {button.title}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
