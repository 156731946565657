import React, { useState } from "react";
import {
  AddFlex,
  CustomInput,
  DialogHeader,
  Gap,
  InputLabel,
  PrimaryActionButton,
} from "../../reusableStyles";
import { MenuItem, Select } from "@mui/material";
import { Currency, isNullOrUndefined } from "../../utils";

function CouponValue({
  currencies,
  inputFields,
  selectedCouponValue,
  couponType,
  closeDialog,
  handleUpdateCouponValues,
}) {
  const [selectedCurrency, setSelectedCurrency] = useState(
    selectedCouponValue?.currency
  );
  const [inputValues, setInputValues] = useState(
    isNullOrUndefined(selectedCouponValue?.values)
      ? {}
      : selectedCouponValue?.values
  );

  const getInputCurrencies = () => {
    if (isNullOrUndefined(selectedCouponValue?.currency)) {
      return Object.keys(Currency).filter(
        (currency) => !currencies.includes(currency)
      );
    } else {
      return Object.keys(Currency);
    }
  };

  return (
    <>
      <DialogHeader
        onClose={() => {
          closeDialog();
        }}
        mainText={"Customise coupon value"}
      />
      <AddFlex
        marginTop="10px"
        gap="10px"
        flexDirection="column"
        padding="10px"
        height="100%"
      >
        <InputLabel style={{ margin: 0 }}>
          Select the currency in which this coupon is valid
        </InputLabel>
        <Select
          size="small"
          value={selectedCurrency}
          onChange={(e) => {
            setSelectedCurrency(e.target.value);
          }}
        >
          {getInputCurrencies().map((currency) => (
            <MenuItem value={currency}>{currency}</MenuItem>
          ))}
        </Select>
        <AddFlex gap="10px" flexDirection="column">
          {inputFields.map((field) => (
            <CustomInput
              key={field.id}
              label={field.label}
              placeholder={field.placeholder}
              type={"number"}
              value={inputValues[field.inputValueField]}
              onChange={(text) => {
                if (
                  couponType === "percentage" &&
                  field.inputValueField === "value" &&
                  text > 100
                )
                  return;
                setInputValues((prev) => ({
                  ...prev,
                  [field.inputValueField]: text,
                }));
              }}
            />
          ))}
        </AddFlex>
        <Gap />
        <PrimaryActionButton
          onClick={() => {
            handleUpdateCouponValues(
              selectedCurrency,
              inputValues,
              selectedCouponValue
            );
            closeDialog();
          }}
        >
          Submit
        </PrimaryActionButton>
      </AddFlex>
    </>
  );
}

export default CouponValue;
