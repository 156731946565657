import React, { useState } from "react";
import { AddFlex, CustomInput } from "../reusableStyles";
import TextEditor from "./TextEditor";
import { YouTube } from "@mui/icons-material";
import JoiningQuestionDialog from "./JoiningQuestions";
import { CustomDivider } from "./Components/BasicDetails";
import Notes from "./Notes";
import ImageHolderComponent from "./ImageHolder";
import { FILE_TYPES, isArrayNullOrUndefined } from "../utils";
import OfferingTestimonials from "./Components/OfferingTestimonials";
import SectionHeader from "./Components/SectionHeader";
import useWindowSize from "../Hooks/useWindowSize";
import HostDetails from "./HostDetails";
import CoverImageOrVideoUpload from "./CoverImageOrVideoUpload";

function Content({ offering, errors, handleSetOffering }) {
  const { isMobileView } = useWindowSize();
  const [coverImageFiles, setCoverImageFiles] = useState(offering.coverImage);
  const [galleryImageFiles, setGalleryImageFiles] = useState(
    offering.imageGallery
  );
  const handleGetFiles = (isMultiple, acceptedFiles) => {
    if (isArrayNullOrUndefined(acceptedFiles)) return;
    if (isMultiple) {
      setGalleryImageFiles(acceptedFiles);
      handleSetOffering("imageGallery", acceptedFiles);
    } else {
      setCoverImageFiles(acceptedFiles);
      handleSetOffering("coverImage", acceptedFiles);
    }
  };
  const handleRemoveFiles = (isMultiple, fileIndex) => {
    if (isMultiple) {
      const new_files = galleryImageFiles.filter((file, index) => {
        return index !== fileIndex;
      });
      setGalleryImageFiles(new_files);
      handleSetOffering("imageGallery", new_files);
    } else {
      const new_files = coverImageFiles.filter((file, index) => {
        return index !== fileIndex;
      });
      setCoverImageFiles(new_files);
      handleSetOffering("coverImage", new_files);
    }
  };
  return (
    <AddFlex flexDirection="column" width="100%">
      <AddFlex
        flexDirection="column"
        style={{ gap: "32px", width: isMobileView() ? "100%" : "75%" }}
      >
        <CustomInput
          label={"Short Bio"}
          value={offering.oneLineSummary}
          placeholder={"Eg: As funny as it can get"}
          footerText={
            "This is just a catchy one-liner, which describes the experience a customer gets"
          }
          onChange={(text) => {
            if (text.length > 100) return;
            handleSetOffering("oneLineSummary", text);
          }}
        />
        <TextEditor
          placeholder={"Short description of your event"}
          errorText={errors["description"]?.err}
          value={offering.description}
          label={"Short Description"}
          getTextEditorInput={(text) => {
            handleSetOffering("description", text);
          }}
        />
        <CoverImageOrVideoUpload
          offering={offering}
          errors={errors}
          handleSetOffering={handleSetOffering}
        />
        {/* <ImageHolderComponent
          showHeader={true}
          isMultiple={false}
          headerTitleText={"Add Cover Image"}
          errorText={errors["coverImage"]?.err}
          headerSubTitleText={"This image will be show in the main link. "}
          handleGetFiles={handleGetFiles}
          files={coverImageFiles}
          handleRemoveFile={handleRemoveFiles}
        /> */}
        <ImageHolderComponent
          showHeader={true}
          showOnly={FILE_TYPES.IMAGES}
          isMultiple={true}
          headerTitleText={"Add Gallery Images"}
          headerSubTitleText={
            "This images will be the image gallery for the public "
          }
          handleGetFiles={handleGetFiles}
          files={galleryImageFiles}
          handleRemoveFile={handleRemoveFiles}
        />
        <div>
          <SectionHeader
            headerText={"Link a youtube video"}
            subHeaderText={
              "This will always be the first item in your image gallery"
            }
            margin={"0 0 20px 0"}
          />
          <CustomInput
            value={offering.youtubeLink}
            placeholder={"Paste the youtube link here."}
            footerText={"This is an optional section"}
            onChange={(text) => {
              handleSetOffering("youtubeLink", text);
            }}
            widget={<YouTube sx={{ color: "red" }} />}
          />
        </div>
      </AddFlex>
      <CustomDivider />
      <Notes
        errors={errors}
        notes={offering.notes}
        handleSetOffering={handleSetOffering}
      />
      <CustomDivider />

      <OfferingTestimonials
        handleSetOffering={handleSetOffering}
        offeringTestimonials={
          offering.testimonials?.data ? offering.testimonials.data : []
        }
      />
      <CustomDivider />
      <HostDetails
        handleSetOffering={handleSetOffering}
        selectedHosts={offering.hosts}
      />
      <CustomDivider />
      <JoiningQuestionDialog
        joiningQuestions={offering.joiningQuestions}
        handleSetOffering={handleSetOffering}
      />
      <CustomDivider />
    </AddFlex>
  );
}

export default Content;
