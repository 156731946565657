import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
function RightDrawerHeader({ headerText, onClose }) {
  return (
    <AddFlex
      padding="10px 15px"
      style={{ borderBottom: "1px solid #ececec" }}
      alignItems="center"
      justify="space-between"
    >
      <CustomText color="black" fontWeight="500">
        {headerText}
      </CustomText>
      <CancelOutlinedIcon onClick={onClose} sx={{ cursor: "pointer" }} />
    </AddFlex>
  );
}

export default RightDrawerHeader;
