import React, { useEffect } from "react";
import { AddFlex } from "../reusableStyles";
import Tabs from "../Tabs";
import {
  LEADS_PAGE_ROUTE,
  OFFERING_BOOKINGS,
  OFFERING_SUMMARY,
  PAGE_SETTINGS_ROUTE,
} from "../OnboardingPage/routeNames";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Divider } from "@mui/material";

const dashboardTabs = () => {
  return [
    { name: "Summary", id: "offering_summary", navURL: OFFERING_SUMMARY },
    { name: "Bookings", id: "offering_booking", navURL: OFFERING_BOOKINGS },
    // { name: "Leads", id: "offeing_leads", navURL: LEADS_PAGE_ROUTE },
    { name: "Settings", id: "offering_settings", navURL: PAGE_SETTINGS_ROUTE },
  ];
};

function OfferingDashboard() {
  const navigate = useNavigate();
  const { offeringId } = useParams();
  const [searchParams] = useSearchParams();

  const getQueryParams = () => {
    return new URLSearchParams({
      pageType: searchParams.get("pageType"),
    }).toString();
  };

  useEffect(() => {
    navigate(
      `${dashboardTabs()[0].navURL.replace(
        ":offeringId",
        offeringId
      )}?${getQueryParams()}`
    );
  }, []);
  const getSelectedTabIndex = () => {
    const location = window.location.pathname;
    const paths = location.split("/");
    const page = paths[paths.length - 1];
    switch (page) {
      case "summary":
        return 0;
      case "bookings":
        return 1;
      // case "leads":
      //   return 2;
      case "settings":
        return 2;
      default:
        return 0;
    }
  };

  return (
    <AddFlex flexDirection="column" height="100%">
      <Tabs
        tabs={dashboardTabs()}
        selectedTabIndex={getSelectedTabIndex()}
        setSelectedTabIndex={(index, tab) => {
          navigate(
            `${tab.navURL.replace(
              ":offeringId",
              offeringId
            )}?${getQueryParams()}`
          );
        }}
        borderBottom={"none"}
        margin={"0 48px 10px 48px"}
      />
      <Divider />
      <AddFlex
        flexDirection="column"
        margin="30px 48px 10px 48px"
        style={{ flexGrow: "1", overflow: "hidden" }}
      >
        <Outlet />
      </AddFlex>
    </AddFlex>
  );
}

export default OfferingDashboard;
