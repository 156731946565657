import { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { handleImageUpload, isArrayNullOrUndefined } from "../utils";
import { AddFlex, CustomText } from "../reusableStyles";
import { Backdrop } from "@mui/material";
import GalleryComponent from "../CreateOffering/GalleryComponent";
import { primaryActionColor } from "../constants";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { getStorage } from "firebase/storage";
import { app } from "../firebase-config";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

const Image = styled.img`
  width: 84px;
  height: 84px;
  max-width: 96px;
  max-height: 96px;
  border-radius: 100%;
  object-fit: cover;
`;

const PlaceholderDiv = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 100%;
  background-color: grey;
`;

function TestimonialPhotoUpload({
  photoURL,
  handleGetFile,
  margin,
  isProfileImage,
}) {
  const [openGallery, setOpenGallery] = useState(false);
  const { userName } = useSelector((state) => state.user.apiUserData);
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const { getInputProps, getRootProps } = useDropzone({
    maxSize: 1024 * 1024 * 2,
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".png", ".svg"],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      handleGetFile(acceptedFiles[0], URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const handleGetFiles = async (isMultiple, file) => {
    handleGetFile(file[0].src);
  };

  return (
    <>
      <AddFlex margin={margin}>
        {photoURL && photoURL.length > 0 ? (
          <Image src={photoURL}></Image>
        ) : (
          <PlaceholderDiv />
        )}
        <AddFlex
          flexDirection="column"
          border="1px solid #EAECF0"
          alignItems="center"
          justify="center"
          height="96px"
          margin="0 0 0 24px"
          borderRadius="8px"
          padding="0 24px"
          backgroundColor="white"
          onClick={() => setOpenGallery(true)}
        >
          <input
            ref={inputRef}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              handleGetFiles(false, e.target.files[0]);
            }}
            // {...getInputProps()}
          />
          <CloudUploadOutlinedIcon sx={{ color: primaryActionColor }} />
          <AddFlex margin="4px 0 10px 0">
            <CustomText
              textAlign="center"
              margin="0 4px 0 0"
              color={primaryActionColor}
            >
              {loading ? "Uploading Image" : "Click to upload"}
            </CustomText>
          </AddFlex>
        </AddFlex>
      </AddFlex>
      <Backdrop
        open={openGallery}
        style={{ position: "fixed", top: 0, left: 0, zIndex: 1111111 }}
        onClick={() => {
          setOpenGallery(false);
        }}
      >
        <GalleryComponent
          isMultiple={false}
          open={openGallery}
          prevSelectedImages={null}
          closeBackdrop={() => setOpenGallery(false)}
          isProfileImage={isProfileImage}
          handleGetSelectedImages={handleGetFiles}
        />
      </Backdrop>
    </>
  );
}

export default TestimonialPhotoUpload;
