import styled from "styled-components";
import { CustomText } from "../reusableStyles";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import React from "react";

export const StyledLink = styled(Link)`
  color: ${(props) => (props.color ? props.color : "white")};
  font-size: ${(props) => props.fontSize && props.fontSize};
  display: flex;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : "6px 6px 6px 14px")};
  margin: ${(props) => (props.margin ? props.margin : "2px 0")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  /* transition: all 0.3s ease-in-out; */
  cursor: pointer;
  color: black;
  text-decoration: none;
  /* &:hover {
    background-color: ${primaryActionColorLight};
    color: ${primaryActionColor};
  } */
`;

const UnselectedColor = "#31363F";

const SidebarNavLink = ({ item, isSelected, setSelectedTabRoute }) => {
  return (
    <StyledLink
      // padding="5px"
      to={`${item.route.split("/")[1]}`}
      onClick={() => setSelectedTabRoute(item.route)}
      style={{
        margin: "0 0 5px 0",
        border: isSelected ? "1px solid #ececec" : "1px solid transparent",
      }}
      backgroundColor={isSelected ? primaryActionColor : "transparent"}
    >
      <div
        style={{
          margin: "0 10px 0 0",
          // height: "30px",
          display: "flex",
          alignItems: "center",
          color: isSelected ? "white" : UnselectedColor,
        }}
      >
        {React.cloneElement(item.icon, {
          sx: {
            fontSize: "18px",
          },
        })}
      </div>

      <CustomText
        color={isSelected ? "white" : UnselectedColor}
        fontWeight={isSelected ? "500" : "500"}
        fontSize="14px"
      >
        {item.name}
      </CustomText>
    </StyledLink>
  );
};

export default SidebarNavLink;
