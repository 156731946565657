import React, { useEffect, useState } from "react";
import { callNonStoreApi } from "../NonStoreApis";
import {
  CreateNewUserCalendars,
  DeleteUserCalendars,
  FetchUserCalendars,
} from "../Api/ApiEndPoints";
import Slots from "./Components/Slots";
import BlockDates from "./Components/BlockDates";
import styled from "styled-components";
import {
  AddFlex,
  CustomInput,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import { Add, Delete, DeleteOutline } from "@mui/icons-material";
import { CircularProgress, Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import { isNullOrUndefined } from "../utils";
import { useDispatch } from "react-redux";
import { setUserCalendarsAction } from "../Store/appTempSlice";
import { enqueueSnackbar } from "notistack";
import ButtonWithAlertDialog from "../CreateOffering/Components/ButtonWithAlertDialog";

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 48px;
  margin: 0px 0;
  height: 100%;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 15px;
  }
`;

function CalendarWrapper() {
  const [userCalendars, setUserCalendars] = useState();
  const [openCreateScheduleDialog, setOpenCreateScheduleDialog] =
    useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState();
  const { calendars } = useSelector((state) => state.appTemp.data);
  const dispatch = useDispatch();
  const handleGetUserCalendars = async () => {
    const response = await callNonStoreApi(FetchUserCalendars);
    dispatch(setUserCalendarsAction(response.data));
    setSelectedCalendar(
      Object.values(response.data.calendars).find(
        (calendar) => calendar.name === "Default"
      )
    );
  };

  const handleDeleteSchedule = async (scheduleId) => {
    const response = await callNonStoreApi(DeleteUserCalendars, {
      calendarId: scheduleId,
    });
    dispatch(setUserCalendarsAction(response.data));
  };

  useEffect(() => {
    if (isNullOrUndefined(calendars)) return;
    setUserCalendars(calendars);
  }, [calendars]);

  useEffect(() => {
    handleGetUserCalendars();
  }, []);

  return (
    userCalendars && (
      <AddFlex marginTop="30px" flexDirection="column" gap="30px">
        <AddFlex gap="10px" padding="0 48px">
          {Object.values(userCalendars.calendars).map((calendar, index) => (
            <AddFlex
              padding="10px"
              gap="5px"
              style={{
                color: "black",
                // fontWeight: "300",
                fontSize: "14px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor:
                  selectedCalendar?.id === calendar.id && "#F2F2F2",
              }}
              onClick={() => {
                setSelectedCalendar(calendar);
              }}
            >
              {calendar.name}{" "}
              {calendar.name !== "Default" && (
                <ButtonWithAlertDialog
                  component={<DeleteOutline sx={{ fontSize: "16px" }} />}
                  buttons={[
                    {
                      title: "Proceed Anyway",
                      action: async () => {
                        await handleDeleteSchedule(calendar.id);
                      },
                    },
                  ]}
                  title={"Are You Sure?"}
                  //   description={description}
                />
              )}
            </AddFlex>
          ))}
          <SecondaryActionButton
            style={{
              padding: "8px 10px",
              margin: 0,
              color: "black",
              border: "1px solid #B0B0B0",
              gap: "5px",
              fontSize: "14px",
              fontWeight: 400,
            }}
            onClick={() => {
              setOpenCreateScheduleDialog(true);
            }}
          >
            <Add sx={{ fontSize: "14px", margin: 0 }} /> New Schedule
          </SecondaryActionButton>
        </AddFlex>
        {selectedCalendar && (
          <Container>
            <Slots _schedule={selectedCalendar} />
            <BlockDates _schedule={selectedCalendar} />
          </Container>
        )}
        <Dialog
          open={openCreateScheduleDialog}
          PaperProps={{ sx: { width: "20vw" } }}
          onClose={() => {
            setOpenCreateScheduleDialog(false);
          }}
        >
          <CalendarDialogContent
            setUserCalendars={setUserCalendars}
            onClose={() => {
              setOpenCreateScheduleDialog(false);
            }}
          />
        </Dialog>
      </AddFlex>
    )
  );
}

export default CalendarWrapper;

const CalendarDialogContent = ({
  _scheduleName,
  setUserCalendars,
  onClose,
}) => {
  const [calendarName, setCalendarName] = useState(
    _scheduleName ? _scheduleName : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleCreateSchedule = async () => {
    try {
      setIsLoading(true);
      const response = await callNonStoreApi(CreateNewUserCalendars, {
        calendarName: calendarName,
      });
      //   setUserCalendars(response.data);
      dispatch(setUserCalendarsAction(response.data));
      onClose();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AddFlex gap="20px" flexDirection="column" padding="10px">
      <CustomText color="black" fontWeight="700" fontSize="24px">
        Create Schedule
      </CustomText>
      <CustomInput
        label={"Schedule Name"}
        placeholder={"Eg: Jhon's schedule"}
        onChange={(text) => {
          setCalendarName(text);
        }}
      />
      <AddFlex gap="10px" marginLeft="auto" justify="flex-end" width="100%">
        <SecondaryActionButton
          style={{
            width: "40%",
          }}
          padding="5px 10px"
          onClick={onClose}
        >
          Cancel
        </SecondaryActionButton>
        <PrimaryActionButton
          disabled={calendarName.length === 0}
          padding="5px 10px"
          onClick={() => {
            handleCreateSchedule();
          }}
          style={{
            width: "40%",
          }}
        >
          {isLoading ? <CircularProgress size={14} /> : "Create"}
        </PrimaryActionButton>
      </AddFlex>
    </AddFlex>
  );
};
