import React, { useState } from "react";
import DropdownComponent from "../../DropdownComponent";
import { handleCopyToClipboard, isNullOrUndefined } from "../../utils";
import {
  AddFlex,
  Arrow,
  CustomInput,
  CustomText,
  DialogHeader,
  Icon,
  InputLabel,
} from "../../reusableStyles";
import meet from "../.././ImageAssets/Google_logo.png";
import zoom from "../.././ImageAssets/zoom.png";
import useWindowSize from "../../Hooks/useWindowSize";
import { ContentCopy, VideoCall } from "@mui/icons-material";
import { primaryActionColor } from "../../constants";
import { enqueueSnackbar } from "notistack";
import { TertiaryButton } from "./ScheduleComponents/CoursesSchedule";
import { useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import {
  Description,
  Option,
  Title,
} from "../../Forms/SchedulingActionSetupDialog";
import { useNavigate } from "react-router";

function OnlineVenueMeetingDetails({ offering, handleSetOffering }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const navigate = useNavigate();
  const { integrations } = useSelector((state) => state.user.integrations);
  const { isMobileView } = useWindowSize();
  const handleSetInputValue = (value) => {
    handleSetOffering("locationDetails.meet_type", value.name);
    handleSetOffering("locationDetails.meet_id", value.id);
    handleSetOffering("locationDetails.venue_name", "Online Meet");
    handleSetOffering("locationDetails.address", value.name);
  };

  const getInitialDropdownValue = () => {
    if (isNullOrUndefined(offering?.locationDetails?.meet_type)) {
      return undefined;
    } else {
      return handleGetOptions().find(
        (item) => item.id === offering?.locationDetails?.meet_id
      );
    }
  };

  const isIntegrationDone = (integration_id) => {
    const integration = integrations.find(
      (integration) => integration.id === integration_id
    );
    return integration.isActivated;
  };

  const handleGetOptions = () => {
    return [
      {
        icon: <VideoCall />,
        id: "custom_video_call",
        name: "Custom Joining Link",
        subText: "If you wish to use your existing links.",
        value: 0,
      },
      // {
      //   icon: (
      //     <img src={meet} alt="" style={{ width: "24px", height: "24px" }} />
      //   ),
      //   id: "automatic_google_meet_link",
      //   name: "Google Meet",
      //   subText: "Auto generated at the time of booking",
      //   value: 1,
      // },
      {
        icon: (
          <img src={zoom} alt="" style={{ width: "24px", height: "24px" }} />
        ),
        id: "zoom_link",
        name: "Zoom",
        callback: isIntegrationDone("zoom")
          ? null
          : () => {
              setDialogContent({
                logo: zoom,
                title: "Complete Zoom Integration",
                integrationPath: `/integrations/zoom`,
                description:
                  "With the help of zoom meetings you will be able to create and manage meeting without any extra effort.",
              });
              setOpenDialog(true);
            },
        subText: "Auto generated at the time of booking",
        value: 1,
      },
    ];
  };
  return (
    <>
      <AddFlex
        margin="25px 0 0 0"
        flexDirection="column"
        width={isMobileView() ? "100%" : "50%"}
      >
        {offering?.locationDetails?.meet_id === "zoom_link" &&
          isNullOrUndefined(offering?.locationDetails?.zoom_data) && (
            <div
              style={{
                width: "100%",
                padding: "10px",
                background: "#FCEBEC",
                margin: "0px 0 15px 0",
                fontSize: "12px",
                fontWeight: "500",
                color: "#410002",
                borderRadius: "5px",
              }}
            >
              Meeting Details Will be auto generated when a booking is done
            </div>
          )}
        <DropdownComponent
          label={"Meeting Link"}
          footerText={"Select from the dropdown"}
          initialValue={getInitialDropdownValue()}
          options={handleGetOptions()}
          getInputValue={handleSetInputValue}
        />
        {offering?.locationDetails?.meet_id === "zoom_link" &&
          offering?.locationDetails?.zoom_data && (
            <AddFlex gap="5px" flexDirection="column" margin="20px 0 0 0">
              <CustomInput
                label={"Meeting Details"}
                disabled={true}
                leftWidget={"Join URL"}
                value={offering?.locationDetails?.zoom_data.join_url}
                leftBackgroundColor={"#F9FAFB"}
                onRightWidgetClick={() => {
                  handleCopyToClipboard(
                    offering?.locationDetails?.zoom_data.join_url
                  ).then(() =>
                    enqueueSnackbar("Join URL copied successfully", {
                      variant: "success",
                    })
                  );
                }}
                widget={
                  <ContentCopy
                    sx={{ fontSize: "16px", color: primaryActionColor }}
                  />
                }
              />
              {/* <CustomText fontSize="12px">
            Join URL: 
          </CustomText> */}
              <AddFlex gap="10px">
                <CustomText fontSize="12px">
                  Meeting ID: {offering?.locationDetails?.zoom_data.meetingId}
                </CustomText>
                <CustomText fontSize="12px">
                  Meeting Password:{" "}
                  {offering?.locationDetails?.zoom_data.meetingPassword}
                </CustomText>
              </AddFlex>
              <AddFlex
                style={{
                  margin: "10px 0 0 0",
                  fontSize: "12px",
                  gap: "5px",
                  borderBottom: "1px solid black",
                  paddingBottom: "3px",
                  width: "max-content",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const meetingDetails = `Joining URL: ${offering?.locationDetails?.zoom_data.join_url}, Meeting ID: ${offering?.locationDetails?.zoom_data.meetingId}, Meeting Password: ${offering?.locationDetails?.zoom_data.meetingPassword}`;
                  handleCopyToClipboard(meetingDetails).then(() =>
                    enqueueSnackbar("Meeting Details successfully", {
                      variant: "success",
                    })
                  );
                }}
              >
                Copy meeting details
              </AddFlex>
            </AddFlex>
          )}
        {offering?.locationDetails?.meet_id === "custom_video_call" && (
          <AddFlex flexDirection="column" marginTop="30px">
            <CustomInput
              label={"Enter Video Call URL"}
              value={offering.locationDetails?.custom_joining_url}
              footerText={
                "This is your Joining URL, where the participants will join"
              }
              onChange={(text) => {
                handleSetOffering("locationDetails.custom_joining_url", text);
              }}
            />
            <InputLabel color="black" style={{ marginTop: "20px" }}>
              Session Joining Information
            </InputLabel>
            <AddFlex>
              <CustomInput
                leftWidget={"ID"}
                value={offering.locationDetails?.custom_joining_meeting_id}
                margin="0 20px 0 0"
                onChange={(text) => {
                  handleSetOffering(
                    "locationDetails.custom_joining_meeting_id",
                    text
                  );
                }}
              />
              <CustomInput
                leftWidget={"Password"}
                value={
                  offering.locationDetails?.custom_joining_meeting_password
                }
                onChange={(text) => {
                  handleSetOffering(
                    "locationDetails.custom_joining_meeting_password",
                    text
                  );
                }}
              />
            </AddFlex>
          </AddFlex>
        )}
      </AddFlex>
      <Dialog
        open={openDialog}
        PaperProps={{
          sx: {
            height: "60vh",
          },
        }}
      >
        <DialogHeader
          mainText={"Important!"}
          onClose={() => setOpenDialog(false)}
          style={{ padding: "10px" }}
        />
        <Option
          style={{ marginTop: "20px" }}
          onClick={() => navigate(dialogContent.integrationPath)}
        >
          <Icon
            style={{ width: "32px", height: "32px" }}
            src={dialogContent.logo}
          />
          <div>
            <Title>{dialogContent.title}</Title>
            <Description>{dialogContent.description}</Description>
          </div>
          <Arrow>→</Arrow>
        </Option>
      </Dialog>
    </>
  );
}

export default OnlineVenueMeetingDetails;
