import React, { useState } from "react";
import { AddFlex, CustomText, InputLabel } from "../../../reusableStyles";
import RightDrawerHeader from "./RightDrawerHeader";
import { BoxedTabs } from "../OfferingPagesTabHeader";
import { IOSSwitch } from "../../../CustomSwitch";
import CardWithQR from "./SharableCards/CardWithQR";
import CardWithoutQR from "./SharableCards/CardWithoutQR";

const templatesWithQRJSON = {
  light: {
    cardBg: "white",
    cardBorder: "1px solid #ececec",
    textColor: "black",
    secondaryTextColor: "black",
    dividerColor: "rgba(255, 186, 0, 1)",
    iconColors: "black",
    buttonColor: "#F8F8F2",
    buttonBorder: "1px solid #8C541F",
    buttonTextColor: "rgba(140, 84, 31, 1)",
    calendarColors: {
      border: "1px solid #F8F8F2",
      monthColor: "rgba(219, 127, 16, 1)",
      monthBoxColor: "rgba(248, 248, 242, 1)",
      dateColor: "black",
    },
    imageGradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.45) 66.66%, rgba(0, 0, 0, 0.95) 97%), 50% / cover no-repeat",
  },
  dark: {
    cardBg: "rgba(4, 24, 35, 1)",
    cardBorder: "1px solid transparent",
    textColor: "white",
    secondaryTextColor: "rgba(248, 238, 212, 1)",
    dividerColor: "rgba(248, 238, 212, 1)",
    iconColors: "#d0d5dd",
    buttonColor: "rgba(248, 238, 212, 1)",
    buttonTextColor: "black",
    buttonBorder: "1px solid rgba(248, 238, 212, 1)",
    calendarColors: {
      border: "1px solid transparent",
      monthColor: "white",
      monthBoxColor: "rgba(254, 51, 124, 1)",
      dateColor: "black",
    },
    imageGradient:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(4, 24, 35, 0.80) 80.28%, #041823 100%), 50% / cover no-repeat",
  },
};

function SharableCardsRightDrawer({ onClose, offering, featureKey }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const getSharableCards = () => {
    if (selectedTabIndex === 0) {
      return (
        <CardWithQR
          offering={offering}
          featureKey={featureKey}
          withQR={true}
          templatesWithQRJSON={
            templatesWithQRJSON[isDarkMode ? "dark" : "light"]
          }
        />
      );
    } else {
      return (
        <CardWithQR
          offering={offering}
          withQR={false}
          featureKey={featureKey}
          templatesWithQRJSON={
            templatesWithQRJSON[isDarkMode ? "dark" : "light"]
          }
        />
      );
    }
  };
  // const getCardLocationAndScheduleDetails = () => {
  //   switch()
  // };
  return (
    <>
      <RightDrawerHeader headerText={"Sharable Cards"} onClose={onClose} />
      <AddFlex flexDirection="column" gap="20px" padding="15px">
        <AddFlex alignItems="center" justify="space-between">
          <BoxedTabs
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            tabs={["With QR", "Without QR"]}
          />
          <AddFlex gap="10px" alignItems="center" justify="center">
            <InputLabel style={{ margin: 0 }}>Switch to Dark Mode</InputLabel>
            <IOSSwitch onChange={() => setIsDarkMode((prev) => !prev)} />
          </AddFlex>
        </AddFlex>
        {getSharableCards()}
      </AddFlex>
    </>
  );
}

export default SharableCardsRightDrawer;
