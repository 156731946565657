import React, { useState } from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import NameAndVenueDetails from "./NameAndVenueDetails";
import styled from "styled-components";
import ScheduleMain from "./ScheduleMain";
import PageGoals from "./PageGoals";
import Pricing from "./Pricing";
import { useSearchParams } from "react-router-dom";
import { FeaturesKeys, getFeatureIdFromName } from "../../utils";
import TravelPricing from "./TravelPricing";
import SubscriptionPricing from "./SubscriptionPricing";
import CurrencySettingsForOffering from "./CurrencySettingsForOffering";
import PlanHolderHeader from "./PlanHolderHeader";
import OneonOnePricing from "./OneonOnePricing";
import SelectCalendar from "./SelectCalendar";

export const CustomDivider = styled.div`
  width: 75%;
  height: 1px;
  background-color: #ececec;
  margin: 32px 0;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 40px 0;
  }
`;

function BasicDetails({ offering, errors, handleSetOffering }) {
  const [searchParams] = useSearchParams();
  const getPricing = () => {
    if (offering.scheduleDetails.length === 0) return;
    const pageType = searchParams.get("pageType");
    switch (pageType) {
      case FeaturesKeys.event.name:
      case FeaturesKeys.course.name:
      case FeaturesKeys.webinar.name:
        return (
          <Pricing
            errors={errors}
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      case FeaturesKeys.travel.name:
        return (
          <TravelPricing
            errors={errors}
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      case FeaturesKeys.subscription.name:
        return (
          <SubscriptionPricing
            errors={errors}
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      case FeaturesKeys.oneonone.name:
        return (
          <OneonOnePricing
            errors={errors}
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <AddFlex flexDirection="column" width="100%">
      <NameAndVenueDetails
        offering={offering}
        errors={errors}
        handleSetOffering={handleSetOffering}
      />
      <CustomDivider />
      {searchParams.get("pageType") === FeaturesKeys.oneonone.name && (
        <SelectCalendar
          calendarId={offering.calendarId}
          handleSetOffering={handleSetOffering}
        />
      )}
      <CustomDivider />
      <ScheduleMain offering={offering} handleSetOffering={handleSetOffering} />
      <CustomDivider />
      <PageGoals
        offering={offering}
        errors={errors}
        pageType={searchParams.get("pageType")}
        handleSetOffering={handleSetOffering}
      />
      <CustomDivider />
      <CurrencySettingsForOffering
        offering={offering}
        pageType={searchParams.get("pageType")}
        isTravel={
          searchParams.get("pageType") === FeaturesKeys.travel.name ||
          searchParams.get("pageType") === FeaturesKeys.oneonone.name
        }
        handleSetOffering={handleSetOffering}
      />
      <CustomDivider />
      {getPricing()}
      <CustomDivider />
    </AddFlex>
  );
}

export default BasicDetails;
