import React, { useState } from "react";
import {
  AddFlex,
  CustomText,
  Gap,
  SecondaryActionButton,
} from "../reusableStyles";
import { Checkbox, Dialog, Drawer, Rating } from "@mui/material";
import TestimonialCardHeader from "./TestimonialCardHeader";
import TestimonialCardBody from "./TestimonialCardBody";
import useWindowSize from "../Hooks/useWindowSize";
import TestimonialForm from "./TestimonialForm";
import TestimonialImageGallery from "./TestimonialImageGallery";
import { format } from "date-fns";
import { Edit, Share } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import TextTestimonialSharableCard from "./TextTestimonialSharableCard";
import { isArrayNullOrUndefined } from "../utils";
import ImageTestiomonialsSharableCard from "./ImageTestiomonialsSharableCard";
import VideoThumbnailGenerator from "./VideoTestimonial";

function TestimonialCard({
  testimonialData,
  isOfferingDialog,
  getTestimonialId,
  isSelected,
  hideIcon,
}) {
  const [openAddTestimonial, setOpenAddTestimonial] = useState(false);
  const [isSharableDialogOpen, setIsSharableDialogOpen] = useState(false);
  const [noOfLinesPerSlide, setNoOfLinesPerSlide] = useState(9);
  const { apiUserData } = useSelector((state) => state.user);
  const { isMobileView } = useWindowSize();
  const {
    id,
    name,
    occupation,
    workplace,
    photoUrl,
    msg,
    imageGallery,
    rating,
    offering,
    videoUrl,
    date,
  } = testimonialData;
  return (
    <>
      <AddFlex
        padding="10px"
        flexDirection="column"
        borderRadius="4px"
        style={{
          flexShrink: 0,
          boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.05)",
        }}
      >
        <TestimonialCardHeader
          name={name}
          id={id}
          getTestimonialId={getTestimonialId}
          hideIcon={hideIcon}
          occupation={occupation}
          workplace={workplace}
          photoUrl={photoUrl}
          isOfferingDialog={isOfferingDialog}
          isSelected={isSelected}
          isWallOfLove={testimonialData.isWallOfLove}
        />

        {/* <Divider /> */}
        <Rating
          value={rating}
          name="simple-controlled"
          readOnly
          size="small"
          sx={{ margin: "10px" }}
          precision={0.5}
        />
        {videoUrl && <VideoThumbnailGenerator videoUrl={videoUrl} />}
        <TestimonialImageGallery imageGallery={imageGallery} />
        <TestimonialCardBody msg={msg} />
        <AddFlex
          alignItems="center"
          margin="18px 0 0 0"
          style={{ color: "#25282C" }}
          padding="0 0 0 10px"
          justify="space-between"
        >
          <div>
            <CustomText fontSize="10px" color="#25282C">
              {format(date ? new Date(date) : new Date(), "dd MMMM yyyy")}
            </CustomText>
            <CustomText fontWeight="400" fontSize="14px" color="black">
              {offering?.name}
            </CustomText>
          </div>
          <Gap />
          <div
            onClick={(e) => {
              if (isOfferingDialog) {
                getTestimonialId(id);
                return;
              }
              setOpenAddTestimonial(true);
            }}
            style={{ padding: "5px 10px", cursor: "pointer" }}
          >
            {isOfferingDialog ? (
              <Checkbox
                checked={isSelected}
                size="small"
                sx={{ width: "14px", padding: 0 }}
              />
            ) : (
              <Edit sx={{ fontSize: "14px", color: "#7f7f7f" }} />
            )}
          </div>
          {/* <SecondaryActionButton
            padding="3px 10px"
            color="#7f7f7f"
            borderColor="#7f7f7f"
            fontSize="12px"
            margin="0 10px 0 0"
            className="StopDragClass"
            onClick={(e) => {
              if (isOfferingDialog) {
                getTestimonialId(id);
                return;
              }
              setOpenAddTestimonial(true);
            }}
          >
            Edit
          </SecondaryActionButton>
          <Share
            className="StopDragClass"
            style={{ fontSize: "14px", color: "#7f7f7f", cursor: "pointer" }}
          </SecondaryActionButton> */}
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsSharableDialogOpen(true);
            }}
            style={{
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            <Share
              className="StopDragClass"
              sx={{ fontSize: "14px", color: "#7f7f7f" }}
            />{" "}
          </div>
        </AddFlex>
      </AddFlex>

      <Drawer
        open={openAddTestimonial}
        onClose={() => setOpenAddTestimonial((prev) => !prev)}
        anchor="right"
        PaperProps={{ sx: { width: isMobileView() ? "100vw" : "35vw" } }}
      >
        <TestimonialForm
          testimonial={testimonialData}
          closeDrawer={() => setOpenAddTestimonial((prev) => !prev)}
        />
      </Drawer>
      <Dialog
        PaperProps={{
          sx: {
            maxWidth: "100vw",
            backgroundColor: "#E9EAEB",
            padding: "20px 30px",
            borderRadius: "16px",
          },
        }}
        onClose={() => {
          setIsSharableDialogOpen((prev) => !prev);
        }}
        open={isSharableDialogOpen}
      >
        <AddFlex
          flexDirection="column"
          gap="25px"
          alignItems="center"
          justify="space-between"
        >
          <AddFlex alignItems="center" justify="center" width="100%">
            <CustomText color="black" fontWeight="600" textAlign="center">
              🎉 Yayyy! Your testimonial card is ready
            </CustomText>
            <HighlightOffIcon
              style={{
                alignSelf: "flex-end",
                margin: "0 0 0 10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsSharableDialogOpen((prev) => !prev);
              }}
            />
          </AddFlex>
          {isArrayNullOrUndefined(imageGallery) ? (
            <TextTestimonialSharableCard
              testimonialData={testimonialData}
              photoURL={apiUserData.photoURL}
              Name={apiUserData.Name}
            />
          ) : (
            <ImageTestiomonialsSharableCard
              noOfLines={noOfLinesPerSlide}
              testimonialData={testimonialData}
              photoURL={apiUserData.photoURL}
              Name={apiUserData.Name}
            />
          )}
        </AddFlex>
      </Dialog>
    </>
  );
}

export default TestimonialCard;

{
  /* <SharableTestimonialCard2
            testimonialData={testimonialData}
            userPhotoUrl={apiUserData.photoURL}
            userName={apiUserData.userName}
          />
          <SharableTestimonialCard3
            testimonialData={testimonialData}
            userPhotoUrl={apiUserData.photoURL}
            userName={apiUserData.userName}
          /> */
}
