import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomText,
  SecondaryActionButton,
} from "../../../reusableStyles";
import { headerTextColor, primaryActionColor } from "../../../constants";
import {
  FeaturesKeys,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../../../utils";
import { Dialog } from "@mui/material";
import { differenceInDays, format } from "date-fns";
import EventOrWorkshopScheduleComponentExpanded from "./EventOrWorkshopScheduleComponentExpanded";
import CourseScheduleComponentExpanded from "./CourseScheduleComponentExpanded";
import { TertiaryButton } from "./CoursesSchedule";
import ButtonWithAlertDialog from "../ButtonWithAlertDialog";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { resetPricingIndexesAction } from "../../../Store/appTempSlice";
import { useDispatch } from "react-redux";
import { callNonStoreApi } from "../../../NonStoreApis";
import {
  CheckIsMeetingCreatedForSlot,
  DeleteZoomMeeting,
} from "../../../Api/ApiEndPoints";

/**
 * This component is Collapsed view for Events/Wokshops and Courses in Page Creation Basic
 * @param {*} param0
 * @returns
 */
function ScheduleComponentCollapsed({
  offering,
  offeringTypeId,
  handleSetOffering,
}) {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleAddNewDate = (newSchedule) => {
    handleSetOffering(`scheduleDetails.push`, newSchedule);
  };

  const getDateString = (scheduleDetail) => {
    // eslint-disable-next-line default-case
    if (isNullOrUndefined(scheduleDetail)) {
      return "New Date";
    }
    // eslint-disable-next-line default-case
    switch (offeringTypeId) {
      case FeaturesKeys.course.id:
        const startDate = format(new Date(scheduleDetail.date), "dd MMM yyyy");
        const endDate = format(new Date(scheduleDetail.endDate), "dd MMM yyyy");
        return `${
          differenceInDays(endDate, startDate) + 1
        } days (${startDate}-${endDate} )`;
      case FeaturesKeys.event.id:
        return `Date: ${format(new Date(scheduleDetail.date), "dd MMMM yyyy")}`;
    }
  };

  const getShouldUpdateZoom = () => {
    return offering.locationDetails?.meet_id === "zoom_link";
  };

  const handleDeleteMeetings = async (schedule) => {
    const timeSlots = schedule.timeSlots;
    for (let timeSlot of timeSlots) {
      const response = await callNonStoreApi(
        CheckIsMeetingCreatedForSlot.replace(":slotId", timeSlot.id)
      );
      const { isMeetingPresent, meeting } = response.data;
      if (isMeetingPresent) {
        const _response = await callNonStoreApi(
          DeleteZoomMeeting.replace(":meetingId", meeting.data.id)
        );
      }
    }
  };

  const getScheduleDialogComponent = () => {
    const scheduleDetail = offering.scheduleDetails[selectedIndex];
    // eslint-disable-next-line default-case
    switch (offeringTypeId) {
      case FeaturesKeys.course.id:
        return (
          <CourseScheduleComponentExpanded
            index={selectedIndex}
            shouldUpdateZoom={getShouldUpdateZoom()}
            handleSetOffering={handleSetOffering}
            scheduleDetail={scheduleDetail}
            setIsExpanded={setIsExpanded}
            dateString={getDateString(scheduleDetail)}
            isOnline={offering.locationDetails?.isOnline}
            duration={offering.durationInMins}
            timezone={offering.timezone}
            handleAddNewDate={handleAddNewDate}
          />
        );
      case FeaturesKeys.event.id:
      case FeaturesKeys.webinar.id:
        return (
          <EventOrWorkshopScheduleComponentExpanded
            index={selectedIndex}
            shouldUpdateZoom={getShouldUpdateZoom()}
            scheduleDetail={scheduleDetail}
            handleSetOffering={handleSetOffering}
            setIsExpanded={setIsExpanded}
            dateString={getDateString(scheduleDetail)}
            isOnline={offering.locationDetails?.isOnline}
            duration={offering.durationInMins}
            timezone={offering.timezone}
            handleAddNewDate={handleAddNewDate}
          />
        );
    }
  };

  return (
    <>
      <AddFlex flexDirection="column" margin="0 0 24px 0" gap="24px">
        {offering.scheduleDetails.map((scheduleDetail, index) => (
          <AddFlex
            style={{
              border: "1px solid #ececec",
              boxShadow: "0 0 1px 0 rgba(0,0,0,0.2)",
              cursor: "pointer",
            }}
            backgroundColor="white"
            padding="20px 32px"
            borderRadius="12px"
            flexDirection="column"
          >
            <AddFlex alignItems="center" style={{ gap: "24px" }}>
              <CustomText
                fontSize="14px"
                fontWeight="600"
                color={headerTextColor}
              >
                {index + 1}
              </CustomText>
              <AddFlex flexDirection="column" style={{ gap: "12px" }}>
                <CustomText
                  fontSize="14px"
                  fontWeight="600"
                  color={headerTextColor}
                >
                  {getDateString(scheduleDetail)}
                </CustomText>
                <AddFlex wrap="wrap">
                  {scheduleDetail.timeSlots.map((slot, index) => (
                    <CustomText fontSize="14px" margin="0 6px 0 0">
                      {getTimeInTwelveHourForm(slot.time)}
                      {""}
                      {/* {index !== scheduleDetail.timeSlots.length - 1 && ", "} */}
                    </CustomText>
                  ))}
                </AddFlex>
              </AddFlex>
              <div style={{ flexGrow: "1" }}></div>
              <AddFlex gap="20px" alignItems="center">
                <SecondaryActionButton
                  onClick={() => {
                    setSelectedIndex(index);
                    setIsExpanded((prev) => !prev);
                  }}
                  style={{
                    padding: "0px",
                    maxWidth: "max-content",
                    paddingBottom: "3px",
                    border: "none",
                    borderRadius: "0px",
                    borderBottom: "1px solid " + primaryActionColor,
                  }}
                >
                  Edit
                </SecondaryActionButton>
                {offering.scheduleDetails.length > 1 && (
                  <ButtonWithAlertDialog
                    component={
                      <DeleteOutlineOutlined
                        sx={{ fontSize: "18px", color: primaryActionColor }}
                      />
                    }
                    buttons={[
                      {
                        title: "Agree",
                        action: async () => {
                          const scheduleDetail =
                            offering.scheduleDetails[index];
                          await handleDeleteMeetings(scheduleDetail);
                          dispatch(resetPricingIndexesAction());
                          handleSetOffering(
                            `scheduleDetails.delete.${index}`,
                            null
                          );
                        },
                      },
                    ]}
                    title={"Are You Sure?!"}
                    description={"You cannot get this data again."}
                  />
                )}
              </AddFlex>
              {/* <KeyboardArrowDown /> */}
            </AddFlex>
          </AddFlex>
        ))}
      </AddFlex>
      <div>
        <TertiaryButton
          buttonName={"Add New Date"}
          onClick={() => {
            setIsExpanded((prev) => !prev);
          }}
        />
      </div>
      <Dialog fullWidth open={isExpanded}>
        {getScheduleDialogComponent()}
      </Dialog>
    </>
  );
}

export default ScheduleComponentCollapsed;
