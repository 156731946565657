import React, { useState } from "react";
import { AmountPaid, Item, Parent } from "../Pages/Components/BatchSaleRow";
import { AddFlex, CustomText, DialogHeader } from "../reusableStyles";
import {
  Currency,
  dashboardFeatureIdentifierBackgroundColor,
  dashboardFeatureIdentifierTextColor,
  formatPrice,
  getFeatureKeyFromFeatureId,
  handleCopyToClipboard,
  isNullOrUndefined,
} from "../utils";
import { Dialog, Divider, Drawer } from "@mui/material";
import { format } from "date-fns";
import { ContentCopyOutlined } from "@mui/icons-material";
import { primaryActionColor } from "../constants";
import { enqueueSnackbar } from "notistack";
import TransactionHistoryCard from "../AllCustomers/TransactionHistoryCard";

function TransactionTabRow({
  headerList,
  sale,
  saleData,
  date,
  index,
  transactionDetails,
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { name, id } = getFeatureKeyFromFeatureId(
    saleData.featureDetails.featureId
  );
  const getTotalOrderLength = () => {
    let length = 0;
    saleData.customerOrder.forEach((order) => {
      length += order.customerDetails.length;
    });
    return length;
  };

  const getSettledStatus = () => {
    return transactionDetails.status;
  };

  const isSettled = () => {
    return (
      getSettledStatus() === "Settled" || getSettledStatus() === "Refunded"
    );
  };

  const StatusChipColors = (status) => {
    if (status === "Pending") {
      return {
        color: "#9e7341",
        border: "1px solid #fdf1da",
        bg: "#fef8ec",
      };
    }
    if (status === "Refunded") {
      return {
        color: "#ababab",
        border: "1px solid #fdf1da",
        bg: "transparent",
      };
    }
    return {
      color: "#337648",
      border: "1px solid #d1f8e2",
      bg: "#ecfdf3",
    };
  };
  return (
    <>
      <Parent
        index={index}
        onClick={() => {
          setOpenDrawer((prev) => !prev);
        }}
      >
        <Item justify="center" percent={headerList[0][0]}>
          <AddFlex alignItems="center" flexDirection="column">
            <CustomText fontSize="14px" color="#101828">
              {saleData.bookingCustomerDetails?.name}
            </CustomText>
            <AddFlex margin="4px 0 0 0">
              <CustomText fontSize="12px">
                Booking For {getTotalOrderLength()}
              </CustomText>
            </AddFlex>
          </AddFlex>
        </Item>
        <Item justify="center" percent={headerList[1][0]}>
          <AddFlex alignItems="center" gap="0px" flexDirection="column">
            <CustomText color="#101828">
              {saleData.featureDetails.name}
            </CustomText>
            <AddFlex margin="4px 0 0 0">
              <AddFlex
                alignItems="center"
                padding="4px 10px"
                width="max-content"
                backgroundColor={dashboardFeatureIdentifierBackgroundColor[id]}
                borderRadius="3px"
              >
                <CustomText
                  color={dashboardFeatureIdentifierTextColor[id]}
                  fontSize="12px"
                >
                  {name}
                </CustomText>
              </AddFlex>
            </AddFlex>
          </AddFlex>
        </Item>
        <Item percent={headerList[2][0]}>
          <AddFlex alignItems="center" gap="5px" flexDirection="column">
            <div>{format(new Date(date), "dd MMM yyyy")}</div>
            <div>{format(new Date(date), "hh:mm a")}</div>
          </AddFlex>
        </Item>
        <Item
          percent={headerList[3][0]}
          style={{ flexDirection: "column", gap: "6px" }}
        >
          <AmountPaid
            couponCode={saleData.couponCode}
            currency={saleData.paymentBreakdown.currency}
            forexConversionRate={saleData.paymentBreakdown.forexConversionRate}
            totalAmount={saleData.paymentBreakdown.totalAmount}
            ticketPriceAfterDiscount={saleData.paymentBreakdown.totalAmount}
          />
        </Item>
        <Item percent={headerList[4][0]}>
          <CustomText textAlign="center" fontWeight={500} color="#101828">
            {formatPrice(saleData.payoutAmount, Currency.INR)}
          </CustomText>
        </Item>
        <Item percent={headerList[5][0]}>
          {saleData.gatewayCode === "razorpay" ? (
            <AddFlex
              padding="3px 15px"
              borderRadius="25px"
              backgroundColor={"#F3F7FF"}
              color={primaryActionColor}
              border={`1px solid ${primaryActionColor}`}
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              {"Check Razorpay"}
            </AddFlex>
          ) : (
            <AddFlex alignItems="center" gap="5px" flexDirection="column">
              {isSettled() && (
                <AddFlex justify="center" gap="12px" align="center">
                  <CustomText>{transactionDetails.bankUtr}</CustomText>
                  {transactionDetails.bankUtr !== "NA" && (
                    <ContentCopyOutlined
                      onClick={async (e) => {
                        e.stopPropagation();
                        handleCopyToClipboard(transactionDetails.bankUtr).then(
                          () => {
                            enqueueSnackbar(
                              "Transation ID copied to clipboard",
                              {
                                variant: "success",
                              }
                            );
                          }
                        );
                      }}
                      sx={{ fontSize: "14px", color: primaryActionColor }}
                    />
                  )}
                </AddFlex>
              )}
              <AddFlex
                padding="3px 15px"
                borderRadius="25px"
                backgroundColor={StatusChipColors(getSettledStatus()).bg}
                color={StatusChipColors(getSettledStatus()).color}
                border={StatusChipColors(getSettledStatus()).border}
                style={{ fontSize: "12px" }}
              >
                {getSettledStatus()}
              </AddFlex>
              <CustomText
                color={primaryActionColor}
                fontSize="12px"
                fontWeight="500"
              >
                PG:{" "}
                {saleData.gatewayCode === "razorpay" ? "RAZORPAY" : "GROWEZY"}
              </CustomText>
            </AddFlex>
          )}
        </Item>
      </Parent>
      <div
        style={{
          backgroundColor: "#ECECEC",
          width: "100%",
          height: "1px",
          flexShrink: 0,
        }}
      ></div>
      <Dialog
        open={openDrawer}
        onClose={() => {
          setOpenDrawer((prev) => !prev);
        }}
        PaperProps={{
          sx: {
            padding: "20px",
            width: "30vw",
          },
        }}
      >
        <AddFlex flexDirection="column" gap="12px">
          <DialogHeader
            mainText={"Transaction Details"}
            onClose={() => {
              setOpenDrawer((prev) => !prev);
            }}
            style={{
              border: "none",
            }}
          />
          <TransactionHistoryCard transaction={sale} />
        </AddFlex>
      </Dialog>
    </>
  );
}

export default TransactionTabRow;
