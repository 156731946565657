import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInputArea,
  CustomInputLabel,
  CustomText,
  DialogHeader,
  Gap,
  PrimaryActionButton,
} from "../../reusableStyles";
import { Divider } from "@mui/material";
import TextAreaInput from "../../Forms/TypeAction/TextAreaInput";
import { Close } from "@mui/icons-material";

const AddCustomNumbers = ({
  customerNumbers,
  handleLinkCustomNumbersToCoupon,
  onClose,
}) => {
  const [err, setErr] = useState("");
  const [inputValue, setInputvalue] = useState("");
  const isValidNumber = (number) => {
    // Define a regular expression to match the desired format
    const regex = /^\+\d+-\d+$/;
    return regex.test(number);
  };

  const handleAddCustomNumbersToCustomers = () => {
    if (err.length > 0) {
      return;
    }
    const newNumbers = inputValue.split(/,\s*/);
    const numericNumbers = newNumbers.filter(
      (num) => isValidNumber(num) && num !== ""
    );
    if (numericNumbers.length === newNumbers.length) {
      // All entries are valid
      setErr("");
      handleLinkCustomNumbersToCoupon(numericNumbers);
      onClose();
    } else {
      // Invalid entries found, display an error message
      setErr("Invalid input. Please enter valid comma-separated numbers.");
    }
  };

  const handleChange = (event) => {
    setErr("");
    setInputvalue(event.target.value);
  };

  const handleCloseDialog = () => {
    onClose();
  };
  useEffect(() => {
    if (customerNumbers.length > 0) {
      setInputvalue(customerNumbers.join(","));
    }
  }, []);
  return (
    <>
      <AddFlex padding="0px 8px">
        <DialogHeader
          mainText={"Add Custom numbers"}
          subText={`The coupon will be applicable to only the customer selected`}
          onClose={handleCloseDialog}
        />
      </AddFlex>
      <AddFlex grow="1" flexDirection="column" style={{ padding: "20px" }}>
        <CustomInputLabel>Enter Comma separated numbers here</CustomInputLabel>
        {err && err.length > 0 && <CustomText color="red">{err}</CustomText>}
        <CustomInputArea
          value={inputValue}
          placeholder="Enter comma(,) separated numbers here."
          onChange={handleChange}
          rows={5}
        />
        <CustomText margin="8px 0 0 0" fontSize="12px">
          Eg:- +91-9999999999
        </CustomText>
        <AddFlex
          margin="10px 0 0 0"
          wrap="wrap"
          style={{ maxHeight: "40vh", overflow: "scroll" }}
        ></AddFlex>
        <Gap />
        <PrimaryActionButton onClick={handleAddCustomNumbersToCustomers}>
          Done
        </PrimaryActionButton>
      </AddFlex>
    </>
  );
};

export default AddCustomNumbers;
