import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  handleOpenInNew,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils.js";
import styled from "styled-components";
import {
  AddFlex,
  CustomText,
  SecondaryActionButton,
  Seperator,
} from "../reusableStyles.js";
import TextAreaInput from "./TypeAction/TextAreaInput.js";
import SliderType from "./TypeAction/SliderType.js";
import CheckBoxType from "./TypeAction/CheckBoxType.js";
import RadioType from "./TypeAction/RadioType.js";
import DropdownType from "./TypeAction/DropdownType.js";
import DecisionBoxType from "./TypeAction/DecisionBoxType.js";
import GoogleMeetType from "./TypeAction/GoogleMeetType.js";
import DateType from "./TypeAction/DateType.js";
import PhoneCallType from "./TypeAction/PhoneCallType.js";
import SingleLineType from "./TypeAction/SingleLineType.js";
import { Divider } from "@mui/material";
import { format } from "date-fns";
import axios from "axios";

const Header = styled.div`
  color: #333;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const ParentComp = styled(AddFlex)`
  height: 100%;
  flex-direction: column;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  padding-bottom: 50px;
  background-color: white;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const FieldsParent = styled(AddFlex)`
  flex-direction: column;
  width: ${(props) => (props.fullScreen ? "95%" : "90%")};
  gap: 12px;
  margin-top: 20px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const SchedulingActionsParent = styled(AddFlex)`
  flex-direction: column;
  width: 90%;
  padding: 30px 20px 0 40px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 15px 20px 0 20px;
  }
`;

const MainParent = styled(AddFlex)`
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  /* background-color: #dcdcdc; */
  @media screen and (max-width: 1024px) {
    padding: 0px;
  }
`;

function FormPreviewLayout({ form, fullScreen, showSchedule, previewLevel }) {
  const getScheduleFormFields = () => {
    if (isNullOrUndefined(form)) return null;
    return Object.values(form.fields).filter(
      (item) => item.type === "googlemeet" || item.type === "phonecall"
    );
  };

  function downloadFile(url, fileName) {
    const customerName = form.customerDetails.name + "_" + fileName;
    console.log(form);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = customerName; // The desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Download failed:", error));
  }

  const getAnswer = (field) => {
    console.log(field);
    if (field.type === "checkbox") {
      return field.data.choices
        .filter((choice) => choice.value === true)
        .map((choice) => choice.label)
        .join(", ");
    } else if (field.type === "dropdown") {
      return field.data.value;
    } else if (field.type === "radio") {
      return field.data.value;
    } else if (field.type === "date") {
      if (isNullOrUndefined(field.data.value)) return;
      return format(new Date(field.data.value), "dd MMM yyyy");
    } else if (field.type === "file-upload") {
      return (
        <AddFlex gap="10px" margin="5px 0">
          <SecondaryActionButton
            onClick={() => {
              handleOpenInNew(field.data.value);
            }}
          >
            View
          </SecondaryActionButton>
          <SecondaryActionButton
            onClick={() => {
              downloadFile(
                field.data.value,
                `${field.data.label}.${field.data.file_type}`
              );
            }}
            href={field.data.value}
            download={field.data.value}
          >
            Download File
          </SecondaryActionButton>
        </AddFlex>
      );
    } else if (field.type === "decisionbox") {
      return field.data.value === true ? "Box Checked" : "Box Unchecked";
    } else {
      return field.data.value;
    }
  };
  return isNullOrUndefined(form) ? (
    <div>Not yet filled</div>
  ) : (
    <MainParent fullScreen={fullScreen}>
      <ParentComp fullScreen={fullScreen}>
        <Header
          style={{ fontSize: "18px", paddingBottom: "0px" }}
          dangerouslySetInnerHTML={{ __html: form.header.data.label }}
        ></Header>
        <FieldsParent fullScreen={fullScreen}>
          {Object.values(form.fields)
            .filter((field) => !field.data.readOnly)
            .map((field) => {
              return (
                <AddFlex
                  flexDirection="column"
                  gap="6px"
                  justify="center"
                  style={{ borderBottom: "1px solid #dfdfdf" }}
                >
                  <CustomText
                    style={{ fontSize: "16px", color: "#222" }}
                    dangerouslySetInnerHTML={{
                      __html: `Q : ${field.data.label}`,
                    }}
                  ></CustomText>
                  <div
                    style={{
                      color: "#636363",
                      marginBottom: "12px",
                      fontSize: "15px",
                    }}
                  >
                    A : {getAnswer(field)}
                  </div>
                </AddFlex>
              );
              {
                /* switch (field.type) {
              case "input":
                return (
                  <SingleLineType
                    data={field.data}
                    previewLevel={previewLevel}
                  />
                );
              case "textarea":
                return (
                  <TextAreaInput
                    data={field.data}
                    previewLevel={previewLevel}
                  />
                );
              case "slider":
                return (
                  <SliderType data={field.data} previewLevel={previewLevel} />
                );
              case "checkbox":
                return (
                  <CheckBoxType data={field.data} previewLevel={previewLevel} />
                );
              case "radio":
                return (
                  <RadioType data={field.data} previewLevel={previewLevel} />
                );
              case "dropdown":
                return (
                  <DropdownType data={field.data} previewLevel={previewLevel} />
                );
              case "date":
                return (
                  <DateType data={field.data} previewLevel={previewLevel} />
                );
              case "decisionbox":
                return (
                  <DecisionBoxType
                    data={field.data}
                    previewLevel={previewLevel}
                  />
                );
            } */
              }
            })}
        </FieldsParent>
      </ParentComp>
    </MainParent>
  );
}

export default FormPreviewLayout;

export const AnswerView = ({ value }) => {
  return <AddFlex></AddFlex>;
};
