import React, { useEffect, useState } from "react";
import {
  AddFlex,
  ContentHeader,
  CustomButton,
  CustomText,
  Group,
  SecondaryActionButton,
} from "../reusableStyles";
import { primaryActionColor } from "../constants";
import { Checkbox, CircularProgress, Dialog } from "@mui/material";
import styled from "styled-components";
import { getLiveFeatureItem, isNullOrUndefined } from "../utils";
import Close from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const StyledAddFlexCol = styled(AddFlex)`
  flex-direction: column;
  min-height: 200px;
  max-height: 450px;
  overflow-y: scroll;
  padding: 0 10px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

function FeatureLinksDialog({
  isOpen,
  links,
  handleAddLinks,
  isTestimonialSection,
  handleLinkCheck,
  handleCloseImportFeatureDialog,
  loading,
  selectedTestimonials,
  setSelectedTestimonials,
  selectedSectionId,
  previousTestimonialsInSection, //if a testimonial section has testimonials already, we will find them here
}) {
  const [clickedFeatureIds, setClickedFeatureIds] = useState([]);
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const addLinksToSection = async (list) => {
    if (!isTestimonialSection) {
      await handleAddLinks(false, null)
        .then(() => {
          setClickedFeatureIds([]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await handleAddLinks(true, list)
        .then((response) => {
          setSelectedTestimonials(null);
        })
        .then(() => {
          setClickedFeatureIds([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCheckbox = (id) => {
    if (clickedFeatureIds.includes(id)) {
      setClickedFeatureIds(
        clickedFeatureIds.filter((_id) => {
          return id !== _id;
        })
      );
      handleLinkCheck(id, false);
    } else {
      setClickedFeatureIds((prevIds) => [...prevIds, id]);
      handleLinkCheck(id, true);
    }
  };

  useEffect(() => {
    if (isTestimonialSection && isOpen) {
      //this is to show the user the pre selected testimonials in a section.
      const testimonialList = isNullOrUndefined(previousTestimonialsInSection)
        ? undefined
        : Object.values(previousTestimonialsInSection);
      if (isNullOrUndefined(testimonialList)) return;
      const mp = new Map();
      testimonialList.forEach((testimonial) => {
        if (isNullOrUndefined(mp.get(testimonial.data.category))) {
          mp.set(testimonial.data.category, [testimonial]);
        } else {
          let list = mp.get(testimonial.data.category);
          list.push(testimonial);
          mp.set(testimonial.category, list);
        }
      });
      setSelectedTestimonials(mp);
    }
  }, [isOpen]);
  return (
    <Dialog open={isOpen} onClose={handleCloseImportFeatureDialog} fullWidth>
      <AddFlex flexDirection="column" padding="20px 30px">
        <AddFlex justify="space-between">
          <ContentHeader margin={0}>Select Links</ContentHeader>
          <Close
            style={{ cursor: "pointer" }}
            onClick={handleCloseImportFeatureDialog}
          />
        </AddFlex>

        <StyledAddFlexCol margin="10px 0 0 0">
          {links.length > 0 ? (
            links.map((_link) => {
              const link = {
                ..._link,
                data: getLiveFeatureItem(
                  features,
                  _link.featureId,
                  _link.data.id
                ),
              };
              return (
                <Group padding="15px 30px" marginTop="10px">
                  <AddFlex
                    style={{ cursor: "pointer" }}
                    alignItems="center"
                    onClick={() => handleCheckbox(link.data.id)}
                  >
                    <StyledCheckbox
                      id="checkbox-feature-dialog"
                      checked={clickedFeatureIds.includes(link.data?.id)}
                    />
                    <CustomText color="black">{link.data?.name}</CustomText>
                  </AddFlex>
                </Group>
              );
            })
          ) : (
            <AddFlex justify="center" margin="auto 0">
              <CustomText>No Links to pull inside the section</CustomText>
            </AddFlex>
          )}
        </StyledAddFlexCol>

        {links && links.length > 0 && (
          <AddFlex justify="center">
            <SecondaryActionButton
              minWidth="150px"
              minHeight="40px"
              borderRadius="4px"
              border={`1px solid ${primaryActionColor}`}
              background={primaryActionColor}
              onClick={addLinksToSection}
              disabled={links.length === 0 || loading}
            >
              {loading ? <CircularProgress size={"16px"} /> : "Add Links"}
            </SecondaryActionButton>
          </AddFlex>
        )}
      </AddFlex>
    </Dialog>
  );
}

export default FeatureLinksDialog;
