import React, { useState } from "react";

function useDialogActionsHook() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  return {
    isDialogOpen,
    openDialog,
    closeDialog,
  };
}

export default useDialogActionsHook;
