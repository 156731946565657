import React, { useEffect, useState } from "react";
import {
  FetchDashboardData,
  FetchDashboardStreamData,
  FetchUpcomingEvents,
} from "../Api/ApiEndPoints";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import ScheduledCalls from "../Pages/ScheduledCalls";
import ActivityStream from "../Pages/ActivityStream";
import { callNonStoreApi } from "../NonStoreApis";
import Loading from "../Loading";
import { enqueueSnackbar } from "notistack";
import AnalyticsView from "../Pages/AnalyticsView";
import useWindowSize from "../Hooks/useWindowSize";
import { formatDate, setUTCDate } from "../utils";
import { format } from "date-fns";
import UpcomingEventCard from "./UpcomingEventCard";
import UpcomingEvents from "./UpcomingEvents";

function DashboardTab() {
  const [basicApiData, setBasicApiData] = useState(null);
  const [streamData, setStreamData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { windowSize, isMobileView } = useWindowSize();
  const [isStreamDataLoading, setIsStreamDataLoading] = useState(true);

  const handleGetOfferingDashboardData = async () => {
    try {
      const response = await callNonStoreApi(FetchDashboardData, {});
      delete response.data.calls._id;
      setBasicApiData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetStreamData = async () => {
    try {
      const response = await callNonStoreApi(FetchDashboardStreamData, {});
      setStreamData(response.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsStreamDataLoading(false);
    }
  };

  useEffect(() => {
    handleGetOfferingDashboardData();
    handleGetStreamData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      //TODO: API CALL FOR GETTING STREAM DATA
      handleGetStreamData();
    }, 300000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return isLoading ? (
    <AddFlex
      alignItems="center"
      justify="center"
      style={{ width: "100%", height: "100%" }}
    >
      <Loading />
    </AddFlex>
  ) : (
    <AddFlex
      padding={isMobileView() ? "20px" : "16px 48px"}
      height="100%"
      style={{
        position: "relative",
        // overflow: isMobileView() ? "scroll" : "hidden",
      }}
      transition="none"
      wrap="wrap"
    >
      <AddFlex
        flexDirection="column"
        gap="33px"
        padding="5px"
        width={isMobileView() ? "100%" : "60%"}
      >
        <AnalyticsView />
        <UpcomingEvents />
        {/* <ScheduledCalls calls={basicApiData?.calls} /> */}
      </AddFlex>
      <Gap />
      {!isStreamDataLoading && <ActivityStream streamData={streamData} />}
    </AddFlex>
  );
}

export default DashboardTab;
