import React, { useState } from "react";
import {
  AddFlex,
  PrimaryActionButton,
  SectionHeaderFancy,
} from "../../reusableStyles";
import TextEditor from "../../CreateOffering/TextEditor";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function Policy({
  headerTitle,
  editorInitialValue,
  headerDescription,
  handleSubmit,
}) {
  const [textEditorInput, setTextEditorInput] = useState(editorInitialValue);
  return (
    <>
      <AddFlex alignItems="center" justify="space-between">
        <SectionHeaderFancy
          Title={headerTitle}
          Description={headerDescription}
        />
        <PrimaryActionButton
          padding="10px"
          onClick={() => handleSubmit(textEditorInput)}
        >
          Save Changes
        </PrimaryActionButton>
      </AddFlex>
      <TextEditor
        value={textEditorInput}
        height="50vh"
        getTextEditorInput={(text) => {
          setTextEditorInput(text);
        }}
      />
    </>
  );
}

export default Policy;
