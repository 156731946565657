import React, { useState } from "react";
import { AddFlex, PrimaryActionButton } from "../reusableStyles";
import { Drawer, Tooltip } from "@mui/material";
import TestimonialForm from "./TestimonialForm";
import useWindowSize from "../Hooks/useWindowSize";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import { useSelector } from "react-redux";
import { getFrontendBaseURL, handleCopyToClipboard } from "../utils";
import { enqueueSnackbar } from "notistack";

function AddTestimonialButton() {
  const { userName } = useSelector((state) => state.user.apiUserData);

  const [openAddTestimonial, setOpenAddTestimonial] = useState(false);
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex
      style={
        isMobileView()
          ? { margin: "0 auto" }
          : { position: "fixed", top: "30px", right: "48px" }
      }
    >
      <Tooltip title={"Copy testimonial link"}>
        <AddFlex
          backgroundColor={primaryActionColorLight}
          width="35px"
          height="35px"
          borderRadius="50%"
          alignItems="center"
          justify="center"
          style={
            isMobileView()
              ? {
                  position: "fixed",
                  top: "20px",
                  right: "20px",
                  cursor: "pointer",
                  boxShadow: "0 0 0 1px rgba(0,0,0,0.05)",
                }
              : { cursor: "pointer", boxShadow: "0 0 0 1px rgba(0,0,0,0.05)" }
          }
        >
          <ContentCopyIcon
            onClick={async () => {
              await handleCopyToClipboard(
                `${getFrontendBaseURL(userName)}testimonial`
              ).then(() => {
                enqueueSnackbar("Link Copied!!", {
                  variant: "success",
                });
              });
            }}
            fontSize="18px"
            sx={{ color: primaryActionColor }}
          />
        </AddFlex>
      </Tooltip>
      <PrimaryActionButton
        margin="0 0 0 10px"
        onClick={() => setOpenAddTestimonial((prev) => !prev)}
      >
        Add New Testimonial
      </PrimaryActionButton>
      <Drawer
        open={openAddTestimonial}
        onClose={() => setOpenAddTestimonial((prev) => !prev)}
        anchor="right"
        PaperProps={{ sx: { width: isMobileView() ? "100vw" : "35vw" } }}
      >
        <TestimonialForm
          closeDrawer={() => setOpenAddTestimonial((prev) => !prev)}
        />
      </Drawer>
    </AddFlex>
  );
}

export default AddTestimonialButton;
