import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  FeaturesKeys,
  Status,
  generateUUID,
  getApiCallParams,
  getFrontendBaseURL,
  handleOpenInNew,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import styled, { css } from "styled-components";
import {
  AddFlex,
  CustomText,
  Gap,
  Header,
  Icon,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import { UserFormsCrudApi } from "../Api/ApiEndPoints";
import Meetlogo from "../ImageAssets/meet_logo.png";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { getAdminFrontendURL } from "../axios";
import { format } from "date-fns";
import { Edit, EditOutlined, Info, RemoveRedEye } from "@mui/icons-material";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import Tabs from "../Tabs";
import MenuButton from "../Links/MenuButton";
import { Tooltip } from "@mui/material";
import useWindowSize from "../Hooks/useWindowSize";
import { useSearchParams } from "react-router-dom";

const FormCardWrapper = styled(AddFlex)`
  flex-direction: column;
  width: 30%;
  @media screen and (max-width: 1024px) {
    width: 43%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const FormCard = styled(AddFlex)`
  padding: 12px 16px;
  flex-grow: 1;
  flex-direction: column;
  font-size: 18px;
  border-radius: 12px;
  z-index: 111;
  overflow-wrap: break-word;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0d5dd;
  gap: 12px;
  background-color: white;
  @media screen and (max-width: 1024px) {
    margin: 10px;
    font-size: 16px;
  }
`;

const FormHeader = styled(CustomText)`
  color: #000;
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width: 1024px) {
    margin-right: 5%;
    font-size: 14px;
  }
`;

const FormSubline = styled(CustomText)`
  color: #475467;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  line-height: 16px;
  @media screen and (max-width: 1024px) {
    font-size: 10px;
  }
`;

const ScheduleTitle = styled(CustomText)`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

const NewFormButton = styled(PrimaryActionButton)`
  /* margin-right: 15%; */
  @media screen and (max-width: 1024px) {
    margin-right: 5%;
    font-size: 12px;
  }
`;

export const getSchedulingFieldType = (form) => {
  if (isArrayNullOrUndefined(form.fields)) return -1;
  const obj = Object.values(form.fields).find(
    (item) => item.type === "googlemeet" || item.type === "phonecall"
  );
  if (isNullOrUndefined(obj)) return -1;
  if (obj.type === "googlemeet") return 1;
  if (obj.type === "phonecall") return 2;
};

export const getFormSubline = (form) => {
  var subline = "",
    count = 0;
  if (isArrayNullOrUndefined(form.fields)) return "No questions are added.";
  const fields = Object.values(form.fields);
  return fields.length + " questions.";

  for (let i = 0; i < fields.length; i++) {
    const item = fields[i];
    if (count === 0) {
      subline += item.data.label;
    } else if (count === 1) {
      subline += ", ";
      subline += item.data.label;
    } else if (count === 2) {
      subline += " and ";
      subline += item.data.label;
    } else if (count >= 3) {
      subline += " and ";
      const rem = fields.length - 3;
      subline += rem + " more " + (rem === 1 ? "question." : "questions.");
      break;
    }
    count++;
  }
  return subline;
};

function FormsTab() {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [_forms, setForms] = useState({
    0: [],
    1: [],
  });
  const forms = useSelector((state) => state.user.apiUserData.forms);
  const { userName } = useSelector((state) => state.user.apiUserData);
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const getSchedulingFieldType = (form) => {
    if (isArrayNullOrUndefined(form.fields)) return -1;
    const obj = Object.values(form.fields).find(
      (item) => item.type === "googlemeet" || item.type === "phonecall"
    );
    if (isNullOrUndefined(obj)) return -1;
    if (obj.type === "googlemeet") return 1;
    if (obj.type === "phonecall") return 2;
  };

  const getFormsIsActive = (formId) => {
    const activeFeatures = [];
    Object.entries(features).forEach(([featureKey, feature]) => {
      if (featureKey !== FeaturesKeys.testimonial.id) {
        feature.items?.forEach((item) => {
          if (
            item.status === Status.PUBLISHED &&
            item.ctas?.some((cta) => cta.data?.formId === formId)
          ) {
            activeFeatures.push(item.name);
          }
        });
      }
    });
    return activeFeatures;
  };

  const handleCreateForm = (isIntake) => {
    const formID = generateUUID();
    dispatch(
      usersApiCall(
        getApiCallParams(UserFormsCrudApi, {
          formId: formID,
          formUpdatetype: "create",
          date: new Date(),
          isIntake: isIntake,
        })
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === "fulfilled") {
        const url = getAdminFrontendURL() + "/newform/" + formID;
        handleOpenInNew(url);
      } else {
        console.error("Cannot create a New Form");
      }
    });
  };

  useEffect(() => {
    const _forms = {
      0: [],
      1: [],
    };
    if (!isNullOrUndefined(forms)) {
      Object.values(forms).forEach((form) => {
        if (form.isIntake) {
          _forms["1"].push(form);
        } else {
          _forms["0"].push(form);
        }
      });
    }
    setForms(_forms);
    setSelectedTabIndex(searchParams.get("isIntake") === "true" ? 1 : 0);
  }, []);

  return (
    <div style={{ padding: isMobileView() ? "20px" : "0 48px" }}>
      <AddFlex alignItems="center" justify="space-between" margin="20px 0 0 0 ">
        {/* <Header>Your Forms</Header> */}
        <MenuButton
          buttonName={"New Form"}
          menuItems={[
            {
              name: "General Form",
              action: () => {
                handleCreateForm(false);
              },
            },
            {
              name: "Intake Form",
              action: () => {
                handleCreateForm(true);
              },
            },
          ]}
          style={{
            position: "fixed",
            right: isMobileView() ? "20px" : "48px",
            top: isMobileView() ? "15px" : "25px",
          }}
        />
      </AddFlex>
      <Tabs
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        tabs={[{ name: "General Forms" }, { name: "Intake Forms" }]}
      />
      <AddFlex wrap="wrap" justify="flex-start" gap="20px" margin="20px 0 0 0 ">
        {forms &&
          _forms[selectedTabIndex].map((form) => (
            <FormCardWrapper>
              <FormCard>
                <div>
                  <FormHeader
                    lineClamp="1"
                    fontWeight="600"
                    dangerouslySetInnerHTML={{
                      __html: form.header.data.label,
                    }}
                  ></FormHeader>
                  <div
                    style={{
                      width: "36px",
                      height: "2px",
                      backgroundColor: "rgba(219, 127, 16, 1)",
                      margin: "6px 0 0 0",
                    }}
                  ></div>
                </div>
                <AddFlex flexDirection="column">
                  <CustomText fontSize="14px" color="black" fontWeight="500">
                    Summary
                  </CustomText>
                  <FormSubline lineClamp="4">
                    -{" Created on "}
                    {format(new Date(form.createdDate), "dd MMM yyyy")}{" "}
                  </FormSubline>
                  <FormSubline lineClamp="4">
                    -
                    {isNullOrUndefined(form.fields)
                      ? " No Fields"
                      : Object.values(form.fields).length === 1
                      ? " Has " + Object.values(form.fields).length + " field"
                      : " Has " +
                        Object.values(form.fields).length +
                        " fields"}{" "}
                  </FormSubline>
                </AddFlex>
                <Gap />
                {!isArrayNullOrUndefined(getFormsIsActive(form.id)) && (
                  <AddFlex alignItems="center">
                    <CustomText fontSize="14px" color="black" fontWeight="500">
                      Usage({" "}
                      {`in ${getFormsIsActive(form.id).length} published pages`}
                      )
                    </CustomText>
                    <Gap />
                    <Tooltip
                      title={
                        <AddFlex>
                          {getFormsIsActive(form.id).map(
                            (activePage) => `- ${activePage}`
                          )}
                        </AddFlex>
                      }
                    >
                      <Info sx={{ color: "#808080", fontSize: "18px" }} />
                    </Tooltip>
                  </AddFlex>
                )}
                <AddFlex
                  alignItems="center"
                  gap="10px"
                  style={{ paddingTop: "10px", borderTop: "1px solid #d0d5dd" }}
                >
                  {getSchedulingFieldType(form) !== -1 && (
                    <>
                      <AddFlex>
                        {getSchedulingFieldType(form) === 1 && (
                          <Icon src={Meetlogo} />
                        )}
                        {getSchedulingFieldType(form) === 2 && (
                          <CallOutlinedIcon sx={{ color: "#0066DA" }} />
                        )}
                      </AddFlex>
                    </>
                  )}
                  <Gap />

                  <div
                    style={{
                      color: "#808080",
                      padding: "0px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleOpenInNew(
                        `${getFrontendBaseURL(userName)}form/${form.urlKey}`
                      );
                      // openAdminFrontendUrl(`/form/preview/${formId}/preview`);
                    }}
                  >
                    <RemoveRedEye sx={{ fontSize: "18px" }} />
                  </div>
                  <div
                    style={{
                      color: "#808080",
                      padding: "0px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleOpenInNew(
                        getAdminFrontendURL() + "/newform/" + form.id
                      );
                    }}
                  >
                    <EditOutlined sx={{ fontSize: "18px" }} />
                  </div>
                  <SecondaryActionButton
                    onClick={() => {
                      navigate(`/forms/responses/${form.id}`);
                    }}
                    style={{ padding: "5px" }}
                  >
                    Responses
                  </SecondaryActionButton>
                </AddFlex>
              </FormCard>
              {/* <AddFlex
                justify="flex-end"
                style={{
                  padding: "12px",
                  backgroundColor: primaryActionColorLight,
                  marginTop: "-5px",
                  borderRadius: "0 0 12px 12px",
                  fontSize: "12px",
                  color: primaryActionColor,
                  fontWeight: "500",
                  // margin: "0 0 0 auto",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/forms/responses/${form.id}`);
                }}
              >
                View Responses
              </AddFlex> */}
            </FormCardWrapper>
          ))}
      </AddFlex>
    </div>
  );
}

export default FormsTab;
