export const UserOnboardingApi = "/user/onboarding";
export const UpdateUser = "/user/update";
export const FetchUserApi = "/links";
export const UpdateUserAdditionalDetails = "/links/updateUserAddtionalDetails";
export const UpdateUserApi = "/links/updateUser";
export const UpdateWebsiteBannerImage = "/links/userWebPageCustomisations";
export const UserFormsCrudApi = "/links/userFormsCrud";
export const UserAvailabilityApi = "/links/updateAvailability";
export const FetchUserFeaturesApi = "/user_features/fetch_user_features_data";
export const UpdateFeature = "user_features/update_feature_data";
export const UpdateUrlKeyForOffering = "user_features/updateUrlKeyForOffering";
export const CheckIsMeetingCreatedForSlot =
  "user_features/zoomMeetings/:slotId";
export const DeleteZoomMeeting = "user_features/zoomMeetings/delete/:meetingId";
export const UpdateTestimonialTags = "user_features/updateTestimonialTags";
export const AddImagesToGallery = "user_features/addImagesToGallery";
export const FetchLeadsForPage = "/leaddata/fetchLeadsForPage";
export const ActionOnLead = "/leaddata/actionOnLead";
export const FetchIntegrationsApi = "/integrations/fetchIntegrations";
export const updateSocialLinks = "/socialLinks/";
export const GoogleCalendarIntegrationApi =
  "/integrations/start-google-calendar-integration";
export const RazorpayIntegrationApi =
  "/integrations/start-razorpay-integration";
export const UpdateContactDetails = "/user/update_contact_details";
export const UpdateUserKYCDetails = "/user/update_business_details/";
export const AllCustomersApi = "/user_features/fetchAllCustomers";
export const FeatureItemSales = "/user_features/sales/:itemId";
export const SendRenewalLinks = "/user_features/sendRenewalLinks";
export const FetchPayoutFeed = "client_payments/getPayoutFeed";
export const FetchTransactionsList = "client_payments/getTransactionsList";
export const FetchSaleDataForBill = "client_payments/getSaleObjectsForBill";
export const FetchUserCalendarBookings = "/leaddata/fetchUserCalendarBookings";
export const SetAdminRequestedUserDetails = "/links/setAdminRequestedUserId";
export const FetchAllCustomers = "user_features/fetchAllCustomers";
export const LogoutUser = "/login/logoutUser";
export const ValidateLoginToken = "/login/validateLoginToken";
export const AddLink = "/links";
export const DeleteLink = "/links/deleteLink";
export const LinkVisibility = "/links/linkVisibility";
export const UpdateLinks = "/links/updateLinks";
export const ImportLinksToSection = "/links/importLinksToSection";
export const ReorderLinks = "/links/reorderLinks";
export const CheckAuthStatus = "/login/checkAuth";
export const AdminOnboardingRedirectURL =
  "/links/adminUserOrboardingRedirectURL";
export const FetchUserLoginMetaData = "/login/getUserLoginMetaData";
export const FetchPageAnalytics = "/pageAnalytics/fetchPageAnalytics";
export const FetchTotalAnalytics = "/pageAnalytics/fetchTotalAnalytics";
export const FetchDashboardData = "/dashboard_data";
export const FetchDashboardStreamData = "/dashboard_data/stream";
export const FetchUpcomingEvents = "/dashboard_data/upcomingEvents";
export const FetchAllSubscriptionPlans = "/subscription_plans/fetchAllPlans";
export const FetchUserSubscriptionPlan = "/usersubscription/fetchUserPlan";
export const FetchAllLeads = "/leaddata/fetchAllLeads";
export const FetchAttendanceForFeatureItem = "/attendance/fetchAttendance";
export const MarkAttendanceForFeatureItem = "/attendance/markAttendace";
export const MarkCallAttendedUnattended =
  "/leaddata/markMeetingAsAttendedOrUnAttended";
export const FetchTotalRevenue = "reports/fetchTotalRevenue";
export const FeatureSummaryInsights = "reports/fetchSummaryReport";
export const FeatureAllSaleReport = "reports/fetchAllSalesReportApi";
export const RescheduleCall = "/leaddata/rescheduleCall";
export const InitiateSubscriptionPaymentApi =
  "/usersubscription/initiateNewSubscription";
export const InitiateUpdateSubscriptionPaymentApi =
  "/usersubscription/initiateUpdateSubscription";
export const FetchSaleStats = "/user_features/fetchSaleStats";
export const UpdateStartDateEndDate =
  "/user_features/updateStartingEndingDateInSubscription";
export const CheckOverlappingTimeSlots =
  "/user_features/checkTimeslotsOveralps";
export const UpdateTimeSlotsInZoom = "/user_features/updateTimeSlotsInZoom";
export const FetchSubdomainClient = "/subdomain_clients/fetchSubdomainClient";
export const FetchSubscriptionsForOffering =
  "/user_features/fetchSubscriptionsForOffering";
export const AddTestimonialToWallOfLove =
  "/testimonials/addOrRemoveInWallOfLove";
export const RequestTestimonials =
  "/testimonials/requestTestimonialsForOffering";
export const MarkAttandanceApiEndpoint = "/user_features/mark_attandance";
export const AddEditCouponCode = "marketing/createCoupon";
export const FetchAllCoupons = "marketing/fetchCouponsOfClient";
export const DeleteCoupon = "marketing/deleteCoupon";
export const FetchAllEventsCustomers = "user_features/fetchAllEventsCustomers";
export const FetchFormResponse = "form_responses/fetchResonses";
export const AddMarkAsLead = "form_responses/addMarkedAsLead";
export const FetchUserCalendars = "userCalendars/getUserCalendars";
export const CreateNewUserCalendars = "userCalendars/createNewCalendar";
export const UpldateUserCalendars = "userCalendars/updateCalendar";
export const DeleteUserCalendars = "userCalendars/deleteCalendar";
export const UpdateWallofLove = "/links/updateWallofLove";
export const FetchMembersForBroadcast = "/broadcast/fetchMembers";
export const BroadcastToMembers = "/broadcast/";

export const FetchAllHosts = "/staff/getAllHosts";
export const AddUpdateHost = "/staff/addUpdateHost";
export const RemoveHost = "/staff/:hostId";
export const CreateClientInvoice = "/client_invoices/createClientInvoice";
export const DeleteClientInvoice = "/client_invoices/deleteClientInvoice";
export const FetchClientInvoices = "/client_invoices/fetchClientInvoices";
