import React from "react";
import {
  AddFlex,
  CustomInputArea,
  CustomText,
  CustomTextArea,
} from "../reusableStyles";

function AboutSection({ about, handleAbout }) {
  return (
    <AddFlex flexDirection="column" margin="32px 0 0 0">
      <CustomTextArea
        label={"About"}
        style={{ marginTop: "0px", minHeight: "100px" }}
        value={about}
        placeholder={"This is where you can write about your business."}
        onChange={(text) => {
          handleAbout(text);
        }}
      />
    </AddFlex>
  );
}

export default AboutSection;
