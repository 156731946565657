import React, { useState } from "react";
import EmptyCaseUIComponent from "../Pages/Components/EmptyCaseUIComponent";
import { Close, Warning } from "@mui/icons-material";
import { primaryActionColor } from "../constants";
import { Currency, formatPrice, isArrayNullOrUndefined } from "../utils";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import styled from "styled-components";
import AllCustomersHeaderStrip from "./AllCustomersHeaderStrip";
import CustomerListRow from "../Pages/Components/CustomerListRow";
import { Divider } from "@mui/material";
import AllCustomersTableHeader from "./AllCustomersTableHeader";
import TransactionHistoryCard from "./TransactionHistoryCard";
import { AddFlex, ContentHeader } from "../reusableStyles";
import { CustomDialog } from "../CreateOffering/JoiningQuestions";
import { Parser } from "@json2csv/plainjs";
import { format } from "date-fns";

const getHeaderList = () => {
  return [
    ["5%", "Sl. No."],
    ["5%", "User"],
    ["5%", "Net Revenue"],
    ["10%", "First Order"],
    ["10%", "Order History"],
  ];
};

const CustomTable = styled.div`
  border-radius: 4px;
  border: 1px solid #ececec;
  background-color: white;
`;

function AllCustomerTable({ _customers }) {
  const [customersConst] = useState(_customers);
  const [customers, setCustomers] = useState(_customers);
  const [paymentHistoryDialog, setPaymentHistoryDialog] = useState(false);
  const [customerTransactionData, setCustomerTransactionData] = useState(null);

  const searchAction = (searchInput) => {
    if (searchInput.length === 0) {
      setCustomers(customersConst);
      return;
    }
    const searchTerm = searchInput.toLowerCase();
    const filteredResults = customersConst.filter((item) => {
      return (
        item.customer.customerData.name.toLowerCase().includes(searchTerm) ||
        item.customer.customerData.customerNumber.includes(searchTerm)
      );
    });
    if (filteredResults.length === 0) {
      return;
    }
    setCustomers(filteredResults);
  };

  const TogglePaymentHistoryDialog = (customer) => {
    setCustomerTransactionData(customer.paymentHistory);
    setPaymentHistoryDialog((prev) => !prev);
  };

  const formatSummarySales = (customers) => {
    return customers.map((customer) => {
      return {
        "Customer Name": customer.customer.customerData.name,
        "Customer Number": customer.customer.customerData.customerNumber,
        "Total Revenue Generated": formatPrice(
          customer.totalCartValue,
          Currency.INR
        ),
        "First Booking Date": format(
          new Date(customer.firstBookingDate),
          "dd MMM yyyy"
        ),
      };
    });
  };

  const handleDownloadAllCustomersReport = async () => {
    try {
      const parser = new Parser();
      const csv = parser.parse(formatSummarySales(customers));
      const element = document.createElement("a");
      element.setAttribute("href", `data:text/csv;charset=utf-8,${csv}`);
      element.setAttribute("download", "All Customers");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  return (
    customers && (
      <>
        <CustomTable style={{ marginTop: "20px", height: "100%" }}>
          <div
            style={{
              padding: "12px 32px",
              borderBottom: "none",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <AllCustomersTableHeader
              searchAction={searchAction}
              handleDownloadAllCustomersReport={
                handleDownloadAllCustomersReport
              }
              totalClients={customers.length}
            />
          </div>
          <Divider />
          <AddMargin
            margin="0px 0 0 0px"
            style={{
              backgroundColor: "white",
              maxWidth: "100vw",
              // overflow: "scroll",
            }}
          >
            <AllCustomersHeaderStrip list={getHeaderList()} />
            <div style={{ overflowY: "scroll", height: "70vh" }}>
              {!isArrayNullOrUndefined(customers) ? (
                <>
                  {customers.map((sale, index) => (
                    <CustomerListRow
                      key={sale.customer.customerId}
                      headerList={getHeaderList()}
                      customer={sale}
                      index={index}
                      clickActionOnRow={TogglePaymentHistoryDialog}
                    />
                  ))}
                </>
              ) : (
                <>
                  <EmptyCaseUIComponent
                    icon={
                      <Warning
                        sx={{ color: primaryActionColor, fontSize: "16px" }}
                      />
                    }
                    emptyTitle={"No customers"}
                    emptyDescription={"Hang in there. "}
                  />
                </>
              )}
              {/* <CustomTableFooter /> */}
            </div>
          </AddMargin>
        </CustomTable>
        <CustomDialog
          open={paymentHistoryDialog}
          onClose={TogglePaymentHistoryDialog}
        >
          <AddFlex justify="center" margin="20px 0">
            <ContentHeader fontWeight="500" fontSize="18px">
              Customer Transaction History
            </ContentHeader>
            <Close
              style={{
                width: "20%",
                alignItems: "center",
                cursor: "pointer",
                position: "absolute",
                right: "0",
              }}
              onClick={TogglePaymentHistoryDialog}
            />
          </AddFlex>
          <Divider />
          <AddMargin margin="30px 15px" maxHeight="20vh">
            <AddFlex flexDirection="column">
              {customerTransactionData?.map((customerTransaction) => (
                <TransactionHistoryCard transaction={customerTransaction} />
              ))}
            </AddFlex>
          </AddMargin>
        </CustomDialog>
      </>
    )
  );
}

export default AllCustomerTable;
