import React, { useState } from "react";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  Gap,
  SecondaryActionButton,
} from "../reusableStyles";
import { isNullOrUndefined } from "../utils";
import { Dialog } from "@mui/material";
import { CityStats } from "./TopCitiesStats";

function TopCountriesStats({ totalViews, countryStats }) {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <AddFlex
        backgroundColor="white"
        flexDirection="column"
        padding="18px"
        width="50%"
        style={{
          borderRadius: "10px",
          gap: "15px",
          marginTop: "20px",
          boxShadow: "0px 0px 10px 1px #E7E9ED",
        }}
      >
        <AddFlex justify="space-between">
          <CustomText fontSize="16px" color="#000" fontWeight="500">
            Top Countries
          </CustomText>
          <Gap />
          {countryStats?.length > 4 && (
            <SecondaryActionButton
              style={{ padding: 0, border: "none" }}
              onClick={() => {
                setOpenDialog((prev) => !prev);
              }}
            >
              View more
            </SecondaryActionButton>
          )}
        </AddFlex>
        {isNullOrUndefined(countryStats) ? (
          <div style={{ alignSelf: "center" }}>No Data </div>
        ) : (
          <CityStats stats={countryStats.slice(0, 4)} totalViews={totalViews} />
        )}
      </AddFlex>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog((prev) => !prev);
        }}
        fullWidth
        PaperProps={{
          sx: {
            padding: "20px",
            borderRadius: "20px",
            height: "60vh",
          },
        }}
      >
        <DialogHeader
          mainText={"All Countries"}
          onClose={() => {
            setOpenDialog((prev) => !prev);
          }}
        />
        <AddFlex flexDirection="column" gap="10px" margin="20px 0 0 0">
          <CityStats stats={countryStats} totalViews={totalViews} />
        </AddFlex>
      </Dialog>
    </>
  );
}

export default TopCountriesStats;
