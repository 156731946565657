import React from "react";
import { CustomText } from "../reusableStyles";
import { headerTextColor } from "../constants";

function OfferingPageSectionHeader({ headerText }) {
  return (
    <CustomText color={headerTextColor} fontSize="18px" fontWeight="500">
      {headerText}
    </CustomText>
  );
}

export default OfferingPageSectionHeader;
