import React, { useRef, useState } from "react";
import { AddFlex } from "../reusableStyles";
import { PlayCircle } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import ReactPlayer from "react-player";
const VideoTestimonial = ({ videoUrl }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <AddFlex
        backgroundColor="black"
        width="100%"
        borderRadius="12px"
        marginBottom="10px"
        alignItems="center"
        justify="center"
        style={{ aspectRatio: 1.75 }}
        onClick={(e) => {
          e.stopPropagation();
          setIsDialogOpen(true);
        }}
      >
        <PlayCircle sx={{ color: "white", fontSize: "38px" }} />
      </AddFlex>
      <Dialog
        onClose={() => {
          setIsDialogOpen(false);
        }}
        open={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        fullWidth
      >
        <ReactPlayer
          url={videoUrl}
          className="react-player"
          playing
          controls
          muted={true}
          width="100%"
          height="100%"
        />
      </Dialog>
    </>
  );
};

export default VideoTestimonial;
