import ReactQuill from "react-quill";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import { AddFlex, CustomText, InputLabel } from "../reusableStyles";
// import "../Styles/RichTextEditor.css";
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const StyledReactQuill = styled(ReactQuill)`
  margin-top: 8px;
  .ql-editor {
    color: #101828;
    background-color: transparent;
    min-height: ${(props) => (props.height ? props.height : "150px")};
    max-height: ${(props) => (props.height ? props.height : "150px")};
  }
`;

function TextEditor(props) {
  const handleChange = (content, delta, source, editor) => {
    if (source === "user") {
      props.getTextEditorInput(content);
    }
  };
  return (
    <div style={{ margin: "0px 0 15px 0", width: "100%" }}>
      {props.label && (
        <InputLabel style={{ fontFamily: props.font }}>
          {props.label}
        </InputLabel>
      )}
      {props.errorText && (
        <CustomText
          margin="3px 0 0 0"
          fontSize="12px"
          fontWeight="500"
          color="red"
          style={{ fontFamily: props.font }}
        >
          {props.errorText}
        </CustomText>
      )}
      <StyledReactQuill
        height={props.height}
        theme="snow"
        readOnly={props.disabled}
        modules={modules}
        formats={formats}
        placeholder={props.placeholder}
        value={props.value}
        onChange={handleChange}
      />
    </div>
  );
}

export default TextEditor;
