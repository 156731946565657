import React from "react";
import { AddFlex, CustomInput } from "../reusableStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress } from "@mui/material";
import { primaryActionColor } from "../constants";
import { Cancel } from "@mui/icons-material";
import Services from "./Services";
import Usage from "./Usage";
import Categories from "./Categories";
import { isNullOrUndefined } from "../utils";
function OnboardingBody({
  name,
  username,
  userAdditionalDetails,
  stage,
  setNewUser,
  errMsg,
  isCheckingUsernameAvailability,
  handleSetNewUserAdditionalDetails,
  isAvailable,
}) {
  const isValidName = () => {
    if (isNullOrUndefined(name) || name.trim().length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const getBodyComponent = (stage) => {
    switch (stage) {
      case 0:
        return (
          <>
            <AddFlex flexDirection="column" style={{ width: "100%" }}>
              <CustomInput
                label="Display Name"
                value={name}
                width={"100%"}
                placeholder={"Enter your name."}
                widget={
                  isValidName() && (
                    <CheckCircleIcon
                      sx={{ color: "green", fontSize: "18px" }}
                    />
                  )
                }
                onChange={(text) => {
                  setNewUser((prev) => {
                    return { ...prev, Name: text };
                  });
                }}
              />
              <AddFlex margin="20px 0 0 0"></AddFlex>
              <CustomInput
                value={username}
                label="Username"
                width={"100%"}
                placeholder={"Choose your username"}
                onChange={(text) => {
                  setNewUser((prev) => {
                    return { ...prev, userName: text.toLowerCase().trim() };
                  });
                }}
                footerText={errMsg}
                footerTextColor={"red"}
                widget={
                  username === "" ? null : isCheckingUsernameAvailability ? (
                    <CircularProgress
                      size={14}
                      sx={{ color: primaryActionColor }}
                    />
                  ) : isAvailable ? (
                    <CheckCircleIcon
                      sx={{ color: "green", fontSize: "18px" }}
                    />
                  ) : (
                    <Cancel sx={{ color: "red", fontSize: "18px" }} />
                  )
                }
              />
            </AddFlex>
          </>
        );
      case 1:
        //category
        return (
          <Categories
            category={userAdditionalDetails?.category}
            handleSetNewUserAdditionalDetails={
              handleSetNewUserAdditionalDetails
            }
          />
        );
      case 2:
        // services
        return (
          <Services
            services={userAdditionalDetails?.services}
            handleSetNewUserAdditionalDetails={
              handleSetNewUserAdditionalDetails
            }
          />
        );
      case 3:
        // usage
        return (
          <Usage
            usage={userAdditionalDetails?.usage}
            handleSetNewUserAdditionalDetails={
              handleSetNewUserAdditionalDetails
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <AddFlex
      flexDirection="column"
      style={{ width: "100%" }}
      padding="0 0 80px 0"
    >
      {getBodyComponent(stage)}
    </AddFlex>
  );
}

export default OnboardingBody;
