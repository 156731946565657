import React, { useEffect, useState } from "react";
import ImageHolderComponent from "./ImageHolder";
import { AddFlex } from "../reusableStyles";
import { BoxedTabs } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import { FILE_TYPES, isArrayNullOrUndefined } from "../utils";
import SectionHeader from "./Components/SectionHeader";

function CoverImageOrVideoUpload({ offering, handleSetOffering, errors }) {
  const [coverImageFiles, setCoverImageFiles] = useState(offering.coverImage);
  const [selectedTabIndex, setSelectedTabIndex] = useState();
  const [showOnly, setShowOnly] = useState();
  const handleGetFiles = (isMultiple, acceptedFiles) => {
    if (isArrayNullOrUndefined(acceptedFiles)) return;
    setCoverImageFiles(acceptedFiles);
    handleSetOffering("coverImage", acceptedFiles);
  };
  const handleRemoveFiles = (isMultiple, fileIndex) => {
    const new_files = coverImageFiles.filter((file, index) => {
      return index !== fileIndex;
    });
    setCoverImageFiles(new_files);
    handleSetOffering("coverImage", new_files);
  };

  useEffect(() => {
    setSelectedTabIndex(offering.coverImage[0]?.isVideo ? 1 : 0);
    setShowOnly(
      offering.coverImage[0]?.isVideo ? FILE_TYPES.VIDEOS : FILE_TYPES.IMAGES
    );
  }, []);

  useEffect(() => {
    setShowOnly(selectedTabIndex === 0 ? FILE_TYPES.IMAGES : FILE_TYPES.VIDEOS);
  }, [selectedTabIndex]);
  return (
    <AddFlex gap="20px" flexDirection="column">
      <SectionHeader
        headerText={"Add Cover Image"}
        subHeaderText={"This image will be show in the main link."}
        // margin="0 0 20px 0"
        errorText={errors["coverImage"]?.err}
      />
      {/* <BoxedTabs
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        tabs={["Upload Image", "Upload Video"]}
      /> */}
      <ImageHolderComponent
        hideHeader={true}
        isMultiple={false}
        showOnly={showOnly}
        handleGetFiles={handleGetFiles}
        files={coverImageFiles}
        handleRemoveFile={handleRemoveFiles}
      />
    </AddFlex>
  );
}

export default CoverImageOrVideoUpload;
