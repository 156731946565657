import React from "react";
import { AddFlex, CustomText, SecondaryActionButton } from "../reusableStyles";
import { useSelector } from "react-redux";
import { FeaturesKeys, isArrayNullOrUndefined } from "../utils";
import { Checkbox } from "@mui/material";

function TagsSelect({ selectedTags, setOpenAddTagDialog, onChange }) {
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const getTags = () => {
    if (isArrayNullOrUndefined(features[FeaturesKeys.testimonial.id]?.tags))
      return [];
    return features[FeaturesKeys.testimonial.id].tags;
  };
  const handleChange = (tag) => {
    if (selectedTags?.some((_tag) => _tag.id === tag.id)) {
      const tags = selectedTags.filter((_tag) => _tag.id !== tag.id);
      onChange(tags);
      return;
    }
    const tags = isArrayNullOrUndefined(selectedTags)
      ? [tag]
      : [...selectedTags, tag];
    onChange(tags);
  };
  return (
    <AddFlex
      flexDirection="column"
      padding="12px"
      borderRadius="8px"
      border="1px solid #D0D5DD"
      margin="0 0 20px 0"
    >
      {getTags().map((tag) => (
        <AddFlex alignItems="center" marginBottom="10px">
          <Checkbox
            checked={selectedTags?.some((_tag) => _tag.id === tag.id)}
            sx={{ padding: "0px" }}
            onChange={() => handleChange(tag)}
          />
          <CustomText margin="0 10px" color="black">
            {tag.name}
          </CustomText>
        </AddFlex>
      ))}
      <div style={{ flexGrow: 1, margin: "10px 0" }}></div>
      <SecondaryActionButton
        onClick={() => setOpenAddTagDialog((prev) => !prev)}
        style={{ width: "100%" }}
      >
        Add New Tag
      </SecondaryActionButton>
    </AddFlex>
  );
}

export default TagsSelect;
