import React from "react";
import { primaryActionColor, primaryTextColor } from "../../constants";
import { CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { AddFlex, CustomText } from "../../reusableStyles";
import { isNullOrUndefined } from "../../utils";
function InactiveChip({
  chipName,
  disableAction,
  showIcon,
  icon,
  action,
  sessionAttendance,
  handleMarkAttandance,
  isLoading,
  width,
}) {
  return (
    <AddFlex
      backgroundColor={"transparent"}
      alignItems="center"
      border={`1px solid ${primaryTextColor}`}
      padding="5px 10px"
      borderRadius="16px"
      onClick={() => {
        if (disableAction) return;
        if (isNullOrUndefined(action)) {
          handleMarkAttandance(!sessionAttendance);
        } else {
          action();
        }
      }}
      width={width}
    >
      <CustomText
        style={{
          borderRight: showIcon && "1px solid #ababab",
          paddingRight: "4px",
        }}
        fontSize="12px"
        fontWeight="500"
        color={"#475466"}
        margin={showIcon && "0 5px 0 0"}
      >
        {chipName ? chipName : "Check-in"}
      </CustomText>

      {showIcon &&
        (isLoading ? (
          <>
            <CircularProgress
              size={14}
              color="info"
              style={{ color: primaryActionColor }}
            />
          </>
        ) : icon ? (
          icon
        ) : (
          <CheckCircleOutlineIcon
            style={{ paddingLeft: "0px", fontSize: "14px" }}
            color={"#475466"}
          />
        ))}
    </AddFlex>
  );
}

export default InactiveChip;
