import React, { useEffect, useState } from "react";
import { HorizontalChips, TimeSlotChip } from "./OfferingBookigsTableHeader";
import PopoverComponent from "../OfferingsPages/OfferingCard/PopoverComponent";
import DatePicker from "react-multi-date-picker";
import { DateOptionsChip } from "./TrackAttendance";
import { AddFlex } from "../../reusableStyles";
import { CalendarMonth } from "@mui/icons-material";
import { format } from "date-fns";
import { Divider } from "@mui/material";

function OneOnOneDateFilters({
  selectedDateAndTimeSlot,
  handleSetSelectedDateAndTimeSlots,
}) {
  const [date, setSelectedDate] = useState(new Date());
  const [datesArr, setDatesArr] = useState([]);
  const [selctedDateIndex, setSelectedDateIndex] = useState();

  function getDateRange(date) {
    const dates = [];
    // Iterate from -3 to +3 to get the required dates
    for (let i = -3; i <= 3; i++) {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + i);
      dates.push(newDate);
    }
    handleSetSelectedDateAndTimeSlots("dates", {
      date: date,
    });
    setDatesArr(dates);
  }
  useEffect(() => {
    getDateRange(new Date(date));
    setSelectedDateIndex(3);
  }, [date]);

  return (
    <AddFlex gap="12px">
      <AddFlex gap="12px" padding="0px 0 0 32px">
        <DatePicker
          onChange={(date) => {
            setSelectedDate(date);
          }}
          highlightToday={false}
          render={(value, openCalendar) => (
            <DateOptionsChip
              onClick={openCalendar}
              isSelected={false}
              date={
                <AddFlex gap="12px" alignItems="center">
                  <>{format(new Date(date), "dd MMM yyyy")}</>
                  <CalendarMonth sx={{ fontSize: "14px" }} />
                </AddFlex>
              }
            />
          )}
        />
      </AddFlex>
      <Divider orientation="vertical" sx={{ marginBottom: "0px" }} />
      <HorizontalChips
        selectedIndex={selctedDateIndex}
        list={datesArr.map((date) => format(new Date(date), "dd MMM"))}
        onItemClicked={(index) => {
          setSelectedDateIndex(index);
          handleSetSelectedDateAndTimeSlots("dates", {
            date: datesArr[index],
          });
          // setSelectedDate(datesArr[index]);
        }}
      />
    </AddFlex>
  );
}

export default OneOnOneDateFilters;
