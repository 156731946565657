import React, { useEffect, useState } from "react";
import {
  AddFlex,
  ButtonWithLoader,
  FullPageParent,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import CurrentSubscriptionPlanCard from "./CurrentPlanCard";
import { DataBox } from "../../Pages/RevenueChips";
import {
  BillingCycle,
  Currency,
  capitalizeFirstLetter,
  formatPrice,
  handleGetDateAfterXDays,
  isNullOrUndefined,
} from "../../utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchUserSubscriptionPlan } from "../../Api/ApiEndPoints";
import { useNavigate } from "react-router";
import { SUBSCRIPTION_PLANS } from "../../OnboardingPage/routeNames";
import Loading from "../../Loading";

const monthly_starter = {
  sid: "aE2Q45q",
  planDetails: { name: "Starter", price: 49900 },
  type: 1,
  isTrial: false,
  startDate: new Date(),
  billingType: 0 /** 0 :- Monthly, 1:- Anual */,
  nextRenuewalInDays: 28,
};

const monthly_pro = {
  sid: "aE2Q45q",
  planDetails: { name: "Professional", price: 60000 },
  type: 2,
  isTrial: false,
  startDate: new Date("03-30-2024"),
  billingType: 0 /** 0 :- Monthly, 1:- Anual */,
  nextRenuewalInDays: 28,
};
const anual_pro = {
  sid: "aE2Q45q",
  planDetails: { name: "Professional", price: 500000 },
  type: 2,
  isTrial: false,
  startDate: new Date(),
  billingType: 1 /** 0 :- Monthly, 1:- Annualy */,
  nextRenuewalInDays: 365,
};

const history = [monthly_starter, monthly_pro];

const SubscriptionDummyDataProLite = {
  userId: "userId",
  planId: "uarFt3f",
  startDate: new Date(),
  isSuspended: false,
  coupons: [],
  quotas: [
    {
      name: "bookings",
      limit: 20,
      used: 15,
    },
    {
      name: "testimonials",
      limit: 30,
      used: 15,
    },
    {
      name: "leads",
      limit: 50,
      used: 15,
    },
  ],
  createdAt: new Date(),
  planName: "Pro Lite",
};

const SubscriptionDummyDataProfessional = {
  userId: "userId",
  planId: "4PKhJ3d",
  startDate: new Date(),
  billingCycle: BillingCycle.Monthly,
  nextBillingDate: new Date("06-02-2024"),
  isSuspended: false,
  suspendedDate: null,
  coupons: [],
  paymentHistory: [
    {
      createdDate: new Date(),
      startDate: new Date(),
      amount: 60000,
      paymentDetails: {
        subTotal: 500000,
        carryOverDiscount: 60000,
        discount: 19900,
        gst: 5400,
        total: 35200,
      },
      paymentBreakdown: {
        subTotal: 500000,
        carryOverDiscount: 60000,
        discount: 19900,
        gst: 5400,
        total: 35200,
        currency: Currency.INR,
      },
      billingCycle: BillingCycle.Monthly,
      nextBillingDate: new Date("06-02-2024"),
      planDetails: {
        planId: "4PKhJ3d",
        name: "Professional",
      },
      transactionStatus: "Success",
      transactionId: "abcdef%5",
    },
  ],
  createdAt: new Date(),
  updatedAt: new Date(),
  planName: "Professional",
};

function SubscriptionPage() {
  const [userSubscriptionData, setUserSubscriptionData] = useState();
  const [loading, setLoading] = useState(true);
  const getPlanDuration = (plan) => {
    const endDate = handleGetDateAfterXDays(
      new Date(plan.startDate),
      plan.nextRenuewalInDays
    );
    return `${format(new Date(plan.startDate), "dd MMM yyyy")} - ${format(
      new Date(endDate),
      "dd MMM yyyy"
    )}`;
  };

  const handleGetUserSubscription = async () => {
    setLoading(true);
    const response = await callNonStoreApi(FetchUserSubscriptionPlan);
    setUserSubscriptionData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    handleGetUserSubscription();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return loading ? (
    <FullPageParent>
      <Loading />
    </FullPageParent>
  ) : (
    <AddFlex
      flexDirection="column"
      gap="20px"
      width="50%"
      margin="20px 0 0 20px"
    >
      {userSubscriptionData.planId === "Pro-Lite-INR-Monthly" && (
        <AddFlex style={{ width: "100%" }} gap="10px" justify="space-between">
          {userSubscriptionData.quotas.map((quota) => (
            <DataBox
              boxTitle={capitalizeFirstLetter(quota.name)}
              text={`${quota.used}/${quota.total}`}
            />
          ))}
        </AddFlex>
      )}
      <CurrentSubscriptionPlanCard
        currentSubscription={userSubscriptionData.activeSubscription}
        quotas={userSubscriptionData.quotas}
        planName={userSubscriptionData.planName}
        billingCycle={userSubscriptionData.billingCycle}
      />
    </AddFlex>
  );
}

export default SubscriptionPage;
