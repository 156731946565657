import React, { useEffect, useRef, useState } from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { Refresh, Search, SearchOutlined } from "@mui/icons-material";
import { inputHeight, primaryActionColor } from "../../constants";
import { Status, isArrayNullOrUndefined, isNullOrUndefined } from "../../utils";
import useWindowSize from "../../Hooks/useWindowSize";
import Close from "@mui/icons-material/Close";
import { Checkbox, CircularProgress, Tooltip } from "@mui/material";
import { filter } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IOSSwitch } from "../../CustomSwitch";
import { useDispatch } from "react-redux";
import { setPagesTabIndexAction } from "../../Store/appTempSlice";
const getPublishedUnpublishedTabs = () => {
  return ["Published", "Unpublished", "Archived", "All"];
};

const StyledSearchInput = styled.input`
  height: "100%";
  flex-grow: 1;
  border: none;
  background-color: transparent;
  border-radius: 4px 0 0 4px;
  padding: 0 15px;
  outline: none;
  &::placeholder {
    color: ${(props) => props.color};
  }
`;

function OfferingPagesTabHeader({
  featureKey,
  setUserFeaturesLocal,
  setLoading,
  setShowSalesDetails,
  loading,
  refreshingData,
  handleGetRevenueSaleData,
  handleGetTicketSaleData,
}) {
  const userFeatures = useSelector(
    (state) => state.userFeatures.userFeatures.features[featureKey.id]
  );
  const { pagesTabSelectedIndex } = useSelector((state) => state.appTemp.data);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();
  const { isDesktopView, isMobileView } = useWindowSize();
  const [showSeacrhbarInMobile, setShowSearchbarInMobile] = useState(false);
  const handleToggleSidebar = () => {
    setShowSearchbarInMobile((prev) => !prev);
  };

  const handlePublishUnpublishTabChange = (index, tab) => {
    dispatch(setPagesTabIndexAction(index));
  };

  const getAllUserFeatures = () => {
    if (isArrayNullOrUndefined(userFeatures?.items)) return [];
    return userFeatures.items.filter(
      (item) =>
        item.status !== Status.ARCHIVED && item.status !== Status.DELETED
    );
  };

  const onSearchTextChanged = (searchText) => {
    if (searchText.length === 0) {
      setUserFeaturesLocal(getAllUserFeatures());
      return;
    }
    const filtered = getAllUserFeatures().filter((feature) => {
      return feature.name.toLowerCase().includes(searchText);
    });
    setUserFeaturesLocal(filtered);
  };

  const updateListBasedOnTab = () => {
    let items;
    switch (pagesTabSelectedIndex) {
      case 0:
        items = userFeatures?.publishedItems;
        setUserFeaturesLocal(items);
        return;

      case 1:
        items = userFeatures?.unPublishedItems;
        setUserFeaturesLocal(items);
        return;
      case 2:
        items = userFeatures?.archived;
        setUserFeaturesLocal(items);
        return;
      case 3:
        items = getAllUserFeatures();
        setUserFeaturesLocal(items);
        return;
      default:
        items = [];
    }
  };

  const getUserFeatures = () => {
    return selectedTabIndex === 0
      ? userFeatures.publishedItems
      : userFeatures.unPublishedItems;
  };

  useEffect(() => {
    updateListBasedOnTab();
    setLoading(false);
  }, [userFeatures, pagesTabSelectedIndex]);

  return (
    <>
      <AddFlex
        gap="12px"
        wrap="wrap"
        alignItems="center"
        justify={!isMobileView() && "space-between"}
      >
        <BoxedTabs
          tabs={getPublishedUnpublishedTabs()}
          selectedTabIndex={pagesTabSelectedIndex}
          setSelectedTabIndex={handlePublishUnpublishTabChange}
        />
        {isDesktopView() && (
          <div style={{ width: "35%" }}>
            <Searchbar showSearchIcon={true} onChange={onSearchTextChanged} />
          </div>
        )}
        {isMobileView() && (
          <>
            <AddFlex
              width="30px"
              justify="center"
              alignItems="center"
              height="30px"
              // backgroundColor={primaryActionColor}
              onClick={handleToggleSidebar}
              borderRadius="50%"
              border={"1px solid " + primaryActionColor}
            >
              <Search sx={{ color: primaryActionColor, fontSize: "16px" }} />
            </AddFlex>
          </>
        )}
        <AddFlex gap="16px" alignItems="center">
          <CustomText margin="0 0 0 5px" color="black">
            Show Sale Details
          </CustomText>
          <IOSSwitch
            sx={{ padding: "0px" }}
            onChange={async (e) => {
              if (e.target.checked) {
                await handleGetTicketSaleData();
              } else {
                setShowSalesDetails(e.target.checked);
              }
            }}
          />
          {loading && <CircularProgress size={14} />}
        </AddFlex>
        <Tooltip title="Refresh sales data">
          <AddFlex
            padding="8px"
            borderRadius="3px"
            style={{ cursor: "pointer", backgroundColor: "#ececec" }}
            onClick={() => handleGetRevenueSaleData(true)}
          >
            {refreshingData ? (
              <CircularProgress size={18} />
            ) : (
              <Refresh sx={{ fontSize: "18px" }} />
            )}
          </AddFlex>
        </Tooltip>
      </AddFlex>

      {showSeacrhbarInMobile && (
        <AddFlex
          alignItems="center"
          margin="10px 0 0 0"
          style={{ width: "100%" }}
        >
          <Searchbar showSearchIcon={false} onChange={onSearchTextChanged} />
          <Close
            onClick={handleToggleSidebar}
            sx={{ color: "grey", margin: "0 10px" }}
          />
        </AddFlex>
      )}
    </>
  );
}

export default OfferingPagesTabHeader;

export const BoxedTabs = ({ selectedTabIndex, setSelectedTabIndex, tabs }) => {
  const { isMobileView } = useWindowSize();
  const getBorderRadius = (arrayLength, index) => {
    if (arrayLength === 1) {
      return "4px";
    }
    if (index === 0) {
      return "4px 0 0 4px";
    }
    if (index === arrayLength - 1) {
      return "0 4px 4px 0";
    }
    return "0px";
  };
  return (
    <AddFlex>
      {tabs.map((elem, index) => (
        <AddFlex
          padding={isMobileView() ? "9px 12px" : "9px 20px"}
          backgroundColor={selectedTabIndex === index && primaryActionColor}
          borderRadius={getBorderRadius(tabs.length, index)}
          onClick={() => setSelectedTabIndex(index, elem)}
          style={{
            color: selectedTabIndex === index ? "white" : primaryActionColor,
            fontSize: "13px",
            cursor: "pointer",
            borderLeft: `1px solid ${primaryActionColor}`,
            borderTop: `1px solid ${primaryActionColor}`,
            borderBottom: `1px solid ${primaryActionColor}`,
            borderRight:
              index === tabs.length - 1
                ? `1px solid ${primaryActionColor}`
                : "",
          }}
        >
          {elem}
        </AddFlex>
      ))}
    </AddFlex>
  );
};

export const Searchbar = ({
  color,
  onChange,
  placeholder,
  showSearchIcon,
  onWidgetClick,
  borderRadius,
  backgroundColor,
  borderColor,
  value,
}) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === "Return") {
        buttonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <AddFlex
      style={{ flexGrow: "1" }}
      height={inputHeight}
      borderRadius={borderRadius ? borderRadius : "4px"}
      backgroundColor={backgroundColor}
      border={`1px solid ${borderColor ? borderColor : "#dddddd"}`}
    >
      <StyledSearchInput
        color={color}
        value={value && value}
        placeholder={placeholder ? placeholder : "Search for offerings..|"}
        onChange={(e) => {
          if (isNullOrUndefined(onChange)) return;
          onChange(e.target.value);
        }}
      />
      {showSearchIcon && (
        <AddFlex
          alignItems="center"
          justify="center"
          borderRadius="0px 4px 4px 0px"
          style={{
            height: inputHeight,
            width: inputHeight,
            borderLeft: `1px solid ${borderColor ? borderColor : "#dddddd"}`,
          }}
          ref={buttonRef}
          onClick={() => {
            if (isNullOrUndefined(onWidgetClick)) return;
            onWidgetClick();
          }}
        >
          <SearchOutlined sx={{ color: color ? color : "grey" }} />
        </AddFlex>
      )}
    </AddFlex>
  );
};
