import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { AddFlexCol, CustomInputLabel, InputLabel } from "./reusableStyles";
import "react-phone-number-input/style.css";
import { Height } from "@mui/icons-material";

function MobileNumber({ number, handleGetMobileNumber }) {
  const [mobileNumber, setMobileNumber] = useState(number);
  const handleMobileNumberChange = (number) => {
    setMobileNumber(number);
  };

  useEffect(() => {
    handleGetMobileNumber(mobileNumber);
  }, [mobileNumber]);
  return (
    <AddFlexCol style={{ flexGrow: 1 }}>
      <InputLabel labelColor={"#333"} marginBottom="8px">
        Number
      </InputLabel>
      <PhoneInput
        defaultCountry="IN"
        placeholder="Enter phone number"
        value={mobileNumber}
        onChange={(text) => {
          handleMobileNumberChange(text);
        }}
      />
    </AddFlexCol>
  );
}

export default MobileNumber;
