import { format } from "date-fns";
import {
  Currency,
  formatPrice,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../utils";

export const formatSummaryForGroupByName = (sales) => {
  const flattenedEvents = sales.map((event, mainIndex) => {
    return {
      "S.No": mainIndex + 1,
      Name: event.name,
      Dates: event.dates.join("\n"),
      "Total Sales": event.totalSales,
      Revenue: formatPrice(event.totalRevenue, Currency.INR),
    };
  });
  return flattenedEvents;
};

export const formatSummaryForGroupByDates = (sales) => {
  const flattenedEvents = sales.map((event, mainIndex) => {
    return {
      "S.No": mainIndex + 1,
      Name: event.name,
      Dates: event.date,
      "Total Sales": event.totalSales,
      Revenue: formatPrice(event.totalRevenue, Currency.INR),
    };
  });
  return flattenedEvents;
};

export const formatSummaryForGroupByTimeSlots = (sales) => {
  const flattenedEvents = sales.map((event, mainIndex) => {
    return {
      "S.No": mainIndex + 1,
      Name: event.name,
      Dates: event.date,
      "Time Slot": getTimeInTwelveHourForm(event.timeSlot),
      "Total Sales": event.totalSales,
      Revenue: formatPrice(event.totalRevenue, Currency.INR),
    };
  });
  return flattenedEvents;
};

export const formatSummaryForGroupByTickets = (sales) => {
  const flattenedEvents = sales.map((event, mainIndex) => {
    return {
      "S.No": mainIndex + 1,
      Name: event.name,
      Dates: event.date,
      Ticket: event.ticket,
      "Time Slot": getTimeInTwelveHourForm(event.timeSlot),
      "Total Sales": event.totalSales,
      Revenue: formatPrice(event.totalRevenue, Currency.INR),
    };
  });
  return flattenedEvents;
};

export const formatAllSales = (sales) => {
  const flattenedEvents = sales.map((event, mainIndex) => {
    return {
      "S.No": mainIndex + 1,
      Name: event.customerName,
      Number: event.customerNumber,
      Email: event.customerEmail,
      "Offering Name": event.name,
      "Booking Date": format(new Date(event.bookingDate), "dd MMM yyyy"),
      Date: format(new Date(event.eventDate), "dd MMM yyyy"),
      Ticket: event.ticket,
      "Time Slot": getTimeInTwelveHourForm(event.timeSlot),
      "Cart Value": formatPrice(event.amount, event.currency),
      Convenience: formatPrice(
        event.paymentBreakdown.convenience +
          (isNullOrUndefined(event.paymentBreakdown.platformFee)
            ? 0
            : event.paymentBreakdown.platformFee),
        event.currency
      ),
      GST: formatPrice(event.paymentBreakdown.gst, event.currency),
      "Amount Paid By The Customer": formatPrice(
        event.paymentBreakdown.totalAmount,
        event.currency
      ),
    };
  });
  return flattenedEvents;
};
