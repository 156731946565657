import styled from "styled-components";
import { AddFlex, CustomText } from "../reusableStyles";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { generateUUID, getApiCallParams, isNullOrUndefined } from "../utils";
import { Backdrop, CircularProgress } from "@mui/material";
import Loader from "../Pages/Components/Loader";
import EmptyCaseUIComponent from "../Pages/Components/EmptyCaseUIComponent";
import { primaryActionColor } from "../constants";
import AdminCTAButton from "../AdminCTAButton";
import MenuButton from "./MenuButton";
import NewLink from "./NewLink";
import DragAndDropLinks from "./DragAndDropLinks";
import EmptyHomeComponent from "./EmptyHomeComponent";
import FeatureLinksDialog from "./FeatureLinksDialog";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import {
  AddLink,
  DeleteLink,
  ImportLinksToSection,
  LinkVisibility,
  ReorderLinks,
  UpdateLinks,
} from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import useWindowSize from "../Hooks/useWindowSize";

export const StyledFlexCol = styled(AddFlex)`
  flex-direction: column;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
  height: auto;
`;

function LinksTab() {
  const navigate = useNavigate();
  //   const { deleteSection } = useAdminServices();
  const dispatch = useDispatch();
  const { apiUserData } = useSelector((state) => state.user);
  const { isMobileView } = useWindowSize();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState(undefined);
  const [featureLinks, setFeatureLinks] = useState([]);
  const [analyticsOpenClicks, setIsAnalyticsOpenClicks] = useState([]);
  const [sectionOpenClicks, setSectionOpenClicks] = useState([]);
  const [isLinkEdit, setIsLinkEdit] = useState(false);
  const [link, setLink] = useState({ id: generateUUID() });
  const [isAddNewLink, setIsAddNewLink] = useState(false);
  const [isUpdatingLinkData, setIsUpdatingLinkData] = useState(false);
  const [isSectionDetailsOpen, setIsSectionDetailsOpen] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [isSectionNameEditClicked, setIsSectionNameEditClicked] = useState("");
  const [newSectionName, setNewSectionName] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [deleteActionFunction, setDeleteActionFunction] = useState(null);
  const [deleteActionArguments, setDeleteActionArguments] = useState([]);
  const [selectedImportLinks, setSelectedImportLinks] = useState({});
  const [selectedTestimonials, setSelectedTestimonials] = useState(undefined);
  const [isUpdatingSectionDetails, setIsUpdatingSectionDetails] =
    useState(false);

  const handleToggleDeleteDialog = (event) => {
    setDeleteActionFunction(null);
    setDeleteActionArguments([]);
    setIsDeleteClicked((prev) => !prev);
  };
  const handleAddNewLink = () => {
    setIsAddNewLink(true);
  };

  const handleResetLinkObj = () => {
    setLink({ id: v4() });
  };
  const handleAddNewFeature = (URL) => {
    navigate(URL);
  };

  const handleAddNewSection = async (dataType) => {
    //data type indicated the type of data in the section, testimonials or batches
    setIsUpdatingLinkData(true);
    const body = {
      id: v4(),
      cardType: "section",
      dataType: dataType,
      showDefault: true,
      data: {
        id: generateUUID(),
        name: "New Section",
        list: {},
      },
      enabled: true,
    };
    dispatch(usersApiCall(getApiCallParams(AddLink, body)))
      .then((res) => {
        setIsSectionDetailsOpen(true);
        setSelectedSectionId(body.data.id);
        setIsUpdatingLinkData(false);
        setSectionOpenClicks((prevState) => [...prevState, body.data.id]);
      })
      .catch((err) => {
        console.log(err);

        setIsUpdatingLinkData(false);
      });
  };

  const handleWindowResize = () => {
    setWindowSize([window.innerWidth, window.innerHeight]);
  };

  const handleCloseNewLinkBox = () => {
    setIsAddNewLink(false);
  };
  const getChangedLinkDetails = (linkData, linkValue) => {
    setLink({ ...link, [linkData]: linkValue });
  };

  const handleSectionShowDefaultSetting = async (sectionId, showDefault) => {
    setIsUpdatingLinkData(true);
    let body = links;
    body = {
      ...body,
      [sectionId]: {
        ...body[sectionId],
        showDefault: showDefault,
      },
    };
    dispatch(usersApiCall(getApiCallParams(AddLink, body[sectionId])))
      .then((res) => {
        setIsUpdatingLinkData(false);
      })
      .catch((err) => {
        console.log(err);
        setIsUpdatingLinkData(false);
      });
  };

  const updateLink = (linkId, linkData, linkValue) => {
    setLinks({
      ...links,
      [linkId]: {
        ...links[linkId],
        data: {
          ...links[linkId].data,
          [linkData]: linkValue,
        },
      },
    });
  };

  const handleSetLinks = () => {
    // if (apiUserData?.onbordingStatus === 1) {
    //   navigate(`/new/createLink/${apiUserData.userName}`);
    //   return;
    // }
    if (isNullOrUndefined(apiUserData.links)) return;
    setLinks(apiUserData.links);
    setFeatureLinks(
      Object.values(apiUserData.links).filter((link) => {
        return link.cardType === "feature";
      })
    );
  };

  const handleReorderLinks = async (newLinksObj) => {
    setLinks(newLinksObj);
    setIsUpdatingLinkData(true);
    dispatch(usersApiCall(getApiCallParams(ReorderLinks, newLinksObj)))
      .then((res) => {
        setIsUpdatingLinkData(false);
      })
      .catch((err) => {
        console.log(err);

        setIsUpdatingLinkData(false);
      });
  };

  const handleIsEdit = (isEdit, link) => {
    setIsLinkEdit(isEdit);
    setLink(link);
  };
  const handleLinkVisibility = async (body) => {
    setIsUpdatingLinkData(true);
    dispatch(usersApiCall(getApiCallParams(LinkVisibility, body)))
      .then((res) => {
        setIsUpdatingLinkData(false);
      })
      .catch((err) => {
        console.log(err);

        setIsUpdatingLinkData(false);
      });
  };

  //   const handleSectionDelete = async (id, idsList) => {
  //     setIsUpdatingLinkData(true);
  //     const body = {
  //       id: id,
  //       idsList: idsList,
  //     };

  //     await deleteSection(body)
  //       .then((res) => {
  //         setLinks(res.data.newDoc.links);
  //         setIsUpdatingLinkData(false);
  //         handleToggleDeleteDialog();
  //       })
  //       .catch((err) => {
  //         console.log(err);

  //         setIsUpdatingLinkData(false);
  //         handleToggleDeleteDialog();
  //       });
  //   };

  const handleSectionDeleteHandler = (id, idsList) => {
    setIsDeleteClicked(true);
    setDeleteActionArguments([id, idsList]);
    setDeleteActionFunction(() => handleDelete);
  };

  const handleDelete = async (id, featureId) => {
    setIsUpdatingLinkData(true);
    const path = "links." + id;
    const body = {
      id: id,
      path: path,
      featureId: featureId,
    };
    //:VISIT
    dispatch(usersApiCall(getApiCallParams(DeleteLink, body)))
      .then((res) => {
        setIsUpdatingLinkData(false);
        // handleToggleDeleteDialog();
      })
      .catch((err) => {
        console.log(err);

        setIsUpdatingLinkData(false);
        // handleToggleDeleteDialog();
      });
  };

  const handleDeleteHandler = (id, featureId) => {
    setIsDeleteClicked(true);
    setDeleteActionArguments([id, featureId]);
    setDeleteActionFunction(() => handleDelete);
  };

  const handleShowAnalytics = (_link) => {
    if (analyticsOpenClicks.includes(_link.id)) {
      const newAnalyticsClickArray = analyticsOpenClicks.filter((id) => {
        return id !== _link.id;
      });
      setIsAnalyticsOpenClicks(newAnalyticsClickArray);
    } else {
      setIsAnalyticsOpenClicks((prevState) => [...prevState, _link.id]);
    }
  };

  const handleChangeSectionName = (name) => {
    setNewSectionName(name);
    updateLink(selectedSectionId, "name", name);
  };
  const handleGetSectionId = (id, isEditClicked) => {
    if (!isEditClicked) {
      if (sectionOpenClicks.includes(id)) {
        const newAnalyticsClickArray = sectionOpenClicks.filter((_id) => {
          return _id !== id;
        });
        setSectionOpenClicks(newAnalyticsClickArray);
      } else {
        setSectionOpenClicks((prevState) => [...prevState, id]);
      }
    } else {
      if (selectedSectionId !== id) {
        setIsSectionNameEditClicked(true);
        setSelectedSectionId(id);
      } else {
        setIsSectionNameEditClicked(false);
        setSelectedSectionId("");
      }
    }
  };

  const handleUpdateSectionName = async () => {
    dispatch(usersApiCall(getApiCallParams(AddLink, links[selectedSectionId])))
      .then((res) => {
        setIsSectionNameEditClicked(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpenImportFeatureDialog = (id) => {
    setSelectedSectionId(id);
    setIsDialogOpen(true);
  };

  const handleLinkCheck = (id, isChecked) => {
    const newLinksObj = { ...selectedImportLinks };
    if (!isChecked) {
      delete newLinksObj[id];
      setSelectedImportLinks(newLinksObj);
    } else {
      setSelectedImportLinks({ ...selectedImportLinks, [id]: 1 });
    }
  };

  const handleAddLinks = async (isTestimonialSection, list) => {
    setIsUpdatingLinkData(true);
    const body = {
      linkIds: Object.keys(selectedImportLinks),
      sectionId: selectedSectionId,
      action: "in",
    };
    dispatch(usersApiCall(getApiCallParams(ImportLinksToSection, body)))
      .then((res) => {
        setIsUpdatingLinkData(false);
        handleCloseImportFeatureDialog();
      })
      .catch((err) => {
        console.log(err);
        setIsUpdatingLinkData(false);
      });
  };

  const handleCloseImportFeatureDialog = () => {
    setSelectedImportLinks({});
    setIsDialogOpen(false);
  };

  const handleSetReorderedSectionDataList = async (
    newLinks,
    selectedSectionId
  ) => {
    setIsUpdatingSectionDetails(true);
    const newLinksObj = {
      ...links,
      [selectedSectionId]: {
        ...links[selectedSectionId],
        data: {
          ...links[selectedSectionId].data,
          list: newLinks,
        },
      },
    };
    dispatch(
      usersApiCall(getApiCallParams(UpdateLinks, { links: newLinksObj }))
    )
      .then((res) => {
        setIsUpdatingSectionDetails(false);
      })
      .catch((err) => {
        console.log(err);

        setIsUpdatingSectionDetails(false);
      });
  };

  const handleMoveLinkToMain = async (id, sectionId) => {
    try {
      setIsUpdatingLinkData(true);
      const body = {
        linkId: id,
        sectionId: sectionId,
        action: "out",
      };
      dispatch(usersApiCall(getApiCallParams(ImportLinksToSection, body)))
        .then((res) => {
          setIsUpdatingLinkData(false);
        })
        .catch((err) => {
          console.log(err);

          setIsUpdatingLinkData(false);
        });
    } catch (error) {
      console.log(error);
      setIsUpdatingLinkData(false);
    }
  };

  const handleChangeLinkVisibilityInSection = async (body, sectionId) => {
    setIsUpdatingSectionDetails(true);
    const id = body.id;
    const newLinksObj = {
      ...links,
      [sectionId]: {
        ...links[sectionId],
        data: {
          ...links[sectionId].data,
          list: {
            ...links[sectionId].data.list,
            [id]: {
              ...links[sectionId].data.list[id],
              enabled: body.enabled,
            },
          },
        },
      },
    };
    dispatch(
      usersApiCall(getApiCallParams(UpdateLinks, { links: newLinksObj }))
    )
      .then((res) => {
        setIsUpdatingSectionDetails(false);
        enqueueSnackbar("Visibility Toggled");
      })
      .catch((err) => {
        console.log(err);

        setIsUpdatingSectionDetails(false);
      });
  };

  //section link delete handler;

  const handleDeleteLinksFromSection = async (id, featureId, sectionId) => {
    setIsUpdatingLinkData(true);
    const path = `links.${sectionId}.data.list.` + id;
    const body = {
      id: id,
      path: path,
      featureId: featureId,
    };
    //:VISIT
    dispatch(usersApiCall(getApiCallParams(DeleteLink, body)))
      .then((res) => {
        setLinks(res.data.newDoc.links);
        setIsUpdatingLinkData(false);
        handleToggleDeleteDialog();
      })
      .catch((err) => {
        console.log(err);
        setIsUpdatingLinkData(false);
        handleToggleDeleteDialog();
      });
  };
  const handleDeleteLinksFromSectionHandler = (id, featureId, sectionId) => {
    setIsDeleteClicked(true);
    setDeleteActionArguments([id, featureId, sectionId]);
    setDeleteActionFunction(() => handleDeleteLinksFromSection);
  };

  const handleDeleteAction = async (callback) => {
    if (callback) {
      await callback(...deleteActionArguments);
    }
  };

  useEffect(() => {
    const data = setTimeout(() => {
      if (newSectionName.length > 0) {
        handleUpdateSectionName();
      }
    }, 2500);
    return () => clearTimeout(data);
  }, [newSectionName]);

  useEffect(() => {
    if (!isNullOrUndefined(apiUserData)) {
      handleSetLinks();
      // fetchUserFeatureData(getConfig());
      setLoading(false);
    }
  }, [apiUserData]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  // const handleSortDate = () => {

  // }

  // const sortingOptions = [
  //   {
  //     id: "sectionButtonMenuItems_0",
  //     name: "Simple Link",
  //     action: handleAddNewLink,
  //     actionArgs: null,
  //   },
  // ];

  const sectionButtonMenuItems = [
    {
      id: "sectionButtonMenuItems_0",
      name: "Simple Link",
      action: handleAddNewLink,
      actionArgs: null,
    },
    {
      id: "sectionButtonMenuItems_1",
      name: "Features Section",
      action: handleAddNewSection,
      actionArgs: ["features"],
    },
  ];

  return (
    apiUserData && (
      <>
        <Backdrop
          open={!isDialogOpen && isUpdatingLinkData}
          sx={{ zIndex: 1111 }}
        >
          <AddFlex align="center" justify="center">
            <CircularProgress />
            <CustomText color="white">Please wait</CustomText>
          </AddFlex>
        </Backdrop>
        <AddFlex alignItems={"flex-start"} width="100%" margin={"0px 0 0 0"}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <StyledFlexCol
                style={{ marginBottom: "30vh" }}
                width={"100%"}
                align="center"
              >
                <AddFlex flexDirection="column" width="100%">
                  <AddFlex
                    justify="space-between"
                    width="100%"
                    margin="0 0 10px 0"
                  >
                    <AddFlex margin="0 0 0 auto">
                      <MenuButton
                        buttonName={"Add New"}
                        menuItems={sectionButtonMenuItems}
                      />
                      {/* <MenuButton
                        buttonName={"Add New"}
                        menuItems={sectionButtonMenuItems}
                      /> */}
                    </AddFlex>
                  </AddFlex>
                  <AddFlex
                    flexDirection="column"
                    // height={
                    //   isAddNewLink &&
                    //   isNullOrUndefined(links) &&
                    //   windowSize[1] + "px"
                    // }
                    padding={"5px"}
                    width="100%"
                    justify="center"
                  >
                    {isAddNewLink && (
                      <NewLink
                        link={link}
                        handleResetLinkObj={handleResetLinkObj}
                        closeNewLinkBox={handleCloseNewLinkBox}
                        getChangedLinkDetails={getChangedLinkDetails}
                      />
                    )}
                    <DragAndDropLinks
                      windowSize={windowSize}
                      links={links}
                      handleSetNewLinks={handleReorderLinks}
                      handleVisibilityChange={handleLinkVisibility}
                      isEdit={isLinkEdit}
                      selectedLinkId={link.id}
                      analyticsOpenClicks={analyticsOpenClicks}
                      handleIsEdit={handleIsEdit}
                      handleResetLinkObj={handleResetLinkObj}
                      updateLink={updateLink}
                      moveLinkToMain={handleMoveLinkToMain}
                      handleDelete={handleDelete}
                      handleSectionDelete={handleSectionDeleteHandler}
                      handleShowAnalytics={handleShowAnalytics}
                      getSectionId={handleGetSectionId}
                      sectionOpenClicks={sectionOpenClicks}
                      isSectionDetailsOpen={isSectionDetailsOpen}
                      selectedSectionId={selectedSectionId}
                      isSectionNameEditClicked={isSectionNameEditClicked}
                      getChangedSectionName={handleChangeSectionName}
                      handleSectionShowDefaultSetting={
                        handleSectionShowDefaultSetting
                      }
                      importFeatures={handleOpenImportFeatureDialog}
                      setReorderedSectionDataList={
                        handleSetReorderedSectionDataList
                      }
                      isUpdatingSectionDetails={isUpdatingSectionDetails}
                      changeLinkVisibilityInSection={
                        handleChangeLinkVisibilityInSection
                      }
                      deleteLinkFromSection={
                        handleDeleteLinksFromSectionHandler
                      }
                    />
                  </AddFlex>
                  <AddFlex flexDirection="column" width="100%">
                    {isNullOrUndefined(links) && !isAddNewLink && <></>}
                  </AddFlex>
                </AddFlex>
              </StyledFlexCol>
            </>
          )}
        </AddFlex>
        <FeatureLinksDialog
          isOpen={isDialogOpen}
          links={featureLinks}
          handleLinkCheck={handleLinkCheck}
          isTestimonialSection={
            links && links[selectedSectionId]?.dataType === "testimonials"
          }
          handleAddLinks={handleAddLinks}
          loading={isUpdatingLinkData}
          handleCloseImportFeatureDialog={handleCloseImportFeatureDialog}
          previousTestimonialsInSection={
            links && links[selectedSectionId]?.dataType === "testimonials"
              ? links[selectedSectionId].data.list
              : null
          }
          selectedTestimonials={selectedTestimonials}
          setSelectedTestimonials={setSelectedTestimonials}
          selectedSectionId={selectedSectionId}
        />
        <Backdrop
          open={isDeleteClicked}
          onClose={handleToggleDeleteDialog}
          sx={{ zIndex: "1111111" }}
        >
          <AddFlex
            flexDirection="column"
            backgroundColor="white"
            width={isMobileView() ? "80vw" : "30vw"}
            borderRadius="4px"
          >
            <EmptyCaseUIComponent
              emptyTitle={"Are you sure you want to delete?"}
              emptyDescription={
                <AddFlex>
                  <AdminCTAButton
                    margin="20px 20px 0 0"
                    buttonColor="wgue"
                    buttonText={"Cancel"}
                    border={`1px solid ${primaryActionColor}`}
                    buttonTextColor={"white"}
                    borderRadius={"4px"}
                    onClick={() => {
                      handleToggleDeleteDialog();
                    }}
                  />
                  <AdminCTAButton
                    loading={isUpdatingLinkData}
                    margin="20px 0px 0 0"
                    buttonText={"Delete"}
                    borderRadius={"4px"}
                    onClick={() => {
                      handleDeleteAction(deleteActionFunction);
                    }}
                  />
                </AddFlex>
              }
            />
          </AddFlex>
        </Backdrop>
      </>
    )
  );
}

export default LinksTab;
