import React, { useEffect, useState } from "react";
import RevenueChips from "./RevenueChips";
import ActivityStream from "./ActivityStream";
import ScheduledCalls from "./ScheduledCalls";
import { AddFlex, Gap } from "../reusableStyles";
import { LeadSource } from "../constants";
import SharableCards from "./SharableCards";
import OfferingSummaryTestimonialsSection from "./OfferingSummaryTestimonialsSection";
import { callNonStoreApi } from "../NonStoreApis";
import {
  FetchDashboardData,
  FetchDashboardStreamData,
} from "../Api/ApiEndPoints";
import { useParams } from "react-router";
import Loading from "../Loading";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FeaturesKeys, isNullOrUndefined } from "../utils";
import { enqueueSnackbar } from "notistack";

const dummyActivityStreamData = {
  bookings: [
    {
      offeringName: "Weightloass",
      name: "Sai Shashank Nibhanupudi",
      bookingDate: "04-03-2024",
      ticketName: "Early Brid +2 More",
      value: 299900,
    },
    {
      offeringName: "Weightloss yoga",
      name: "Sai",
      bookingDate: "04-04-2024",
      ticketName: "Gold",
      value: 99900,
    },
    {
      offeringName: "Weightloss yoga",
      name: "Shashank",
      bookingDate: "04-05-2024",
      ticketName: "Sliver",
      value: 79900,
    },
  ],
  leads: [
    {
      offeringName: "Weight Loss Yoga",
      name: "Sai Shashank Nibhanupudi",
      source: LeadSource.WhatsappDm,
    },
    {
      offeringName: "1 Month Diet Course",
      name: "Shashank",
      source: LeadSource.LeadForm,
      data: {
        date: new Date("12-04-2024"),
        timeSlot: {
          start: "15:30",
        },
      },
    },
    {
      offeringName: "Say Yoga!",
      name: "Shashank",
      source: LeadSource.PhoneCall,
      data: {
        date: new Date(),
        timeSlot: {
          start: "15:30",
        },
      },
    },
  ],
};

function OfferingSummary() {
  const [apiResponse, setApiResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [streamData, setStreamData] = useState(null);
  const [isStreamDataLoading, setIsStreamDataLoading] = useState(true);
  const { offeringId } = useParams();
  const [searchParams] = useSearchParams();
  const { features } = useSelector((state) => state.userFeatures.userFeatures);

  const handleGetOffering = () => {
    const pageType = searchParams.get("pageType").toLowerCase();
    const offering = features[FeaturesKeys[pageType].id].items.find(
      (item) => item.id === offeringId
    );
    return offering;
  };

  const handleGetOfferingDashboardData = async () => {
    const response = await callNonStoreApi(FetchDashboardData, {
      offeringId: offeringId,
    });
    const offering = handleGetOffering();
    const revenue = isNullOrUndefined(offering?.netRevenue)
      ? 0
      : offering.netRevenue;
    response.data.businessData.revenue = revenue;
    setApiResponse(response.data);
    setIsLoading(false);
  };

  const handleGetStreamData = async () => {
    try {
      const response = await callNonStoreApi(FetchDashboardStreamData, {
        offeringId: offeringId,
      });
      setStreamData(response.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsStreamDataLoading(false);
    }
  };

  useEffect(() => {
    handleGetOfferingDashboardData();
    handleGetStreamData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      //TODO: API CALL FOR GETTING STREAM DATA
      handleGetStreamData();
    }, 300000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return isLoading ? (
    <AddFlex
      alignItems="center"
      justify="center"
      style={{ width: "100%", height: "100%" }}
    >
      <Loading />
    </AddFlex>
  ) : (
    <AddFlex
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      <AddFlex
        flexDirection="column"
        gap="53px"
        padding="5px"
        width="60%"
        height="100%"
        style={{ overflow: "scroll" }}
      >
        {/* <RevenueChips revenueData={apiResponse?.businessData} /> */}
        <ScheduledCalls calls={apiResponse?.calls} />
        <OfferingSummaryTestimonialsSection offering={handleGetOffering()} />
      </AddFlex>
      <Gap />
      {!isStreamDataLoading && <ActivityStream streamData={streamData} />}
    </AddFlex>
  );
}

export default OfferingSummary;
