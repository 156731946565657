import {
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  AddFlex,
  PrimaryActionButton,
  SectionHeaderFancy,
} from "../reusableStyles";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import { getApiCallParams, isNullOrUndefined } from "../utils";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import { updateSocialLinks } from "../Api/ApiEndPoints";
import CheckIcon from "@mui/icons-material/Check";
import { PLATFORMS_DATA } from "../constants";

const Links = styled.div`
  width: 100%;
  margin-left: 0px;
  margin-top: 20px;
  @media (min-width: 0px) and (max-width: 999px) {
    width: 85%;
  }
`;

const Link = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* justify-content:space-around; */
`;

const Left = styled.div`
  width: 5%;
  margin: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Right = styled.div`
  width: 90%;
  margin: 12px;
  display: flex;
  flex-direction: column;
`;

const Icon = styled.img`
  width: 55%;
  min-width: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 0px) and (max-width: 680px) {
    width: 32px;
    /* height:32px; */
  }
`;

const Title = styled.p`
  width: 100%;
  font-size: 2vw;
  margin-top: 6px;
  margin-bottom: 8px;
  font-size: 15px;
  font-family: Roboto, "sans-serif";
  color: #344054;

  @media (min-width: 0) and (max-width: 400px) {
    font-size: 14px;
  }
`;

const Status = styled.div``;

const CustomTextField = muiStyled(TextField)({
  "& .MuiInputBase-root": {
    padding: 0,
    "& input": {
      padding: "5px 5px",
    },
  },
  "& .MuiFormHelperText-root": {
    textAlign: "start",
  },
});

const CustomTextFieldForPhone = muiStyled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    padding: 0,
    "& input": {
      padding: "5px 5px",
    },
  },
});

const CustomSelect = muiStyled(Select)({
  width: "100%",
  padding: 0,
  "& #country": {
    padding: "5px",
  },
});

const CustomFormHelperText = muiStyled(FormHelperText)({
  textAlign: "start",
  color: "#667085",
});

function Socials({ windowSize }) {
  // importing data from userContext
  const { apiUserData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //   const { updateSocials } = useFeatureServices();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  let [platforms, setPlatforms] = useState(new Set());
  const [countrycode, setcountrycode] = useState(+91);
  const [socialData, setSocialData] = useState({});
  const handleChange = (event) => {
    setcountrycode(event.target.value);
  };

  const validate = (el, value, regex, platform) => {
    el.target.parentElement.parentElement.nextSibling.style.color = "";
    el.target.parentElement.parentElement.nextSibling.innerHTML = "";
    const res = regex.test(value);

    if (res == true || value.length === 0) {
      // Do API call to save the input
      if (platform === "WhatsApp") {
        value = countrycode + value;
      }
      // handleSocialLinkChange(platform, value, el);
      setSocialData({ ...socialData, [platform]: value });
    } else {
      el.target.style.border = "1px solid #BDBEBF";
      el.target.style.borderRadius = "5px";
      el.target.parentElement.parentElement.nextSibling.style.color = "red";
      el.target.parentElement.parentElement.nextSibling.innerHTML =
        "Invalid Format";

      // remove check mark if already present
      let newPlatforms = Array.from(platforms).map((item) => {
        if (item.platform === platform) {
          return { ...item, isLoading: false, saved: false };
        } else {
          return item;
        }
      });
      setPlatforms(newPlatforms);
    }
  };

  let added = [];
  let all = [];
  const platformsList = new Set(PLATFORMS_DATA);

  const handleUpdateLinks = async () => {
    setLoading(true);
    dispatch(
      usersApiCall(
        getApiCallParams(updateSocialLinks, {
          socials: socialData,
        })
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === "fulfilled") {
        setLoading(false);
        enqueueSnackbar("Social links updated successfully", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wrong, please try again.", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      }
    });
    // let res = await updateSocials(socialData, getConfig()).then((res) => {
    //   setData([res.data.newDoc]);
    //
    // });
  };
  useEffect(() => {
    // fetch data from data backend
    let socialData = apiUserData["socialLinks"];
    setSocialData(socialData);
    var platform;
    if (added != null) {
      added.splice(0, added.length);
    }
    if (all != null) {
      all.splice(0, all.length);
    }
    platformsList.forEach((item) => {
      platform = item.platform;

      item.status =
        (!isNullOrUndefined(socialData) && platform in socialData) ||
        item.showDefault;
      item.value =
        isNullOrUndefined(socialData) || !(platform in socialData)
          ? null
          : socialData[platform];

      if (platform == "WhatsApp" && item.value != null) {
        let cc = item.value.slice(0, -10);
        setcountrycode(cc);
        item.value = item.value.slice(-10);
      }

      item.inputText =
        isNullOrUndefined(socialData) ||
        !(platform in socialData) ||
        isNullOrUndefined(socialData[item])
          ? item.inputText
          : "updated";

      if (item.status == true) added.push(item);
      all.push(item);
    });

    setPlatforms(added);
  }, [apiUserData]);

  return (
    <AddMargin margin="30px 0 0 0px">
      <AddFlex alignItems="center" justify="space-between" width={"100%"}>
        <SectionHeaderFancy
          Title={"Social Links"}
          Description={
            "Display links to your email, whatsapp and social profiles as icons"
          }
        />
        <PrimaryActionButton onClick={handleUpdateLinks}>
          {loading ? (
            <CircularProgress size={14} style={{ color: "white" }} />
          ) : (
            "Save"
          )}
        </PrimaryActionButton>
      </AddFlex>

      <Links>
        {Array.from(platforms).map((item, idx) =>
          item.platform !== "WhatsApp" ? (
            <Link key={idx}>
              <Left>
                <Icon src={item.icon} />
              </Left>
              <Right>
                <Title>{item.title}</Title>

                <CustomTextField
                  variant="outlined"
                  defaultValue={item.value}
                  placeholder={item.placeholder}
                  id={item.platform}
                  data-prev=""
                  onChange={(e) =>
                    validate(e, e.target.value, item.reg, item.platform)
                  }
                />
                <CustomFormHelperText>{item.inputText}</CustomFormHelperText>
              </Right>
              <Status>
                {item.saved == false && item.isLoading === true && (
                  <CircularProgress />
                )}
                {item.saved === true && item.isLoading === false && (
                  <CheckIcon
                    style={{
                      color: "green",
                      translate: "0 calc(46.5px + 33px/2 - 12px)",
                    }}
                  />
                )}
              </Status>
            </Link>
          ) : (
            <Link key={idx}>
              <Left>
                <Icon src={item.icon} />
              </Left>
              <Right>
                <Title>{item.title}</Title>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "16%", margin: "0 20px 0 0" }}>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="country"
                      defaultValue={+91}
                      value={countrycode}
                      disabled={true}
                      onChange={handleChange}
                    >
                      <MenuItem value={+91}>+91</MenuItem>
                    </CustomSelect>
                    <CustomFormHelperText>Country Code</CustomFormHelperText>
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextFieldForPhone
                      variant="outlined"
                      defaultValue={item.value}
                      data-prev=""
                      placeholder={item.placeholder}
                      id="number"
                      onChange={(e) =>
                        validate(e, e.target.value, item.reg, item.platform)
                      }
                    />
                    <CustomFormHelperText>Number</CustomFormHelperText>
                  </div>
                </div>
              </Right>
              <Status>
                {item.saved == false && item.isLoading === true && (
                  <CircularProgress />
                )}
                {item.saved === true && item.isLoading === false && (
                  <CheckIcon
                    style={{
                      color: "green",
                      translate: "0 calc(46.5px + 33px/2 - 12px)",
                    }}
                  />
                )}
              </Status>
            </Link>
          )
        )}
      </Links>
    </AddMargin>
  );
}

export default Socials;
