import React from "react";
import {
  AddFlex,
  CustomInputArea,
  CustomText,
  CustomTextArea,
} from "../reusableStyles";

function UserBio({ bio, handleBioChange }) {
  return (
    <AddFlex flexDirection="column" margin="32px 0 0 0">
      <CustomTextArea
        label={"Bio"}
        style={{ marginTop: "0px", minHeight: "100px" }}
        value={bio}
        placeholder="This helps users to know what your business is about."
        onChange={(text) => {
          handleBioChange(text);
        }}
      />
    </AddFlex>
  );
}

export default UserBio;
