import { Backdrop } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AddFlex, CustomText } from "../reusableStyles";
import GalleryComponent from "./GalleryComponent";
import { primaryActionColor } from "../constants";
import { ReactSortable } from "react-sortablejs";
import { isNullOrUndefined } from "../utils";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDropzone } from "react-dropzone";
import SectionHeader from "./Components/SectionHeader";
import { Close } from "@mui/icons-material";

const StyledReactSortable = styled(ReactSortable)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const ItemImg = styled.img`
  width: 80px;
  height: 80px;
  margin: 4px;
  object-fit: cover;
  cursor: grab;
`;

export const ImageHolder = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  margin: ${(props) => props.margin && props.margin};
`;

export const ImageHolderHeader = styled.div`
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #d0d5dd;
  padding: 18px;
  padding-bottom: 6px;
  border-radius: 4px 4px 0 0;
`;

export const ImageHolderBody = styled.div`
  min-height: ${(props) => (props.height ? props.height : "144px")};
  background-color: white;
  display: flex;
  border: 1px solid #d0d5dd;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  padding: 5px;
  border-radius: 0 0 4px 4px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 100%;
  background-color: #f2f4f7;
  width: 40px;
  height: 40px;
  color: primaryActionColor;
`;

function ImageHolderComponent({
  isMultiple,
  width,
  files,
  errorText,
  hideHeader,
  headerTitleText,
  headerSubTitleText,
  bodyHeight,
  showOnly,
  handleRemoveFile,
  handleGetFiles,
}) {
  const [openGallery, setOpenGallery] = useState(false);
  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".png", ".svg"],
      "video/mp4": [".mp4"],
    },
    maxSize: 3 * 1024 * 1024, //limit to 1MB
    multiple: true,
    onDrop: (acceptedFiles, rejectedFiles) => {
      handleGetFiles(acceptedFiles, rejectedFiles);
    },
  });
  const handleDragEnd = (result) => {
    handleGetFiles(isMultiple, result);
  };
  const handleOpenGallery = () => {
    setOpenGallery(true);
  };

  return (
    <div>
      {!hideHeader && (
        <SectionHeader
          headerText={headerTitleText}
          subHeaderText={headerSubTitleText}
          // margin="0 0 20px 0"
        />
      )}
      {errorText && (
        <CustomText
          margin="3px 0 0 0"
          fontSize="12px"
          fontWeight="500"
          color="red"
        >
          {errorText}
        </CustomText>
      )}
      <div style={{ margin: "0 0 20px 0" }}></div>
      <ImageHolderBody
        height={bodyHeight}
        width={width}
        onClick={handleOpenGallery}
      >
        <AddFlex margin="0 0 10px 0" justify="center" wrap="wrap">
          {files &&
            files.map((file, index) => (
              <AddFlex>
                {!file.isVideo && (
                  <AddFlex
                    backgroundColor="#ececec"
                    position="absolute"
                    height="22px"
                    width="22px"
                    borderRadius="100%"
                    alignItem="center"
                    justify="center"
                    top="0px"
                    right="0px"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveFile(isMultiple, index);
                    }}
                  >
                    <CancelIcon />
                  </AddFlex>
                )}
                {file.isVideo ? (
                  <AddFlex
                    alignItems="center"
                    padding="5px 10px 5px 10px"
                    gap="5px"
                    style={{ color: "#097849" }}
                    backgroundColor="#ECFDF3"
                    border="1px solid #ACEFC6"
                    borderRadius="4px"
                    width={"100%"}
                  >
                    <p
                      style={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        letterSpacing: "0.3px",
                        lineClamp: 1,
                        flexGrow: 1,
                        // maxWidth: "90%",
                        // "-webkit-line-clamp": 1,
                      }}
                    >
                      {file.name}
                    </p>

                    <Close
                      sx={{ color: "#097849", fontSize: "18px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveFile(isMultiple, index);
                      }}
                    />
                  </AddFlex>
                ) : (
                  <ItemImg
                    key={index}
                    src={!file.uploaded ? file.preview : file.src}
                  />
                )}
              </AddFlex>
            ))}
        </AddFlex>
        <IconWrapper>
          <CloudUploadIcon sx={{ color: primaryActionColor }} />
        </IconWrapper>
        <AddFlex margin="0 0 10px 0">
          <CustomText margin="0 4px 0 0" color={primaryActionColor}>
            Click to upload
          </CustomText>
          <CustomText>or drag and drop</CustomText>
        </AddFlex>
        {files === null ||
          files === undefined ||
          (files.length === 0 && (
            <AddFlex margin="2px 0 0 0">
              <CustomText fontSize="12px">
                SVG, PNG, JPG or GIF (max. 800x400px)
              </CustomText>
            </AddFlex>
          ))}
      </ImageHolderBody>
      <Backdrop
        open={openGallery}
        style={{ position: "fixed", top: 0, left: 0, zIndex: 1111111 }}
        onClick={() => {
          setOpenGallery(false);
        }}
      >
        <GalleryComponent
          isMultiple={isMultiple}
          prevSelectedImages={files}
          showOnly={showOnly}
          open={openGallery}
          closeBackdrop={() => setOpenGallery(false)}
          handleGetSelectedImages={handleGetFiles}
        />
      </Backdrop>
    </div>
  );
}

export default ImageHolderComponent;
