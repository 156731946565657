import React, { useEffect, useState } from "react";
import {
  ActionButton,
  AddFlex,
  CustomInput,
  CustomText,
  PrimaryActionButton,
} from "../../reusableStyles";
import FillAFormCustomiseDialog from "./FillAForCustomiseDialog";
import CallbackAndMeetCustomiseDialog from "./CallbackAndMeetCustomiseDialog";
import WhatsappCustomiseDialog from "./WhatsappCustomiseDialog";
import { CancelOutlined } from "@mui/icons-material";
import { CTA_Button_Types, isNullOrUndefined } from "../../utils";
import { useSelector } from "react-redux";
import ScheduleSetup from "./ScheduleComponents/ScheduleSetup";
import { primaryActionColorLight } from "../../constants";
import { enqueueSnackbar } from "notistack";

function PageGoalsCustomiseDialog({
  isMobileView,
  pageGoal,
  closeDialog,
  iconClose,
  handleAddCTA,
  setDetails,
  button_data,
}) {
  const [buttonName, setButtonName] = useState("");
  const [data, setData] = useState({});
  const [info, setInfo] = useState("");
  const [buttonNameErr, setButtonNameErr] = useState(null);

  const isGoogleCalendarSetupDone = useSelector((state) => {
    if (state.user.apiUserData.activeIntegrations) {
      return state.user.apiUserData.activeIntegrations.includes(
        "google_calendar"
      );
    }
    return false;
  });
  const isAvailabilitySet = useSelector(
    (state) => !isNullOrUndefined(state.user.apiUserData.availability)
  );

  const getActionUndoneCount = () => {
    return (isAvailabilitySet ? 0 : 1) + (isGoogleCalendarSetupDone ? 0 : 1);
  };

  const getFormBody = (type) => {
    switch (type) {
      case CTA_Button_Types.FORM: {
        return (
          <FillAFormCustomiseDialog
            data={data}
            setData={setData}
            isIntake={false}
          />
        );
      }
      case CTA_Button_Types.CALL: {
        return getActionUndoneCount() !== 0 ? (
          <ScheduleSetup
            isAvailabilitySet={isAvailabilitySet}
            showCalendarIntegration={false}
            getActionUndoneCount={getActionUndoneCount}
          />
        ) : (
          <CallbackAndMeetCustomiseDialog
            closeDialog={closeDialog}
            data={data}
            setData={setData}
          />
        );
      }
      case CTA_Button_Types.GOOGLEMEET: {
        return getActionUndoneCount() !== 0 ? (
          <ScheduleSetup
            isAvailabilitySet={isAvailabilitySet}
            showCalendarIntegration={true}
            getActionUndoneCount={getActionUndoneCount}
          />
        ) : (
          <CallbackAndMeetCustomiseDialog
            closeDialog={closeDialog}
            data={data}
            setData={setData}
          />
        );
      }
      case CTA_Button_Types.WHATSAPP: {
        return <WhatsappCustomiseDialog data={data} setData={setData} />;
      }
      default: {
        <></>;
      }
    }
  };
  const showCTAName = (buttonType) => {
    if (
      buttonType === CTA_Button_Types.CALL ||
      buttonType === CTA_Button_Types.GOOGLEMEET
    ) {
      return getActionUndoneCount() === 0;
    }
    return true;
  };

  const getValidations = (data) => {
    switch (pageGoal.type) {
      case CTA_Button_Types.CALL:
      case CTA_Button_Types.GOOGLEMEET: {
        if (isNullOrUndefined(data.duration) || Number(data.duration) <= 0) {
          return { status: false, message: "Enter the call duration" };
        }
        return { status: true, message: "" };
      }
      case CTA_Button_Types.WHATSAPP: {
        if (isNullOrUndefined(data.whatsappNum)) {
          return { status: false, message: "Enter your phone number" };
        }
        return { status: true, message: "" };
      }
      case CTA_Button_Types.FORM: {
        if (isNullOrUndefined(data.formId)) {
          return { status: false, message: "Please select a form" };
        }
        return { status: true, message: "" };
      }
      default:
        return { status: true, message: "" };
    }
  };

  useEffect(() => {
    if (isNullOrUndefined(button_data)) {
      setButtonName(pageGoal.name);
      setData({});
    } else {
      setButtonName(button_data.name);
      setData(button_data.data);
    }
  }, []);
  useEffect(() => {
    const undoneCount = getActionUndoneCount();
    if (
      pageGoal.type === CTA_Button_Types.CALL ||
      pageGoal.type === CTA_Button_Types.GOOGLEMEET
    ) {
      if (undoneCount !== 0) {
        setInfo("Complete Your Setup");
      } else {
        setInfo(null);
      }
    }
  }, [isGoogleCalendarSetupDone]);
  return (
    <AddFlex
      flexDirection="column"
      style={{ paddingBottom: "20px" }}
      width={"100%"}
      height="100%"
    >
      <AddFlex
        padding="18px 20px"
        alignItems="center"
        justify="center"
        style={{ borderBottom: "1px solid #ececec" }}
      >
        <AddFlex alignItems="center">
          <img
            src={pageGoal.image}
            alt={pageGoal.name + "growezy"}
            style={{ width: "22px", height: "22px" }}
          />
          <CustomText
            color="black"
            margin="0 10px"
            fontWeight="600"
            fontSize="18px"
          >
            CTA : {pageGoal.name}
          </CustomText>
        </AddFlex>
        <CancelOutlined
          onClick={() => iconClose()}
          sx={{
            position: "absolute",
            right: "20px",
            fontSize: "22px",
            cursor: "pointer",
          }}
        />
      </AddFlex>
      {info?.length > 0 && (
        <AddFlex
          padding="20px"
          justify="center"
          backgroundColor={primaryActionColorLight}
        >
          <CustomText color="black" fontSize="14px">
            {info}
          </CustomText>
        </AddFlex>
      )}
      <AddFlex flexDirection="column" grow={1} padding="20px 32px">
        {showCTAName(pageGoal.type) && (
          <CustomInput
            label="Customise your button name"
            value={buttonName}
            borderColor={buttonNameErr !== null && "red"}
            margin={"0 0 8px 0"}
            footerText={
              "This name will appear on the button while a customer is booking."
            }
            onChange={(text) => {
              if (text.length > 20) return;
              setButtonName(text);
              setButtonNameErr(null);
            }}
          ></CustomInput>
        )}
        {getFormBody(pageGoal.type)}
      </AddFlex>
      <div
        style={{
          padding: "10px",
          backgroundColor: "white",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          borderTop: "1px solid #ececec",
        }}
      >
        <PrimaryActionButton
          style={{
            margin: "0px auto",
            padding: "8px 44px",
          }}
        >
          <CustomText
            color="white"
            onClick={() => {
              if (buttonName.trim().length === 0) {
                setButtonNameErr(true);
              }
              const { status, message } = getValidations(data);
              if (!status) {
                enqueueSnackbar(message, {
                  variant: "error",
                });
                return;
              }
              closeDialog(data, buttonName);
              setData({});
            }}
            fontSize="14px"
          >
            Add This CTA button
          </CustomText>
        </PrimaryActionButton>
      </div>
    </AddFlex>
  );
}

export default PageGoalsCustomiseDialog;
