import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInputArea,
  Group,
  InputLabel,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import OfferingPageSectionHeader from "./OfferingPageSectionHeader";
import { DataBox } from "./RevenueChips";
import { CircularProgress, Dialog } from "@mui/material";
import ImageHolderComponent from "../CreateOffering/ImageHolder";
import { callNonStoreApi } from "../NonStoreApis";
import { enqueueSnackbar } from "notistack";
import { RequestTestimonials } from "../Api/ApiEndPoints";
import WhatsappPreview from "./WhatsappPreview";
import { FeaturesKeys, getFrontendBaseURL } from "../utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { TESTIMONIALS_ROUTE } from "../OnboardingPage/routeNames";

function OfferingSummaryTestimonialsSection({ offering }) {
  const [testimonialData, setTestimonialData] = useState({});
  const navigate = useNavigate();
  const { apiUserData } = useSelector((state) => state.user);
  const { features } = useSelector((state) => state.userFeatures.userFeatures);

  const [openTesttimonialsDialog, setOpenTestimonialsDialog] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleUpdateTestimonialData = (key, value) => {
    setTestimonialData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const getTotalTestimonials = () => {
    let total = 0;
    features[FeaturesKeys.testimonial.id]?.items?.forEach((item) => {
      if (offering.id === item.offering?.id) {
        total += 1;
      }
    });
    return total;
  };
  const handleRequestTestimonialApi = async () => {
    try {
      setLoading(true);
      const response = await callNonStoreApi(
        RequestTestimonials,
        testimonialData
      );
      enqueueSnackbar("Testimonial Request Link Sent", {
        variant: "success",
        hideIconVariant: true,
      });
      setOpenTestimonialsDialog((prev) => !prev);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong, please try again", {
        variant: "error",
        hideIconVariant: true,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const testimonialLink = `${getFrontendBaseURL(
      apiUserData.userName
    )}/testimonial`;
    setTestimonialData({ link: testimonialLink });
  }, []);
  return (
    <div>
      <OfferingPageSectionHeader headerText={"Testimonials"} />
      <Group marginTop="20px" flexDirection="column">
        <DataBox
          boxTitle={"Total Testimonials"}
          text={getTotalTestimonials()}
          width={"40%"}
        />
        <AddFlex gap="10px">
          <SecondaryActionButton
            margin="20px 0 0 0"
            onClick={() => navigate(TESTIMONIALS_ROUTE)}
          >
            View Tetsimonials
          </SecondaryActionButton>
          <PrimaryActionButton
            margin="20px 0 0 0"
            onClick={() => setOpenTestimonialsDialog((prev) => !prev)}
          >
            Request Tetsimonials
          </PrimaryActionButton>
        </AddFlex>
      </Group>
      <Dialog
        open={openTesttimonialsDialog}
        onClose={() => setOpenTestimonialsDialog((prev) => !prev)}
        PaperProps={{
          sx: {
            width: "60vw",
            height: "80vh",
            maxWidth: "unset",
          },
        }}
      >
        <AddFlex height="100%">
          <AddFlex width="50%">
            <WhatsappPreview testimonialData={testimonialData} />
          </AddFlex>
          <AddFlex
            grow="1"
            padding="10px 20px"
            gap="20px"
            flexDirection="column"
          >
            <div>
              <InputLabel>Write text for testimonials</InputLabel>
              <CustomInputArea
                style={{ marginTop: "12px" }}
                placeholder="Enter text here"
                onChange={(e) =>
                  handleUpdateTestimonialData("text", e.target.value)
                }
              />
            </div>
            <ImageHolderComponent
              isMultiple={false}
              headerTitleText={"Upload Image for your request message"}
              headerSubTitleText={
                "An image always helps users to give testimonials"
              }
              files={imageFile}
              handleGetFiles={(isMultiple, imgUrl) => {
                setImageFile(imgUrl);
                handleUpdateTestimonialData("imgUrl", imgUrl[0].src);
              }}
            />
            <div style={{ flexGrow: "1" }}></div>
            <PrimaryActionButton onClick={handleRequestTestimonialApi}>
              {loading ? (
                <CircularProgress size={14} sx={{ color: "white" }} />
              ) : (
                "Request Testimonial"
              )}
            </PrimaryActionButton>
          </AddFlex>
        </AddFlex>
      </Dialog>
    </div>
  );
}

export default OfferingSummaryTestimonialsSection;
